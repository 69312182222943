import React, { useContext, useEffect, useState } from "react";
import { Order } from "../../../context/Order";
import client from "../../../config/apolloClient";
import { SERVICE_IMAGE_QUERY } from "../../../graphql/queries/ServiceImageQuery";
import { formatCurrency, formatDate } from "../../../utils/helpers";

function PFOrderOverview() {
  const { getOrderDetails, orderDetails } = useContext(Order);

  const [sImageUrl, setSImageUrl] = useState("");

  const packageUrl = orderDetails?.packageUrl;

  const getServiceImage = async () => {
    const { data } = await client.query({
      query: SERVICE_IMAGE_QUERY,
      variables: {
        url: packageUrl,
      },
    });
    setSImageUrl(data?.package?.data?.attributes?.image?.data?.attributes?.url);
  };

  useEffect(() => {
    getOrderDetails();
  }, []);

  useEffect(() => {
    if (packageUrl) {
      getServiceImage();
    }
  }, [packageUrl]);

  return (
    <>
      <div className="col-lg-4 col-md-5">
        <div className="page-card rounded-3 position-relative p-3 p-md-4 mt-md-0 mt-4">
          <div className="card-body">
            <div className="card border-0">
              <img
                className="card-img-top"
                src={sImageUrl}
                alt={orderDetails?.service}
              />
              <div className="card-body px-0 py-4 pb-0">
                <h4 className="h5 fw-semibold card-title mb-3">
                  {orderDetails?.service}
                </h4>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item px-0 d-flex justify-content-between align-items-center">
                    <span className="card-service-label">Order Number</span>
                    <span className="card-service-value">
                      {orderDetails?.orderNumber}
                    </span>
                  </li>
                  <li className="list-group-item px-0 d-flex justify-content-between align-items-center">
                    <span className="card-service-label">Order Date</span>
                    <span className="card-service-value">
                      {formatDate(orderDetails?.createdAt)}
                    </span>
                  </li>
                  <li className="list-group-item px-0 d-flex justify-content-between align-items-center">
                    <span className="card-service-label">Order Completed</span>
                    <span className="card-service-value">
                      {formatDate(
                        orderDetails?.dateCompleted
                          ? orderDetails?.dateCompleted
                          : orderDetails?.autoComplete
                      )}
                    </span>
                  </li>
                  <li className="list-group-item px-0 d-flex justify-content-between align-items-center">
                    <span className="card-service-label">Quantity</span>
                    <span className="card-service-value">
                      {" "}
                      X {orderDetails?.quantity}
                    </span>
                  </li>
                  <li className="list-group-item px-0 d-flex justify-content-between align-items-center">
                    <span className="card-service-label">Total Price</span>
                    <span className="card-service-value">
                      {formatCurrency(orderDetails?.totalPaidAmount)}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            {/*.card-body*/}
          </div>
          {/*.page-card*/}
        </div>
        {/*.col-grid*/}
      </div>
    </>
  );
}

export default PFOrderOverview;
