import React, { useContext, useEffect } from "react";
import { OrderCartDetail } from "../../../context/OrderCart";
import { formatCurrency } from "../../../utils/helpers";

function TableMainH() {
  const { overdueCartData, getOverdueCart } = useContext(OrderCartDetail);

  useEffect(() => {
    getOverdueCart();
  }, []);

  return (
    <>
      <div className="table-responsive mb-3">
        <table className="table emp-attend-list mb-0">
          <thead>
            <tr>
              <th scope="col">Service</th>
              <th scope="col">Quantity</th>
              <th scope="col">Unit Price</th>
              <th scope="col">Net Subtotal</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {overdueCartData?.service} ({overdueCartData?.package})
              </td>
              <td>{overdueCartData?.quantity}</td>
              <td>{formatCurrency(overdueCartData?.price)}</td>
              <td>
                {formatCurrency(
                  overdueCartData?.quantity * overdueCartData?.price
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

export default TableMainH;
