import React, { useContext } from "react";
import { AuthContext } from "../../../context/Auth";
import countries from "../../../utils/countries.json";
import Select from "react-select";

function ClientBillingInfo() {
  const {
    userInfo,
    updateUser,
    updateUserInfo,
    setUpdateUserInfo,
    loading,
    cancelUpdate,
  } = useContext(AuthContext);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setUpdateUserInfo({ ...updateUserInfo, [id]: value });
    userInfo[id] = value;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedUserInfo = {
      ...updateUserInfo,
      password: "",
    };
    updateUser(updatedUserInfo);
  };

  const selectCountry = (sc) => {
    setUpdateUserInfo({ ...updateUserInfo, country: sc.value });
  };

  return (
    <>
      <div className="row position-relative">
        <div className="col-12">
          <h4 className="h5 fw-semibold mb-3 mb-md-4 pe-5">
            Billing information (Displayed on Invoice)
          </h4>
          {/* <a
            href="#"
            className="edit-icon text-dark position-absolute top-0 end-0 translate-middle p-2 d-inline-flex rounded-pill mt-2"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={12}
              height={12}
              fill="currentColor"
              className="bi bi-pencil"
              viewBox="0 0 16 16"
            >
              <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
            </svg>
          </a> */}
        </div>
        <div className="col-12">
          <div className="row g-md-4 g-3">
            <div className="col-sm-6">
              <label htmlFor="firstName" className="form-label fw-semibold">
                First name
              </label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                placeholder
                defaultValue
                required
                onChange={handleChange}
                value={userInfo?.firstName ? userInfo?.firstName : ""}
              />
            </div>
            <div className="col-sm-6">
              <label htmlFor="lastName" className="form-label fw-semibold">
                Last name
              </label>
              <input
                type="text"
                className="form-control"
                id="lastName"
                placeholder
                defaultValue
                required
                onChange={handleChange}
                value={userInfo?.lastName ? userInfo?.lastName : ""}
              />
            </div>
            <div className="col-sm-6">
              <label htmlFor="address" className="form-label fw-semibold">
                Address
              </label>
              <input
                type="text"
                className="form-control"
                id="address"
                placeholder="1234 Main St"
                required
                onChange={handleChange}
                value={userInfo?.address ? userInfo?.address : ""}
              />
            </div>
            <div className="col-sm-6">
              <label htmlFor="address2" className="form-label fw-semibold">
                Address 2{" "}
                <span className="text-muted fw-light small">(Optional)</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="address2"
                placeholder="Apartment, Building Name or Number"
                onChange={handleChange}
                value={userInfo?.address2 ? userInfo?.address2 : ""}
              />
            </div>
            <div className="col-sm-6">
              <label htmlFor="company" className="form-label fw-semibold">
                Company
              </label>
              <input
                type="text"
                className="form-control"
                id="company"
                placeholder
                defaultValue
                required
                onChange={handleChange}
                value={userInfo?.company ? userInfo?.company : ""}
              />
            </div>
            <div className="col-sm-6">
              <label htmlFor="country" className="form-label fw-semibold">
                Country
              </label>
              <Select
                options={countries.map((country) => ({
                  label: country.name,
                  value: country.name,
                }))}
                onChange={selectCountry}
                isSearchable
                placeholder={
                  userInfo?.country ? userInfo?.country : "Select your country"
                }
              />
            </div>
            <div className="col-sm-6">
              <label htmlFor="state" className="form-label fw-semibold">
                State
              </label>
              <input
                type="text"
                className="form-control"
                id="state"
                placeholder
                defaultValue
                required
                onChange={handleChange}
                value={userInfo?.state ? userInfo?.state : ""}
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="zipCode" className="form-label fw-semibold">
                Postcode
              </label>
              <input
                type="text"
                className="form-control"
                id="zipCode"
                placeholder
                required
                onChange={handleChange}
                value={userInfo?.zipCode ? userInfo?.zipCode : ""}
              />
            </div>
            <div></div>
            {/* <div className="col-12 mt-3 mb-4"> */}
            {/* <div className="form-check my-1">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="same-address"
                />
                <label
                  className="form-check-label small text-secondary"
                  htmlFor="same-address"
                >
                  Shipping address is the same as my billing address
                </label>
              </div>
              <div className="form-check my-1">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="save-info"
                />
                <label
                  className="form-check-label small text-secondary"
                  htmlFor="save-info"
                >
                  Save this information for next time
                </label>
              </div> */}
            {/* </div> */}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="btn-wrapper mt-2">
            <a
              href="#"
              className="fs-6 btn btn-light me-2 px-4 py-2 text-decoration-none"
              onClick={cancelUpdate}
            >
              Cancel
            </a>
            <button
              className="btn primary-btn fw-semibold fs-6 px-4 py-2"
              onClick={handleSubmit}
            >
              {loading && (
                <span className="spinner-grow spinner-grow-sm me-2"></span>
              )}
              {!loading ? "Update" : "Updating..."}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClientBillingInfo;
