import { useContext, useEffect, useState } from "react";
import { LoadingContext } from "../context/LoadingLogo";
import axios from "axios";
import { GET_ALL_ORDER_API_URL } from "../config/endPoints";

function useAllOrders() {
  const { setLogoLoading } = useContext(LoadingContext);

  const [allOrders, setAllOrders] = useState([]);

  const getAllOrders = async () => {
    try {
      setLogoLoading(true);
      const res = await axios.get(GET_ALL_ORDER_API_URL);
      setAllOrders(res?.data?.orders);
      setLogoLoading(false);
    } catch (err) {
      console.error(err);
      setLogoLoading(false);
    }
  };
  useEffect(() => {
    getAllOrders();
  }, []);
  return allOrders;
}

export default useAllOrders;
