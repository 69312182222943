import React, { useContext } from "react";
import { InvoiceCtx } from "../../../context/Invoice";
import { formatDateString } from "../../../utils/helpers";

function CriBillingInfo() {
  const { criNumber, crInvoice } = useContext(InvoiceCtx);

  return (
    <>
      <div className="card-header">
        {/* <div className="row">
          <div className="col-12">
            <div className="date-invocie text-end mb-4">
              <h3 className="h5">22 April 2018</h3>
            </div>
          </div>
        </div> */}
        {/*.row*/}
        <div className="row invoice-info">
          <div className="col-md-6 invoice-col">
            {/* <strong className="small mb-2 d-block">From</strong> */}
            <address>
              <strong className="h4 fw-semibold d-block mb-2">AAMAX</strong>
              <span className="small text-secondary">
                2nd Floor College House, 17 King Edwards Road, Ruislip, United
                Kingdom, HA4 7AE
              </span>
              <br />
              <strong className="small">Phone: </strong>+92-308-4281241{" "}
              <strong className="small">Email: </strong>
              info@aamax.co
            </address>
          </div>
          {/*.col-grid*/}
          <div className="col-md-6 text-md-end">
            {/* <strong className="small mb-2 d-block">To</strong> */}
            <address>
              <strong className="h4 fw-semibold d-block mb-2">
                {`${crInvoice?.user?.firstName} ${crInvoice?.user?.lastName}`}
              </strong>
              <span className="small text-secondary">
                {crInvoice?.user?.company && `${crInvoice?.user?.company}, `}
                {crInvoice?.user?.address && `${crInvoice?.user?.address}, `}
                {crInvoice?.user?.state && `${crInvoice?.user?.state}, `}
                {crInvoice?.user?.zipCode && `${crInvoice?.user?.zipCode}, `}
                {crInvoice?.user?.country && `${crInvoice?.user?.country}`}
              </span>
              <br />
              {crInvoice?.user?.phone && (
                <>
                  <strong className="small">Phone: </strong>
                  <span>{crInvoice?.user?.phone}</span>
                </>
              )}{" "}
              {crInvoice?.user?.email && (
                <>
                  <strong className="small">Email: </strong>
                  <span>{crInvoice?.user?.email}</span>
                </>
              )}
            </address>
          </div>
          {/*.col-grid*/}
          <div className="col-12">
            <div className="invoice-details bg-light border px-3 py-2 mb-3 mt-3">
              <div className="row">
                <div className="col-md-6 col-lg-3">
                  <span className="small">
                    <b>Invoice Number: </b>
                    {criNumber}
                  </span>
                </div>
                <div className="col-md-6 col-lg-3">
                  <span className="small">
                    <b>Invoice Type:</b> Credit
                  </span>
                </div>
                <div className="col-md-6 col-lg-3">
                  <span className="small">
                    <b>Paid On:</b> {formatDateString(crInvoice?.createdAt)}
                  </span>
                </div>
                <div className="col-md-6 col-lg-3">
                  <span className="small">
                    <b>Paid Via:</b> {crInvoice?.paymentMethod}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/*.col-grid*/}
        </div>
        {/*.row*/}
      </div>
    </>
  );
}

export default CriBillingInfo;
