import React from "react";
import { Link } from "react-router-dom";

function NoInvoices() {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-card rounded-3 position-relative p-3 p-md-4 text-center d-flex align-items-center justify-content-center">
            <div className="card-body">
              <h4 className="my-1 fw-bold mb-4 pb-2">
                Your invoices will appear here
              </h4>
              <Link to="/services">
                <button
                  type="submit"
                  className="btn primary-btn fw-semibold fs-6 px-4 py-2"
                >
                  Explore Services
                </button>
              </Link>
            </div>
            {/*.card-body*/}
          </div>
          {/*.page-card*/}
        </div>
        {/*.col-grid*/}
      </div>
      {/*.row*/}
    </>
  );
}

export default NoInvoices;
