import React, { useContext, useEffect } from "react";
import { Order } from "../../../context/Order";
import { formatDateString, getStatusColor } from "../../../utils/helpers";
import { Link } from "react-router-dom";
import { SearchCtx } from "../../../context/SearchContext";
import Pagination from "../../misc/Pagination";
import { AuthContext } from "../../../context/Auth";
import { InvoiceCtx } from "../../../context/Invoice";

function CompletedOrdersDetail() {
  const { setSOrderId, cOrders, getCompletedOrders, paginationData } =
    useContext(Order);
  const { searchOrder } = useContext(SearchCtx);
  const { userInfo } = useContext(AuthContext);
  const { setInvoiceNumber } = useContext(InvoiceCtx);

  const sortedOrders = cOrders?.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  const filteredOrders = sortedOrders?.filter(
    (order) =>
      order.service.toLowerCase().includes(searchOrder.toLowerCase()) ||
      order.orderNumber.toLowerCase().includes(searchOrder.toLowerCase())
  );

  const sOrderIdHandler = (oid) => {
    setSOrderId(oid);
    localStorage.setItem("sOrderId", oid);
  };

  const sInvoiceIdHandler = (iid) => {
    setInvoiceNumber(iid);
    localStorage.setItem("sInvoiceId", iid);
  };

  useEffect(() => {
    getCompletedOrders();
  }, [paginationData.currentPage]);

  return (
    <>
      <div className="card-body mt-4">
        <div className="table-responsive">
          <table className="table table-hover emp-attend-list">
            <thead>
              <tr>
                <th scope="col">Order number</th>
                <th scope="col">Service</th>
                <th scope="col">Date Ordered</th>
                <th scope="col">Date Completed</th>
                <th scope="col">Assigned Team</th>
                <th scope="col">Status</th>
                {(userInfo?.userType === "admin" ||
                  userInfo?.userType === "client" ||
                  userInfo?.userType === "clientTm" ||
                  userInfo?.userType === "viewer") && (
                  <th scope="col">Invoice</th>
                )}
              </tr>
            </thead>
            <tbody>
              {filteredOrders?.map((order, i) => (
                <tr key={i}>
                  <td>{order?.orderNumber}</td>
                  <td>
                    {
                      <Link
                        to={
                          order?.status === "Incomplete"
                            ? "/submit-requirements"
                            : "/order-details"
                        }
                        style={{ textDecoration: "none", color: "#212529" }}
                        onClick={() => sOrderIdHandler(order._id)}
                      >{`${order?.service} (${order?.package})`}</Link>
                    }
                  </td>
                  <td>{formatDateString(order?.createdAt)}</td>
                  <td>{formatDateString(order?.dateCompleted)}</td>
                  <td>
                    {order?.teamWorking?.length > 0 ? (
                      order?.teamWorking?.map((t, i) => (
                        <span key={i}>
                          {t?.firstName}
                          {i !== order?.teamWorking?.length - 1 ? ", " : ""}
                        </span>
                      ))
                    ) : (
                      <span>No Team Assigned Yet</span>
                    )}
                  </td>
                  <td>
                    <span
                      className={`d-inline-block badge ${getStatusColor(
                        order?.status
                      )}`}
                    >
                      {order?.status === "Incomplete" ? (
                        <Link
                          to="/submit-requirements"
                          style={{ textDecoration: "none", color: "white" }}
                          onClick={() => sOrderIdHandler(order._id)}
                        >
                          Submit Requirements
                        </Link>
                      ) : (
                        order?.status
                      )}
                    </span>
                  </td>

                  {(userInfo?.userType === "admin" ||
                    userInfo?.userType === "client" ||
                    userInfo?.userType === "clientTm" ||
                    userInfo?.userType === "viewer") && (
                    <td>
                      <Link
                        to="/paid-invoice"
                        className="preview-action small text-decoration-none fw-semibold d-flex align-items-center"
                        onClick={() => sInvoiceIdHandler(order?.invoiceNumber)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          fill="currentColor"
                          className="bi bi-eye me-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                          <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                        </svg>
                        View
                      </Link>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination />
        {/*.table-responsive*/}
      </div>
    </>
  );
}

export default CompletedOrdersDetail;
