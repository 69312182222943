import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function OrderDetailsSkeleton() {
  return (
    <>
      <div className="row">
        <div className="col-lg-8 col-md-12">
          <div className="page-card rounded-3 position-relative p-3 p-md-4">
            <div className="card-body">
              <div className="tabs-wrapper">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="activity-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#activity-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="activity-tab-pane"
                      //   aria-selected="true"
                    >
                      <Skeleton width={91} height={39} />
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="activity-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#activity-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="activity-tab-pane"
                      //   aria-selected="true"
                    >
                      <Skeleton width={91} height={39} />
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="activity-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#activity-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="activity-tab-pane"
                      //   aria-selected="true"
                    >
                      <Skeleton width={91} height={39} />
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="activity-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#activity-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="activity-tab-pane"
                      //   aria-selected="true"
                    >
                      <Skeleton width={91} height={39} />
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="activity-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#activity-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="activity-tab-pane"
                      //   aria-selected="true"
                    >
                      <Skeleton width={91} height={39} />
                    </button>
                  </li>
                </ul>
                <ul className="list-group border-0 rounded-0">
                  <li className="list-group-item px-0 py-3 d-flex flex-wrap align-items-center border-0 border-bottom">
                    <h4 className="h6 m-0 d-inline-flex align-items-center">
                      {/* You Place Order */}
                      <Skeleton width={161} height={30} />
                    </h4>
                    <span className="small d-inline-flex text-secondary ms-4 ps-2">
                      <Skeleton width={118} height={21} />
                    </span>
                  </li>
                  <li className="list-group-item px-0 py-3 d-flex flex-wrap align-items-center border-0 border-bottom">
                    <h4 className="h6 m-0 d-inline-flex align-items-center">
                      <Skeleton width={288} height={30} />
                    </h4>
                    <span className="small d-inline-flex text-secondary ms-4 ps-2">
                      <Skeleton width={118} height={21} />
                    </span>
                  </li>
                  <li className="list-group-item px-0 py-3 d-flex flex-wrap align-items-center border-0 border-bottom">
                    <h4 className="h6 m-0 d-inline-flex align-items-center">
                      <Skeleton width={178} height={30} />
                    </h4>
                    <span className="small d-inline-flex text-secondary ms-4 ps-2">
                      <Skeleton width={118} height={21} />
                    </span>
                  </li>
                  <li className="list-group-item px-0 py-3 d-flex flex-wrap align-items-center border-0 border-bottom">
                    <h4 className="h6 m-0 d-inline-flex align-items-center">
                      <Skeleton width={366} height={30} />
                    </h4>
                    <span className="small d-inline-flex text-secondary ms-4 ps-2">
                      <Skeleton width={118} height={21} />
                    </span>
                  </li>
                  <li className="list-group-item px-0 py-3 d-flex flex-wrap align-items-center border-0 border-bottom">
                    <h4 className="h6 m-0 d-inline-flex align-items-center">
                      <Skeleton width={180} height={30} />
                    </h4>
                    <span className="small d-inline-flex text-secondary ms-4 ps-2">
                      <Skeleton width={118} height={21} />
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-12">
          <div className="page-card rounded-3 position-relative p-3 p-md-4 mt-lg-0 mt-4">
            <div className="card-body">
              <div className="card border-0">
                <div className="d-flex align-items-center">
                  {/* <img
                    className="rounded-pill me-3"
                    src="./assets/images/service-detail-img.jpg"
                    alt="img user-img"
                    width={60}
                    height={60}
                  /> */}
                  <Skeleton
                    className="rounded-pill me-3"
                    width={60}
                    height={60}
                  />
                  <div>
                    <h4 className="h6 fw-semibold card-title mb-2">
                      <Skeleton width={180} height={19.2} />
                    </h4>
                    <span className="badge small text-uppercase">
                      <Skeleton width={95} height={20} />
                    </span>
                  </div>
                </div>
                <div className="card-body px-0 py-4 pb-0">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item px-0 d-flex justify-content-between align-items-center">
                      <span className="card-service-label">
                        <Skeleton width={108} height={24} />
                      </span>
                      <span className="card-service-value">
                        <Skeleton width={73} height={24} />
                      </span>
                    </li>
                    <li className="list-group-item px-0 d-flex justify-content-between align-items-center">
                      <span className="card-service-label">
                        <Skeleton width={83} height={24} />
                      </span>
                      <span className="card-service-value">
                        <Skeleton width={100} height={24} />
                      </span>
                    </li>
                    <li className="list-group-item px-0 d-flex justify-content-between align-items-center">
                      <span className="card-service-label">
                        <Skeleton width={101} height={24} />
                      </span>
                      <span className="card-service-value">
                        <Skeleton width={90} height={24} />
                      </span>
                    </li>
                    <li className="list-group-item px-0 d-flex justify-content-between align-items-center">
                      <span className="card-service-label">
                        <Skeleton width={65} height={24} />
                      </span>
                      <span className="card-service-value">
                        <Skeleton width={25} height={24} />
                      </span>
                    </li>
                    <li className="list-group-item px-0 d-flex justify-content-between align-items-center">
                      <span className="card-service-label">
                        <Skeleton width={80} height={24} />
                      </span>
                      <span className="card-service-value">
                        <Skeleton width={41} height={24} />
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              {/*.card*/}
            </div>
            {/*.card-body*/}
          </div>
          {/*.page-card*/}
        </div>
        {/*.col-grid*/}
      </div>
    </>
  );
}

export default OrderDetailsSkeleton;
