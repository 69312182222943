import React, { useEffect } from "react";
import ClientHeader from "../../components/layout/client/ClientHeader";
import Footer from "../../components/layout/Footer";
import CharityDescContent from "../../components/client/charity/CharityDescContent";
import CharityDetailSec from "../../components/client/charity/CharityDetailSec";
import { Helmet } from "react-helmet";

function Charity() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Charity – AAMAX</title>
      </Helmet>
      {/*=============== Start AAMAX OMS  ===============*/}

      <ClientHeader>
        {/*.header*/}
        <main className="page-content d-flex flex-column justify-content-between h-100">
          <div className="container-fluid p-3 p-md-4">
            {/* <CharityMainHeading /> */}
            {/*.row*/}
            <div className="row">
              <div className="col-12">
                <div className="page-card rounded-3 position-relative p-3 p-md-4">
                  <CharityDescContent />
                  <CharityDetailSec />
                  {/*.card-body*/}
                </div>
                {/*.page-card*/}
              </div>
              {/*.col-grid*/}
            </div>
            {/*.row*/}
          </div>
          {/*.container-fluid*/}
          <Footer />
        </main>
      </ClientHeader>
      {/*.page-content"*/}
      {/*=============== End AAMAX OMS  ===============*/}
    </>
  );
}

export default Charity;
