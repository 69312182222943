import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../../context/Auth";

function NoUsers() {
  const {
    addUser,
    setConfirmPassword,
    loading,
    userInfo,
    addTeamMember,
    setAddTeamMember,
  } = useContext(AuthContext);

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === "confirmPassword") {
      setConfirmPassword(value);
    } else {
      setAddTeamMember({ ...addTeamMember, [id]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addUser(addTeamMember);
  };

  useEffect(() => {
    setAddTeamMember((prevData) => ({ ...prevData, userId: userInfo?._id }));
  }, [userInfo]);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-card rounded-3 position-relative p-3 p-md-4 text-center d-flex align-items-center justify-content-center">
            <div className="card-body">
              <h4 className="my-1 fw-bold mb-4 pb-2">
                Your users will appear here
              </h4>
              <button
                className="btn secondary-btn rounded-pill fs-6 mt-sm-0 mt-2 ms-sm-2 px-3 justify-content-sm-start justify-content-center d-inline-flex align-items-center"
                data-bs-toggle="modal"
                data-bs-target="#inviteUserModal"
              >
                <span className="icon me-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={14}
                    height={14}
                    fill="currentColor"
                    className="bi bi-plus-lg"
                    viewBox="0 0 16 16"
                  >
                    <path
                      strokeWidth=".5"
                      stroke="currentColor"
                      fillRule="evenodd"
                      d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                    />
                  </svg>
                </span>
                Add User
              </button>
              {/* invite-user-modal */}
            </div>
            {/*.card-body*/}
          </div>
          {/*.page-card*/}
        </div>
        <div
          className="modal fade"
          id="inviteUserModal"
          tabIndex={-1}
          aria-labelledby="inviteUserModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header dark-bg px-4">
                <h1
                  className="modal-title fs-5 text-white"
                  id="cardPaymentModalLabel"
                >
                  Add User
                </h1>
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body px-4">
                <div className="add-user-text-info">
                  <ul className="list-unstyled mb-4 pb-md-2">
                    <li className="list-item">
                      <p>
                        <strong className="text-dark">Viewer :</strong> Can only
                        view orders, invoices, messages, and other reports.
                      </p>
                    </li>
                    <li className="list-item">
                      <p>
                        <strong className="text-dark">Editor :</strong> Has full
                        access to your account, can place orders, reply to
                        messages, and more!
                      </p>
                    </li>
                  </ul>
                </div>
                <form className="needs-validation mt-lg-3">
                  <div className="row g-3">
                    <div className="col-sm-6">
                      <label
                        htmlFor="email"
                        className="form-label small fw-semibold"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder
                        value={addTeamMember.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-sm-6">
                      <label
                        htmlFor="country"
                        className="form-label small fw-semibold"
                      >
                        Role
                      </label>
                      <select
                        className="form-select"
                        id="userType"
                        required
                        value={addTeamMember.userType}
                        onChange={handleChange}
                      >
                        <option value="">Please Select</option>
                        <option value="viewer">Viewer</option>
                        <option value="clientTm">Editor</option>
                      </select>
                    </div>
                    <div className="col-sm-6">
                      <label
                        htmlFor="firstName"
                        className="form-label small fw-semibold"
                      >
                        First Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        placeholder
                        value={addTeamMember.firstName}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-sm-6">
                      <label
                        htmlFor="lastName"
                        className="form-label small fw-semibold"
                      >
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        placeholder
                        value={addTeamMember.lastName}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-sm-6">
                      <label
                        htmlFor="password"
                        className="form-label small fw-semibold"
                      >
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        placeholder
                        value={addTeamMember.password}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-sm-6">
                      <label
                        htmlFor="confirmPassword"
                        className="form-label small fw-semibold"
                      >
                        Confirm Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="confirmPassword"
                        placeholder
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {/*.row*/}
                  {/* / Change address form*/}
                  <div className="btn-wrapper text-end mb-3 mt-4 mt-lg-5">
                    <a
                      href="#"
                      className="fs-6 btn btn-light me-2 px-4 py-2 text-decoration-none"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Cancel
                    </a>
                    <button
                      onClick={handleSubmit}
                      disabled={loading}
                      className="btn primary-btn fw-semibold fs-6 px-4 py-2"
                    >
                      {loading && (
                        <span className="spinner-grow spinner-grow-sm me-2"></span>
                      )}
                      {!loading ? "Add User" : "Adding User"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/*.invite-user-modal*/}
        {/*.col-grid*/}
      </div>
      {/*.row*/}
    </>
  );
}

export default NoUsers;
