import React, { useEffect } from "react";
import Footer from "../../components/layout/Footer";
import ClientHeader from "../../components/layout/client/ClientHeader";
import CareersMainHeading from "../../components/client/careers/CareersMainHeading";
import CareersOpenPositions from "../../components/client/careers/CareersOpenPositions";
import { Helmet } from "react-helmet";

function Careers() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Careers – AAMAX</title>
      </Helmet>
      {/*=============== Start AAMAX OMS  ===============*/}

      <ClientHeader>
        {/*.header*/}
        <main
          className="page-content d-flex flex-column justify-content-between h-100"
          id="price-scroll"
        >
          <div className="container-fluid p-3 p-md-4">
            <CareersMainHeading />
            <CareersOpenPositions />
            {/*.row*/}
          </div>
          {/*.container-fluid*/}
          <Footer />
        </main>
      </ClientHeader>
      {/*.page-content"*/}
      {/*=============== End AAMAX OMS  ===============*/}
    </>
  );
}

export default Careers;
