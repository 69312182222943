import { useEffect, useState } from "react";
import client from "../config/apolloClient";
import { SERVICE_QUERY } from "../graphql/queries/ServiceQuery";
import { useParams } from "react-router-dom";

function useService() {
  const params = useParams();

  const [data, setData] = useState({});

  const fetchData = async () => {
    try {
      const { data } = await client.query({
        query: SERVICE_QUERY,
        variables: {
          url: params.url,
        },
      });
      setData(data?.service?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return data;
}

export default useService;
