import { message } from "antd";
import axios from "axios";
import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RESET_PASSWORD_API_URL } from "../../../config/endPoints";
import { AuthContext } from "../../../context/Auth";
import setAuthToken from "../../../utils/setAuthToken";

function UpdatePassword({ token }) {
  const navigate = useNavigate();
  const { fetchUserInfo } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPassword({ ...password, [name]: value });
  };

  const submit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (!password.newPassword || !password.confirmPassword) {
      message.error("Hold on! You forgot to fill in both password fields. ");
      setLoading(false);
      return;
    }

    if (password.newPassword.length < 8) {
      message.error("New password should be at least 8 characters long.");
      setLoading(false);
      return;
    }

    if (password.confirmPassword !== password.newPassword) {
      message.error(
        "Uh-oh! The new password and confirm password do not match."
      );
      setLoading(false);
    } else {
      axios
        .post(RESET_PASSWORD_API_URL, {
          ...password,
          token,
        })
        .then((res) => {
          setLoading(false);
          message.success("Success! Your password has been reset.");
          const token = res.data.token;
          if (token) {
            setAuthToken(token);
            localStorage.setItem("token", token);
            navigate(
              res?.data?.userType === "admin" ? "/total-orders" : "/dashboard"
            );
            fetchUserInfo(token);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <form action="#" className="sign-in-form">
        <div className="form-title text-center mb-5">
          <h3 className="display-6 fw-semibold mb-md-3 mb-2">Reset password</h3>
          <p className="text-muted">Enter your new password below:</p>
        </div>
        <div className="input-group mb-md-5 mb-4 rounded-2 overflow-hidden border">
          <span
            className="input-group-text bg-light text-muted border-0 px-3"
            id="basic-addon1"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={22}
              height={22}
              fill="currentColor"
              class="bi bi-shield-lock"
              viewBox="0 0 16 16"
            >
              <path d="M5.338 1.59a61 61 0 0 0-2.837.856.48.48 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.7 10.7 0 0 0 2.287 2.233c.346.244.652.42.893.533q.18.085.293.118a1 1 0 0 0 .101.025 1 1 0 0 0 .1-.025q.114-.034.294-.118c.24-.113.547-.29.893-.533a10.7 10.7 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.8 11.8 0 0 1-2.517 2.453 7 7 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7 7 0 0 1-1.048-.625 11.8 11.8 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 63 63 0 0 1 5.072.56" />
              <path d="M9.5 6.5a1.5 1.5 0 0 1-1 1.415l.385 1.99a.5.5 0 0 1-.491.595h-.788a.5.5 0 0 1-.49-.595l.384-1.99a1.5 1.5 0 1 1 2-1.415" />
            </svg>
          </span>

          <input
            id="newpassword"
            name="newPassword"
            type="password"
            onChange={handleChange}
            className="form-control bg-white border-0 px-3"
            placeholder="Password"
            autofocus
          />
        </div>
        <div className="input-group mb-md-5 mb-4 rounded-2 overflow-hidden border">
          <span
            className="input-group-text bg-light text-muted border-0 px-3"
            id="basic-addon1"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={22}
              height={22}
              fill="currentColor"
              class="bi bi-shield-lock"
              viewBox="0 0 16 16"
            >
              <path d="M5.338 1.59a61 61 0 0 0-2.837.856.48.48 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.7 10.7 0 0 0 2.287 2.233c.346.244.652.42.893.533q.18.085.293.118a1 1 0 0 0 .101.025 1 1 0 0 0 .1-.025q.114-.034.294-.118c.24-.113.547-.29.893-.533a10.7 10.7 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.8 11.8 0 0 1-2.517 2.453 7 7 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7 7 0 0 1-1.048-.625 11.8 11.8 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 63 63 0 0 1 5.072.56" />
              <path d="M9.5 6.5a1.5 1.5 0 0 1-1 1.415l.385 1.99a.5.5 0 0 1-.491.595h-.788a.5.5 0 0 1-.49-.595l.384-1.99a1.5 1.5 0 1 1 2-1.415" />
            </svg>
          </span>
          <input
            id="confirmpassword"
            name="confirmPassword"
            type="password"
            onChange={handleChange}
            className="form-control bg-white border-0 px-3"
            placeholder="Confirm Password"
            autofocus
          />
        </div>
        <button
          type="submit"
          className="btn primary-btn shadow w-100 mb-2"
          onClick={submit}
        >
          {loading && (
            <span className="spinner-grow spinner-grow-sm me-2"></span>
          )}
          {!loading ? "Update password" : "Updating password"}
        </button>
        <p className="mt-4 text-center text-muted">
          Back to{" "}
          <Link
            to="/"
            className="text-decoration-none text-decoration-underline create-account-action"
          >
            Sign in
          </Link>
        </p>
      </form>
    </>
  );
}

export default UpdatePassword;
