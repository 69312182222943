import React from "react";

function ReviewsPagination() {
  return (
    <>
      <div className="card-footer p-3 p-md-4 d-flex justify-content-end">
        <ul className="pagination mb-0">
          <li className="page-item mx-1">
            <a className="page-link" href="#" aria-label="Previous">
              <span aria-hidden="true">
                <i className="bi bi-chevron-left" />
              </span>
            </a>
          </li>
          <li className="page-item mx-1">
            <a className="page-link active" href="#">
              1
            </a>
          </li>
          <li className="page-item mx-1">
            <a className="page-link" href="#">
              2
            </a>
          </li>
          <li className="page-item mx-1">
            <a className="page-link" href="#">
              3
            </a>
          </li>
          <li className="page-item mx-1">
            <a className="page-link" href="#" aria-label="Next">
              <span aria-hidden="true">
                <i className="bi bi-chevron-right" />
              </span>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}

export default ReviewsPagination;
