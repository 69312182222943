import React, { useContext } from "react";
import { InvoiceCtx } from "../../../context/Invoice";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../context/Auth";
import { OrderCartDetail } from "../../../context/OrderCart";
import { formatCurrency } from "../../../utils/helpers";

function OrderNInvoiceDetail() {
  const { unpaidInv } = useContext(InvoiceCtx);
  const { userInfo } = useContext(AuthContext);
  const { setOverdueCid } = useContext(OrderCartDetail);

  const setOverdueId = (id) => {
    setOverdueCid(id);
    localStorage.setItem("overdueCid", id);
  };

  return (
    <>
      <div className="card-body">
        <div className="row">
          <div className="col-12">
            <div className="table-responsive mb-3">
              <table className="table emp-attend-list mb-0">
                <thead>
                  <tr>
                    <th scope="col">Service</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Unit Price</th>
                    <th scope="col">Net Subtotal</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {unpaidInv?.service} ({unpaidInv?.package})
                    </td>
                    <td>{unpaidInv?.quantity}</td>
                    <td>{formatCurrency(unpaidInv?.price)}</td>
                    <td>
                      {formatCurrency(unpaidInv?.quantity * unpaidInv?.price)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/*.col-grid*/}
        </div>
        {/*.row*/}
        <div className="row">
          <div className="col-12 text-end">
            <div>
              <p className="text-dark">
                Sub Total :{" "}
                {formatCurrency(unpaidInv?.quantity * unpaidInv?.price)}
              </p>
              <p className="text-dark">% VAT : $0.00</p>
              <p className="text-dark">Express Delivery : $0.00</p>
              <p className="text-dark">Bank Transfer Discount : -$0.00</p>
            </div>
            <div className="total-payment border-top pt-3">
              <h3 className="h5">
                <b>Total :</b>{" "}
                {formatCurrency(unpaidInv?.quantity * unpaidInv?.price)}
              </h3>
            </div>
          </div>
          {/*.col-grid*/}
        </div>
        {/*.row*/}
        {(userInfo?.userType === "admin" ||
          userInfo?.userType === "client" ||
          userInfo?.userType === "clientTm") && (
          <div className="row">
            <div className="col-12 text-end">
              <Link
                to="/make-overdue-payment"
                type="button"
                className="btn oms-btn fw-semibold mt-3 px-3 d-inline-flex align-items-center"
                onClick={() => setOverdueId(unpaidInv?._id)}
              >
                <span className="icon d-inline-flex me-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-credit-card"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z" />
                    <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z" />
                  </svg>
                </span>{" "}
                Submit Payment
              </Link>
            </div>
            {/*.col-grid*/}
          </div>
        )}
        {/*.row*/}
      </div>
    </>
  );
}

export default OrderNInvoiceDetail;
