import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import {
  GET_CREDIT_INVOICE_API_URL,
  GET_INVOICE_API_URL,
  GET_INVOICES_API_URL,
  GET_UNPAID_INVOICE_API_URL,
} from "../config/endPoints";
import { LoadingContext } from "./LoadingLogo";
import { message } from "antd";

const InvoiceCtx = createContext();

const InvoiceContext = ({ children }) => {
  const { setLogoLoading } = useContext(LoadingContext);

  const [invoice, setInvoice] = useState({});
  const [unpaidInv, setUnpaidInv] = useState({});

  const [invoices, setInvoices] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState(
    localStorage.getItem("sInvoiceId") || ""
  );

  const [crInvoice, setCrInvoice] = useState({});
  const [criNumber, setCriNumber] = useState(
    localStorage.getItem("sCriId") || ""
  );

  const [unpaidInvNo, setUnpaidInvNo] = useState(
    localStorage.getItem("sUnpaidInvId") || ""
  );

  const getInvoice = async () => {
    try {
      if (invoiceNumber) {
        const res = await axios.get(`${GET_INVOICE_API_URL}/${invoiceNumber}`);
        if (res?.data) {
          setInvoice(res.data);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getCrInvoice = async () => {
    try {
      if (criNumber) {
        const res = await axios.get(
          `${GET_CREDIT_INVOICE_API_URL}/${criNumber}`
        );
        if (res?.data) {
          setCrInvoice(res.data);
        }
      }
    } catch (err) {
      message.error(err?.response?.data?.error);
    }
  };

  const getInvoices = async () => {
    try {
      const res = await axios.get(GET_INVOICES_API_URL);
      if (res?.data) {
        setInvoices(res.data);
      }
      setLogoLoading(false);
    } catch (err) {
      console.log(err);
      setLogoLoading(false);
    }
  };

  const getUnpaidInvoice = async () => {
    try {
      if (unpaidInvNo) {
        const res = await axios.get(
          `${GET_UNPAID_INVOICE_API_URL}/${unpaidInvNo}`
        );
        if (res?.data) {
          setUnpaidInv(res.data);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <InvoiceCtx.Provider
      value={{
        invoice,
        getInvoice,
        unpaidInvNo,
        setUnpaidInvNo,
        getUnpaidInvoice,
        unpaidInv,
        invoices,
        getInvoices,
        setInvoiceNumber,
        invoiceNumber,
        crInvoice,
        criNumber,
        setCriNumber,
        getCrInvoice,
      }}
    >
      {children}
    </InvoiceCtx.Provider>
  );
};

export { InvoiceContext, InvoiceCtx };
