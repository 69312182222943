import React from "react";

function GuaranteeSec() {
  return (
    <>
      <section className="money-back-sec">
        <div className="row justify-content-center ">
          <div className="col-xl-8 col-lg-12">
            <div className="money-back-block justify-content-center row mx-0 py-4 py-sm-4 rounded-3">
              <div className="col-sm-auto col-12 text-center py-3">
                <span className="icons-wrap position-relative">
                  <img
                    src="assets/images/satisfaction.png"
                    width={74}
                    height={74}
                    alt
                  />
                </span>
              </div>
              {/*.col-grid*/}
              <div className="col-lg-9 col pe-md-4 py-3 text-sm-start text-center">
                <h4 className="fs-3 fw-semibold mb-2 text-dark">
                  No room for dissatisfaction
                </h4>
                <p className=" mb-0 opacity-75 text-dark">
                  Choose us for unparalleled service—where dedication to
                  perfection and client satisfaction ensures each project
                  becomes a success story.
                </p>
              </div>
              {/*.col-grid*/}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default GuaranteeSec;
