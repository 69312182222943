import React from "react";
import { Link } from "react-router-dom";

function ServicesDetails({ data }) {
  const firstSlice = Array.isArray(data) ? data.slice(0, 3) : [];
  const secondSlice = Array.isArray(data) ? data.slice(3, 6) : [];

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-card rounded-3 position-relative p-3 p-md-4">
            <div className="card-body">
              <div
                className="accordion services-accodion row"
                id="accordionServices"
              >
                <div className="col-lg-6">
                  {firstSlice?.map((s) => (
                    <div
                      className="accordion-item rounded-2 overflow-hidden mb-4"
                      key={s?.attributes?.id}
                    >
                      <h2
                        className="accordion-header"
                        id={`service-${s?.attributes?.sid}`}
                      >
                        <button
                          className="accordion-button border-0 fs-6 fw-semibold py-3 collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#service-collapse${s?.attributes?.sid}`}
                          aria-expanded="false"
                          aria-controls={`service-collapse${s?.attributes?.sid}`}
                        >
                          <span className="icon d-inline-flex justify-content-center align-items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={16}
                              height={16}
                              fill="currentColor"
                              className="bi bi-code-slash"
                              viewBox="0 0 16 16"
                            >
                              <path d={s?.attributes?.icon} />
                            </svg>
                          </span>
                          {s?.attributes?.name}
                        </button>
                      </h2>
                      <div
                        id={`service-collapse${s?.attributes?.sid}`}
                        className="accordion-collapse collapse collapsed"
                        aria-labelledby={`service-${s?.attributes?.sid}`}
                        data-bs-parent="#accordionServices"
                      >
                        <div className="accordion-body">
                          <ul className="services-list">
                            {s?.attributes?.subCategories?.map((sc) => (
                              <li className="list-item" key={sc?.id}>
                                <Link to={`/${sc?.url}`} className="list-link">
                                  {sc?.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}

                  {/*.accordion-item*/}
                </div>
                {/*.col-grid*/}
                <div className="col-lg-6">
                  {secondSlice?.map((s) => (
                    <div
                      className="accordion-item rounded-2 overflow-hidden mb-4"
                      key={s?.attributes?.id}
                    >
                      <h2
                        className="accordion-header"
                        id={`service-${s?.attributes?.sid}`}
                      >
                        <button
                          className="accordion-button border-0 fs-6 fw-semibold py-3 collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#service-collapse${s?.attributes?.sid}`}
                          aria-expanded="false"
                          aria-controls={`service-collapse${s?.attributes?.sid}`}
                        >
                          <span className="icon d-inline-flex justify-content-center align-items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={16}
                              height={16}
                              fill="currentColor"
                              className="bi bi-code-slash"
                              viewBox="0 0 16 16"
                            >
                              <path d={s?.attributes?.icon} />
                            </svg>
                          </span>
                          {s?.attributes?.name}
                        </button>
                      </h2>
                      <div
                        id={`service-collapse${s?.attributes?.sid}`}
                        className="accordion-collapse collapse collapsed"
                        aria-labelledby={`service-${s?.attributes?.sid}`}
                        data-bs-parent="#accordionServices"
                      >
                        <div className="accordion-body">
                          <ul className="services-list">
                            {s?.attributes?.subCategories?.map((sc) => (
                              <li className="list-item" key={sc?.id}>
                                <Link to={`/${sc?.url}`} className="list-link">
                                  {sc?.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}

                  {/*.accordion-item*/}
                </div>
                {/*.col-grid*/}
              </div>
              {/*.accordion*/}
            </div>
            {/*.card-body*/}
          </div>
          {/*.page-card*/}
        </div>
        {/*.col-grid*/}
      </div>
    </>
  );
}

export default ServicesDetails;
