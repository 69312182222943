import React, { useContext, useEffect } from "react";
import { Order } from "../../../context/Order";
import ButtonTabs from "./ButtonTabs";
import Delivery from "./Delivery";
import MessagesTab from "./MessagesTab";
import ActivityTab from "./ActivityTab";
import DetailsTab from "./DetailsTab";
import RequirementsTab from "./RequirementsTab";
import Revision from "./Revision";

function OrderDButtons() {
  const { getOrderDetails } = useContext(Order);

  useEffect(() => {
    getOrderDetails();
  }, []);

  return (
    <>
      <div className="col-lg-8 col-md-12">
        <div className="page-card rounded-3 position-relative p-3 p-md-4">
          <div className="card-body">
            <div className="tabs-wrapper">
              <ButtonTabs />
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade py-3 show active"
                  id="activity-tab-pane"
                  role="tabpanel"
                  aria-labelledby="activity-tab"
                  tabIndex={0}
                >
                  <ActivityTab />
                </div>
                {/*.activity-tab*/}
                <div
                  className="tab-pane fade py-3"
                  id="details-tab-pane"
                  role="tabpanel"
                  aria-labelledby="details-tab"
                  tabIndex={0}
                >
                  <DetailsTab />
                </div>
                {/*.details-tab*/}
                <div
                  className="tab-pane fade py-3"
                  id="requirements-tab-pane"
                  role="tabpanel"
                  aria-labelledby="requirements-tab"
                  tabIndex={0}
                >
                  <RequirementsTab />
                </div>
                {/*.requirements-tab*/}
                <div
                  className="tab-pane fade py-3"
                  id="message-tab-pane"
                  role="tabpanel"
                  aria-labelledby="message-tab"
                  tabIndex={0}
                >
                  <MessagesTab />
                </div>

                {/*.message-tab*/}
                <div
                  className="tab-pane fade py-3"
                  id="delivery-tab-pane"
                  role="tabpanel"
                  aria-labelledby="delivery-tab"
                  tabIndex={0}
                >
                  <Delivery />

                  {/*.delivery-item*/}
                </div>
                {/*.delivery-tab*/}

                <div
                  className="tab-pane fade py-3"
                  id="revision-tab-pane"
                  role="tabpanel"
                  aria-labelledby="revision-tab"
                  tabIndex={0}
                >
                  <Revision />

                  {/*.delivery-item*/}
                </div>
                {/*.delivery-tab*/}
              </div>
            </div>
            {/*.tabs-wrapper*/}
          </div>
          {/*.card-body*/}
        </div>
        {/*.page-card*/}
      </div>
    </>
  );
}

export default OrderDButtons;
