import React, { useContext, useEffect } from "react";
import Footer from "../../components/layout/Footer";
import ClientHeader from "../../components/layout/client/ClientHeader";
import { AuthContext } from "../../context/Auth";
import { useNavigate } from "react-router-dom";
import { InvoiceCtx } from "../../context/Invoice";
import CriHeading from "../../components/client/creditInvoice/CriHeading";
import CriBillingInfo from "../../components/client/creditInvoice/CriBillingInfo";
import CriDetail from "../../components/client/creditInvoice/CriDetail";
import { Helmet } from "react-helmet";

function CreditInvoice() {
  const navigate = useNavigate();

  const { userInfo } = useContext(AuthContext);
  const { criNumber, getCrInvoice } = useContext(InvoiceCtx);

  if (userInfo?.userType === "manager" || userInfo?.userType === "tMember") {
    navigate("/dashboard");
  }

  useEffect(() => {
    if (criNumber) {
      getCrInvoice();
    }
  }, [criNumber]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>{`Invoice: ${criNumber}`} – AAMAX</title>
      </Helmet>
      {/*=============== Start AAMAX OMS  ===============*/}

      <ClientHeader>
        {/*.header*/}
        <main className="page-content d-flex flex-column justify-content-between h-100">
          <div id="crinvoice-content" className="container-fluid p-3 p-md-4">
            <CriHeading />
            {/*.row*/}
            <div className="row">
              <div className="col-12">
                <div className="page-card rounded-3 position-relative p-3 p-md-4">
                  <CriBillingInfo />
                  {/*.card-header*/}
                  <CriDetail />
                  {/*.card-body*/}
                </div>
                {/*.page-card*/}
              </div>
              {/*.col-grid*/}
            </div>
            {/*.row*/}
          </div>
          {/*.container-fluid*/}
          <Footer />
        </main>
      </ClientHeader>
      {/*.page-content"*/}
      {/*=============== End AAMAX OMS  ===============*/}
    </>
  );
}

export default CreditInvoice;
