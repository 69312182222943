import React, { useContext, useEffect } from "react";
import { Order } from "../../../context/Order";
import { AuthContext } from "../../../context/Auth";
import { MessageCtx } from "../../../context/Message";
import EmojiPicker from "emoji-picker-react";
import { Tooltip } from "antd";
import { constructImageUrl } from "../../../utils/helpers";
import io from "socket.io-client";
import { BACKEND_URL } from "../../../config/endPoints";

function ActivityMessageBox() {
  const { orderDetails } = useContext(Order);
  const { userInfo } = useContext(AuthContext);
  const {
    showEmojiPicker,
    messageContent,
    handleMessageChange,
    sendMessage,
    toggleEmojiPicker,
    handleEmojiSelection,
    emojiPickerRef,
    handleMsgAttachments,
    setMessages,
    getMessages,
    resetSelection,
    uploading,
    requirementsFiles,
    currentFileIndex,
    currentChunkIndex,
    chunkSize,
  } = useContext(MessageCtx);

  useEffect(() => {
    const socket = io(BACKEND_URL);

    socket.on("newMessage", (data) => {
      setMessages((prevMessages) => [...prevMessages, data.message]);
      getMessages();
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <>
      {(userInfo?.userType === "client" ||
        userInfo?.userType === "clientTm" ||
        userInfo?.userType === "tMember" ||
        userInfo?.userType === "manager" ||
        userInfo?.userType === "admin") &&
        (orderDetails?.status === "In Progress" ||
          orderDetails?.status === "Delivered" ||
          orderDetails?.status === "Revision") && (
          <div className="write-msg-box bg-light p-3 mt-4">
            <div>
              <div>
                <div className="d-flex flex-wrap flex-md-row flex-column align-items-start">
                  {userInfo?.imageUrl ? (
                    <img
                      className="rounded-circle me-3"
                      src={constructImageUrl(userInfo?.imageUrl)}
                      width={40}
                      height={40}
                    />
                  ) : (
                    <div className="user-avator-order secondary-bg rounded-circle d-flex align-items-center justify-content-center text-uppercase fw-semibold text-white me-2">
                      <span className="name-txt">
                        {`${
                          userInfo?.firstName
                            ? userInfo.firstName.charAt(0).toUpperCase()
                            : ""
                        }${
                          userInfo?.lastName
                            ? userInfo.lastName.charAt(0).toUpperCase()
                            : ""
                        }`}
                      </span>
                    </div>
                  )}
                  <textarea
                    className="form-control flex-fill w-md-auto p-3 ml-1 mt-md-0 mt-2 shadow-none textarea"
                    id="exampleFormControlTextarea3"
                    placeholder="Send message..."
                    rows={5}
                    defaultValue={""}
                    value={messageContent.content}
                    onChange={handleMessageChange}
                  />
                </div>
                <div className="d-flex flex-wrap justify-content-between ms-md-5 ps-2 mb-0 mt-3">
                  <ul className="textarea-meta-list list-unstyled d-flex flex-wrap align-items-center ps-0 mb-0">
                    <li>
                      <button
                        type="button"
                        className="btn p-1 border rounded-2"
                        // data-bs-toggle="dropdown"
                        // aria-expanded="false"
                        onClick={toggleEmojiPicker}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="text-secondary"
                          width={20}
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </button>
                      {showEmojiPicker && (
                        <div ref={emojiPickerRef}>
                          <EmojiPicker
                            onEmojiClick={handleEmojiSelection}
                            height={425}
                            width={300}
                          />
                        </div>
                      )}
                    </li>
                    <li className="file-attachment">
                      {requirementsFiles?.length > 0 &&
                      currentFileIndex === null ? (
                        <button className="btn text-secondary p-1 pe-0 opacity-75 ms-2">
                          <Tooltip
                            title="Reset Selection"
                            mouseEnterDelay={0.1}
                          >
                            <img
                              src="/assets/images/x.svg"
                              alt="X Icon"
                              width="20"
                              height="20"
                              onClick={resetSelection}
                            />
                          </Tooltip>
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn p-1 px-2 border mx-1 rounded-2 text-secondary"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            fill="currentColor"
                            className="bi bi-paperclip"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                          </svg>
                          <Tooltip title="Max 50MB/File" mouseEnterDelay={0.1}>
                            <input
                              type="file"
                              name="files"
                              multiple
                              onChange={handleMsgAttachments}
                            />{" "}
                            <small>Attach files</small>
                          </Tooltip>
                        </button>
                      )}
                    </li>
                    <li>
                      <span className="secondary-text small">
                        <small>
                          {requirementsFiles?.length > 0 &&
                          currentFileIndex !== null
                            ? `Uploading ${currentFileIndex + 1}/${
                                requirementsFiles?.length
                              } (${Math.round(
                                (currentChunkIndex /
                                  Math.ceil(
                                    requirementsFiles[currentFileIndex]?.size /
                                      chunkSize
                                  )) *
                                  100
                              )}%)`
                            : requirementsFiles?.length > 0
                            ? `${requirementsFiles?.length} ${
                                requirementsFiles?.length === 1
                                  ? "File"
                                  : "Files"
                              } Attached`
                            : ""}
                        </small>
                      </span>
                    </li>
                  </ul>
                  <button
                    className="btn primary-btn fw-semibold fs-6 px-4 my-md-0 my-2"
                    type="button"
                    onClick={sendMessage}
                    disabled={uploading}
                  >
                    {uploading && (
                      <span className="spinner-grow spinner-grow-sm me-2"></span>
                    )}

                    {uploading ? "Uploading..." : "Send"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  );
}

export default ActivityMessageBox;
