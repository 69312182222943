import React, { useEffect, useRef, useState } from "react";

import VideoJS from "../../../utils/videoJs";
import useService from "../../../hooks/useService";

export const ServiceVideo = () => {
  const data = useService();

  const [videoThumbnail, setVideoThumbnail] = useState(undefined);

  const video = data?.attributes?.video?.data?.attributes?.url;

  const playerRef = useRef(null);

  const videoJsOptions = {
    autoplay: false,
    controls: true,
    preload: "auto",
    poster: videoThumbnail,
    sources: [
      {
        src: video ? video : "./assets/videos/aamax-website-about-us.mp4",
        type: "video/mp4",
      },
    ],
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    player.on("waiting", () => {});

    player.on("dispose", () => {});
  };

  useEffect(() => {
    setVideoThumbnail(data?.attributes?.videoThumbnail?.data?.attributes?.url);
  }, [data]);

  if (!videoThumbnail) {
    return null;
  }

  return (
    <div className="video-wrapper rounded">
      <div className="video-js vjs-theme-fantasy shadow">
        <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
      </div>
    </div>
  );
};
