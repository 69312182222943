import React, { useContext, useEffect } from "react";
import { InvoiceCtx } from "../../../context/Invoice";
import {
  formatCurrency,
  formatDateString,
  getInvStatusColor,
  getInvType,
} from "../../../utils/helpers";
import { Link } from "react-router-dom";
import { SearchCtx } from "../../../context/SearchContext";

function InvoicesDetails() {
  const { invoices, getInvoices, setInvoiceNumber, setUnpaidInvNo } =
    useContext(InvoiceCtx);
  const { searchOrder } = useContext(SearchCtx);

  const filteredInvoices = invoices?.invoices?.filter(
    (inv) =>
      inv?.order?.service?.toLowerCase().includes(searchOrder.toLowerCase()) ||
      inv?.invoiceNumber?.toLowerCase().includes(searchOrder.toLowerCase()) ||
      inv?.orderCart?.service?.toLowerCase().includes(searchOrder.toLowerCase())
  );

  const sInvoiceIdHandler = (iid) => {
    setInvoiceNumber(iid);
    localStorage.setItem("sInvoiceId", iid);
  };

  const sUnpaidInvIdHandler = (iid) => {
    setUnpaidInvNo(iid);
    localStorage.setItem("sUnpaidInvId", iid);
  };

  useEffect(() => {
    getInvoices();
  }, []);

  return (
    <>
      <div className="card-body mt-4">
        <div className="table-responsive">
          <table className="table table-hover emp-attend-list">
            <thead>
              <tr>
                <th scope="col">Invoice Number</th>
                <th scope="col">Service</th>
                <th scope="col">Issued On</th>
                <th scope="col">Amount</th>
                <th scope="col">Status</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredInvoices?.slice(0, 25).map((inv, i) => (
                <tr key={i}>
                  <td>{inv?.invoiceNumber}</td>
                  <td>
                    <Link
                      to={
                        inv?.status === "unpaid"
                          ? "/unpaid-invoice"
                          : "/paid-invoice"
                      }
                      onClick={() => {
                        inv?.status === "unpaid"
                          ? sUnpaidInvIdHandler(inv?.invoiceNumber)
                          : sInvoiceIdHandler(inv?.invoiceNumber);
                      }}
                      style={{ textDecoration: "none", color: "#212529" }}
                    >
                      {inv?.order?.service
                        ? `${inv?.order?.service} (${inv?.order?.package})`
                        : `${inv?.orderCart?.service} (${inv?.orderCart?.package})`}
                    </Link>
                  </td>
                  <td>{formatDateString(inv?.orderCart?.createdAt)}</td>
                  <td>
                    {inv?.order?.totalPaidAmount
                      ? formatCurrency(inv?.order?.totalPaidAmount)
                      : formatCurrency(
                          inv?.orderCart?.price * inv?.orderCart?.quantity
                        )}
                  </td>
                  <td>
                    <span
                      className={`d-inline-block badge ${getInvStatusColor(
                        inv?.status
                      )}`}
                    >
                      {getInvType(inv?.status)}
                    </span>
                  </td>
                  <td>
                    <Link
                      to={
                        inv?.status === "unpaid"
                          ? "/unpaid-invoice"
                          : "/paid-invoice"
                      }
                      className="preview-action small text-decoration-none fw-semibold d-flex align-items-center"
                      onClick={() => {
                        inv?.status === "unpaid"
                          ? sUnpaidInvIdHandler(inv?.invoiceNumber)
                          : sInvoiceIdHandler(inv?.invoiceNumber);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-eye me-1"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                      </svg>
                      View
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/*.table-responsive*/}
      </div>
      {/*.card-body*/}
    </>
  );
}

export default InvoicesDetails;
