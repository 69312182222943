import React from "react";

function ContactInfo() {
  return (
    <>
      <div className="col-xl-10">
        <div className="contact-info d-flex gap-4 flex-md-row flex-column mb-4">
          <div className="contact-feature flex-fill bg-light d-flex flex-column text-center align-items-center py-lg-5 px-4 py-4 rounded-3">
            <div className="icon-wrapper mb-1">
              <span className="icon rounded-3 primary-text d-inline-flex align-items-center justify-content-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={38}
                  height={38}
                  fill="currentColor"
                  className="bi bi-envelope"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                </svg>
              </span>
            </div>
            {/*.icon-wrapper*/}
            <div className="contact-details">
              <span className="d-block fw-semibold fs-5 mb-2">Email</span>
              <a
                href="mailto:info@aamax.co"
                target="_blank"
                className="text-decoration-none fs-6 fw-normal text-secondary"
              >
                info@aamax.co
              </a>
              {/* <p className="text-decoration-none mb-0 small fw-normal text-secondary">
                Available 24/7
              </p> */}
            </div>
            {/*.contact-details*/}
          </div>
          {/*.contact-feature*/}

          {/*.contact-feature*/}
          <div className="contact-feature flex-fill bg-light d-flex flex-column text-center align-items-center py-lg-5 px-4 py-4 rounded-3">
            <div className="icon-wrapper mb-1">
              <span className="icon primary-text rounded-3 d-inline-flex align-items-center justify-content-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="38"
                  height="38"
                  fill="currentColor"
                  class="bi bi-whatsapp"
                  viewBox="0 0 16 16"
                >
                  <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
                </svg>
              </span>
            </div>
            {/*.icon-wrapper*/}
            <div className="contact-details">
              <span className="d-block fw-semibold fs-5 mb-2">WhatsApp</span>
              <a
                href="https://wa.me/+923084281241"
                target="_blank"
                className="text-decoration-none fs-6 fw-normal text-secondary"
              >
                +92-308-4281241
              </a>
            </div>
            {/*.contact-details*/}
          </div>
          <div className="contact-feature flex-fill bg-light d-flex flex-column text-center align-items-center py-lg-5 px-4 py-4 rounded-3">
            <div className="icon-wrapper mb-1">
              <span className="icon rounded-3 primary-text d-inline-flex align-items-center justify-content-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="38"
                  height="38"
                  fill="currentColor"
                  class="bi bi-geo-alt"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                </svg>
              </span>
            </div>
            {/*.icon-wrapper*/}
            <div className="contact-details">
              <span className="d-block fw-semibold fs-5 mb-2">Location</span>
              <a
                href="#"
                className="text-decoration-none fs-6 fw-normal text-secondary"
              >
                Ruislip, UK, HA4 7AE
              </a>
              {/* <p className="text-decoration-none mb-0 small fw-normal text-secondary">
                10:00 AM - 09:00 PM
              </p> */}
            </div>
            {/*.contact-details*/}
          </div>
          {/*.contact-feature*/}
        </div>
        {/*.contact-info*/}
      </div>
    </>
  );
}

export default ContactInfo;
