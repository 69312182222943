import { gql } from "@apollo/client";

export const CAREERS_QUERY = gql`
  query Careers {
    careers {
      data {
        attributes {
          mainHeading
          openPositions {
            id
            jobTitle
            jobType
            location
          }
        }
      }
    }
  }
`;
