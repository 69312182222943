import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { SearchCtx } from "../../../context/SearchContext";

function DashboardSearchOrder() {
  const location = useLocation();
  const currentUrl = location.pathname;
  const firstWord = currentUrl.split("-")[0];
  const orderType =
    firstWord.charAt(1).toUpperCase() + firstWord.slice(2).toLocaleLowerCase();

  const { searchOrder, setSearchOrder } = useContext(SearchCtx);

  const handleSearchChange = (e) => {
    setSearchOrder(e.target.value);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-9 col-lg-10 col-xl-5">
          <div className="search-wrapper d-flex flex-column flex-md-row align-items-md-center">
            <label className="h6 fw-semibold me-2 mb-0" htmlFor="searh-input">
              {orderType === "Dashboard" ? "" : orderType} Orders
            </label>
            <form
              className="search-form position-relative flex-fill mt-md-0 mt-2"
              action="#"
            >
              <input
                className="form-control w-100 rounded-pill ps-3"
                type="text"
                placeholder="Search..."
                aria-label="Search"
                value={searchOrder}
                onChange={handleSearchChange}
              />
              <span className="search-box-icon position-absolute">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={14}
                  height={14}
                  fill="currentColor"
                  className="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
              </span>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardSearchOrder;
