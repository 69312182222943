import { Navigate, Outlet, useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { useContext } from "react";
import { AuthContext } from "../../context/Auth";

function ProtectedRoutes() {
  const token = localStorage.getItem("token");

  const { setRedirectUrl } = useContext(AuthContext);

  const location = useLocation();

  if (token) {
    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        setRedirectUrl(
          location.search
            ? `${location.pathname}${location.search}`
            : location.pathname
        );
        return <Navigate to={"/"} />;
      }
    } catch (error) {
      return <Navigate to={"/"} />;
    }
  } else {
    setRedirectUrl(
      location.search
        ? `${location.pathname}${location.search}`
        : location.pathname
    );
    return <Navigate to={"/"} />;
  }

  return <Outlet />;
}

export default ProtectedRoutes;
