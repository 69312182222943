import React, { useContext } from "react";
import { AuthContext } from "../../../context/Auth";

function POPMainHeading() {
  const { userInfo } = useContext(AuthContext);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-header d-flex flex-wrap justify-content-between align-items-end py-2 mb-lg-2">
            <h4 className="my-1 fw-bold">Order Summary</h4>
            <span className="avail-credits fw-semibold my-1 d-inline-block">
              Available Credits:{" "}
              <span className="secondary-text">${userInfo?.credit}.00</span>
            </span>
          </div>
          {/*.page-header*/}
        </div>
        {/*.col-grid*/}
      </div>
    </>
  );
}

export default POPMainHeading;
