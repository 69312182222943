import React, { useEffect } from "react";
import Footer from "../../components/layout/Footer";
import ClientHeader from "../../components/layout/client/ClientHeader";
import SRMainHeading from "../../components/client/submitRequirements/SRMainHeading";
import SubmitReqDetails from "../../components/client/submitRequirements/SubmitReqDetails";
import SRServiceOverview from "../../components/client/submitRequirements/SRServiceOverview";
import { Helmet } from "react-helmet";

function SubmitRequirements() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Submit Requirements – AAMAX</title>
      </Helmet>
      {/*=============== Start AAMAX OMS  ===============*/}

      <ClientHeader>
        {/*.header*/}
        <main className="page-content d-flex flex-column justify-content-between h-100">
          <div className="container-fluid p-3 p-md-4">
            <SRMainHeading />
            {/*.row*/}
            <div className="row">
              <SubmitReqDetails />
              {/*.col-grid*/}
              <SRServiceOverview />
              {/*.row*/}
            </div>
            {/*.container-fluid*/}
            <Footer />
          </div>
        </main>
      </ClientHeader>
      {/*.page-content"*/}
      {/*=============== End AAMAX OMS  ===============*/}
    </>
  );
}

export default SubmitRequirements;
