import React, { useContext } from "react";
import Footer from "../../components/layout/Footer";
import ClientHeader from "../../components/layout/client/ClientHeader";
import InvoicesDetails from "../../components/client/viewInvoices/InvoicesDetails";
import SearchInvoice from "../../components/client/viewInvoices/SearchInvoice";
import NoInvoices from "../../components/client/viewInvoices/NoInvoices";
import DashboardSkeleton from "../../components/client/dashboard/DashboardSkeleton";
import { LoadingContext } from "../../context/LoadingLogo";
import useInvoices from "../../hooks/useInvoices";
import { Helmet } from "react-helmet";

function ViewInvoices() {
  const invoices = useInvoices();

  const { logoLoading } = useContext(LoadingContext);

  return (
    <>
      <Helmet>
        <title>Invoices – AAMAX</title>
      </Helmet>
      {/*=============== Start AAMAX OMS  ===============*/}

      <ClientHeader>
        {/*.header*/}
        <main className="page-content d-flex flex-column justify-content-between h-100">
          <div className="container-fluid p-3 p-md-4">
            {/* <VIHeading /> */}
            {/*.row*/}
            {logoLoading ? (
              <>
                <div className="row">
                  <div className="col-12">
                    <div className="page-card rounded-3 position-relative p-3 p-md-4">
                      <div className="card-header p-3 bg-light">
                        <DashboardSkeleton />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : invoices?.invoices?.length === 0 ? (
              <NoInvoices />
            ) : (
              <div className="row">
                <div className="col-12">
                  <div className="page-card rounded-3 position-relative p-3 p-md-4">
                    <div className="card-header p-3 bg-light">
                      <SearchInvoice />
                    </div>
                    {/*.card-header*/}
                    <InvoicesDetails />

                    {/*.card-footer*/}
                  </div>
                  {/*.page-card*/}
                </div>
                {/*.col-grid*/}
              </div>
            )}
            {/*.row*/}
          </div>
          {/*.container-fluid*/}
          <Footer />
        </main>
      </ClientHeader>
      {/*.page-content"*/}
      {/*=============== End AAMAX OMS  ===============*/}
    </>
  );
}

export default ViewInvoices;
