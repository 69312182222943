import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/Auth";
import { DELETE_ADMIN_USER_API_URL } from "../../../config/endPoints";
import axios from "axios";
import { Popconfirm, message } from "antd";
import { constructImageUrl } from "../../../utils/helpers";
import { SearchCtx } from "../../../context/SearchContext";

function MUATable() {
  const token = localStorage.getItem("token");

  const { getAdminUsers, adminUsers, updateAUser, loading } =
    useContext(AuthContext);

  const { searchOrder } = useContext(SearchCtx);

  const [selectedUInfo, setSelectedUInfo] = useState({});
  const [updateAltUser, setUpdateAltUser] = useState({
    email: "",
    userType: "",
    firstName: "",
    lastName: "",
    status: "",
    department: "",
    password: "",
    image: [],
  });

  const sUid = selectedUInfo?._id;

  const handleChange = (e) => {
    const { id, value } = e.target;
    setUpdateAltUser({ ...updateAltUser, [id]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateAUser(updateAltUser, sUid);
  };

  const confirm = (id) => {
    axios
      .delete(`${DELETE_ADMIN_USER_API_URL}/${id}`)
      .then(() => {
        getAdminUsers(token);
        message.success("User deleted successfully.");
      })
      .catch((err) => {
        message.error(err?.response?.data?.error);
      });
  };

  const MAX_ATTACHMENTS = 1;
  const MAX_FILE_SIZE_MB = 2;

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file?.length > MAX_ATTACHMENTS) {
      message.error(`You can upload only ${MAX_ATTACHMENTS} image.`);
      e.target.value = null;
      return;
    }

    for (let i = 0; i < file?.length; i++) {
      if (file[i].size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        message.error(`Image size should not exceed ${MAX_FILE_SIZE_MB} MB.`);
        e.target.value = null;
        return;
      }
    }

    const fileName = file?.name;
    const ext = fileName
      ?.substring(fileName?.lastIndexOf(".") + 1)
      .toLowerCase();

    const allowedExtensions = [
      "jpg",
      "jpeg",
      "png",
      "gif",
      "JPEG",
      "JPG",
      "PNG",
      "GIF",
    ];

    if (!allowedExtensions.includes(ext)) {
      message.error("Only image files (JPEG, JPG, PNG, GIF) are allowed.");
      e.target.value = null;
      return;
    }

    setUpdateAltUser((prev) => ({ ...prev, image: [file] }));
  };

  const filteredUsers = adminUsers?.filter(
    (user) =>
      user.firstName.toLowerCase().includes(searchOrder.toLowerCase()) ||
      user.lastName.toLowerCase().includes(searchOrder.toLowerCase()) ||
      user.email.toLowerCase().includes(searchOrder.toLowerCase()) ||
      user.department.toLowerCase().includes(searchOrder.toLowerCase())
  );

  useEffect(() => {
    setUpdateAltUser({
      email: selectedUInfo?.email,
      userType: selectedUInfo?.userType,
      firstName: selectedUInfo?.firstName,
      lastName: selectedUInfo?.lastName,
      status: selectedUInfo?.status,
      department: selectedUInfo?.department,
      password: "",
    });
  }, [selectedUInfo]);

  useEffect(() => {
    if (token) {
      getAdminUsers(token);
    }
  }, []);

  return (
    <>
      <div className="card-body pt-4">
        <div className="table-responsive">
          <table className="table table-hover emp-attend-list">
            <thead>
              <tr>
                <th scope="col">Image</th>
                <th scope="col">Name</th>
                <th scope="col">Department</th>
                <th scope="col">Email</th>
                <th scope="col">Role</th>
                <th scope="col">Status</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers?.map((ad, i) => (
                <tr key={i}>
                  <td>
                    {ad?.imageUrl ? (
                      <img
                        className="table-img"
                        src={constructImageUrl(ad?.imageUrl)}
                      />
                    ) : (
                      <div className="user-avator-order secondary-bg rounded-circle d-flex align-items-center justify-content-center text-uppercase fw-semibold text-white me-2">
                        <span className="name-txt">
                          {`${
                            ad?.firstName
                              ? ad.firstName.charAt(0).toUpperCase()
                              : ""
                          }${
                            ad?.lastName
                              ? ad.lastName.charAt(0).toUpperCase()
                              : ""
                          }`}
                        </span>
                      </div>
                    )}
                  </td>
                  <td>{`${ad?.firstName} ${ad?.lastName}`}</td>
                  <td>{ad?.department}</td>
                  <td>{ad?.email}</td>
                  <td>{ad?.userType === "manager" ? "Manager" : "Team"}</td>
                  <td>
                    <span
                      className={`d-inline-block badge ${
                        ad?.status === "inactive" ? "bg-danger" : "green-col"
                      }`}
                    >
                      {ad?.status.charAt(0).toUpperCase() + ad?.status.slice(1)}
                    </span>
                  </td>
                  <td>
                    <button
                      className="tb-action edit"
                      data-bs-toggle="modal"
                      data-bs-target="#editUserModal"
                      onClick={() => setSelectedUInfo(ad)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-pencil-square"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path
                          fillRule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                        />
                      </svg>
                    </button>
                    <Popconfirm
                      title="Confirm Delete"
                      description="Are you sure you want to delete this user?"
                      onConfirm={() => confirm(ad?._id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <button className="tb-action delete">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          fill="currentColor"
                          className="bi bi-trash3"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                        </svg>
                      </button>
                    </Popconfirm>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div
            className="modal fade"
            id="editUserModal"
            tabIndex={-1}
            aria-labelledby="editUserModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header dark-bg px-4">
                  <h1
                    className="modal-title fs-5 text-white"
                    id="cardPaymentModalLabel"
                  >
                    Edit Employee
                  </h1>
                  <button
                    type="button"
                    className="btn-close btn-close-white"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body px-4">
                  <form className="needs-validation mt-lg-3">
                    <div className="row g-3">
                      <div className="col-sm-12">
                        <label
                          htmlFor="inputFile01"
                          className="form-label small fw-semibold"
                        >
                          Upload image
                        </label>
                        <input
                          type="file"
                          className="upload-file-input w-100"
                          id="image"
                          onChange={handleImageChange}
                        />
                      </div>
                      <div className="col-sm-6">
                        <label
                          htmlFor="firstName"
                          className="form-label small fw-semibold"
                        >
                          First Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="firstName"
                          placeholder
                          value={updateAltUser.firstName}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-sm-6">
                        <label
                          htmlFor="lastName"
                          className="form-label small fw-semibold"
                        >
                          Last Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="lastName"
                          placeholder
                          value={updateAltUser.lastName}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-sm-6">
                        <label
                          htmlFor="department"
                          className="form-label small fw-semibold"
                        >
                          Department
                        </label>
                        <select
                          className="form-select"
                          id="department"
                          value={updateAltUser.department}
                          onChange={handleChange}
                        >
                          <option value="">Select Role</option>
                          <option value="Digital Marketing">
                            Digital Marketing
                          </option>
                          <option value="Web Development">
                            Web Development
                          </option>
                        </select>
                      </div>
                      <div className="col-sm-6">
                        <label
                          htmlFor="email"
                          className="form-label small fw-semibold"
                        >
                          Email
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          placeholder
                          value={updateAltUser.email}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-sm-6">
                        <label
                          htmlFor="email"
                          className="form-label small fw-semibold"
                        >
                          Password
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          placeholder
                          value={updateAltUser.password}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="col-sm-6">
                        <label
                          htmlFor="userType"
                          className="form-label small fw-semibold"
                        >
                          Role
                        </label>
                        <select
                          className="form-select"
                          id="userType"
                          required
                          value={updateAltUser.userType}
                          onChange={handleChange}
                        >
                          <option value="">Select Role</option>
                          <option value="manager">Manager</option>
                          <option value="tMember">Team</option>
                        </select>
                      </div>
                      <div className="col-sm-6">
                        <label
                          htmlFor="status"
                          className="form-label small fw-semibold"
                        >
                          Status
                        </label>
                        <select
                          className="form-select"
                          id="status"
                          required
                          value={updateAltUser.status}
                          onChange={handleChange}
                        >
                          <option value="">Please Select</option>
                          <option value="active">Active</option>
                          <option value="inactive">Inactive</option>
                        </select>
                      </div>
                    </div>
                    {/*.row*/}
                    {/* / Change address form*/}
                    <div className="btn-wrapper text-end mb-3 mt-4 mt-lg-5">
                      <a
                        href="#"
                        className="fs-6 btn btn-light me-2 px-4 py-2 text-decoration-none"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Cancel
                      </a>
                      <button
                        onClick={handleSubmit}
                        disabled={loading}
                        className="btn primary-btn fw-semibold fs-6 px-4 py-2"
                      >
                        {loading && (
                          <span className="spinner-grow spinner-grow-sm me-2"></span>
                        )}
                        {!loading ? "Update User" : "Updating User"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/*.edit-user-modal*/}
        </div>
        {/*.table-responsive*/}
      </div>
    </>
  );
}

export default MUATable;
