import React, { useContext, useEffect } from "react";
import {
  formatCurrency,
  formatDateString,
  getStatusColor,
} from "../../../utils/helpers";

import { CreditCtx } from "../../../context/Credit";
import setAuthToken from "../../../utils/setAuthToken";
import { InvoiceCtx } from "../../../context/Invoice";
import { AuthContext } from "../../../context/Auth";
import { Link } from "react-router-dom";

function CreditHistoryTable() {
  const { credits, getCredits } = useContext(CreditCtx);
  const { setCriNumber } = useContext(InvoiceCtx);
  const { userInfo } = useContext(AuthContext);

  const token = localStorage.getItem("token");

  const sInvoiceIdHandler = (iid) => {
    setCriNumber(iid);
    localStorage.setItem("sCriId", iid);
  };

  useEffect(() => {
    if (token) {
      setAuthToken(token);
      getCredits(token);
    }
  }, []);

  return (
    <>
      <div className="card-body pt-4">
        <div className="table-responsive">
          <table className="table table-hover emp-attend-list">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Credit</th>
                <th scope="col">Sum Incl. Free Credit</th>
                <th scope="col">Status</th>
                <th scope="col">Unverification Reason</th>
                <th scope="col">Payment Method</th>
                {(userInfo?.userType === "admin" ||
                  userInfo?.userType === "client" ||
                  userInfo?.userType === "clientTm" ||
                  userInfo?.userType === "viewer") && (
                  <th scope="col">Invoice</th>
                )}
              </tr>
            </thead>
            <tbody>
              {credits?.map((credit, i) => (
                <tr key={i}>
                  <td>{formatDateString(credit?.createdAt)}</td>
                  <td>{formatCurrency(credit?.credit)}</td>
                  <td>
                    {credit?.verifyingAmount
                      ? `${formatCurrency(credit?.verifyingAmount)}`
                      : "–"}
                  </td>

                  <td>
                    <span
                      className={`d-inline-block badge ${getStatusColor(
                        credit?.creditStatus
                      )}`}
                    >
                      {credit?.creditStatus}
                    </span>
                  </td>
                  <td>
                    {credit?.rejectionReason ? credit?.rejectionReason : "–"}
                  </td>

                  <td>{credit?.paymentMethod}</td>
                  {(userInfo?.userType === "admin" ||
                    userInfo?.userType === "client" ||
                    userInfo?.userType === "clientTm" ||
                    userInfo?.userType === "viewer") && (
                    <td>
                      <Link
                        to="/credit-invoice"
                        className="preview-action small text-decoration-none fw-semibold d-flex align-items-center"
                        onClick={() => sInvoiceIdHandler(credit?.invoiceNumber)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          fill="currentColor"
                          className="bi bi-eye me-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                          <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                        </svg>
                        View
                      </Link>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>

          {/*.edit-user-modal*/}
        </div>
        {/*.table-responsive*/}
      </div>
    </>
  );
}

export default CreditHistoryTable;
