import React, { createContext, useState } from "react";

const ExpandedLink = createContext();

function SidebarNavContext({ children }) {
  const [expandedItem, setExpandedItem] = useState(null);

  return (
    <ExpandedLink.Provider
      value={{
        expandedItem,
        setExpandedItem,
      }}
    >
      {children}
    </ExpandedLink.Provider>
  );
}

export { SidebarNavContext, ExpandedLink };
