import React, { useEffect } from "react";

function AboutIcons() {
  useEffect(() => {
    // Counter Value
    function startCounterAnimation(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const target = entry.target;
          const duration = 2000;
          const targetValue = parseInt(target.getAttribute("data-val"));
          let startTime = null;
          // counter target value
          const updateCounter = (timestamp) => {
            if (!startTime) startTime = timestamp;
            const progress = timestamp - startTime;
            const increment = (targetValue / duration) * progress;

            if (increment < targetValue) {
              target.textContent = Math.ceil(increment);
              requestAnimationFrame(updateCounter);
            } else {
              target.textContent = targetValue;
            }
          };
          requestAnimationFrame(updateCounter);
          observer.unobserve(target);
        }
      });
    }
    // counter animation
    const observer = new IntersectionObserver(startCounterAnimation, {
      threshold: 0.5,
    });
    const counters = document.querySelectorAll(".count");
    counters.forEach((counter) => observer.observe(counter));
    // counter onload
    function startCountersOnLoad() {
      counters.forEach((counter) => {
        counter.textContent = "0";
        const duration = 5000;
        const targetValue = parseInt(counter.getAttribute("data-val"));
        let startTime = null;
        const updateCounter = (timestamp) => {
          if (!startTime) startTime = timestamp;
          const progress = timestamp - startTime;
          const increment = (targetValue / duration) * progress;

          if (increment < targetValue) {
            counter.textContent = Math.ceil(increment);
            requestAnimationFrame(updateCounter);
          } else {
            counter.textContent = targetValue;
          }
        };
        requestAnimationFrame(updateCounter);
      });
    }
    document.addEventListener("DOMContentLoaded", startCountersOnLoad);
  }, []);

  return (
    <>
      <section
        className="stats-sec poition-relative py-lg-5 py-4 mt-lg-4"
        id="oms-stats"
      >
        <div className="container">
          <div className="row gy-lg-0 gy-4">
            {/*.col-grid*/}
            <div className="col-lg-3 col-sm-6">
              <div className="counter d-flex align-items-center mx-xl-4 mx-lg-2 my-md-0 my-2">
                <span className="icon primary-text">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={38}
                    height={38}
                    fill="currentColor"
                    className="bi bi-layers"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8.235 1.559a.5.5 0 0 0-.47 0l-7.5 4a.5.5 0 0 0 0 .882L3.188 8 .264 9.559a.5.5 0 0 0 0 .882l7.5 4a.5.5 0 0 0 .47 0l7.5-4a.5.5 0 0 0 0-.882L12.813 8l2.922-1.559a.5.5 0 0 0 0-.882l-7.5-4zm3.515 7.008L14.438 10 8 13.433 1.562 10 4.25 8.567l3.515 1.874a.5.5 0 0 0 .47 0l3.515-1.874zM8 9.433 1.562 6 8 2.567 14.438 6 8 9.433z" />
                  </svg>
                </span>
                {/*icon*/}
                <div className="ms-3">
                  <h4 className="mb-1 fw-semibold">
                    <span data-val={1000} className="count">
                      0
                    </span>
                    +
                  </h4>
                  <p className="mb-0">Projects Completed</p>
                </div>
              </div>
              {/*.counter*/}
            </div>
            {/*.col-grid*/}
            <div className="col-lg-3 col-sm-6">
              <div className="counter d-flex align-items-center mx-xl-4 mx-lg-2 my-md-0 my-2">
                <span className="icon primary-text">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={38}
                    height={38}
                    fill="currentColor"
                    className="bi bi-person"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
                  </svg>
                </span>
                {/*icon*/}
                <div className="ms-3">
                  <h4 className="mb-1 fw-semibold">
                    <span data-val={500} className="count">
                      0
                    </span>
                    +
                  </h4>
                  <p className="mb-0">Happy Clients</p>
                </div>
              </div>
              {/*.counter*/}
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="counter d-flex align-items-center mx-xl-4 mx-lg-2 my-md-0 my-2">
                <span className="icon primary-text">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="38"
                    height="38"
                    fill="currentColor"
                    class="bi bi-emoji-smile"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                    <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.5 3.5 0 0 0 8 11.5a3.5 3.5 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5" />
                  </svg>
                </span>
                {/*icon*/}
                <div className="ms-3">
                  <h4 className="mb-1 fw-semibold">
                    <span data-val={100} className="count">
                      0
                    </span>
                    %
                  </h4>
                  <p className="mb-0">Client Satisfaction Rate</p>
                </div>
              </div>
              {/*.counter*/}
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="counter d-flex align-items-center mx-xl-4 mx-lg-2 my-md-0 my-2">
                <span className="icon primary-text">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={38}
                    height={38}
                    fill="currentColor"
                    class="bi bi-patch-check"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0"
                    />
                    <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911z" />
                  </svg>
                </span>
                {/*icon*/}
                <div className="ms-3">
                  <h4 className="mb-1 fw-semibold">
                    <span data-val={100} className="count">
                      0
                    </span>
                    %
                  </h4>
                  <p className="mb-0">High Quality</p>
                </div>
              </div>
              {/*.counter*/}
            </div>
          </div>
          {/*.row*/}
        </div>
        {/*.container*/}
      </section>
    </>
  );
}

export default AboutIcons;
