import React from "react";

function CharityDescContent() {
  return (
    <>
      <div className="card-header col-lg-9">
        <h4 className="h5 fw-semibold mb-3">
          Each month, a portion of our company's profits is dedicated to
          supporting AAM Nation Care, a charitable organization established by
          AA Muhammadi, the founder of AAMAX.
        </h4>
        <p>
          Please read the paragraph below about AAM Nation Care, its goals, and
          the work it is doing to help people.
        </p>
      </div>
    </>
  );
}

export default CharityDescContent;
