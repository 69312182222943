import { message } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FORGET_PASSWORD_API_URL } from "../config/endPoints";
import axios from "axios";
import { Helmet } from "react-helmet";

function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email)
      return message.error(
        "Email required to reset password. Please enter email."
      );
    setLoading(true);
    axios
      .post(FORGET_PASSWORD_API_URL, { email })
      .then((res) => {
        setLoading(false);
        message.success(
          "Password reset email sent. Check your inbox and spam folder!"
        );
        setEmail("");
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.response.data.error);
      });
  };

  return (
    <>
      <Helmet>
        <title>Forgot Password – AAMAX</title>
      </Helmet>
      {/*====================== Start Sign in Form ======================*/}
      <section className="oms-authentication py-md-5 py-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-7 col-xl-5">
              <div className="form-wrapper py-md-4">
                <div className="logo-wrapper text-center mb-md-5 mb-4 pb-lg-4 pb-2">
                  <Link to="/">
                    <img src="assets/images/logo-dark.png" alt="aamax" />
                  </Link>
                </div>
                <form action="#" className="sign-in-form">
                  <div className="form-title text-center mb-5">
                    <h3 className="display-6 fw-semibold mb-md-3 mb-2">
                      Reset password
                    </h3>
                    <p className="text-muted">
                      Enter your email address and we'll send you a link to
                      reset your password.
                    </p>
                  </div>
                  <div className="input-group mb-md-5 mb-4 rounded-2 overflow-hidden border">
                    <span
                      className="input-group-text bg-light text-muted border-0 px-3"
                      id="basic-addon1"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={22}
                        height={22}
                        fill="currentColor"
                        className="bi bi-envelope"
                        viewBox="0 0 16 16"
                      >
                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                      </svg>
                    </span>
                    <input
                      id="email"
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-control bg-white border-0 px-3"
                      placeholder="Email Address"
                      autofocus
                    />
                  </div>
                  <button
                    type="submit"
                    disabled={loading}
                    onClick={handleSubmit}
                    className="btn primary-btn shadow w-100 mb-2"
                  >
                    {loading && (
                      <span className="spinner-grow spinner-grow-sm me-2"></span>
                    )}
                    {!loading ? "Submit" : "Sending Email..."}
                  </button>
                  <p className="mt-4 text-center text-muted">
                    Back to{" "}
                    <Link
                      to="/"
                      className="text-decoration-none text-decoration-underline create-account-action"
                    >
                      Sign In
                    </Link>
                  </p>
                </form>
              </div>
              {/*.form-wrapper*/}
            </div>
            {/*.col-grid*/}
          </div>
          {/*.row*/}
        </div>
        {/*.container*/}
      </section>
      {/*====================== End Sign in Form ======================*/}
    </>
  );
}

export default ForgotPassword;
