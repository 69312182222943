import { gql } from "@apollo/client";

export const SERVICE_IMAGE_QUERY = gql`
  query ($url: String!) {
    package(url: $url) {
      data {
        attributes {
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;
