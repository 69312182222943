import axios from "axios";
import React, { useContext, useEffect } from "react";
import { BACKEND_URL, COMPLETE_ORDER_API_URL } from "../../../config/endPoints";
import { Order } from "../../../context/Order";
import WaitForDelivery from "./WaitForDelivery";
import { AuthContext } from "../../../context/Auth";
import {
  constructImageUrl,
  convertToHTML,
  formatDateTime,
  formatFileSize,
  getFileIcon,
  truncateFileName,
} from "../../../utils/helpers";
import { Popconfirm, Tooltip, message } from "antd";
import { UtilsCtx } from "../../../context/UtilsContext";
import { RevisionCtx } from "../../../context/Revision";
import { useNavigate } from "react-router-dom";
import DeliverOrder from "./DeliverOrder";
import { DeliveryCtx } from "../../../context/DeliverOrder";
import io from "socket.io-client";

function Delivery() {
  const {
    getOrderDetails,
    orderDetails,
    sOrderId,
    orderId,
    reqRevision,
    setReqRevision,
  } = useContext(Order);
  const { userInfo } = useContext(AuthContext);
  const { handleDownloadAttachment } = useContext(UtilsCtx);
  const {
    sendRevisionReq,
    revisionDesc,
    setRevisionDesc,
    handleRevisionChange,
    handleRevisionAttachments,
    resetSelection,
    uploading,
    requirementsFiles,
    currentFileIndex,
    currentChunkIndex,
    chunkSize,
  } = useContext(RevisionCtx);

  const { deliveries, setDeliveries, getDeliveries } = useContext(DeliveryCtx);

  const navigate = useNavigate();

  const reqOrderId = sOrderId ? sOrderId : orderId;

  const confirm = async (id) => {
    try {
      const res = await axios.put(`${COMPLETE_ORDER_API_URL}/${id}`);
      message.success(res?.data?.message);
      getOrderDetails();
      navigate("/public-feedback");
    } catch (err) {
      message.error(err?.response?.data?.error);
    }
  };

  const revisionClickHandler = () => {
    setReqRevision((prevValue) => !prevValue);
    setRevisionDesc({
      description: "",
    });
    resetSelection();
  };

  useEffect(() => {
    const socket = io(BACKEND_URL);

    socket.on("newDelivery", (data) => {
      setDeliveries((prevDelivery) => [...prevDelivery, data.delivery]);
      getDeliveries();
      getOrderDetails();
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <>
      {deliveries?.length === 0 ? (
        (userInfo?.userType === "tMember" ||
          userInfo?.userType === "manager" ||
          userInfo?.userType === "admin") &&
        (orderDetails?.status === "In Progress" ||
          orderDetails?.status === "Revision") ? (
          <DeliverOrder />
        ) : (
          <WaitForDelivery
            devlDate={orderDetails?.deliveryDate}
            oStatus={orderDetails?.status}
          />
        )
      ) : (
        <>
          {(userInfo?.userType === "tMember" ||
            userInfo?.userType === "manager" ||
            userInfo?.userType === "admin") &&
            (orderDetails?.status === "In Progress" ||
              orderDetails?.status === "Revision") && <DeliverOrder />}
          <>
            <ul className="list-group border-0 rounded-0">
              {deliveries?.map((d, i) => (
                <li
                  className="list-group-item px-0 py-3 d-flex flex-wrap align-items-center border-0 border-bottom"
                  key={i}
                >
                  <a
                    className=""
                    data-bs-toggle="collapse"
                    href={`#collapse${i}`}
                    role="button"
                    aria-expanded="true"
                    aria-controls={`collapse${i}`}
                  >
                    <h4 className="h6 m-0 d-inline-flex align-items-center">
                      <span className="icon bg-icon-style d-inline-flex rounded-pill justify-content-center align-items-center me-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-bag-check"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                          />
                          <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z" />
                        </svg>
                      </span>
                      <strong className="me-1 secondary-text d-inline-block">
                        Delivery
                      </strong>
                      #{i + 1}
                    </h4>

                    <span className="small d-inline-flex text-secondary ms-4 ps-2">
                      {formatDateTime(d?.timestamp)}
                    </span>
                  </a>
                  <div className="collapse w-100 mt-2 show" id={`collapse${i}`}>
                    <div className="card card-body rounded-2 border-0 py-0">
                      <div className="devivery-item-wrapper">
                        <div className="delivery-item">
                          <div className="d-flex pt-3">
                            <div>
                              {d?.user?.imageUrl ? (
                                <img
                                  className="rounded-circle me-3"
                                  src={constructImageUrl(d?.user?.imageUrl)}
                                  width={40}
                                  height={40}
                                />
                              ) : (
                                <div className="user-avator-order secondary-bg rounded-circle d-flex align-items-center justify-content-center text-uppercase fw-semibold text-white me-3">
                                  <span className="name-txt">
                                    {`${
                                      d?.user?.firstName
                                        ? d?.user?.firstName
                                            .charAt(0)
                                            .toUpperCase()
                                        : ""
                                    }${
                                      d?.user?.lastName
                                        ? d?.user?.lastName
                                            .charAt(0)
                                            .toUpperCase()
                                        : ""
                                    }`}
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="mt-2 pe-sm-4">
                              <h4 className="h6 d-flex flex-column justify-content-start">
                                <span className="d-block name lh-sm">
                                  {userInfo?._id === d?.user?._id
                                    ? "Me"
                                    : `${d?.user?.firstName} ${d?.user?.lastName}`}
                                </span>
                              </h4>

                              <p
                                className="small"
                                dangerouslySetInnerHTML={convertToHTML(
                                  d?.description
                                )}
                              />

                              {d?.deliveryAttachments?.length > 0 && (
                                <div className="attachment-wrapper pt-2">
                                  <span className="h6 d-inline-block mb-3">
                                    {d?.deliveryAttachments?.length === 1
                                      ? "Attachment"
                                      : "Attachments"}
                                  </span>
                                  <div className="attach-thumb-wrapper d-flex flex-wrap align-items-center gap-3">
                                    {d?.deliveryAttachments?.map((ra, i) => (
                                      <div
                                        className="attach-thumb-item rounded-1 overflow-hidden"
                                        key={i}
                                      >
                                        <figure className="mb-0 bg-light d-flex align-items-center justify-content-center">
                                          <i
                                            className={getFileIcon(
                                              `${BACKEND_URL}/${ra?.filePath}`
                                            )}
                                          />
                                        </figure>
                                        <figcaption className="d-flex justify-content-between align-items-center px-3">
                                          <span className="small">
                                            {truncateFileName(ra?.filename, 10)}{" "}
                                            ({formatFileSize(ra?.filesize)})
                                          </span>
                                          <span className="icons-wrapper">
                                            <span
                                              className="icon file-download secondary-text mx-2 opacity-75"
                                              onClick={() =>
                                                handleDownloadAttachment(
                                                  ra?.filename,
                                                  ra?.filePath
                                                )
                                              }
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={16}
                                                height={16}
                                                fill="currentColor"
                                                className="bi bi-download"
                                                viewBox="0 0 16 16"
                                              >
                                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                              </svg>
                                            </span>
                                          </span>
                                        </figcaption>
                                      </div>
                                    ))}
                                    {/*.attach-thumb-item*/}

                                    {/*.attach-thumb-item rounded-1 overflow-hidden*/}
                                  </div>

                                  {/*.attach-thumb-wrapper*/}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </>
          {userInfo?.userType === "tMember" ||
          userInfo?.userType === "manager" ||
          userInfo?.userType === "admin"
            ? ""
            : orderDetails?.status === "Delivered" && (
                <>
                  <div className="alert-wrapper mt-4">
                    <div
                      className="alert alert-primary d-flex gap-2 "
                      role="alert"
                    >
                      <spa className="icon">
                        <i className="bi bi-info-circle-fill" />
                      </spa>
                      {userInfo?.userType === "viewer" ? (
                        <div className="small">
                          Please note that you have until{" "}
                          <strong>
                            {formatDateTime(orderDetails?.autoComplete)}
                          </strong>{" "}
                          to approve this delivery or request a revision. Please
                          ask your editor if you need any changes or revision.
                        </div>
                      ) : (
                        <div className="small">
                          Please note that you have until{" "}
                          <strong>
                            {formatDateTime(orderDetails?.autoComplete)}
                          </strong>{" "}
                          to approve this delivery or request a revision. After
                          this date, the order will be finalized and marked as
                          complete.
                        </div>
                      )}
                    </div>
                  </div>
                  {(userInfo?.userType === "client" ||
                    userInfo?.userType === "clientTm") && (
                    <>
                      {reqRevision ? (
                        <div className="card-body">
                          <div className="write-msg-box bg-light p-4 mt-4">
                            <div className="d-flex flex-wrap flex-column gap-md-4 gap-3">
                              <div className="d-flex align-items-center">
                                {userInfo?.imageUrl ? (
                                  <img
                                    className="rounded-circle me-3"
                                    src={constructImageUrl(userInfo?.imageUrl)}
                                    width={45}
                                    height={45}
                                  />
                                ) : (
                                  <div className="user-avator-order secondary-bg rounded-circle d-flex align-items-center justify-content-center text-uppercase fw-semibold text-white me-2">
                                    <span className="name-txt">
                                      {`${
                                        userInfo?.firstName
                                          ? userInfo.firstName
                                              .charAt(0)
                                              .toUpperCase()
                                          : ""
                                      }${
                                        userInfo?.lastName
                                          ? userInfo.lastName
                                              .charAt(0)
                                              .toUpperCase()
                                          : ""
                                      }`}
                                    </span>
                                  </div>
                                )}
                                <h6 className="h6 mb-0">
                                  What revisions would you like AAMAX to make?
                                </h6>
                              </div>
                              <textarea
                                className="form-control flex-fill w-100 p-3 ml-1 mt-md-0 mt-2 shadow-none textarea"
                                id="exampleFormControlTextarea3"
                                placeholder="Your description..."
                                rows={8}
                                defaultValue={""}
                                value={revisionDesc.description}
                                onChange={handleRevisionChange}
                              />
                            </div>
                            <div className="d-flex flex-wrap justify-content-between gap-3 mb-0 mt-3 mt-md-4">
                              <div className="d-flex flex-wrap gap-3 align-items-center">
                                <button
                                  className="btn primary-btn flex-fill fw-semibold fs-6 px-4 py-2"
                                  type="button"
                                  onClick={sendRevisionReq}
                                  disabled={uploading}
                                >
                                  {uploading && (
                                    <span className="spinner-grow spinner-grow-sm me-2"></span>
                                  )}

                                  {uploading
                                    ? "Uploading..."
                                    : "Send Revision Request"}
                                </button>
                                <button
                                  className="btn primary-btn-outline fw-semibold flex-fill fs-6 px-4 py-2"
                                  type="button"
                                  onClick={revisionClickHandler}
                                >
                                  Back
                                </button>
                              </div>
                              <ul className="textarea-meta-list list-unstyled d-flex flex-wrap align-items-center gap-2 ps-0 mb-0">
                                <li>
                                  <span className="secondary-text small">
                                    <small>
                                      {requirementsFiles?.length > 0 &&
                                      currentFileIndex !== null
                                        ? `Uploading ${currentFileIndex + 1}/${
                                            requirementsFiles?.length
                                          } (${Math.round(
                                            (currentChunkIndex /
                                              Math.ceil(
                                                requirementsFiles[
                                                  currentFileIndex
                                                ]?.size / chunkSize
                                              )) *
                                              100
                                          )}%)`
                                        : requirementsFiles?.length > 0
                                        ? `${requirementsFiles?.length} ${
                                            requirementsFiles?.length === 1
                                              ? "File"
                                              : "Files"
                                          } Attached`
                                        : ""}
                                    </small>
                                  </span>
                                </li>

                                <li className="file-attachment">
                                  {requirementsFiles?.length > 0 &&
                                  currentFileIndex === null ? (
                                    <button className="btn text-secondary p-1 pe-0 opacity-75 ms-2">
                                      <Tooltip
                                        title="Reset Selection"
                                        mouseEnterDelay={0.1}
                                      >
                                        <img
                                          src="/assets/images/x.svg"
                                          alt="X Icon"
                                          width="20"
                                          height="20"
                                          onClick={resetSelection}
                                        />
                                      </Tooltip>
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      className="btn p-1 px-2 border mx-1 rounded-2 text-secondary"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={20}
                                        height={20}
                                        fill="currentColor"
                                        className="bi bi-paperclip"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                                      </svg>
                                      <Tooltip
                                        title="Max 50MB/File"
                                        mouseEnterDelay={0.1}
                                      >
                                        <input
                                          type="file"
                                          name="files"
                                          multiple
                                          onChange={handleRevisionAttachments}
                                        />{" "}
                                        <small>Attach files</small>
                                      </Tooltip>
                                    </button>
                                  )}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="card-body">
                          <div className="cta-msg-box border text-center p-4 p-md-5 bg-light bg-opacity-50">
                            <div className="cta-msg-box-inner">
                              <div className="icon-wrapper mb-4">
                                <img
                                  src="./assets/images/logo-dark.png"
                                  className="img-fluid"
                                  alt
                                />
                              </div>
                              <div className="cta-msg-content pt-3">
                                <h5>
                                  Are you satisfied with the delivery and ready
                                  to approve it?
                                </h5>
                                <div className="btn-wrapper pt-3 d-flex flex-wrap gap-3 justify-content-center">
                                  <Popconfirm
                                    title="Confirm Complete"
                                    description="Are you sure you want to complete this order?"
                                    onConfirm={() => confirm(reqOrderId)}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <button className="btn primary-btn fw-semibold fs-6 px-4 py-2">
                                      Approve Delivery
                                    </button>
                                  </Popconfirm>
                                  <button
                                    className="btn primary-btn-outline fw-semibold fs-6 px-4 py-2"
                                    onClick={revisionClickHandler}
                                  >
                                    Request Revision
                                  </button>
                                </div>
                              </div>
                            </div>
                            {/*.cta-msg-box-inner*/}
                          </div>
                          {/*.cta-msg-box*/}
                        </div>
                      )}
                    </>
                  )}
                  {/*.card-body*/}
                </>
              )}
        </>
      )}
    </>
  );
}

export default Delivery;
