import { useContext, useEffect, useState } from "react";
import { LoadingContext } from "../context/LoadingLogo";
import { ORDER_API_URL } from "../config/endPoints";
import axios from "axios";
import { Order } from "../context/Order";

function useOrderDetails() {
  const { setLogoLoading } = useContext(LoadingContext);
  const { orderId, sOrderId } = useContext(Order);

  const [data, setData] = useState({});

  const reqOrderId = sOrderId ? sOrderId : orderId;

  const fetchData = async () => {
    try {
      if (!reqOrderId) {
        return;
      }

      setLogoLoading(true);

      const res = await axios.get(`${ORDER_API_URL}/${reqOrderId}`);
      setData(res?.data?.order);
      setLogoLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLogoLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return data;
}

export default useOrderDetails;
