import React, { useContext } from "react";
import Footer from "../../components/layout/Footer";
import ClientHeader from "../../components/layout/client/ClientHeader";
import DashboardSearchOrder from "../../components/client/dashboard/DashboardSearchOrder";
import DashboardOrdersDetail from "../../components/client/dashboard/DashboardOrdersDetail";
import NoOrders from "../../components/client/dashboard/NoOrders";
import { LoadingContext } from "../../context/LoadingLogo";
import DashboardSkeleton from "../../components/client/dashboard/DashboardSkeleton";
import useAllOrders from "../../hooks/useAllOrders";
import { Helmet } from "react-helmet";

function Dashboard() {
  const allOrders = useAllOrders();

  const { logoLoading } = useContext(LoadingContext);

  return (
    <>
      <Helmet>
        <title>Dashboard – AAMAX</title>
      </Helmet>
      {/*=============== Start AAMAX OMS  ===============*/}

      <ClientHeader>
        {/*.header*/}

        <main className="page-content d-flex flex-column justify-content-between h-100">
          <div className="container-fluid p-3 p-md-4">
            {logoLoading ? (
              <>
                <div className="row">
                  <div className="col-12">
                    <div className="page-card rounded-3 position-relative p-3 p-md-4">
                      <div className="card-header p-3 bg-light">
                        <DashboardSkeleton />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : allOrders?.length === 0 ? (
              <NoOrders />
            ) : (
              <>
                {/*.row*/}
                <div className="row">
                  <div className="col-12">
                    <div className="page-card rounded-3 position-relative p-3 p-md-4">
                      <div className="card-header p-3 bg-light">
                        <DashboardSearchOrder />
                      </div>
                      {/*.card-header*/}
                      <DashboardOrdersDetail />
                      {/*.card-body*/}
                      {/* <div class="card-footer p-3 p-md-4 pt-md-0 pt-0 d-flex justify-content-end">
                  <nav aria-label="Page navigation example">
                      <ul class="pagination">
                        <li class="page-item">
                          <a class="page-link" href="#" aria-label="Previous">
                            <span aria-hidden="true"><i class="bi bi-chevron-left"></i></span>
                          </a>
                        </li>
                        <li class="page-item"><a class="page-link active" href="#">1</a></li>
                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                        <li class="page-item">
                          <a class="page-link" href="#" aria-label="Next">
                            <span aria-hidden="true"><i class="bi bi-chevron-right"></i></span>
                          </a>
                        </li>
                      </ul>
                  </nav>
              </div> */}
                      {/*.card-footer*/}
                    </div>
                    {/*.page-card*/}
                  </div>
                  {/*.col-grid*/}
                </div>
              </>
            )}
            {/*.row*/}
          </div>
          {/*.container-fluid*/}
          <Footer />
        </main>
        {/*.page-content"*/}
        {/*=============== End AAMAX OMS  ===============*/}
      </ClientHeader>

      {/*.page-content"*/}
      {/*=============== End AAMAX OMS  ===============*/}
    </>
  );
}

export default Dashboard;
