import axios from "axios";
import React, { createContext, useState } from "react";
import { CREDITS_API_URL } from "../config/endPoints";
import { message } from "antd";

const CreditCtx = createContext();

const CreditContext = ({ children }) => {
  const [credits, setCredits] = useState([]);

  const getCredits = async (token) => {
    try {
      if (token) {
        const res = await axios.get(CREDITS_API_URL);
        setCredits(res?.data?.credits);
      }
    } catch (err) {
      message.error(err?.response?.data?.error);
    }
  };

  return (
    <CreditCtx.Provider
      value={{
        credits,
        getCredits,
      }}
    >
      {children}
    </CreditCtx.Provider>
  );
};

export { CreditContext, CreditCtx };
