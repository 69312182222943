import React, { useContext, useEffect, useState } from "react";
import { Order } from "../../../context/Order";
import client from "../../../config/apolloClient";
import { PACKAGE_QUERY } from "../../../graphql/queries/PackageQuery";
import { formatCurrency, orderDuration } from "../../../utils/helpers";
import { AuthContext } from "../../../context/Auth";
import { Link } from "react-router-dom";

function DetailsTab() {
  const { orderDetails } = useContext(Order);
  const { userInfo } = useContext(AuthContext);

  const [serviceFeatures, setServiceFeatures] = useState();

  const expDelvAmountCalc =
    0.2 * (orderDetails?.quantity * orderDetails?.price);

  const expDeliveryAmount = orderDetails?.expDelivery ? expDelvAmountCalc : 0;

  const tenPercAmount = 0.1 * (orderDetails?.quantity * orderDetails?.price);
  const expTenPercAmount = 0.1 * expDeliveryAmount;

  const bankTransferAmount =
    orderDetails?.paymentMethod === "Bank Transfer"
      ? tenPercAmount + expTenPercAmount
      : 0;

  const packageUrl = orderDetails?.packageUrl;

  const fetchSFeatures = async () => {
    const { data } = await client.query({
      query: PACKAGE_QUERY,
      variables: {
        url: packageUrl,
      },
    });
    setServiceFeatures(data?.package?.data);
  };

  useEffect(() => {
    if (packageUrl) {
      fetchSFeatures();
    }
  }, [packageUrl]);

  return (
    <>
      <ul className="list-group border-0 rounded-0">
        <li className="list-group-item px-2 py-3 d-flex flex-wrap align-items-center border-0 border-bottom">
          <h4 className="h6 m-0 d-inline-flex align-items-center">
            Order number
          </h4>
          <span className="small d-inline-flex text-secondary ms-4 ps-2">
            {orderDetails?.orderNumber}
          </span>
        </li>
        <li className="list-group-item px-2 py-3 d-flex flex-wrap align-items-center border-0 border-bottom">
          <div className="table-responsive flex-grow-1">
            <table className="table table-header-white mb-0">
              <thead>
                <tr>
                  <th scope="col">Service</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Duration</th>
                  {(userInfo?.userType === "admin" ||
                    userInfo?.userType === "client" ||
                    userInfo?.userType === "clientTm" ||
                    userInfo?.userType === "viewer") && (
                    <th scope="col">Price</th>
                  )}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border-0">
                    {orderDetails?.service} ({orderDetails?.package}
                    )
                    <br />
                    <ul className="ps-2 mt-2">
                      {serviceFeatures?.attributes?.features?.map((sf, i) => (
                        <li className="mb-1" key={i}>
                          {sf?.name}
                        </li>
                      ))}
                    </ul>
                  </td>
                  <td className="border-0">{orderDetails?.quantity}</td>
                  <td className="border-0">
                    {orderDuration(
                      serviceFeatures?.attributes?.duration,
                      orderDetails?.expDelivery
                    )}
                  </td>
                  {(userInfo?.userType === "admin" ||
                    userInfo?.userType === "client" ||
                    userInfo?.userType === "clientTm" ||
                    userInfo?.userType === "viewer") && (
                    <td className="border-0">
                      {formatCurrency(
                        orderDetails?.quantity * orderDetails?.price
                      )}
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        </li>
        {(userInfo?.userType === "admin" ||
          userInfo?.userType === "client" ||
          userInfo?.userType === "clientTm" ||
          userInfo?.userType === "viewer") && (
          <>
            <li className="list-group-item px-2 py-3 d-flex flex-wrap justify-content-between align-items-center border-0 border-bottom">
              <h4 className="h6 m-0">Subtotal</h4>
              <span className="d-inline-flex ms-4 ps-2">
                {formatCurrency(orderDetails?.quantity * orderDetails?.price)}
              </span>
            </li>
            <li className="list-group-item px-2 py-3 d-flex flex-wrap justify-content-between align-items-center border-0 border-bottom">
              <h4 className="h6 m-0">Express Delivery</h4>
              <span className="d-inline-flex ms-4 ps-2">
                {formatCurrency(expDeliveryAmount)}
              </span>
            </li>
            <li className="list-group-item px-2 py-3 d-flex flex-wrap justify-content-between align-items-center border-0 border-bottom">
              <h4 className="h6 m-0">Bank Transfer Discount</h4>
              <span className="d-inline-flex ms-4 ps-2">
                -{formatCurrency(bankTransferAmount)}
              </span>
            </li>
            <li className="list-group-item px-2 py-3 primary-bg-light d-flex flex-wrap justify-content-between align-items-center border-0 border-bottom">
              <h4 className="h6 m-0">Total</h4>
              <span className="d-inline-flex ms-4 ps-2">
                {formatCurrency(orderDetails?.totalPaidAmount)}
              </span>
            </li>
          </>
        )}
      </ul>
      {(userInfo?.userType === "admin" ||
        userInfo?.userType === "client" ||
        userInfo?.userType === "clientTm" ||
        userInfo?.userType === "viewer") && (
        <p className="small text-center mb-0 mt-4">
          If anything appears to be missing or incorrect, please do not hesitate
          to contact our{" "}
          <Link to="/contact-support">Customer Support Team</Link>.
        </p>
      )}
    </>
  );
}

export default DetailsTab;
