import { Tooltip, message } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  ADD_CREDIT_API_URL,
  PAYMENT_INTENT_API_URL,
  UPLOAD_API_URL,
} from "../../../config/endPoints";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { formatCurrency, truncateFileName } from "../../../utils/helpers";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { AuthContext } from "../../../context/Auth";
import CreditPayPalButton from "../../../utils/CreditPayPalButton";

function PaymentMethodsSec() {
  const MAX_ATTACHMENTS = 1;
  const MAX_FILE_SIZE_MB = 50;
  const chunkSize = 500 * 1024;

  const navigate = useNavigate();

  const { userInfo } = useContext(AuthContext);

  const [modalType, setModalType] = useState("cardPaymentModal");
  const [paymentMethod, setPaymentMethod] = useState("Card");
  const [credit, setCredit] = useState(0);
  const [verifyingAmount, setVerifyingAmount] = useState(0);
  const [requirementsFiles, setRequirementsFiles] = useState([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(null);
  const [lastUploadedFileIndex, setLastUploadedFileIndex] = useState(null);
  const [currentChunkIndex, setCurrentChunkIndex] = useState(null);
  const [uploadedAttachments, setUploadedAttachments] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cardHolderName, setCardHolderName] = useState("");

  const paymentProof = uploadedAttachments;

  const creditAsNumber = parseFloat(credit);

  const tenPercAmount = 0.1 * creditAsNumber;

  const bankTransferAmount =
    paymentMethod === "Bank Transfer" ? tenPercAmount : 0;

  const totalCredit = creditAsNumber + bankTransferAmount;

  useEffect(() => {
    setVerifyingAmount(totalCredit);
  }, [totalCredit]);

  const formData = {
    credit,
    paymentMethod,
    paymentProof,
    verifyingAmount,
  };

  const resetSelection = () => {
    setCurrentFileIndex(null);
    setLastUploadedFileIndex(null);
    setCurrentChunkIndex(null);
    setUploadedAttachments([]);
    setRequirementsFiles([]);
  };

  const handlePaymentProofChange = (e) => {
    const files = e.target.files;
    if (files.length > MAX_ATTACHMENTS) {
      message.error(`You can only attach ${MAX_ATTACHMENTS} file.`);
      e.target.value = null;
      return;
    }

    for (let i = 0; i < files.length; i++) {
      if (files[i].size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        message.error(`File size should not exceed ${MAX_FILE_SIZE_MB} MB.`);
        e.target.value = null;
        return;
      }
    }

    setRequirementsFiles(files);
  };

  function readNUploadCurrentChunk() {
    const reader = new FileReader();
    const file = requirementsFiles[currentFileIndex];
    if (!file) {
      return;
    }
    const from = currentChunkIndex * chunkSize;
    const to = from + chunkSize;
    const blob = file.slice(from, to);
    reader.onload = (e) => uploadAChunk(e);
    reader.readAsDataURL(blob);
  }

  function uploadAChunk(readerEvent) {
    const file = requirementsFiles[currentFileIndex];
    const data = readerEvent.target.result;
    const params = new URLSearchParams();
    params.set("name", file.name);
    params.set("size", file.size);
    params.set("currentChunkIndex", currentChunkIndex);
    params.set("totalChunks", Math.ceil(file.size / chunkSize));
    params.set("fileType", "paymentproof");
    const headers = { "Content-Type": "application/octet-stream" };
    const url = `${UPLOAD_API_URL}?` + params.toString();
    axios.post(url, data, { headers }).then((response) => {
      const file = requirementsFiles[currentFileIndex];
      const filesize = requirementsFiles[currentFileIndex].size;
      const chunks = Math.ceil(filesize / chunkSize) - 1;
      const isLastChunk = currentChunkIndex === chunks;
      if (isLastChunk) {
        file.finalFilename = response.data.finalFilename;

        setLastUploadedFileIndex(currentFileIndex);
        setCurrentChunkIndex(null);
        setUploadedAttachments((prevAttachments) => [
          ...prevAttachments,
          {
            filename: file.name,
            filePath: response.data,
            filesize: filesize,
          },
        ]);
      } else {
        setCurrentChunkIndex(currentChunkIndex + 1);
      }
    });
  }

  useEffect(() => {
    if (lastUploadedFileIndex === null) {
      return;
    }
    const isLastFile = lastUploadedFileIndex === requirementsFiles.length - 1;
    const nextFileIndex = isLastFile ? null : currentFileIndex + 1;
    setCurrentFileIndex(nextFileIndex);
  }, [lastUploadedFileIndex]);

  useEffect(() => {
    if (requirementsFiles.length > 0) {
      if (currentFileIndex === null) {
        setCurrentFileIndex(
          lastUploadedFileIndex === null ? 0 : lastUploadedFileIndex + 1
        );
      }
    }
  }, [requirementsFiles.length]);

  useEffect(() => {
    if (currentFileIndex !== null) {
      setCurrentChunkIndex(0);
    }
  }, [currentFileIndex]);

  useEffect(() => {
    if (currentChunkIndex !== null) {
      readNUploadCurrentChunk();
    }
  }, [currentChunkIndex]);

  useEffect(() => {
    if (requirementsFiles?.length > 0 && currentFileIndex !== null) {
      setUploading(true);
    } else {
      setUploading(false);
    }
  }, [requirementsFiles, currentFileIndex]);

  const modalTypeHandler = (id, pm) => {
    setModalType(id);
    setPaymentMethod(pm);
  };

  const handleAddCredit = async (e) => {
    e.preventDefault();
    if (paymentMethod === "Bank Transfer" && paymentProof.length === 0) {
      message.error("Please provide payment proof to add the credit.");
      return;
    }
    try {
      setLoading(true);
      await axios.post(ADD_CREDIT_API_URL, formData);
      message.success(
        paymentMethod === "Bank Transfer"
          ? "Payment proof submitted! We'll verify and update your account soon."
          : "Success! Your account has been credited."
      );
      setLoading(false);
      const cancelButton = document.querySelector(
        "#bankPaymentModal .btn-light"
      );
      if (cancelButton) {
        cancelButton.click();
      }
      const cancelCardModal = document.querySelector(
        "#cardPaymentModal .btn-close-white"
      );
      if (cancelCardModal) {
        cancelCardModal.click();
      }
      const PayPalModal = document.querySelector(
        "#paypalPaymentModal .btn-close-white"
      );
      if (PayPalModal) {
        PayPalModal.click();
      }
      navigate(
        paymentMethod === "Bank Transfer"
          ? "/verifying-credit"
          : "/credit-confirmation"
      );
    } catch (err) {
      setLoading(false);
      message.error(err?.response?.data?.error);
    }
  };

  const stripe = useStripe();
  const elements = useElements();

  const stripePaymentSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements || isLoading) {
      return;
    }

    try {
      setIsLoading(true);

      const amount = credit;

      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: {
          email: userInfo?.email,
          name: cardHolderName
            ? cardHolderName
            : `${userInfo?.firstName} ${userInfo?.lastName}`,
        },
      });

      if (error) {
        message.error(error.message);
        return;
      }

      const res = await axios.post(PAYMENT_INTENT_API_URL, { amount });

      const data = await res.data;

      const result = await stripe.confirmCardPayment(data.clientSecret, {
        payment_method: paymentMethod.id,
      });

      if (result.error) {
        message.error(result.error.message);
      } else {
        handleAddCredit(e);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSetCredit = (e) => {
    const newCreditValue = e.target.value;
    setCredit(newCreditValue);
    localStorage.setItem("credit", newCreditValue);
  };

  const handleAddPayPalCredit = async () => {
    const storedCredit = localStorage.getItem("credit");

    const paypalFormData = {
      credit: storedCredit,
      paymentMethod: "PayPal",
    };

    try {
      await axios.post(ADD_CREDIT_API_URL, paypalFormData);
      message.success("Success! Your account has been credited.");

      const PayPalModal = document.querySelector(
        "#paypalPaymentModal .btn-close-white"
      );
      if (PayPalModal) {
        PayPalModal.click();
      }
      navigate("/credit-confirmation");
    } catch (err) {
      message.error(err?.response?.data?.error);
    }
  };

  const paypal = useRef();

  const amountRef = useRef();

  useEffect(() => {
    const storedCredit = localStorage.getItem("credit");
    amountRef.current = storedCredit;

    const clearPayPalButton = () => {
      const paypalElement = paypal.current;
      while (paypalElement.firstChild) {
        paypalElement.removeChild(paypalElement.firstChild);
      }
    };

    const renderPayPalButton = () => {
      window.paypal
        .Buttons({
          createOrder: (data, actions, err) => {
            return actions.order.create({
              intent: "CAPTURE",
              purchase_units: [
                {
                  description: "AAMAX account credit",
                  amount: {
                    currency_code: "USD",
                    value: amountRef.current,
                  },
                },
              ],
            });
          },

          onApprove: async (data, actions) => {
            await actions.order.capture();

            const storedCredit = parseFloat(localStorage.getItem("credit"));

            if (storedCredit !== parseFloat(amountRef.current)) {
              localStorage.setItem("credit", 0);
              message.error(
                "Oops! Something went wrong. Please contact our support team."
              );
              return;
            }

            handleAddPayPalCredit();
            setTimeout(() => {
              localStorage.setItem("credit", 0);
            }, 1000);
          },
          onError: (err) => {
            message.error(
              "Oops! Something went wrong. Please refresh the page and try again."
            );
          },
        })
        .render(paypal.current);
    };

    if (!paypal.current.firstChild) {
      clearPayPalButton();
      renderPayPalButton();
    }
  }, [credit]);

  return (
    <>
      <div className="card-body">
        <div className="payment-method-wrapper mb-4">
          <h4 className="h6 fw-semibold mb-3">Select a payment method</h4>
          <div className="payment-method-list d-flex flex-column">
            <div className="form-check py-2">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="cardPayment"
                defaultChecked
                onClick={() => modalTypeHandler("cardPaymentModal", "Card")}
              />
              <label
                className="form-check-label fw-semibold"
                htmlFor="cardPayment"
              >
                <span className="icon d-inline-block me-2 ms-1 text-secondary">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    fill="currentColor"
                    className="bi bi-credit-card"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z" />
                    <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z" />
                  </svg>
                </span>{" "}
                Card
                <span className="small ps-4 ms-3 text-secondary fw-normal d-block">
                  Pay with credit or debit card
                </span>
              </label>
            </div>
            {/* <div className="form-check py-2">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="paypalPayment"
                onClick={() => modalTypeHandler("paypalPaymentModal", "PayPal")}
              />
              <label
                className="form-check-label fw-semibold"
                htmlFor="paypalPayment"
              >
                <span className="icon d-inline-block me-2 ms-1 secondary-text">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    fill="currentColor"
                    className="bi bi-paypal"
                    viewBox="0 0 16 16"
                  >
                    <path d="M14.06 3.713c.12-1.071-.093-1.832-.702-2.526C12.628.356 11.312 0 9.626 0H4.734a.7.7 0 0 0-.691.59L2.005 13.509a.42.42 0 0 0 .415.486h2.756l-.202 1.28a.628.628 0 0 0 .62.726H8.14c.429 0 .793-.31.862-.731l.025-.13.48-3.043.03-.164.001-.007a.351.351 0 0 1 .348-.297h.38c1.266 0 2.425-.256 3.345-.91.379-.27.712-.603.993-1.005a4.942 4.942 0 0 0 .88-2.195c.242-1.246.13-2.356-.57-3.154a2.687 2.687 0 0 0-.76-.59l-.094-.061ZM6.543 8.82a.695.695 0 0 1 .321-.079H8.3c2.82 0 5.027-1.144 5.672-4.456l.003-.016c.217.124.4.27.548.438.546.623.679 1.535.45 2.71-.272 1.397-.866 2.307-1.663 2.874-.802.57-1.842.815-3.043.815h-.38a.873.873 0 0 0-.863.734l-.03.164-.48 3.043-.024.13-.001.004a.352.352 0 0 1-.348.296H5.595a.106.106 0 0 1-.105-.123l.208-1.32.845-5.214Z" />
                  </svg>
                </span>{" "}
                PayPal
                <span className="payment-item-text small ps-4 ms-3 text-secondary fw-normal d-block">
                  Pay with PayPal
                </span>
              </label>
            </div> */}
            <div className="form-check py-2">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="bankPayment"
                onClick={() =>
                  modalTypeHandler("bankPaymentModal", "Bank Transfer")
                }
              />
              <label
                className="form-check-label fw-semibold"
                htmlFor="bankPayment"
              >
                <span className="icon d-inline-block me-2 ms-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    fill="currentColor"
                    className="bi bi-bank"
                    viewBox="0 0 16 16"
                  >
                    <path d="m8 0 6.61 3h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.38l.5 2a.498.498 0 0 1-.485.62H.5a.498.498 0 0 1-.485-.62l.5-2A.501.501 0 0 1 1 13V6H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 3h.89zM3.777 3h8.447L8 1zM2 6v7h1V6zm2 0v7h2.5V6zm3.5 0v7h1V6zm2 0v7H12V6zM13 6v7h1V6zm2-1V4H1v1zm-.39 9H1.39l-.25 1h13.72l-.25-1Z" />
                  </svg>
                </span>{" "}
                Bank Transfer
                <span className="payment-item-text small ps-4 ms-3 text-secondary fw-normal d-block">
                  Get 10% free credit with bank transfer
                </span>
              </label>
            </div>
          </div>
        </div>
        <div className="amount-input col-8 col-sm-5 col-md-3 col-lg-3 col-xl-2">
          <label
            htmlFor="exampleFormControlInput1"
            className="form-label fw-semibold text-secondary opacity-75 small"
          >
            Amount to add ($)
          </label>
          <input
            type="number"
            value={credit}
            onChange={handleSetCredit}
            className="form-control fw-semibold"
            id="exampleFormControlInput1"
          />
        </div>
        <div className="btn-wrapepr">
          <a
            href="#"
            className="btn primary-btn rounded-1 fw-semibold mt-4 d-inline-flex align-items-center"
            data-bs-toggle="modal"
            data-bs-target={`#${modalType}`}
          >
            <span className="icon d-inline-flex me-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={14}
                height={14}
                fill="currentColor"
                className="bi bi-shield-check"
                viewBox="0 0 16 16"
              >
                <path
                  strokeWidth=".75"
                  stroke="currentColor"
                  d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z"
                />
                <path
                  strokeWidth=".75"
                  stroke="currentColor"
                  d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                />
              </svg>
            </span>
            {credit === 0 ? "Add Credit" : `Pay ${formatCurrency(credit)}`}
          </a>
        </div>
        <div
          className="modal fade"
          id="cardPaymentModal"
          tabIndex={-1}
          aria-labelledby="cardPaymentModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header dark-bg">
                <h1
                  className="modal-title fs-5 text-white"
                  id="cardPaymentModalLabel"
                >
                  Please enter your card details
                </h1>
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <form className="needs-validation">
                  <div className="row gy-3 mb-4">
                    <div className="col-md-12 position-relative">
                      <label
                        htmlFor="card-element"
                        className="form-label small fw-semibold"
                      >
                        Card number
                      </label>
                      <CardElement
                        className="card-form-control"
                        id="card-element"
                        options={{
                          style: {
                            base: {
                              fontSize: "16px",
                              color: "#424770",
                              "::placeholder": {
                                color: "#aab7c4",
                              },
                            },
                          },
                        }}
                      />
                    </div>
                    <div className="col-md-12">
                      <label
                        htmlFor="card-holder-name"
                        className="form-label small fw-semibold"
                      >
                        Cardholder's name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="card-holder-name"
                        placeholder="Enter cardholder's name"
                        value={cardHolderName}
                        onChange={(e) => setCardHolderName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row mb-3 align-items-center justify-content-between">
                    <div className="col-auto">
                      <h5 className=" fs-5 mb-0">Billing information</h5>
                    </div>
                    <div className="col-auto text-end">
                      <a
                        href="/account-settings"
                        className="small"
                        target="_blank"
                      >
                        <span className="icon me-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={14}
                            height={14}
                            fill="currentColor"
                            className="bi bi-pencil-square"
                            viewBox="0 0 16 16"
                          >
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                            <path
                              fillRule="evenodd"
                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                            />
                          </svg>
                        </span>
                        Update billing info
                      </a>
                    </div>
                  </div>
                  {/*.row*/}
                  <div className="row g-3">
                    <div className="col-12">
                      <ul className="list-unstyled bg-light py-3 px-3 mb-4">
                        <li className="small fw-semibold mb-2">
                          {`${userInfo?.firstName} ${userInfo?.lastName}`}
                        </li>
                        <li className="small text-secondary">
                          {userInfo?.company && `${userInfo?.company} `}
                        </li>
                        <li className="small text-secondary">
                          {userInfo?.address && `${userInfo?.address}, `}
                          {userInfo?.state && `${userInfo?.state}, `}
                          {userInfo?.zipCode && `${userInfo?.zipCode}, `}
                        </li>
                        <li className="small text-secondary">
                          {userInfo?.country && `${userInfo?.country}`}
                        </li>
                        <li className="small text-secondary">
                          {userInfo?.phone && userInfo?.phone}
                        </li>
                        <li className="small text-secondary">
                          {userInfo?.email && userInfo?.email}
                        </li>
                      </ul>
                    </div>
                  </div>

                  <button
                    className="w-100 btn secondary-btn rounded-2 fs-6 py-2 fw-semibold"
                    type="button"
                    onClick={stripePaymentSubmit}
                    disabled={loading || isLoading}
                  >
                    {(loading || isLoading) && (
                      <span className="spinner-grow spinner-grow-sm me-2"></span>
                    )}

                    {loading || isLoading
                      ? "Processing Order..."
                      : `Proceed to Pay ${formatCurrency(credit)}`}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="paypalPaymentModal"
          tabIndex={-1}
          aria-labelledby="paypalPaymentModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header dark-bg">
                <h1
                  className="modal-title fs-5 text-white"
                  id="paypalPaymentModalLabel"
                >
                  Please make the paymet with PayPal
                </h1>
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <form className="needs-validation">
                  <div ref={paypal}></div>
                  <button
                    className="w-100 btn secondary-btn rounded-2 fs-6 py-2 fw-semibold"
                    type="button"
                    disabled={true}
                  >
                    {`Total to Pay ${formatCurrency(credit)}`}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="bankPaymentModal"
          tabIndex={-1}
          aria-labelledby="bankPaymentModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-xl-down modal-lg modal-xl">
            <div className="modal-content">
              <div className="modal-header dark-bg">
                <h1
                  className="modal-title fs-5 text-white"
                  id="bankPaymentModalLabel"
                >
                  Bank Tranfser Details
                </h1>
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              {/*.modal-header*/}
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-9">
                    <p className="note-bank-tranfer small">
                      <span className="fw-semibold text-dark">
                        You will Pay
                      </span>{" "}
                      {formatCurrency(credit)} and{" "}
                      <span className="fw-semibold text-dark">will Get</span>{" "}
                      {formatCurrency(totalCredit)} Credit
                    </p>
                  </div>
                </div>
                <div className="row gy-3">
                  <div className="col-lg-4 col-md-12">
                    <div className="bank-transfer-wrapper border rounded p-4 h-100">
                      <div className="bank-logo-wrapper pb-md-2 mb-4">
                        <img
                          src="./assets/images/Payoneer_logo.png"
                          className="img-fluid"
                          alt
                        />
                      </div>
                      <div className="bank-details">
                        <ul className="bank-details-list list-unstyled d-flex flex-column m-0">
                          <li>
                            <span className="fw-semibold">
                              aamconsultants17@gmail.com
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/*.col-grid*/}
                  <div className="col-lg-4 col-md-6">
                    <div className="bank-transfer-wrapper border rounded p-4 h-100">
                      <div className="bank-logo-wrapper pb-md-2 mb-4">
                        <img
                          src="./assets/images/bank-transfer.png"
                          className="img-fluid"
                          alt
                        />
                      </div>
                      <div className="bank-details">
                        <ul className="bank-details-list list-unstyled d-flex flex-column m-0">
                          <li>
                            <span className="fw-semibold">Bank Name:</span>
                            <p>First Century Bank</p>
                          </li>
                          <li>
                            <span className="fw-semibold">Bank address:</span>
                            <p>1731 N Elm St Commerce, GA 30529 USA</p>
                          </li>
                          <li>
                            <span className="fw-semibold">Routing(ABA):</span>
                            <p>061120084</p>
                          </li>
                          <li>
                            <span className="fw-semibold">Account number:</span>
                            <p>4016176037730</p>
                          </li>
                          <li>
                            <span className="fw-semibold">Account type:</span>
                            <p>Checking</p>
                          </li>
                          <li>
                            <span className="fw-semibold">
                              Beneficiary name:
                            </span>
                            <p>Muhammad Abdullah</p>
                          </li>
                          <li className="mt-3">
                            <span className="text-danger fw-semibold">
                              Please make payments only from the <br /> company
                              bank account; payments from <br />
                              personal accounts won't go through.
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/*.col-grid*/}
                  <div className="col-lg-4 col-md-6">
                    <div className="bank-transfer-wrapper border rounded p-4 h-100">
                      <div className="bank-logo-wrapper pb-md-2 mb-4">
                        <img
                          src="./assets/images/bank-transfer.png"
                          className="img-fluid"
                          alt
                        />
                      </div>
                      <div className="bank-details">
                        <ul className="bank-details-list list-unstyled d-flex flex-column m-0">
                          <li>
                            <span className="fw-semibold">Bank Name:</span>
                            <p>Barclays</p>
                          </li>
                          <li>
                            <span className="fw-semibold">Sort code:</span>
                            <p>231486</p>
                          </li>
                          <li>
                            <span className="fw-semibold">Account number:</span>
                            <p>00914185</p>
                          </li>
                          <li>
                            <span className="fw-semibold">
                              Beneficiary name:
                            </span>
                            <p>Muhammad Abdullah</p>
                          </li>
                          <li className="mt-3">
                            <span className="text-danger fw-semibold">
                              Please make payments only from the <br /> company
                              bank account; payments from <br />
                              personal accounts won't go through.
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/*.col-grid*/}
                </div>
                {/*.row*/}
              </div>
              {/*.modal-body*/}
              <div className="modal-footer d-flex flex-wrap justify-content-between gap-3">
                <div className="modal-footer-left">
                  {requirementsFiles?.length > 0 &&
                  currentFileIndex === null ? (
                    <ul className="textarea-meta-list list-unstyled d-flex flex-wrap align-items-center ps-0 mb-0">
                      <li>
                        <span className="d-inline-block small my-1 me-1 fw-semibold">
                          Attached Payment Proof :
                        </span>
                      </li>

                      <li>
                        <span className="secondary-text small">
                          <small>
                            <button className="btn text-secondary p-1 pe-0 opacity-75 ms-2">
                              <Tooltip
                                title="Reset Selection"
                                mouseEnterDelay={0.1}
                              >
                                <img
                                  src="/assets/images/x.svg"
                                  alt="X Icon"
                                  width="20"
                                  height="20"
                                  onClick={resetSelection}
                                />
                              </Tooltip>
                            </button>
                          </small>
                        </span>
                      </li>
                      <li>
                        <span className="secondary-text small">
                          <small>
                            {truncateFileName(requirementsFiles[0]?.name, 30)}
                          </small>
                        </span>
                      </li>
                    </ul>
                  ) : (
                    <ul className="textarea-meta-list list-unstyled d-flex flex-wrap align-items-center ps-0 mb-0">
                      <li>
                        <span className="d-inline-block small my-1 me-1 fw-semibold">
                          Payment proof :
                        </span>
                      </li>
                      <li className="file-attachment">
                        <button
                          type="button"
                          className="btn p-1 px-2 border primary-border primary-text mx-1 rounded-2 text-secondary bg-white"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            fill="currentColor"
                            className="bi bi-paperclip"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                          </svg>
                          <Tooltip title="Max 50MB/File" mouseEnterDelay={0.1}>
                            <input
                              type="file"
                              name="file"
                              multiple={false}
                              onChange={handlePaymentProofChange}
                            />
                          </Tooltip>{" "}
                          <small className="primary-text">
                            Attach screenshot...
                          </small>
                        </button>
                      </li>
                    </ul>
                  )}
                </div>
                <div className="btn-wrapper text-end">
                  <a
                    href="#"
                    className="fs-6 btn btn-light me-2 px-4 py-2 text-decoration-none"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={resetSelection}
                  >
                    Cancel
                  </a>
                  <button
                    type="submit"
                    className="btn primary-btn fw-semibold fs-6 px-4 py-2"
                    onClick={handleAddCredit}
                    disabled={uploading || loading}
                  >
                    {uploading && (
                      <span className="spinner-grow spinner-grow-sm me-2"></span>
                    )}

                    {uploading
                      ? "Uploading..."
                      : loading
                      ? "Processing..."
                      : "Submit"}
                  </button>
                </div>
              </div>
            </div>
            {/*.modal-content*/}
          </div>
          {/*.modal-dialog*/}
        </div>
      </div>
    </>
  );
}

export default PaymentMethodsSec;
