import React, { useContext } from "react";
import html2pdf from "html2pdf.js";
import { InvoiceCtx } from "../../../context/Invoice";

function UIHeading() {
  const { unpaidInvNo } = useContext(InvoiceCtx);

  const downloadPDF = () => {
    const content = document.getElementById("unpaid-invoice");

    const contentClone = content.cloneNode(true);

    const downloadButton = contentClone.querySelector(".icon");
    const submitPaymentButton = contentClone.querySelector(".btn.oms-btn");

    if (downloadButton) {
      downloadButton.parentElement.remove();
    }

    if (submitPaymentButton) {
      submitPaymentButton.remove();
    }

    html2pdf()
      .from(contentClone)
      .set({
        image: { type: "jpeg", quality: 1 },
        html2canvas: { dpi: 200, letterRendering: true },
        jsPDF: { unit: "in", format: "letter" },
      })
      .save(`${unpaidInvNo}.pdf`);

    contentClone.remove();
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-header d-flex flex-wrap justify-content-between align-items-center py-2 mb-lg-2">
            <h4 className="my-1 fw-bold">
              Invoice - <span className="text-danger">Overdue</span>
            </h4>
            <ul className="list-unstyled control-list m-0 d-flex flex-wrap gap-md-4 mt-3 mt-md-0">
              <li className="me-3 me-md-0">
                <a
                  href="#"
                  className="text-decoration-none small d-flex align-items-center my-1"
                  onClick={downloadPDF}
                >
                  <span className="icon d-inline-flex me-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={14}
                      height={14}
                      fill="currentColor"
                      className="bi bi-download"
                      viewBox="0 0 16 16"
                    >
                      <path
                        strokeWidth=".75"
                        stroke="currentColor"
                        d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"
                      />
                      <path
                        strokeWidth=".75"
                        stroke="currentColor"
                        d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"
                      />
                    </svg>
                  </span>
                  Download PDF
                </a>
              </li>
            </ul>
          </div>
          {/*.page-header*/}
        </div>
        {/*.col-grid*/}
      </div>
    </>
  );
}

export default UIHeading;
