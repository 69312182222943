import React, { useContext } from "react";
import Footer from "../../components/layout/Footer";
import ClientHeader from "../../components/layout/client/ClientHeader";
import NoOrders from "../../components/client/dashboard/NoOrders";
import DashboardSearchOrder from "../../components/client/dashboard/DashboardSearchOrder";
import useCompOrders from "../../hooks/useCompOrders";
import CompletedOrdersDetail from "../../components/client/completedOrders/CompletedOrdersDetail";
import DashboardSkeleton from "../../components/client/dashboard/DashboardSkeleton";
import { LoadingContext } from "../../context/LoadingLogo";
import { Helmet } from "react-helmet";

function CompletedOrders() {
  const cOrders = useCompOrders();

  const { logoLoading } = useContext(LoadingContext);

  return (
    <>
      <Helmet>
        <title>Completed Orders – AAMAX</title>
      </Helmet>
      {/*=============== Start AAMAX OMS  ===============*/}

      <ClientHeader>
        {/*.header*/}
        <main
          className="page-content d-flex flex-column justify-content-between h-100"
          id="price-scroll"
        >
          <div className="container-fluid p-3 p-md-4">
            {logoLoading ? (
              <>
                <div className="row">
                  <div className="col-12">
                    <div className="page-card rounded-3 position-relative p-3 p-md-4">
                      <div className="card-header p-3 bg-light">
                        <DashboardSkeleton />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : cOrders?.length === 0 ? (
              <NoOrders status={"completed"} />
            ) : (
              <>
                {/*.row*/}
                <div className="row">
                  <div className="col-12">
                    <div className="page-card rounded-3 position-relative p-3 p-md-4">
                      <div className="card-header p-3 bg-light">
                        <DashboardSearchOrder />
                      </div>
                      {/*.card-header*/}
                      <CompletedOrdersDetail />
                      {/*.card-body*/}
                      {/*.card-footer*/}
                    </div>
                    {/*.page-card*/}
                  </div>
                  {/*.col-grid*/}
                </div>
              </>
            )}
            {/*.row*/}
          </div>
          {/*.container-fluid*/}
          <Footer />
        </main>
      </ClientHeader>
      {/*.page-content"*/}
      {/*=============== End AAMAX OMS  ===============*/}
    </>
  );
}

export default CompletedOrders;
