import { gql } from "@apollo/client";

export const ABOUT_US_QUERY = gql`
  query AboutUs {
    aboutUs {
      data {
        attributes {
          mainHeading
          description
          video {
            data {
              attributes {
                url
              }
            }
          }
          icons {
            id
            icon
            heading
            number
            operator
          }
          whyChooseUs {
            id
            heading
            description
            url
          }
          reviews {
            id
            review
            name
            company
            rating
          }
          team {
            id
            name
            designation
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
          videoThumbnail {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;
