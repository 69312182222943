import React, { useContext, useEffect } from "react";
import { OrderCartDetail } from "../../../context/OrderCart";
import { formatCurrency } from "../../../utils/helpers";

function TableMainHeading() {
  const { orderCartData, getOrderDetail } = useContext(OrderCartDetail);

  useEffect(() => {
    getOrderDetail();
  }, []);

  return (
    <>
      <div className="table-responsive mb-3">
        <table className="table emp-attend-list mb-0">
          <thead>
            <tr>
              <th scope="col">Service</th>
              <th scope="col">Quantity</th>
              <th scope="col">Unit Price</th>
              <th scope="col">Net Subtotal</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {orderCartData?.service} ({orderCartData?.package})
              </td>
              <td>{orderCartData?.quantity}</td>
              <td>{formatCurrency(orderCartData?.price)}</td>
              <td>
                {formatCurrency(orderCartData?.quantity * orderCartData?.price)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

export default TableMainHeading;
