import React, { useContext } from "react";
import Footer from "../../components/layout/Footer";
import ClientHeader from "../../components/layout/client/ClientHeader";
import POQMainHeading from "../../components/client/placeOrderQuantity/POQMainHeading";
import POQDetail from "../../components/client/placeOrderQuantity/POQDetail";
import { Helmet } from "react-helmet";
import { OrderCartDetail } from "../../context/OrderCart";

function PlaceOrderQuantity() {
  const { orderCart } = useContext(OrderCartDetail);

  return (
    <>
      <Helmet>
        <title>
          Choose Quantity for Your {orderCart?.service} Order – AAMAX
        </title>
      </Helmet>
      {/*=============== Start AAMAX OMS  ===============*/}

      <ClientHeader>
        {/*.header*/}
        <main className="page-content d-flex flex-column justify-content-between h-100">
          <div className="container-fluid p-3 p-md-4">
            <POQMainHeading />
            {/*.row*/}
            <POQDetail />
            {/*.row*/}
          </div>
          {/*.container-fluid*/}
          <Footer />
        </main>
      </ClientHeader>
      {/*.page-content"*/}
      {/*=============== End AAMAX OMS  ===============*/}
    </>
  );
}

export default PlaceOrderQuantity;
