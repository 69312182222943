import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../../context/Auth";
import { constructImageUrl, formatCurrency } from "../../../utils/helpers";
import { SearchCtx } from "../../../context/SearchContext";

function CreditTable() {
  const { credits, getCredits } = useContext(AuthContext);
  const { searchOrder } = useContext(SearchCtx);

  const token = localStorage.getItem("token");

  const filteredCredits = credits?.filter(
    (user) =>
      user?.firstName?.toLowerCase().includes(searchOrder.toLowerCase()) ||
      user?.lastName?.toLowerCase().includes(searchOrder.toLowerCase()) ||
      user?.email?.toLowerCase().includes(searchOrder.toLowerCase()) ||
      user?.company?.toLowerCase().includes(searchOrder.toLowerCase())
  );

  useEffect(() => {
    if (token) {
      getCredits(token);
    }
  }, []);

  return (
    <>
      <div className="card-body pt-4">
        <div className="table-responsive">
          <table className="table table-hover emp-attend-list">
            <thead>
              <tr>
                <th scope="col">Image</th>
                <th scope="col">Name</th>
                <th scope="col">Company</th>
                <th scope="col">Email</th>
                <th scope="col">Phone Number</th>
                <th scope="col">Credit</th>
              </tr>
            </thead>
            <tbody>
              {filteredCredits?.slice(0, 50).map((c, i) => (
                <tr key={i}>
                  <td>
                    {c?.imageUrl ? (
                      <img
                        className="table-img"
                        src={constructImageUrl(c?.imageUrl)}
                      />
                    ) : (
                      <div className="user-avator-order secondary-bg rounded-circle d-flex align-items-center justify-content-center text-uppercase fw-semibold text-white me-2">
                        <span className="name-txt">
                          {`${
                            c?.firstName
                              ? c?.firstName.charAt(0).toUpperCase()
                              : ""
                          }${
                            c?.lastName
                              ? c?.lastName.charAt(0).toUpperCase()
                              : ""
                          }`}
                        </span>
                      </div>
                    )}
                  </td>
                  <td>{`${c?.firstName} ${c?.lastName}`}</td>
                  <td>{c?.company ? c?.company : "–"}</td>
                  <td>{c?.email}</td>
                  <td>{c?.phone ? c?.phone : "–"}</td>
                  <td>{formatCurrency(c?.credit)}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {/*.edit-user-modal*/}
        </div>
        {/*.table-responsive*/}
      </div>
    </>
  );
}

export default CreditTable;
