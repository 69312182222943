import React, { createContext, useState } from "react";

const LoadingContext = createContext();

const LoadingLogoContext = ({ children }) => {
  const [logoLoading, setLogoLoading] = useState(true);

  return (
    <LoadingContext.Provider
      value={{
        logoLoading,
        setLogoLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

export { LoadingLogoContext, LoadingContext };
