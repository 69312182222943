import React from "react";
import ClientHeader from "../../components/layout/client/ClientHeader";
import Footer from "../../components/layout/Footer";
import { Link } from "react-router-dom";
import { DOMAIN_URL } from "../../config/endPoints";
import { Helmet } from "react-helmet";

function CreditConfirmation() {
  return (
    <>
      <Helmet>
        <title>Credit Transaction Successful – AAMAX</title>
      </Helmet>
      {/*=============== Start AAMAX OMS  ===============*/}

      <ClientHeader>
        {/*.header*/}
        <main className="page-content d-flex flex-column justify-content-between h-100">
          <div className="container-fluid p-3 p-md-4">
            {/*.row*/}
            <div className="row">
              <div className="col-12">
                <div className="page-card rounded-3 position-relative p-3 p-md-4">
                  <section className="oms-404 py-5">
                    <div className="container">
                      <div className="row align-items-center justify-content-center">
                        <div className="col-md-8 col-xl-6 my-3 text-center">
                          <figure className="mb-4 mb-md-5">
                            <img
                              src={`${DOMAIN_URL}/assets/images/success.png`}
                              width={220}
                              className="img-fluid"
                              alt
                            />
                          </figure>
                          <h3 className="fs-1 mb-3">
                            Credit Transaction Successful!
                          </h3>
                          <p className="fs-6 text-muted">
                            Thank you for adding credit to your account! Your
                            transaction was successful, and your account has
                            been credited.{" "}
                          </p>
                          <p className="fs-6 mb-5 text-muted">
                            If you have any questions, please don't hesitate to
                            reach out to our customer support team.
                          </p>
                          <Link to="/credit-history" className="btn oms-btn">
                            Back to Credit History
                          </Link>
                        </div>
                        {/*.col-grid*/}
                      </div>
                      {/*.row*/}
                    </div>
                    {/*.container*/}
                  </section>
                  {/*.card-body*/}
                </div>
                {/*.page-card*/}
              </div>
              {/*.col-grid*/}
            </div>
            {/*.row*/}
          </div>
          {/*.container-fluid*/}
          <Footer />
        </main>
      </ClientHeader>
      {/*.page-content"*/}
      {/*=============== End AAMAX OMS  ===============*/}
    </>
  );
}

export default CreditConfirmation;
