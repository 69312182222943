import React from "react";
import { formatDateTime } from "../../../utils/helpers";

function WaitForDelivery({ devlDate, oStatus }) {
  return (
    <>
      <div>
        {/*.alert-wrapper*/}
        <div className="delivery-progress text-center pt-4 mt-3">
          <div className="img-wrapper mb-4 pb-1">
            <img
              src={
                oStatus === "Cancelled"
                  ? "./assets/images/cancelled.png"
                  : "./assets/images/hourglass.png"
              }
              className="img-fluid"
              alt
              style={{ maxWidth: 85, maxHeight: 85 }}
            />
          </div>
          <h5 className="h5 fw-semibold mb-3">
            {oStatus === "Cancelled"
              ? "Your order have been cancelled."
              : "The best things are worth the wait"}
          </h5>
          <p className="small">
            {" "}
            {oStatus === "Cancelled" ? (
              "If you have any questions, please contact customer service."
            ) : (
              <>
                <span className="primary-text fw-semibold">AAMAX</span> is
                expected to deliver this order by {formatDateTime(devlDate)}
              </>
            )}
          </p>
        </div>
      </div>
    </>
  );
}

export default WaitForDelivery;
