import { useContext, useEffect, useState } from "react";
import client from "../config/apolloClient";

import { SERVICE_CATEGORIES } from "../graphql/queries/ServiceCategories";
import { LoadingContext } from "../context/LoadingLogo";

function useServices() {
  const { setLogoLoading } = useContext(LoadingContext);

  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      setLogoLoading(true);

      const { data } = await client.query({ query: SERVICE_CATEGORIES });
      setData(data?.serviceCategories?.data);
      setLogoLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLogoLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return data;
}

export default useServices;
