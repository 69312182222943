import { gql } from "@apollo/client";

export const PACKAGE_QUERY = gql`
  query ($url: String!) {
    package(url: $url) {
      data {
        attributes {
          features(pagination: { limit: 25 }) {
            name
          }
          duration
        }
      }
    }
  }
`;
