import React, { useContext, useEffect } from "react";
import Footer from "../../components/layout/Footer";
import ClientHeader from "../../components/layout/client/ClientHeader";
import PFMainHeading from "../../components/client/publicFeedback/PFMainHeading";
import FeedbackDetails from "../../components/client/publicFeedback/FeedbackDetails";
import PFOrderOverview from "../../components/client/publicFeedback/PFOrderOverview";
import { Order } from "../../context/Order";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

function PublicFeedback() {
  const { getOrderDetails, orderDetails } = useContext(Order);

  const navigate = useNavigate();

  if (
    orderDetails?.status === "Incomplete" ||
    orderDetails?.status === "In Progress" ||
    orderDetails?.status === "Delivered" ||
    orderDetails?.status === "Revision" ||
    orderDetails?.status === "Cancelled" ||
    orderDetails?.feedback?.review
  ) {
    navigate("/order-details");
  }

  useEffect(() => {
    getOrderDetails();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Rate Your Order – AAMAX</title>
      </Helmet>
      {/*=============== Start AAMAX OMS  ===============*/}

      <ClientHeader>
        {/*.header*/}
        <main className="page-content d-flex flex-column justify-content-between h-100">
          <div className="container-fluid p-3 p-md-4">
            <PFMainHeading />
            {/*.row*/}
            <div className="row">
              <FeedbackDetails />
              {/*.col-grid*/}
              <PFOrderOverview />
              {/*.row*/}
            </div>
            {/*.container-fluid*/}
            <Footer />
          </div>
        </main>
      </ClientHeader>
      {/*.page-content"*/}
      {/*=============== End AAMAX OMS  ===============*/}
    </>
  );
}

export default PublicFeedback;
