import React, { useContext } from "react";
import { OrderCartDetail } from "../../../context/OrderCart";
import { Link } from "react-router-dom";

function POQDetail() {
  const { orderCart, setOrderCart, saveCartInfo } = useContext(OrderCartDetail);

  const quantityChange = (e) => {
    const newQuantity = e.target.value;
    setOrderCart({
      ...orderCart,
      quantity: newQuantity,
    });
  };

  const handleNext = () => {
    saveCartInfo();
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-card rounded-3 position-relative p-3 p-md-4">
            <div className="card-body">
              <div className="payment-method-wrapper mb-4">
                {/* <ul className="ps-3">
                  <li className="text-secondary my-1">
                    Express Delivery Available at Checkout
                  </li>
                  <li className="text-secondary my-1">
                    Get This Order Deliverd in 3 Days
                  </li>
                  <li className="text-secondary my-1">
                    For data on local search terms,please include the locality
                    in your keyword topic.
                  </li>
                  <li className="text-secondary my-1">
                    Select shorter, board 1-3 word keyword topics for best
                    results
                  </li>
                </ul> */}
              </div>
              <h4 className="h5 fw-semibold mb-3">Choose Quantity</h4>
              <div className="amount-input col-8 col-sm-5 col-md-3 col-lg-3 col-xl-2">
                {/* <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label fw-semibold text-secondary opacity-75 small"
                >
                  Keyword Research Reports
                </label> */}
                <input
                  type="number"
                  className="form-control fw-semibold"
                  id="exampleFormControlInput1"
                  value={orderCart.quantity}
                  onChange={quantityChange}
                  placeholder="0"
                />
              </div>
              <div className="btn-wrapepr">
                <button
                  className="btn primary-btn rounded-1 fw-semibold mt-4 px-4 d-inline-flex align-items-center"
                  onClick={handleNext}
                >
                  Next
                </button>
              </div>
            </div>
            {/*.card-body*/}
          </div>
          {/*.page-card*/}
        </div>
        {/*.col-grid*/}
      </div>
    </>
  );
}

export default POQDetail;
