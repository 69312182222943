import React from "react";
import { Link } from "react-router-dom";

function CtaSec() {
  return (
    <>
      <section className="cta-sec py-2">
        <div className="row align-items-center justify-content-center">
          <div className="col-xl-8 col-lg-12 d-flex flex-wrap align-items-center justify-content-center text-center my-2">
            <h2 className="fs-5 d-inline-block my-3">
              Let’s Start a Project Together
            </h2>
            <Link
              type="button"
              to="/services"
              className="btn oms-btn d-inline-block position-relative overflow-hidden mx-3"
            >
              Order Now
            </Link>
          </div>
          {/*.col-grid*/}
        </div>
        {/*.row*/}
      </section>
    </>
  );
}

export default CtaSec;
