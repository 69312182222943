import React from "react";

function COLTable() {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-card rounded-3 position-relative p-3 p-md-4">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover emp-attend-list">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Order Number</th>
                      <th scope="col">Service</th>
                      <th scope="col">Date Ordered</th>
                      <th scope="col">Delivery Date</th>
                      <th scope="col">Team</th>
                      <th scope="col">Order Price</th>
                      <th scope="col">Status</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Fsjfgr345</td>
                      <td>Webiste Copyright (Basic)</td>
                      <td>07-21-2023</td>
                      <td>09-12-2023</td>
                      <td>Usman Ali</td>
                      <td>$59</td>
                      <td>
                        <span className="d-inline-block badge blue-col">
                          Active
                        </span>
                      </td>
                      <td>
                        <button
                          className="tb-action edit"
                          data-bs-toggle="modal"
                          data-bs-target="#editUserModal"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            className="bi bi-pencil-square"
                            viewBox="0 0 16 16"
                          >
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                            <path
                              fillRule="evenodd"
                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                            />
                          </svg>
                        </button>
                        <button className="tb-action delete">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            className="bi bi-trash3"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                          </svg>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Fsjfgr345</td>
                      <td>Webiste Copyright (Basic)</td>
                      <td>07-21-2023</td>
                      <td>09-12-2023</td>
                      <td>Usman Ali</td>
                      <td>$59</td>
                      <td>
                        <span className="d-inline-block badge green-col">
                          Complete
                        </span>
                      </td>
                      <td>
                        <button
                          className="tb-action edit"
                          data-bs-toggle="modal"
                          data-bs-target="#editUserModal"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            className="bi bi-pencil-square"
                            viewBox="0 0 16 16"
                          >
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                            <path
                              fillRule="evenodd"
                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                            />
                          </svg>
                        </button>
                        <button className="tb-action delete">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            className="bi bi-trash3"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                          </svg>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Fsjfgr345</td>
                      <td>Webiste Copyright (Basic)</td>
                      <td>07-21-2023</td>
                      <td>09-12-2023</td>
                      <td>Usman Ali</td>
                      <td>$59</td>
                      <td>
                        <span className="d-inline-block badge bg-warning">
                          Inactive
                        </span>
                      </td>
                      <td>
                        <button
                          className="tb-action edit"
                          data-bs-toggle="modal"
                          data-bs-target="#editUserModal"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            className="bi bi-pencil-square"
                            viewBox="0 0 16 16"
                          >
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                            <path
                              fillRule="evenodd"
                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                            />
                          </svg>
                        </button>
                        <button className="tb-action delete">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            className="bi bi-trash3"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                          </svg>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  className="modal fade"
                  id="editUserModal"
                  tabIndex={-1}
                  aria-labelledby="editUserModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                      <div className="modal-header dark-bg px-4">
                        <h1
                          className="modal-title fs-5 text-white"
                          id="cardPaymentModalLabel"
                        >
                          Edit Order
                        </h1>
                        <button
                          type="button"
                          className="btn-close btn-close-white"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        />
                      </div>
                      <div className="modal-body px-4">
                        <form className="needs-validation mt-lg-3">
                          <div className="row g-3">
                            <div className="col-sm-6">
                              <label
                                htmlFor="name"
                                className="form-label small fw-semibold"
                              >
                                Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                disabled
                                id="name"
                                placeholder
                                defaultValue="KWMC Solution"
                              />
                            </div>
                            <div className="col-sm-6">
                              <label
                                htmlFor="name"
                                className="form-label small fw-semibold"
                              >
                                Order Number
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                disabled
                                id="name"
                                placeholder
                                defaultValue="Fsjfgr345"
                              />
                            </div>
                            <div className="col-sm-6">
                              <label
                                htmlFor="name"
                                className="form-label small fw-semibold"
                              >
                                Services
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                placeholder
                                defaultValue="Webiste Copyright (Basic)"
                              />
                            </div>
                            <div className="col-sm-6">
                              <label
                                htmlFor="name"
                                className="form-label small fw-semibold"
                              >
                                Working On
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                placeholder
                                defaultValue="Usman Ali"
                              />
                            </div>
                            <div className="col-sm-6">
                              <label
                                htmlFor="email"
                                className="form-label small fw-semibold"
                              >
                                Delivery Date
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                id="email"
                              />
                            </div>
                            <div className="col-sm-6">
                              <label
                                htmlFor="country"
                                className="form-label small fw-semibold"
                              >
                                Status
                              </label>
                              <select
                                className="form-select"
                                id="country"
                                required
                              >
                                <option value>Active</option>
                                <option>Complete</option>
                                <option>Pending</option>
                              </select>
                            </div>
                          </div>
                          {/*.row*/}
                          {/* / Change address form*/}
                          <div className="btn-wrapper text-end mb-3 mt-4 mt-lg-5">
                            <a
                              href="#"
                              className="fs-6 btn btn-light me-2 px-4 py-2 text-decoration-none"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            >
                              Cancel
                            </a>
                            <button
                              type="submit"
                              className="btn primary-btn fw-semibold fs-6 px-4 py-2"
                            >
                              Update Order
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                {/*.edit-user-modal*/}
              </div>
              {/*.table-responsive*/}
            </div>
            {/*.card-body*/}
          </div>
          {/*.page-card*/}
        </div>
        {/*.col-grid*/}
      </div>
    </>
  );
}

export default COLTable;
