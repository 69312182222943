import { gql } from "@apollo/client";

export const SERVICE_CATEGORIES = gql`
  query ServiceCategories {
    serviceCategories(sort: "id:asc") {
      data {
        id
        attributes {
          name
          icon
          sid
          subCategories(pagination: { limit: 50 }) {
            id
            name
            url
          }
        }
      }
    }
  }
`;
