import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function DashboardSkeleton() {
  return (
    <>
      <div className="card-body mt-4">
        <div className="table-responsive">
          <table className="table table-hover emp-attend-list">
            <tbody>
              {[...Array(5)].map((_) => (
                <tr>
                  {Array(6)
                    .fill()
                    .map((_, index) => (
                      <td>
                        <Skeleton height={25} />
                      </td>
                    ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/*.table-responsive*/}
      </div>
    </>
  );
}

export default DashboardSkeleton;
