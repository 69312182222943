import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import {
  GET_ACTIVE_ORDERS_API_URL,
  GET_ALL_ORDER_API_URL,
  GET_CANCELLED_ORDERS_API_URL,
  GET_COMPLETED_ORDERS_API_URL,
  ORDER_API_URL,
  TEAM_WORKING_API_URL,
  VERIFYING_CREDITS_API_URL,
  VERIFYING_ORDERS_API_URL,
} from "../config/endPoints";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import { AuthContext } from "./Auth";
import { LoadingContext } from "./LoadingLogo";
import confetti from "canvas-confetti";

const Order = createContext();

function OrderContext({ children }) {
  const { setLoading } = useContext(AuthContext);
  const { setLogoLoading } = useContext(LoadingContext);

  const navigate = useNavigate();

  const [orderDetail, setOrderDetail] = useState({
    service: "",
    package: "",
    packageUrl: "",
    price: "",
    quantity: "",
    expDelivery: false,
    totalPaidAmount: "",
    deliveryDate: "",
    invoiceNumber: "",
    paymentMethod: "",
    paymentProof: [],
  });

  const pService = localStorage.getItem("description");
  const totalAmount = localStorage.getItem("totalAmount");
  const pExpDelivery = localStorage.getItem("pExpDelivery");
  const pPackage = localStorage.getItem("pPackage");
  const pPackageUrl = localStorage.getItem("pPackageUrl");
  const pPrice = localStorage.getItem("pPrice");
  const pQuantity = localStorage.getItem("pQuantity");
  const pDeliveryDate = localStorage.getItem("pDeliveryDate");
  const pInvoiceNumber = localStorage.getItem("pInvoiceNumber");

  const paypalOrderDetail = {
    service: pService,
    package: pPackage,
    packageUrl: pPackageUrl,
    price: pPrice,
    quantity: pQuantity,
    expDelivery: pExpDelivery,
    totalPaidAmount: totalAmount,
    deliveryDate: pDeliveryDate,
    invoiceNumber: pInvoiceNumber,
    paymentMethod: "PayPal",
  };

  const [orderId, setOrderId] = useState(localStorage.getItem("orderId") || "");
  const [sOrderId, setSOrderId] = useState(
    localStorage.getItem("sOrderId") || ""
  );
  const [orderDetails, setOrderDetails] = useState({});
  const [allOrders, setAllOrders] = useState([]);
  const [aOrders, setAOrders] = useState([]);
  const [cOrders, setCOrders] = useState([]);
  const [cancOrders, setCancOrders] = useState([]);
  const [VerifOrders, setVerifOrders] = useState([]);
  const [VerifCredits, setVerifCredits] = useState([]);
  const [reqRevision, setReqRevision] = useState(false);
  const [deliverYOrder, setDeliverYOrder] = useState(false);
  const [cOLoading, setCOLoading] = useState(false);

  const [updateTeamNO, setUpdateTeamNO] = useState({
    deliveryDate: null,
    status: "",
    cancReason: "",
    teamWorking: [],
  });

  const page_size = 10;
  const pageSize = page_size;
  const [nextPageCount, setNextPageCount] = useState(page_size);
  const [lastPageItemsCount, setLastPageItemsCount] = useState(page_size);
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    totalPages: 0,
  });

  const triggerConfetti = () => {
    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }

    confetti({
      angle: randomInRange(55, 125),
      spread: randomInRange(150, 170),
      particleCount: randomInRange(150, 200),
      origin: { y: 0.6 },
    });
  };

  const createOrder = async () => {
    if (
      orderDetail?.paymentMethod === "Bank Transfer" &&
      orderDetail?.paymentProof.length === 0
    ) {
      message.error("Please provide payment proof to place the order.");
      return;
    }
    try {
      setCOLoading(true);
      const res = await axios.post(ORDER_API_URL, orderDetail);
      const newOrderId = res?.data?.order?._id;
      setOrderId(newOrderId);
      setSOrderId(newOrderId);
      localStorage.setItem("orderId", newOrderId);
      localStorage.setItem("sOrderId", newOrderId);
      message.success(
        "Order Successfully Placed! Thanks for your order with AAMAX"
      );
      setCOLoading(false);
      getOrderDetails();
      const cancelButton = document.querySelector(
        "#bankPaymentModal .btn-light"
      );
      if (cancelButton) {
        cancelButton.click();
      }
      const cancelCardModal = document.querySelector(
        "#cardPaymentModal .btn-close-white"
      );
      if (cancelCardModal) {
        cancelCardModal.click();
      }
      const PayPalModal = document.querySelector(
        "#paypalPaymentModal .btn-close-white"
      );
      if (PayPalModal) {
        PayPalModal.click();
      }
      navigate(
        res?.data?.order?.status === "Verifying Payment"
          ? "/verifying-payment"
          : "/submit-requirements"
      );
      triggerConfetti();
      setTimeout(() => {
        triggerConfetti();
      }, 1000);
    } catch (err) {
      setCOLoading(false);
      message.error(err?.response?.data?.error);
      console.error(err);
    }
  };

  const createPayPalOrder = async () => {
    try {
      const res = await axios.post(ORDER_API_URL, paypalOrderDetail);
      const newOrderId = res?.data?.order?._id;
      setOrderId(newOrderId);
      setSOrderId(newOrderId);
      localStorage.setItem("orderId", newOrderId);
      localStorage.setItem("sOrderId", newOrderId);
      message.success(
        "Order Successfully Placed! Thanks for your order with AAMAX"
      );
      getOrderDetails();

      const PayPalModal = document.querySelector(
        "#paypalPaymentModal .btn-close-white"
      );
      if (PayPalModal) {
        PayPalModal.click();
      }
      navigate("/submit-requirements");
      triggerConfetti();
      setTimeout(() => {
        triggerConfetti();
      }, 1000);
    } catch (err) {
      message.error(err?.response?.data?.error);
      console.error(err);
    }
  };

  const reqOrderId = sOrderId ? sOrderId : orderId;

  const getOrderDetails = async () => {
    try {
      if (!reqOrderId) {
        return;
      }
      const res = await axios.get(`${ORDER_API_URL}/${reqOrderId}`);
      setOrderDetails(res?.data?.order);
    } catch (err) {
      console.error(err);
    }
  };

  const getAllOrders = async () => {
    try {
      const res = await axios.get(
        GET_ALL_ORDER_API_URL +
          `?currentPage=${paginationData.currentPage}&pageSize=${page_size}`
      );
      setAllOrders(res?.data?.orders);
      setLogoLoading(false);
      setLastPageItemsCount(res?.data?.meta?.lastPageItemsCount);
      setNextPageCount(res?.data?.meta?.nextPageCount);
      setPaginationData({
        currentPage: res?.data?.meta?.currentPage,
        totalPages: res?.data?.meta?.totalPages,
      });
    } catch (err) {
      console.error(err);
      setLogoLoading(false);
    }
  };

  const getVerifyingOrders = async () => {
    try {
      const res = await axios.get(VERIFYING_ORDERS_API_URL);
      setVerifOrders(res?.data?.orders);
      setLogoLoading(false);
    } catch (err) {
      console.error(err);
      setLogoLoading(false);
    }
  };

  const getVerifyingCredits = async () => {
    try {
      const res = await axios.get(VERIFYING_CREDITS_API_URL);
      setVerifCredits(res?.data?.credits);
    } catch (err) {
      console.error(err);
    }
  };

  const getActiveOrders = async () => {
    try {
      const res = await axios.get(
        GET_ACTIVE_ORDERS_API_URL +
          `?currentPage=${paginationData.currentPage}&pageSize=${page_size}`
      );
      setAOrders(res?.data?.aOrders);
      setLastPageItemsCount(res?.data?.meta?.lastPageItemsCount);
      setNextPageCount(res?.data?.meta?.nextPageCount);
      setPaginationData({
        currentPage: res?.data?.meta?.currentPage,
        totalPages: res?.data?.meta?.totalPages,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const getCompletedOrders = async () => {
    try {
      const res = await axios.get(
        GET_COMPLETED_ORDERS_API_URL +
          `?currentPage=${paginationData.currentPage}&pageSize=${page_size}`
      );
      setCOrders(res?.data?.cOrders);
      setLastPageItemsCount(res?.data?.meta?.lastPageItemsCount);
      setNextPageCount(res?.data?.meta?.nextPageCount);
      setPaginationData({
        currentPage: res?.data?.meta?.currentPage,
        totalPages: res?.data?.meta?.totalPages,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const getCancelledOrders = async () => {
    try {
      const res = await axios.get(
        GET_CANCELLED_ORDERS_API_URL +
          `?currentPage=${paginationData.currentPage}&pageSize=${page_size}`
      );
      setCancOrders(res?.data?.cOrders);
      setLastPageItemsCount(res?.data?.meta?.lastPageItemsCount);
      setNextPageCount(res?.data?.meta?.nextPageCount);
      setPaginationData({
        currentPage: res?.data?.meta?.currentPage,
        totalPages: res?.data?.meta?.totalPages,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const updateTeamWorking = (updateTeamNO, sOid) => {
    if (
      updateTeamNO?.status === "Cancelled" &&
      updateTeamNO?.cancReason?.trim() === ""
    ) {
      message.error("Please add a reason to cancel the order.");
      return;
    }

    if (updateTeamNO?.cancReason?.length > 20) {
      message.error("Cancel Reason should be less than 20 characters.");
      return;
    }

    setLoading(true);

    axios
      .put(`${TEAM_WORKING_API_URL}/${sOid}`, updateTeamNO)
      .then((res) => {
        message.success(res.data.message);
        setTimeout(() => {
          const cancelButton = document.querySelector(
            "#editUserModal .btn-light"
          );
          if (cancelButton) {
            cancelButton.click();
          }
          setLoading(false);
          setUpdateTeamNO({
            deliveryDate: null,
            status: "",
            teamWorking: [],
          });
          getAllOrders();
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.response.data.error);
        console.log(err);
      });
  };

  return (
    <Order.Provider
      value={{
        setOrderDetail,
        createOrder,
        getOrderDetails,
        orderDetails,
        orderId,
        getAllOrders,
        allOrders,
        sOrderId,
        setSOrderId,
        reqRevision,
        setReqRevision,
        deliverYOrder,
        setDeliverYOrder,
        setOrderDetails,
        updateTeamWorking,
        updateTeamNO,
        setUpdateTeamNO,
        nextPageCount,
        lastPageItemsCount,
        paginationData,
        setPaginationData,
        setNextPageCount,
        pageSize,
        getActiveOrders,
        aOrders,
        getCompletedOrders,
        cOrders,
        getCancelledOrders,
        cancOrders,
        VerifOrders,
        getVerifyingOrders,
        VerifCredits,
        getVerifyingCredits,
        cOLoading,
        orderDetail,
        triggerConfetti,
        createPayPalOrder,
      }}
    >
      {children}
    </Order.Provider>
  );
}

export { OrderContext, Order };
