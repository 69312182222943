import React, { useContext } from "react";
import { AuthContext } from "../../../context/Auth";

function NotAllowedToAddUsers() {
  const { userInfo } = useContext(AuthContext);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-card rounded-3 position-relative p-3 p-md-4 text-center d-flex align-items-center justify-content-center">
            <div className="card-body">
              <h4 className="my-1 mb-4 pb-2">
                You are not allowed to add or view users since you have been
                added by{" "}
                {`${userInfo?.addedByClientId?.firstName} ${userInfo?.addedByClientId?.lastName}`}{" "}
                ({userInfo?.addedByClientId?.email})
              </h4>
            </div>
            {/*.card-body*/}
          </div>
          {/*.page-card*/}
        </div>
        {/*.col-grid*/}
      </div>
      {/*.row*/}
    </>
  );
}

export default NotAllowedToAddUsers;
