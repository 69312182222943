import React, { useContext, useEffect, useState } from "react";
import { Order } from "../../../context/Order";
import { UtilsCtx } from "../../../context/UtilsContext";
import {
  convertToHTML,
  formatFileSize,
  getFileIcon,
  truncateFileName,
} from "../../../utils/helpers";
import {
  BACKEND_URL,
  REQS_A_ORDER_API_URL,
  UPLOAD_API_URL,
} from "../../../config/endPoints";
import { Tooltip, message } from "antd";
import axios from "axios";
import { AuthContext } from "../../../context/Auth";
import io from "socket.io-client";

function RequirementsTab() {
  const MAX_ATTACHMENTS = 5;
  const MAX_FILE_SIZE_MB = 50;
  const chunkSize = 500 * 1024;

  const { getOrderDetails, orderDetails, setOrderDetails, sOrderId, orderId } =
    useContext(Order);
  const { userInfo } = useContext(AuthContext);

  const { handleDownloadAttachment } = useContext(UtilsCtx);

  const [requirementsFiles, setRequirementsFiles] = useState([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(null);
  const [lastUploadedFileIndex, setLastUploadedFileIndex] = useState(null);
  const [currentChunkIndex, setCurrentChunkIndex] = useState(null);
  const [uploadedAttachments, setUploadedAttachments] = useState([]);
  const [uploading, setUploading] = useState(false);

  const [reqsAfterOrder, setReqsAfterOrder] = useState({
    title: "",
    text: "",
  });

  const formData = {
    title: reqsAfterOrder.title,
    text: reqsAfterOrder.text,
    reqsAOAttachments: uploadedAttachments,
  };

  const resetSelection = () => {
    setCurrentFileIndex(null);
    setLastUploadedFileIndex(null);
    setCurrentChunkIndex(null);
    setUploadedAttachments([]);
    setRequirementsFiles([]);
  };

  const handleTitleChange = (e) => {
    setReqsAfterOrder((prev) => ({ ...prev, title: e.target.value }));
  };

  const handleTextChange = (e) => {
    setReqsAfterOrder((prev) => ({ ...prev, text: e.target.value }));
  };

  const handleAOReqFChange = (e) => {
    const files = e.target.files;
    if (files.length > MAX_ATTACHMENTS) {
      message.error(`You can only attach up to ${MAX_ATTACHMENTS} files.`);
      e.target.value = null;
      return;
    }

    for (let i = 0; i < files.length; i++) {
      if (files[i].size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        message.error(`File size should not exceed ${MAX_FILE_SIZE_MB} MB.`);
        e.target.value = null;
        return;
      }
    }

    setRequirementsFiles(files);
  };

  function readNUploadCurrentChunk() {
    const reader = new FileReader();
    const file = requirementsFiles[currentFileIndex];
    if (!file) {
      return;
    }
    const from = currentChunkIndex * chunkSize;
    const to = from + chunkSize;
    const blob = file.slice(from, to);
    reader.onload = (e) => uploadAChunk(e);
    reader.readAsDataURL(blob);
  }

  function uploadAChunk(readerEvent) {
    const file = requirementsFiles[currentFileIndex];
    const data = readerEvent.target.result;
    const params = new URLSearchParams();
    params.set("name", file.name);
    params.set("size", file.size);
    params.set("currentChunkIndex", currentChunkIndex);
    params.set("totalChunks", Math.ceil(file.size / chunkSize));
    params.set("fileType", "reqsaoattachments");
    const headers = { "Content-Type": "application/octet-stream" };
    const url = `${UPLOAD_API_URL}?` + params.toString();
    axios.post(url, data, { headers }).then((response) => {
      const file = requirementsFiles[currentFileIndex];
      const filesize = requirementsFiles[currentFileIndex].size;
      const chunks = Math.ceil(filesize / chunkSize) - 1;
      const isLastChunk = currentChunkIndex === chunks;
      if (isLastChunk) {
        file.finalFilename = response.data.finalFilename;

        setLastUploadedFileIndex(currentFileIndex);
        setCurrentChunkIndex(null);
        setUploadedAttachments((prevAttachments) => [
          ...prevAttachments,
          {
            filename: file.name,
            filePath: response.data,
            filesize: filesize,
          },
        ]);
      } else {
        setCurrentChunkIndex(currentChunkIndex + 1);
      }
    });
  }

  useEffect(() => {
    if (lastUploadedFileIndex === null) {
      return;
    }
    const isLastFile = lastUploadedFileIndex === requirementsFiles.length - 1;
    const nextFileIndex = isLastFile ? null : currentFileIndex + 1;
    setCurrentFileIndex(nextFileIndex);
  }, [lastUploadedFileIndex]);

  useEffect(() => {
    if (requirementsFiles.length > 0) {
      if (currentFileIndex === null) {
        setCurrentFileIndex(
          lastUploadedFileIndex === null ? 0 : lastUploadedFileIndex + 1
        );
      }
    }
  }, [requirementsFiles.length]);

  useEffect(() => {
    if (currentFileIndex !== null) {
      setCurrentChunkIndex(0);
    }
  }, [currentFileIndex]);

  useEffect(() => {
    if (currentChunkIndex !== null) {
      readNUploadCurrentChunk();
    }
  }, [currentChunkIndex]);

  useEffect(() => {
    if (requirementsFiles?.length > 0 && currentFileIndex !== null) {
      setUploading(true);
    } else {
      setUploading(false);
    }
  }, [requirementsFiles, currentFileIndex]);

  const reqOrderId = sOrderId ? sOrderId : orderId;

  const handleSubmit = async () => {
    if (!reqsAfterOrder.title.trim()) {
      message.error("Please add requirements title to continue.");
      return;
    }
    if (!reqsAfterOrder.text.trim()) {
      message.error("Please add requirements description to continue.");
      return;
    }

    try {
      const res = await axios.put(
        `${REQS_A_ORDER_API_URL}/${reqOrderId}`,
        formData
      );
      if (res.status === 200) {
        message.success(res?.data?.message);
        setReqsAfterOrder({
          title: "",
          text: "",
        });
        setRequirementsFiles([]);
        getOrderDetails();
      }
    } catch (err) {
      message.error(err?.response?.data?.error);
      console.error("Error updating order:", err);
    }
  };

  const handleResetForm = (e) => {
    e.preventDefault();
    setReqsAfterOrder({
      title: "",
      text: "",
    });
    setRequirementsFiles([]);
  };

  useEffect(() => {
    const socket = io(BACKEND_URL);

    socket.on("reqsAfterOrder", (data) => {
      setOrderDetails((prevOrder) => ({
        ...prevOrder,
        ...data.reqsAfterOrder,
      }));
      getOrderDetails();
    });

    return () => {
      socket.disconnect();
    };
  }, []);
  return (
    <>
      <ul className="requirement-list list-unstyled my-3">
        <li className="d-flex my-3 py-1">
          <span className="list-count d-block me-3 primary-text opacity-75">
            01.
          </span>
          <article>
            <h4 className="h6 position-relative pe-4">Requirements</h4>
            {orderDetails?.requirements?.text && (
              <p
                className="small"
                dangerouslySetInnerHTML={convertToHTML(
                  orderDetails?.requirements?.text
                )}
              />
            )}
            {orderDetails?.requirements?.attachments?.length > 0 && (
              <div className="attachment-wrapper pt-2">
                <span className="h6 d-inline-block mb-3">
                  {orderDetails?.requirements?.attachments?.length === 1
                    ? "Attachment"
                    : "Attachments"}
                </span>
                <div className="attach-thumb-wrapper d-flex flex-wrap align-items-center gap-3">
                  {orderDetails?.requirements?.attachments?.map((ra, i) => (
                    <div
                      className="attach-thumb-item rounded-1 overflow-hidden"
                      key={i}
                    >
                      <figure className="mb-0 bg-light d-flex align-items-center justify-content-center">
                        <i
                          className={getFileIcon(
                            `${BACKEND_URL}/${ra?.filePath}`
                          )}
                        />
                      </figure>
                      <figcaption className="d-flex justify-content-between align-items-center px-3">
                        <span className="small">
                          {truncateFileName(ra?.filename, 10)} (
                          {formatFileSize(ra?.filesize)})
                        </span>
                        <span className="icons-wrapper">
                          <span
                            className="icon file-download secondary-text mx-2 opacity-75"
                            onClick={() =>
                              handleDownloadAttachment(
                                ra?.filename,
                                ra?.filePath
                              )
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={16}
                              height={16}
                              fill="currentColor"
                              className="bi bi-download"
                              viewBox="0 0 16 16"
                            >
                              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                            </svg>
                          </span>
                        </span>
                      </figcaption>
                    </div>
                  ))}
                  {/*.attach-thumb-item*/}

                  {/*.attach-thumb-item rounded-1 overflow-hidden*/}
                </div>
                {/*.attach-thumb-wrapper*/}
              </div>
            )}
          </article>
        </li>
        {(orderDetails?.addnlRequirements?.text ||
          orderDetails?.addnlRequirements?.addnlAttachments?.length > 0) && (
          <li className="d-flex my-3 py-1">
            {(orderDetails?.addnlRequirements?.text ||
              orderDetails?.addnlRequirements?.addnlAttachments?.length >
                0) && (
              <span className="list-count d-block me-3 primary-text opacity-75">
                02.
              </span>
            )}
            <article>
              {(orderDetails?.addnlRequirements?.text ||
                orderDetails?.addnlRequirements?.addnlAttachments?.length >
                  0) && (
                <h4 className="h6 position-relative pe-4">
                  Additional requirements
                </h4>
              )}

              {orderDetails?.addnlRequirements?.text && (
                <p
                  className="small"
                  dangerouslySetInnerHTML={convertToHTML(
                    orderDetails?.addnlRequirements?.text
                  )}
                />
              )}
              {orderDetails?.addnlRequirements?.addnlAttachments?.length >
                0 && (
                <div className="attachment-wrapper pt-2">
                  <span className="h6 d-inline-block mb-3">
                    {orderDetails?.addnlRequirements?.addnlAttachments
                      ?.length === 1
                      ? "Attachment"
                      : "Attachments"}
                  </span>
                  <div className="attach-thumb-wrapper d-flex flex-wrap align-items-center gap-3">
                    {orderDetails?.addnlRequirements?.addnlAttachments?.map(
                      (ra, i) => (
                        <div
                          className="attach-thumb-item rounded-1 overflow-hidden"
                          key={i}
                        >
                          <figure className="mb-0 bg-light d-flex align-items-center justify-content-center">
                            <i
                              className={getFileIcon(
                                `${BACKEND_URL}/${ra?.filePath}`
                              )}
                            />
                          </figure>
                          <figcaption className="d-flex justify-content-between align-items-center px-3">
                            <span className="small">
                              {truncateFileName(ra?.filename, 10)} (
                              {formatFileSize(ra?.filesize)})
                            </span>
                            <span className="icons-wrapper">
                              <span
                                className="icon file-download secondary-text mx-2 opacity-75"
                                onClick={() =>
                                  handleDownloadAttachment(
                                    ra?.filename,
                                    ra?.filePath
                                  )
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={16}
                                  height={16}
                                  fill="currentColor"
                                  className="bi bi-download"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                </svg>
                              </span>
                            </span>
                          </figcaption>
                        </div>
                      )
                    )}
                    {/*.attach-thumb-item*/}

                    {/*.attach-thumb-item rounded-1 overflow-hidden*/}
                  </div>

                  {/*.attach-thumb-wrapper*/}
                </div>
              )}
            </article>
          </li>
        )}
        {(orderDetails?.reqsAfterOrder?.text ||
          orderDetails?.reqsAfterOrder?.title) && (
          <li className="d-flex my-3 py-1">
            {(orderDetails?.reqsAfterOrder?.text ||
              orderDetails?.reqsAfterOrder?.title) && (
              <span className="list-count d-block me-3 primary-text opacity-75">
                {!orderDetails?.addnlRequirements?.text &&
                orderDetails?.addnlRequirements?.addnlAttachments?.length === 0
                  ? "02."
                  : "03."}
              </span>
            )}
            <article>
              {orderDetails?.reqsAfterOrder?.title && (
                <h4 className="h6 position-relative pe-4">
                  {orderDetails?.reqsAfterOrder?.title}
                </h4>
              )}

              {orderDetails?.reqsAfterOrder?.text && (
                <p
                  className="small"
                  dangerouslySetInnerHTML={convertToHTML(
                    orderDetails?.reqsAfterOrder?.text
                  )}
                />
              )}
              {orderDetails?.reqsAfterOrder?.reqsAOAttachments?.length > 0 && (
                <div className="attachment-wrapper pt-2">
                  <span className="h6 d-inline-block mb-3">
                    {orderDetails?.reqsAfterOrder?.reqsAOAttachments?.length ===
                    1
                      ? "Attachment"
                      : "Attachments"}
                  </span>
                  <div className="attach-thumb-wrapper d-flex flex-wrap align-items-center gap-3">
                    {orderDetails?.reqsAfterOrder?.reqsAOAttachments?.map(
                      (ra, i) => (
                        <div
                          className="attach-thumb-item rounded-1 overflow-hidden"
                          key={i}
                        >
                          <figure className="mb-0 bg-light d-flex align-items-center justify-content-center">
                            <i
                              className={getFileIcon(
                                `${BACKEND_URL}/${ra?.filePath}`
                              )}
                            />
                          </figure>
                          <figcaption className="d-flex justify-content-between align-items-center px-3">
                            <span className="small">
                              {truncateFileName(ra?.filename, 10)} (
                              {formatFileSize(ra?.filesize)})
                            </span>
                            <span className="icons-wrapper">
                              <span
                                className="icon file-download secondary-text mx-2 opacity-75"
                                onClick={() =>
                                  handleDownloadAttachment(
                                    ra?.filename,
                                    ra?.filePath
                                  )
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={16}
                                  height={16}
                                  fill="currentColor"
                                  className="bi bi-download"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                </svg>
                              </span>
                            </span>
                          </figcaption>
                        </div>
                      )
                    )}
                    {/*.attach-thumb-item*/}

                    {/*.attach-thumb-item rounded-1 overflow-hidden*/}
                  </div>
                </div>
              )}
            </article>
          </li>
        )}
      </ul>
      {(userInfo?.userType === "client" || userInfo?.userType === "clientTm") &&
        (orderDetails?.status === "In Progress" ||
          orderDetails?.status === "Revision") &&
        (!orderDetails?.reqsAfterOrder?.title ||
          !orderDetails?.reqsAfterOrder?.text) && (
          <div className="text-area-wrapper py-md-3">
            <div className="form-label-group mb-3">
              <label htmlFor="firstName" className="form-label small">
                Title
              </label>
              <input
                type="text"
                className="form-control"
                id="reqTitle"
                placeholder
                defaultValue
                required
                value={reqsAfterOrder.title}
                onChange={handleTitleChange}
              />
            </div>

            <label className="form-label small">Description</label>
            <div className="form-text-area border rounded-3">
              <div className="form-label-group mb-0">
                <textarea
                  className="form-control border-0 p-3"
                  id="exampleFormControlTextarea3"
                  rows={6}
                  defaultValue={""}
                  value={reqsAfterOrder.text}
                  onChange={handleTextChange}
                />
              </div>
              <div className="form-footer border-top px-2 py-1 d-flex justify-content-between align-items-center">
                <small className="counter-value-text text-secondary small opacity-75">
                  {/* <span className="char-count">0</span> / 1500{" "} */}
                </small>
                <div className="file-attachment">
                  <span
                    className={`attachment-numb ${
                      requirementsFiles.length > 0
                        ? "secondary-text"
                        : "text-secondary"
                    } small`}
                  >
                    {requirementsFiles?.length > 0 && currentFileIndex !== null
                      ? `Uploading ${currentFileIndex + 1}/${
                          requirementsFiles?.length
                        } (${Math.round(
                          (currentChunkIndex /
                            Math.ceil(
                              requirementsFiles[currentFileIndex]?.size /
                                chunkSize
                            )) *
                            100
                        )}%)`
                      : requirementsFiles?.length > 0
                      ? `${requirementsFiles?.length} ${
                          requirementsFiles?.length === 1 ? "File" : "Files"
                        } Attached`
                      : "No File Chosen"}
                  </span>
                  {requirementsFiles?.length > 0 &&
                  currentFileIndex === null ? (
                    <button className="btn text-secondary p-1 pe-0 opacity-75 ms-2">
                      <Tooltip title="Reset Selection" mouseEnterDelay={0.1}>
                        <img
                          src="/assets/images/x.svg"
                          alt="X Icon"
                          width="20"
                          height="20"
                          onClick={resetSelection}
                        />
                      </Tooltip>
                    </button>
                  ) : (
                    <button className="btn text-secondary p-1 pe-0 opacity-75 ms-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={20}
                        height={20}
                        fill="currentColor"
                        className="bi bi-paperclip"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                      </svg>
                      <Tooltip title="Max 50MB/File" mouseEnterDelay={0.1}>
                        <input
                          type="file"
                          name="file"
                          multiple
                          onChange={handleAOReqFChange}
                        />
                      </Tooltip>
                    </button>
                  )}
                </div>
              </div>
            </div>
            {/*.form-text-area*/}
            <div className="btn-wrapper mt-4 pt-lg-3 text-end">
              <a
                href="#"
                className="primary-action me-2 opacity-75 text-decoration-none small text-decoration-underline"
                onClick={(e) => handleResetForm(e)}
              >
                Reset
              </a>
              <button
                type="submit"
                className="btn primary-btn fw-semibold fs-6 px-4 py-2"
                onClick={handleSubmit}
                disabled={uploading}
              >
                {uploading && (
                  <span className="spinner-grow spinner-grow-sm me-2"></span>
                )}

                {uploading ? "Uploading..." : "Submit"}
              </button>
            </div>
          </div>
        )}
    </>
  );
}

export default RequirementsTab;
