import React, { useContext, useEffect, useState } from "react";
import ClientSidebar from "./ClientSidebar";
import { AuthContext } from "../../../context/Auth";
import { Link } from "react-router-dom";
import { constructImageUrl } from "../../../utils/helpers";
import WhatsAppButton from "../WhatsAppButton";
// import { LiveChatCtx } from "../../../context/LiveChat";

function ClientHeader({ children }) {
  const token = localStorage.getItem("token");

  const { logout, userInfo, fetchUserInfo } = useContext(AuthContext);
  // const { setIsLiveChatOpen } = useContext(LiveChatCtx);

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const handleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  const handleFullScreenToggle = () => {
    const contentDiv = document.documentElement;

    if (!isFullscreen) {
      if (contentDiv.requestFullscreen) {
        contentDiv.requestFullscreen();
      } else if (contentDiv.mozRequestFullScreen) {
        contentDiv.mozRequestFullScreen();
      } else if (contentDiv.webkitRequestFullscreen) {
        contentDiv.webkitRequestFullscreen();
      } else if (contentDiv.msRequestFullscreen) {
        contentDiv.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  useEffect(() => {
    const fullscreenChangeHandler = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", fullscreenChangeHandler);

    return () => {
      document.removeEventListener("fullscreenchange", fullscreenChangeHandler);
    };
  }, []);

  useEffect(() => {
    if (token) {
      fetchUserInfo(token);
    }
  }, []);

  return (
    <div
      className={`aamax-oms bg-light ${isSidebarOpen ? "sidebar-toggle" : ""}`}
    >
      <ClientSidebar />
      <header className=".header">
        <nav className="dashboard-nav secondary-bg position-fixed top-0 border-bottom py-2 px-3 mb-0">
          <div className="nav-inner position-relative d-flex align-items-center">
            <div className="d-flex align-items-center">
              <a
                id="sidebarBtn"
                className="btn-hamburger d-inline-flex p-2"
                onClick={handleSidebar}
              >
                <span className="d-block position-relative" />
              </a>
              {/*.btn-hamburger*/}
              {userInfo?.userType === "viewer" ||
              userInfo?.userType === "manager" ||
              userInfo?.userType === "tMember" ? (
                ""
              ) : (
                <div className="btn-wrapper d-none d-md-block">
                  {userInfo?.userType === "admin" ? (
                    <Link
                      to="/total-orders"
                      className="btn white-btn-outline rounded-pill fw-semibold ms-3"
                    >
                      Admin Dashboard
                    </Link>
                  ) : (
                    <Link
                      to="/services"
                      className="btn white-btn-outline rounded-pill fw-semibold ms-3"
                    >
                      Order Now
                    </Link>
                  )}
                </div>
              )}
            </div>
            <div className="flex-fill ">
              <div className="nav-icons-wrapper d-flex flex-wrap justify-content-end  align-items-center gap-3 gap-sm-4 ms-auto">
                <span
                  id="fullWidthBtn"
                  className="d-inline-flex icons-wrapper"
                  onClick={handleFullScreenToggle}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={18}
                    height={18}
                    fill="currentColor"
                    className="bi bi-fullscreen"
                    viewBox="0 0 16 16"
                  >
                    <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z" />
                  </svg>
                </span>

                {/* <div className="dropdown notifications-dropdown dropdown-end">
                  <a
                    className="d-flex align-items-center text-decoration-none"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="d-inline-flex position-relative">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={20}
                        height={20}
                        fill="currentColor"
                        className="bi bi-bell"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
                      </svg>
                      <span className="unread-badge d-inline-block position-absolute" />
                    </span>
                  </a>
                  <div className="dropdown-menu dropdown-menu-end border-0 py-0">
                    <div className="d-flex align-items-center justify-content-between gap-2 border-bottom p-3">
                      <h4 className="mb-0">Notifications</h4>
                      <span className="badge position-relative rounded-0">
                        4 Unread
                      </span>
                    </div>
                    <ul className="list-unstyled mb-0">
                      <li>
                        <a
                          className="dropdown-item d-flex border-bottom"
                          href="#"
                        >
                          <span className="notification-logo position-relative d-flex align-items-center justify-content-center flex-shrink-0 rounded-pill me-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={16}
                              height={16}
                              fill="currentColor"
                              className="bi bi-cash-stack"
                              viewBox="0 0 16 16"
                            >
                              <path d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1H1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
                              <path d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V5zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2H3z" />
                            </svg>
                          </span>
                          <div className="notification-content pe-2 me-1">
                            <h6 className="mb-0">Discount Available</h6>
                            <p className="text-truncate text-muted mb-0">
                              Discount Available On Products
                            </p>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item d-flex border-bottom"
                          href="#"
                        >
                          <span className="notification-logo position-relative d-flex align-items-center justify-content-center flex-shrink-0 rounded-pill me-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={16}
                              height={16}
                              fill="currentColor"
                              className="bi bi-check-circle"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                              <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                            </svg>
                          </span>
                          <div className="notification-content pe-2 me-1">
                            <h6 className="mb-0">
                              Order Placed <span>ID: 12345</span>{" "}
                            </h6>
                            <p className="text-truncate text-muted mb-0">
                              Order Placed Successfully
                            </p>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item d-flex border-bottom"
                          href="#"
                        >
                          <span className="notification-logo position-relative d-flex align-items-center justify-content-center flex-shrink-0 rounded-pill me-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={16}
                              height={16}
                              fill="currentColor"
                              className="bi bi-clock"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                            </svg>
                          </span>
                          <div className="notification-content pe-2 me-1">
                            <h6 className="mb-0">
                              Order Delayed <span>ID: 13772</span>{" "}
                            </h6>
                            <p className="text-truncate text-muted mb-0">
                              Order Delayed Unfortunately
                            </p>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item d-flex align-items-center border-bottom"
                          href="#"
                        >
                          <span className="notification-logo position-relative d-flex align-items-center justify-content-center flex-shrink-0 rounded-pill me-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={16}
                              height={16}
                              fill="currentColor"
                              className="bi bi-gift"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3 2.5a2.5 2.5 0 0 1 5 0 2.5 2.5 0 0 1 5 0v.006c0 .07 0 .27-.038.494H15a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 1 14.5V7a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h2.038A2.968 2.968 0 0 1 3 2.506V2.5zm1.068.5H7v-.5a1.5 1.5 0 1 0-3 0c0 .085.002.274.045.43a.522.522 0 0 0 .023.07zM9 3h2.932a.56.56 0 0 0 .023-.07c.043-.156.045-.345.045-.43a1.5 1.5 0 0 0-3 0V3zM1 4v2h6V4H1zm8 0v2h6V4H9zm5 3H9v8h4.5a.5.5 0 0 0 .5-.5V7zm-7 8V7H2v7.5a.5.5 0 0 0 .5.5H7z" />
                            </svg>
                          </span>
                          <div className="notification-content pe-2 me-1">
                            <h6 className="mb-0">
                              Your Order Has Been Shipped
                            </h6>
                            <p className="text-truncate text-muted mb-0">
                              Order No: 30606 Has Shipped
                            </p>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div> */}
                {/*.dropdown*/}
                {/* <span className="d-inline-flex position-relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={20}
                    height={20}
                    fill="currentColor"
                    className="bi bi-chat"
                    viewBox="0 0 16 16"
                    onClick={() => setIsLiveChatOpen(true)}
                  >
                    <path d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z" />
                  </svg>
                </span> */}
                {/* 
                <div className="dropdown mail-dropdown dropdown-end">
                  <a
                    href="#"
                    className="d-flex align-items-center text-decoration-none"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="d-inline-flex position-relative">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={20}
                        height={20}
                        fill="currentColor"
                        className="bi bi-envelope"
                        viewBox="0 0 16 16"
                      >
                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                      </svg>
                    </span>
                  </a>
                  <div
                    className="dropdown-menu border-0 p-0"
                    data-popper-placement="bottom-start"
                  >
                    <div className="mail-head d-flex justify-content-between align-items-center p-3">
                      <h5 className="mb-0">Messages</h5>
                      <a href="#" className="btn primary-btn-outline">
                        <small>Show All</small>
                      </a>
                    </div>
                    <div className="mail-body-content">
                      <ul className="mail-list ps-0 mb-0">
                        <li className="d-flex justify-content-between">
                          <div className="chat-user d-flex gap-3">
                            <img
                              src="https://s3.amazonaws.com/speedyui-static/navbar-horizontal/profile.jpg"
                              className="img-fluid"
                              alt
                            />
                            <div className="chat-about d-flex flex-column">
                              <a href className="text-decoration-none">
                                <h6 className="mb-0 text-dark small">
                                  Bob Frapples
                                </h6>
                              </a>
                              <span className="d-inline-block text-secondary mt-1">
                                Template Represents simply...
                              </span>
                            </div>
                          </div>
                          <div className="status online">online</div>
                        </li>
                        <li className="d-flex justify-content-between">
                          <div className="chat-user d-flex gap-3">
                            <div className="user-avator-order secondary-bg rounded-circle d-flex align-items-center justify-content-center text-uppercase fw-semibold text-white">
                              <span className="name-txt">js</span>
                            </div>
                            <div className="chat-about d-flex flex-column">
                              <a href className="text-decoration-none">
                                <h6 className="mb-0 text-dark small">Greta</h6>
                              </a>
                              <span className="d-inline-block text-secondary mt-1">
                                Template Represents simply...
                              </span>
                            </div>
                          </div>
                          <div className="status away">Away</div>
                        </li>
                        <li className="d-flex justify-content-between">
                          <div className="chat-user d-flex gap-3">
                            <img
                              src="https://s3.amazonaws.com/speedyui-static/navbar-horizontal/profile.jpg"
                              className="img-fluid"
                              alt
                            />
                            <div className="chat-about d-flex flex-column">
                              <a href className="text-decoration-none">
                                <h6 className="mb-0 text-dark small">joseph</h6>
                              </a>
                              <span className="d-inline-block text-secondary mt-1">
                                Template Represents simply...
                              </span>
                            </div>
                          </div>
                          <div className="status busy">busy</div>
                        </li>
                        <li className="d-flex justify-content-between">
                          <div className="chat-user d-flex gap-3">
                            <img
                              src="https://s3.amazonaws.com/speedyui-static/navbar-horizontal/profile.jpg"
                              className="img-fluid"
                              alt
                            />
                            <div className="chat-about d-flex flex-column">
                              <a href className="text-decoration-none">
                                <h6 className="mb-0 text-dark small">
                                  William Bob
                                </h6>
                              </a>
                              <span className="d-inline-block text-secondary mt-1">
                                Template Represents simply...
                              </span>
                            </div>
                          </div>
                          <div className="status offline">offline</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}

                <div className="dropdown profile-dropdown dropdown-end">
                  <a
                    className="d-flex align-items-center text-white gap-1 gap-sm-2 text-decoration-none dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div className="profile d-block rounded-pill overflow-hidden me-sm-1 ">
                      {userInfo?.imageUrl ? (
                        <img
                          src={constructImageUrl(userInfo?.imageUrl)}
                          className="rounded-pill user-img img-fluid"
                          id="pfImg"
                          width={110}
                          height={110}
                          alt
                        />
                      ) : (
                        <span className="user-name-txt">
                          {`${
                            userInfo?.firstName
                              ? userInfo.firstName.charAt(0).toUpperCase()
                              : ""
                          }${
                            userInfo?.lastName
                              ? userInfo.lastName.charAt(0).toUpperCase()
                              : ""
                          }`}
                        </span>
                      )}
                    </div>
                    {/*.profile*/}
                    <div className="profile-bio">
                      <h6 className="fw-semibold mb-1 lh- d-none d-md-block">
                        {userInfo?.firstName}
                      </h6>
                    </div>
                    {/*.profile-bio*/}
                  </a>
                  <ul className="dropdown-menu dropdown-menu-end border-0 mt-2 py-0">
                    <li>
                      <Link
                        className="dropdown-item d-flex align-items-center gap-2 py-2"
                        to="/account-settings"
                      >
                        <span className="d-inline-flex lh-base">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={18}
                            height={18}
                            fill="currentColor"
                            className="bi bi-person"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
                          </svg>
                        </span>
                        Profile
                      </Link>
                    </li>
                    {userInfo?.userType === "manager" ||
                    userInfo?.userType === "tMember" ? (
                      ""
                    ) : (
                      <>
                        <li>
                          <Link
                            className="dropdown-item d-flex align-items-center"
                            to="/credit-history"
                          >
                            <span className="d-inline-flex lh-base me-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={13}
                                height={13}
                                fill="currentColor"
                                className="bi bi-wallet2"
                                viewBox="0 0 16 16"
                              >
                                <path d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499L12.136.326zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484L5.562 3zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z" />
                              </svg>
                            </span>
                            Credit: ${userInfo?.credit}.00
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item d-flex align-items-center"
                            to="/manage-users"
                          >
                            <span className="d-inline-flex lh-base me-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={14}
                                height={14}
                                fill="currentColor"
                                className="bi bi-people"
                                viewBox="0 0 16 16"
                              >
                                <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4" />
                              </svg>
                            </span>
                            Manage Users
                          </Link>
                        </li>
                      </>
                    )}
                    <li>
                      <a
                        className="dropdown-item d-flex align-items-center"
                        onClick={() => {
                          logout();
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <span className="d-inline-flex lh-base me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={14}
                            height={14}
                            fill="currentColor"
                            className="bi bi-box-arrow-in-left"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 3.5a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 1 1 0v2A1.5 1.5 0 0 1 9.5 14h-8A1.5 1.5 0 0 1 0 12.5v-9A1.5 1.5 0 0 1 1.5 2h8A1.5 1.5 0 0 1 11 3.5v2a.5.5 0 0 1-1 0v-2z"
                            />
                            <path
                              fillRule="evenodd"
                              d="M4.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H14.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"
                            />
                          </svg>
                        </span>
                        Sign Out
                      </a>
                    </li>
                  </ul>
                  {/*.dropdown-menu*/}
                </div>
                {/*.dropdown*/}
              </div>
              {/*.nav-icons-wrapper*/}
            </div>
          </div>
        </nav>
        {/*dashboard-nav*/}
      </header>
      {children}
      {(userInfo?.userType === "client" ||
        userInfo?.userType === "clientTm" ||
        userInfo?.userType === "viewer" ||
        userInfo?.userType === "admin") && <WhatsAppButton />}
    </div>
  );
}

export default ClientHeader;
