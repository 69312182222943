import React, { useContext } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { ExpandedLink } from "../../../context/SidebarNavContext";
import { AuthContext } from "../../../context/Auth";
import TeamSidebar from "./TeamSidebar";
import { formatCurrency } from "../../../utils/helpers";

function ClientSidebar() {
  const location = useLocation();
  const { expandedItem, setExpandedItem } = useContext(ExpandedLink);
  const { logout, userInfo } = useContext(AuthContext);
  const activePath = location.pathname;

  const ordersUrlsToCheck = [
    "/services",
    "/active-order",
    "/cancelled-orders",
    "/view-invoices",
  ];
  const companyUrlsToCheck = [
    "/about-us",
    "/team",
    "/careers",
    "/faqs",
    "/charity",
  ];

  const creditUrlsToCheck = ["/add-credits", "/credit-history"];

  const creditNestedPaths = creditUrlsToCheck.some((url) =>
    activePath.includes(url)
  );
  const ordersNestedPaths = ordersUrlsToCheck.some((url) =>
    activePath.includes(url)
  );
  const companyNestedPaths = companyUrlsToCheck.some((url) =>
    activePath.includes(url)
  );

  const handleCollapse = (id) => {
    if (expandedItem === id) {
      setExpandedItem(null);
    } else {
      setExpandedItem(id);
    }
  };

  return (
    <>
      {userInfo?.userType === "manager" || userInfo?.userType === "tMember" ? (
        <TeamSidebar />
      ) : (
        <aside className="position-fixed top-0 start-0">
          <div className="sidebar-wrapper h-100 d-flex flex-column">
            <div className="logo-wrapper">
              <Link to="/" className="navbar-brand d-block">
                <img
                  className="img-fluid px-3 logo"
                  src="./assets/images/logo.png"
                  alt
                />
                <img
                  className="img-fluid px-3 logo-icon"
                  src="./assets/images/logo-icon.png"
                  alt
                />
              </Link>
              {/*.navbar-brand*/}
            </div>
            <div className="sidebar-menu-wrapper py-4 my-2 flex-fill d-flex flex-column justify-content-between">
              <ul
                className="sidebar-listing list-unstyled px-3 mb-0 pb-4"
                id="collapse"
              >
                <li className="nav-item">
                  <NavLink
                    to="/dashboard"
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link active d-flex align-items-center rounded mb-1"
                        : "nav-link d-flex align-items-center rounded mb-1"
                    }
                  >
                    <span className="icon d-inline-flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-house-door"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146ZM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5Z" />
                      </svg>
                    </span>
                    <span className="sidebar-item ms-2">Dashboard</span>
                  </NavLink>
                  {/*.nav-link*/}
                </li>
                {/*.nav-item*/}
                <li className="nav-item">
                  <a
                    className={`${
                      expandedItem === "creditsCollapse" || creditNestedPaths
                        ? "nav-link active d-flex align-items-center rounded mb-1"
                        : "nav-link d-flex align-items-center rounded collapsed mb-1"
                    } `}
                    data-bs-toggle="collapse"
                    href="#creditsCollapse"
                    role="button"
                    aria-expanded={
                      expandedItem === "creditsCollapse" ? "true" : "false"
                    }
                    aria-controls="chartsCollapse"
                    onClick={() => handleCollapse("creditsCollapse")}
                  >
                    <span className="icon d-inline-flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-credit-card-2-back"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1z" />
                        <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm13 2v5H1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm-1 9H2a1 1 0 0 1-1-1v-1h14v1a1 1 0 0 1-1 1z" />
                      </svg>
                    </span>
                    <span className="sidebar-item ms-2">Credit</span>
                    <span className="sidebar-arrow d-inline-flex ms-auto">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={14}
                        height={14}
                        fill="currentColor"
                        className="bi bi-chevron-right"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                        />
                      </svg>
                    </span>
                  </a>
                  <ul
                    className={
                      expandedItem === "creditsCollapse"
                        ? "collapse show list-unstyled ps-0"
                        : "collapse list-unstyled ps-0"
                    }
                    id="creditsCollapse"
                    data-bs-parent="#collapse"
                  >
                    <li className="nav-item ps-2">
                      <span className="nav-link px-3 py-2 d-flex align-items-center">
                        <span className="icon d-inline-flex">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            className="bi bi-wallet2"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499L12.136.326zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484L5.562 3zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z" />
                          </svg>
                        </span>
                        <span className="sidebar-item">
                          {formatCurrency(userInfo?.credit)}
                        </span>
                      </span>
                    </li>
                    <li className="nav-item ps-2">
                      <NavLink
                        to="/add-credits"
                        className={({ isActive }) =>
                          isActive
                            ? "nav-link active d-flex align-items-center px-3 py-2"
                            : "nav-link d-flex align-items-center px-3 py-2"
                        }
                      >
                        <span className="icon d-inline-flex">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            className="bi bi-plus-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                          </svg>
                        </span>
                        <span className="sidebar-item">Add Credits</span>
                      </NavLink>
                    </li>
                    <li className="nav-item ps-2">
                      <NavLink
                        to="/credit-history"
                        className={({ isActive }) =>
                          isActive
                            ? "nav-link active d-flex align-items-center px-3 py-2"
                            : "nav-link d-flex align-items-center px-3 py-2"
                        }
                      >
                        <span className="icon d-inline-flex">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-coin"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518z" />
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                            <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11m0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12" />
                          </svg>
                        </span>
                        <span className="sidebar-item">Credit History</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>
                {/*.nav-item*/}
                <li className="nav-item">
                  <a
                    className={`${
                      expandedItem === "ordersCollapse" || ordersNestedPaths
                        ? "nav-link active d-flex align-items-center rounded mb-1"
                        : "nav-link d-flex align-items-center rounded collapsed mb-1"
                    } `}
                    data-bs-toggle="collapse"
                    href="#ordersCollapse"
                    role="button"
                    aria-expanded={
                      expandedItem === "ordersCollapse" ? "true" : "false"
                    }
                    aria-controls="chartsCollapse"
                    onClick={() => handleCollapse("ordersCollapse")}
                  >
                    <span className="icon d-inline-flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-cart2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l1.25 5h8.22l1.25-5H3.14zM5 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
                      </svg>
                    </span>
                    <span className="sidebar-item ms-2">Orders</span>
                    <span className="sidebar-arrow d-inline-flex ms-auto">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={14}
                        height={14}
                        fill="currentColor"
                        className="bi bi-chevron-right"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                        />
                      </svg>
                    </span>
                  </a>
                  <ul
                    className={
                      expandedItem === "ordersCollapse"
                        ? "collapse show list-unstyled ps-0"
                        : "collapse list-unstyled ps-0"
                    }
                    id="ordersCollapse"
                    data-bs-parent="#collapse"
                  >
                    <li className="nav-item ps-2">
                      <NavLink
                        to="/services"
                        className={({ isActive }) =>
                          isActive
                            ? "nav-link active d-flex align-items-center px-3 py-2"
                            : "nav-link d-flex align-items-center px-3 py-2"
                        }
                      >
                        <span className="icon d-inline-flex">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            className="bi bi-plus-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                          </svg>
                        </span>
                        <span className="sidebar-item">Order Now</span>
                      </NavLink>
                    </li>
                    <li className="nav-item ps-2">
                      <NavLink
                        to="/active-order"
                        className={({ isActive }) =>
                          isActive
                            ? "nav-link active d-flex align-items-center px-3 py-2"
                            : "nav-link d-flex align-items-center px-3 py-2"
                        }
                      >
                        <span className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            className="bi bi-card-list"
                            viewBox="0 0 16 16"
                          >
                            <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                            <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                          </svg>
                        </span>
                        <span className="sidebar-item">Active</span>
                      </NavLink>
                    </li>
                    <li className="nav-item ps-2">
                      <NavLink
                        to="/completed-order"
                        className={({ isActive }) =>
                          isActive
                            ? "nav-link active d-flex align-items-center px-3 py-2"
                            : "nav-link d-flex align-items-center px-3 py-2"
                        }
                      >
                        <span className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            className="bi bi-check2-square"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5z" />
                            <path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0" />
                          </svg>
                        </span>
                        <span className="sidebar-item">Completed</span>
                      </NavLink>
                    </li>
                    <li className="nav-item ps-2">
                      <NavLink
                        to="/cancelled-orders"
                        className={({ isActive }) =>
                          isActive
                            ? "nav-link active d-flex align-items-center px-3 py-2"
                            : "nav-link d-flex align-items-center px-3 py-2"
                        }
                      >
                        <span className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            className="bi bi-trash"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                          </svg>
                        </span>
                        <span className="sidebar-item">Cancelled</span>
                      </NavLink>
                    </li>
                    <li className="nav-item ps-2">
                      <NavLink
                        to="/view-invoices"
                        className={({ isActive }) =>
                          isActive
                            ? "nav-link active d-flex align-items-center px-3 py-2"
                            : "nav-link d-flex align-items-center px-3 py-2"
                        }
                      >
                        <span className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={14}
                            height={14}
                            fill="currentColor"
                            className="bi bi-receipt"
                            viewBox="0 0 16 16"
                          >
                            <path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27zm.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0l-.509-.51z" />
                            <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5z" />
                          </svg>
                        </span>
                        <span className="sidebar-item">View Invoices</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>
                {/*.nav-item*/}
                <li className="nav-item">
                  <NavLink
                    to="/services"
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link active d-flex align-items-center rounded mb-1"
                        : "nav-link d-flex align-items-center rounded mb-1"
                    }
                  >
                    <span className="icon d-inline-flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-gear"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                        <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
                      </svg>
                    </span>
                    <span className="sidebar-item ms-2">Services</span>
                  </NavLink>
                  {/*.nav-link*/}
                </li>
                {/*.nav-item*/}
                <li className="nav-item">
                  <NavLink
                    to="/account-settings"
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link active d-flex align-items-center rounded mb-1"
                        : "nav-link d-flex align-items-center rounded mb-1"
                    }
                  >
                    <span className="icon d-inline-flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-person-circle"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                        <path
                          fillRule="evenodd"
                          d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                        />
                      </svg>
                    </span>
                    <span className="sidebar-item ms-2">Profile</span>
                  </NavLink>
                  {/*.nav-link*/}
                </li>
                {/*.nav-item*/}
                <li className="nav-item">
                  <a
                    className={`${
                      expandedItem === "companyCollapse" || companyNestedPaths
                        ? "nav-link active d-flex align-items-center rounded mb-1"
                        : "nav-link d-flex align-items-center rounded collapsed mb-1"
                    } `}
                    data-bs-toggle="collapse"
                    href="#companyCollapse"
                    role="button"
                    aria-expanded={
                      expandedItem === "companyCollapse" ? "true" : "false"
                    }
                    aria-controls="chartsCollapse"
                    onClick={() => handleCollapse("companyCollapse")}
                  >
                    <span className="icon d-inline-flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-buildings"
                        viewBox="0 0 16 16"
                      >
                        <path d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022ZM6 8.694 1 10.36V15h5V8.694ZM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15Z" />
                        <path d="M2 11h1v1H2v-1Zm2 0h1v1H4v-1Zm-2 2h1v1H2v-1Zm2 0h1v1H4v-1Zm4-4h1v1H8V9Zm2 0h1v1h-1V9Zm-2 2h1v1H8v-1Zm2 0h1v1h-1v-1Zm2-2h1v1h-1V9Zm0 2h1v1h-1v-1ZM8 7h1v1H8V7Zm2 0h1v1h-1V7Zm2 0h1v1h-1V7ZM8 5h1v1H8V5Zm2 0h1v1h-1V5Zm2 0h1v1h-1V5Zm0-2h1v1h-1V3Z" />
                      </svg>
                    </span>
                    <span className="sidebar-item ms-2">Company</span>
                    <span className="sidebar-arrow d-inline-flex ms-auto">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={14}
                        height={14}
                        fill="currentColor"
                        className="bi bi-chevron-right"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                        />
                      </svg>
                    </span>
                  </a>
                  <ul
                    className={
                      expandedItem === "companyCollapse"
                        ? "collapse show list-unstyled ps-0"
                        : "collapse list-unstyled ps-0"
                    }
                    id="companyCollapse"
                    data-bs-parent="#collapse"
                  >
                    <li className="nav-item ps-2">
                      <NavLink
                        to="/about-us"
                        className={({ isActive }) =>
                          isActive
                            ? "nav-link active d-flex align-items-center px-3 py-2"
                            : "nav-link d-flex align-items-center px-3 py-2"
                        }
                      >
                        <span className="icon d-inline-flex">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            className="bi bi-info-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                          </svg>
                        </span>
                        <span className="sidebar-item">About</span>
                      </NavLink>
                    </li>
                    <li className="nav-item ps-2">
                      <NavLink
                        to="/terms-and-conditions"
                        className={({ isActive }) =>
                          isActive
                            ? "nav-link active d-flex align-items-center px-3 py-2"
                            : "nav-link d-flex align-items-center px-3 py-2"
                        }
                      >
                        <span className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-card-list"
                            viewBox="0 0 16 16"
                          >
                            <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2z" />
                            <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8m0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0M4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0m0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0" />
                          </svg>
                        </span>
                        <span className="sidebar-item">Terms of Service</span>
                      </NavLink>
                    </li>
                    {/* <li className="nav-item ps-2">
                      <NavLink
                        to="/faqs"
                        className={({ isActive }) =>
                          isActive
                            ? "nav-link active d-flex align-items-center px-3 py-2"
                            : "nav-link d-flex align-items-center px-3 py-2"
                        }
                      >
                        <span className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            className="bi bi-question-square"
                            viewBox="0 0 16 16"
                          >
                            <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                            <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
                          </svg>
                        </span>
                        <span className="sidebar-item">FAQs</span>
                      </NavLink>
                    </li> */}

                    <li className="nav-item ps-2">
                      <NavLink
                        to="/careers"
                        className={({ isActive }) =>
                          isActive
                            ? "nav-link active d-flex align-items-center px-3 py-2"
                            : "nav-link d-flex align-items-center px-3 py-2"
                        }
                      >
                        <span className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            className="bi bi-briefcase"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
                          </svg>
                        </span>
                        <span className="sidebar-item">Careers</span>
                      </NavLink>
                    </li>
                    <li className="nav-item ps-2">
                      <NavLink
                        to="/charity"
                        className={({ isActive }) =>
                          isActive
                            ? "nav-link active d-flex align-items-center px-3 py-2"
                            : "nav-link d-flex align-items-center px-3 py-2"
                        }
                      >
                        <span className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            className="bi bi-universal-access-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 4.143A1.071 1.071 0 1 0 8 2a1.071 1.071 0 0 0 0 2.143Zm-4.668 1.47 3.24.316v2.5l-.323 4.585A.383.383 0 0 0 7 13.14l.826-4.017c.045-.18.301-.18.346 0L9 13.139a.383.383 0 0 0 .752-.125L9.43 8.43v-2.5l3.239-.316a.38.38 0 0 0-.047-.756H3.379a.38.38 0 0 0-.047.756Z" />
                            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM1 8a7 7 0 1 1 14 0A7 7 0 0 1 1 8Z" />
                          </svg>
                        </span>
                        <span className="sidebar-item">Charity</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>
                {/*.nav-item*/}
                <li className="nav-item">
                  <NavLink
                    to="/contact-support"
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link active d-flex align-items-center rounded mb-1"
                        : "nav-link d-flex align-items-center rounded mb-1"
                    }
                  >
                    <span className="icon d-inline-flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-headset"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5z" />
                      </svg>
                    </span>
                    <span className="sidebar-item ms-2">Contact</span>
                  </NavLink>
                  {/*.nav-link*/}
                </li>
                {/*.nav-item*/}
              </ul>
              <ul className="sidebar-listing list-unstyled px-3 mb-0">
                {/* <li className="nav-item">
                  <a
                    className="nav-link d-flex align-items-center rounded mb-1"
                    href="#"
                  >
                    <span className="icon d-inline-flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-chat-dots"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                        <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z" />
                      </svg>
                    </span>
                    <span className="sidebar-item ms-2">Live Chat</span>
                  </a>
                </li> */}
                {/*.nav-item*/}
                <li className="nav-item">
                  <a
                    className="nav-link d-flex align-items-center rounded mb-1"
                    onClick={() => {
                      logout();
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <span className="icon d-inline-flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-box-arrow-in-left"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 3.5a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 1 1 0v2A1.5 1.5 0 0 1 9.5 14h-8A1.5 1.5 0 0 1 0 12.5v-9A1.5 1.5 0 0 1 1.5 2h8A1.5 1.5 0 0 1 11 3.5v2a.5.5 0 0 1-1 0v-2z"
                        />
                        <path
                          fillRule="evenodd"
                          d="M4.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H14.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"
                        />
                      </svg>
                    </span>
                    <span className="sidebar-item ms-2">Sign Out</span>
                  </a>
                  {/*.nav-link*/}
                </li>
                {/*.nav-item*/}
              </ul>
              {/*.sidebar-listing*/}
            </div>
          </div>
          {/*.sidebar-wrapper*/}
        </aside>
      )}
    </>
  );
}

export default ClientSidebar;
