import React from "react";
import Footer from "../../components/layout/Footer";
import AdminHeader from "../../components/layout/admin/AdminHeader";
import COLHeading from "../../components/admin/clientOrdersList/COLHeading";
import COLTable from "../../components/admin/clientOrdersList/COLTable";
import { Helmet } from "react-helmet";

function ClientOrdersList() {
  return (
    <>
      <Helmet>
        <title>Client Orders List – AAMAX</title>
      </Helmet>
      {/*=============== Start AAMAX OMS  ===============*/}
      <AdminHeader>
        {/*.header*/}
        <main className="page-content d-flex flex-column justify-content-between h-100">
          <div className="container-fluid p-3 p-md-4">
            <COLHeading />
            {/*.row*/}
            <COLTable />
            {/*.row*/}
          </div>
          {/*.container-fluid*/}
          <Footer />
        </main>
      </AdminHeader>
      {/*.page-content"*/}
      {/*=============== End AAMAX OMS  ===============*/}
    </>
  );
}

export default ClientOrdersList;
