import React, { useContext, useEffect } from "react";
import ClientHeader from "../../components/layout/client/ClientHeader";
import Footer from "../../components/layout/Footer";
import { Link, useNavigate } from "react-router-dom";
import { DOMAIN_URL } from "../../config/endPoints";
import { Order } from "../../context/Order";
import { Helmet } from "react-helmet";

function VerifyingPayment() {
  const { getOrderDetails, orderDetails } = useContext(Order);

  const navigate = useNavigate();

  if (orderDetails?.status !== "Verifying Payment") {
    navigate("/order-details");
  }

  useEffect(() => {
    getOrderDetails();
  }, []);

  return (
    <>
      <Helmet>
        <title>Verifying Payment – AAMAX</title>
      </Helmet>
      {/*=============== Start AAMAX OMS  ===============*/}

      <ClientHeader>
        {/*.header*/}
        <main className="page-content d-flex flex-column justify-content-between h-100">
          <div className="container-fluid p-3 p-md-4">
            {/*.row*/}
            <div className="row">
              <div className="col-12">
                <div className="page-card rounded-3 position-relative p-3 p-md-4">
                  <section className="oms-404 py-5">
                    <div className="container">
                      <div className="row align-items-center justify-content-center">
                        <div className="col-md-8 col-xl-6 my-3 text-center">
                          <figure className="mb-4 mb-md-5">
                            <img
                              src={`${DOMAIN_URL}/assets/images/verifying-payment.png`}
                              width={220}
                              className="img-fluid"
                              alt
                            />
                          </figure>
                          <h3 className="fs-1 mb-3">Thanks for Choosing Us!</h3>
                          <p className="fs-6 text-muted">
                            Your payment is currently being verified, and your
                            order will be processed as soon as it's confirmed.
                            This usually takes just a few hours, but you'll
                            receive an email notification the moment it's
                            complete.
                          </p>
                          <p className="fs-6 mb-5 text-muted">
                            If you have any questions in the meantime, please
                            don't hesitate to reach out to our customer support
                            team.
                          </p>
                          <Link to="/dashboard" className="btn oms-btn">
                            Back to Dashboard{" "}
                          </Link>
                        </div>
                        {/*.col-grid*/}
                      </div>
                      {/*.row*/}
                    </div>
                    {/*.container*/}
                  </section>
                  {/*.card-body*/}
                </div>
                {/*.page-card*/}
              </div>
              {/*.col-grid*/}
            </div>
            {/*.row*/}
          </div>
          {/*.container-fluid*/}
          <Footer />
        </main>
      </ClientHeader>
      {/*.page-content"*/}
      {/*=============== End AAMAX OMS  ===============*/}
    </>
  );
}

export default VerifyingPayment;
