import React, { useContext, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Rating from "react-rating";
import { message } from "antd";
import axios from "axios";
import { FEEDBACK_ORDER_API_URL } from "../../../config/endPoints";
import { Order } from "../../../context/Order";
import copy from "clipboard-copy";

function FeedbackDetails() {
  const navigate = useNavigate();

  const { sOrderId, orderId, orderDetails } = useContext(Order);

  const [feedback, setFeedback] = useState({
    review: "",
    communication: 0,
    service: 0,
    recommend: 0,
  });

  const handleRatingChange = (type, rating) => {
    setFeedback((prevFeedback) => ({
      ...prevFeedback,
      [type]: rating,
    }));
  };

  const handleReviewChange = (e) => {
    const newReview = e.target.value.slice(0, 251);

    if (newReview.length > 250) {
      message.error("Maximum character limit reached (250 characters).");
    } else {
      setFeedback((prevFeedback) => ({
        ...prevFeedback,
        review: newReview,
      }));
    }
  };

  const reqOrderId = sOrderId ? sOrderId : orderId;

  const submitReview = async () => {
    if (
      !feedback.review ||
      feedback.communication === 0 ||
      feedback.service === 0 ||
      feedback.recommend === 0
    ) {
      message.error("Please write review and select the rating.");
      return;
    }
    try {
      const res = await axios.put(
        `${FEEDBACK_ORDER_API_URL}/${reqOrderId}`,
        feedback
      );
      message.success(res?.data?.message);
      setFeedback({
        review: "",
        communication: 0,
        service: 0,
        recommend: 0,
      });
      const successOrderModal = new window.bootstrap.Modal(
        document.getElementById("successOrderModal")
      );
      successOrderModal.show();
    } catch (err) {
      message.error(err?.response?.data?.error);
    }
  };

  const handleNavigate = () => {
    navigate("/order-details");
  };

  const shareUrl = "https://aamax.co";
  const shareTitle = `High-Quality ${orderDetails.service} Services from AAMAX`;
  const msg = `I recently hired AAMAX for ${orderDetails?.service} services, impressive results at a great price! Consider them for your business.`;

  const copyMsg = `Upgrade your digital presence with quality services from ${shareUrl}.`;

  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${shareUrl}&text=${encodeURIComponent(
    `${msg} ${shareTitle}:`
  )}`;
  const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    `${msg} ${shareUrl}`
  )}`;

  const inputRef = useRef(null);

  const handleCopy = async () => {
    if (inputRef.current) {
      try {
        await copy(inputRef.current.value);
        message.success("Text copied to clipboard");
      } catch (err) {
        message.error("Failed to copy text to clipboard");
      }
    }
  };

  return (
    <>
      <div className="col-lg-8 col-md-7">
        <div className="page-card rounded-3 position-relative p-3 p-md-4">
          <div className="card-body">
            {/* <h4 className="h5 fw-semibold mb-3">
              Share your experience with the community, to help them make better
              decisions
            </h4> */}
            <div>
              <ul className="list-unstyled my-3">
                <li className="d-flex justify-content-between my-3 gap-4 py-2">
                  <article className="pe-md-4">
                    <h4 className="h6 position-relative">
                      Communication with team
                    </h4>
                    <p className="small mb-0">
                      How responsive was the team during the process?
                    </p>
                  </article>
                  <ul className="review-list list-unstyled d-flex gap-1 primary-text">
                    <Rating
                      initialRating={feedback.communication}
                      emptySymbol={
                        <span className="icon" style={{ margin: "0 2px" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            className="bi bi-star-fill text-secondary"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                          </svg>
                        </span>
                      }
                      fullSymbol={
                        <span className="icon" style={{ margin: "0 2px" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            className="bi bi-star-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                          </svg>
                        </span>
                      }
                      onClick={(value) =>
                        handleRatingChange("communication", value)
                      }
                    />
                  </ul>
                </li>
                <li className="d-flex justify-content-between my-3 gap-4 py-2">
                  <article className="pe-md-4">
                    <h4 className="h6 position-relative">
                      Service as described
                    </h4>
                    <p className="small mb-0">
                      Did the results match the service description?
                    </p>
                  </article>
                  <ul className="review-list list-unstyled d-flex gap-1 primary-text">
                    <Rating
                      initialRating={feedback.service}
                      emptySymbol={
                        <span className="icon" style={{ margin: "0 2px" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            className="bi bi-star-fill text-secondary"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                          </svg>
                        </span>
                      }
                      fullSymbol={
                        <span className="icon" style={{ margin: "0 2px" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            className="bi bi-star-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                          </svg>
                        </span>
                      }
                      onClick={(value) => handleRatingChange("service", value)}
                    />
                  </ul>
                </li>
                <li className="d-flex justify-content-between my-3 gap-4 py-2">
                  <article className="pe-md-4">
                    <h4 className="h6 position-relative">
                      Buy again or recommend
                    </h4>
                    <p className="small mb-0">
                      Would you recommend our services to others?
                    </p>
                  </article>
                  <ul className="review-list list-unstyled d-flex gap-1 primary-text">
                    <Rating
                      initialRating={feedback.recommend}
                      emptySymbol={
                        <span className="icon" style={{ margin: "0 2px" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            className="bi bi-star-fill text-secondary"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                          </svg>
                        </span>
                      }
                      fullSymbol={
                        <span className="icon" style={{ margin: "0 2px" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            className="bi bi-star-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                          </svg>
                        </span>
                      }
                      onClick={(value) =>
                        handleRatingChange("recommend", value)
                      }
                    />
                  </ul>
                </li>
              </ul>
            </div>
            <div className="form-wrapepr mb-3 pb-2 mt-4 pt-md-3">
              <label className="form-label fw-semibold">
                Describe your experience working with AAMAX.
              </label>
              <div className="form-text-area border rounded-3">
                <div className="form-label-group mb-0">
                  <textarea
                    className="form-control border-0 p-3"
                    id="exampleFormControlTextarea3"
                    rows={6}
                    value={feedback?.review}
                    onChange={handleReviewChange}
                    maxLength={251}
                  />
                </div>
                {/* <div className="form-footer border-top px-2 py-1 d-flex justify-content-between align-items-center">
                  <small className="counter-value-text text-secondary small opacity-75">
                    <span className="char-count">{feedback.review.length}</span>{" "}
                    / 250{" "}
                  </small>
                </div> */}
              </div>
              {/*.form-text-area*/}
            </div>
            {/*.form-wrapepr*/}

            <div className="btn-wrapper mt-4 pt-lg-3 text-end">
              <Link
                to="/order-details"
                className="primary-action me-2 opacity-75 text-decoration-none small"
              >
                Skip
              </Link>
              <button
                type="submit"
                className="btn primary-btn fw-semibold fs-6 px-4 py-2"
                onClick={submitReview}
              >
                Send Feedback
              </button>
              <div
                className="modal fade"
                id="successOrderModal"
                tabIndex={-1}
                aria-labelledby="successOrderModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content position-relative">
                    <div className="modal-body p-5">
                      <div className="success-order-modal text-center">
                        <div className="img-wrapper mb-4 mb-md-5">
                          <img
                            src="./assets/images/success claps.jpg"
                            className
                            height={180}
                            alt
                          />
                        </div>
                        <div>
                          <h4 className="fw-semibold mb-4">Spread the word!</h4>
                          <p className="mb-4 pb-md-2">
                            Did you love the work by AAMAX? Share your positive
                            experience with your friends – they'll thank you for
                            it!
                          </p>
                          <ul className="social-list-two list-unstyled d-flex align-items-center justify-content-center mb-4 pb-md-3">
                            <li className="mx-2">
                              <a
                                href={facebookShareUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="bi bi-facebook" />
                              </a>
                            </li>
                            <li className="mx-2">
                              <a
                                href={twitterShareUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="bi bi-twitter" />
                              </a>
                            </li>
                            <li className="mx-2">
                              <a
                                href={whatsappShareUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="bi bi-whatsapp" />
                              </a>
                            </li>
                          </ul>
                          <div className="input-group mb-3">
                            <label className="form-label small text-secondary opacity-75 mb-3 col-12">
                              Or copy message to share
                            </label>
                            <input
                              ref={inputRef}
                              type="text"
                              className="form-control rounded-2 border bg-white px-3 pe-5"
                              defaultValue={copyMsg}
                              disabled
                              aria-describedby="button-addon2"
                            />
                            <button
                              className="btn bg-tranparnt rounded-2 position-absolute end-0 bottom-0 mb-1"
                              type="button"
                              id="button-addon2"
                              onClick={handleCopy}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={16}
                                height={16}
                                fill="currentColor"
                                className="bi bi-copy"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="btn-close position-absolute end-0 m-3"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={handleNavigate}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*.card-body*/}
        </div>
        {/*.page-card*/}
      </div>
    </>
  );
}

export default FeedbackDetails;
