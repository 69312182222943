import React, { useContext } from "react";
import { Order } from "../../../context/Order";

function NoMessage() {
  const { orderDetails } = useContext(Order);

  return (
    <>
      {orderDetails?.status === "Completed" ||
      orderDetails?.status === "Cancelled" ? (
        <div className="empty-msgs text-center pt-4 mt-3">
          <h5 className="h5 fw-semibold mb-2">
            No messages found for this order.
          </h5>
          <p className="small">
            You won't see any future messages since this order has been{" "}
            {orderDetails?.status}.
          </p>
        </div>
      ) : (
        <div className="empty-msgs text-center pt-4 mt-3">
          <h5 className="h5 fw-semibold mb-2">No Messages Yet</h5>
          <p className="small">When you have messages you'll see them here</p>
        </div>
      )}
    </>
  );
}

export default NoMessage;
