import React from "react";
import { Link } from "react-router-dom";
import { DOMAIN_URL } from "../../src/config/endPoints";
import { Helmet } from "react-helmet";

function PageNotFound() {
  return (
    <>
      <Helmet>
        <title>Page Not Found – AAMAX</title>
      </Helmet>
      {/*====================== Start 404 Error ======================*/}
      <section className="oms-404 py-5">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-8 col-xl-6 my-3 text-center">
              <figure className="mb-4 mb-md-5">
                <img
                  src={`${DOMAIN_URL}/assets/images/404-error.png`}
                  width={220}
                  className="img-fluid"
                  alt
                />
              </figure>
              <h3 className="fs-1 mb-3">Page Not Found</h3>
              <p className="fs-6 mb-5 text-muted">
                Uh oh, looks like you've stumbled onto a page that doesn't
                exist. We apologize for any confusion or frustration this may
                cause. Please use the link below to navigate our site, or
                contact us if you have any questions.
              </p>
              <Link to="/" className="btn oms-btn">
                Back to Homepage{" "}
              </Link>
            </div>
            {/*.col-grid*/}
          </div>
          {/*.row*/}
        </div>
        {/*.container*/}
      </section>
      {/*====================== End 404 Error ======================*/}
    </>
  );
}

export default PageNotFound;
