import React, { useContext, useEffect, useState } from "react";
import { Order } from "../../../context/Order";
import { DeliveryCtx } from "../../../context/DeliverOrder";
import { AuthContext } from "../../../context/Auth";
import { Tooltip } from "antd";
import { constructImageUrl } from "../../../utils/helpers";

function DeliverOrder() {
  const { orderDetails, setDeliverYOrder, deliverYOrder } = useContext(Order);
  const {
    deliverOrder,
    deliveryDesc,
    setDeliveryDesc,
    handleDeliveryChange,
    handleDeliveryAttachments,
    resetSelection,
    uploading,
    requirementsFiles,
    currentFileIndex,
    currentChunkIndex,
    chunkSize,
  } = useContext(DeliveryCtx);
  const { userInfo } = useContext(AuthContext);

  const deliveryClickHandler = (e) => {
    e.preventDefault();
    setDeliverYOrder((prevValue) => !prevValue);
    setDeliveryDesc({
      description: "",
    });
    resetSelection();
  };

  const deliveryDate = orderDetails?.deliveryDate;

  const calcimeRemaining = () => {
    const now = new Date().getTime();
    const deliveryTime = new Date(deliveryDate).getTime();
    const timeDifference = deliveryTime - now;

    if (timeDifference <= 0) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  };

  const [timeRemaining, setTimeRemaining] = useState(calcimeRemaining());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calcimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, [deliveryDate]);

  return (
    <>
      {(userInfo?.userType === "tMember" ||
        userInfo?.userType === "manager" ||
        userInfo?.userType === "admin") && (
        <>
          {deliverYOrder ? (
            <div className="card-body">
              <div className="write-msg-box bg-light p-4 mt-4">
                <div className="d-flex flex-wrap flex-column gap-md-4 gap-3">
                  <div className="d-flex align-items-center">
                    {userInfo?.imageUrl ? (
                      <img
                        className="rounded-circle me-3"
                        src={constructImageUrl(userInfo?.imageUrl)}
                        width={45}
                        height={45}
                      />
                    ) : (
                      <div className="user-avator-order secondary-bg rounded-circle d-flex align-items-center justify-content-center text-uppercase fw-semibold text-white me-2">
                        <span className="name-txt">
                          {`${
                            userInfo?.firstName
                              ? userInfo.firstName.charAt(0).toUpperCase()
                              : ""
                          }${
                            userInfo?.lastName
                              ? userInfo.lastName.charAt(0).toUpperCase()
                              : ""
                          }`}
                        </span>
                      </div>
                    )}
                    <h6 className="h6 mb-0">
                      Write description about order delivery here.
                    </h6>
                  </div>
                  <textarea
                    className="form-control flex-fill w-100 p-3 ml-1 mt-md-0 mt-2 shadow-none textarea"
                    id="exampleFormControlTextarea3"
                    placeholder="Your description..."
                    rows={8}
                    defaultValue={""}
                    value={deliveryDesc.description}
                    onChange={handleDeliveryChange}
                  />
                </div>
                <div className="d-flex flex-wrap justify-content-between gap-3 mb-0 mt-3 mt-md-4">
                  <div className="d-flex flex-wrap gap-3 align-items-center">
                    <button
                      className="btn primary-btn flex-fill fw-semibold fs-6 px-4 py-2"
                      type="button"
                      onClick={deliverOrder}
                      disabled={uploading}
                    >
                      {uploading && (
                        <span className="spinner-grow spinner-grow-sm me-2"></span>
                      )}

                      {uploading ? "Uploading..." : "Deliver Your Order"}
                    </button>
                    <button
                      className="btn primary-btn-outline fw-semibold flex-fill fs-6 px-4 py-2"
                      type="button"
                      onClick={deliveryClickHandler}
                    >
                      Back to Order Details
                    </button>
                  </div>
                  <ul className="textarea-meta-list list-unstyled d-flex flex-wrap align-items-center gap-2 ps-0 mb-0">
                    <li>
                      <span className="secondary-text small">
                        <small>
                          {requirementsFiles?.length > 0 &&
                          currentFileIndex !== null
                            ? `Uploading ${currentFileIndex + 1}/${
                                requirementsFiles?.length
                              } (${Math.round(
                                (currentChunkIndex /
                                  Math.ceil(
                                    requirementsFiles[currentFileIndex]?.size /
                                      chunkSize
                                  )) *
                                  100
                              )}%)`
                            : requirementsFiles?.length > 0
                            ? `${requirementsFiles?.length} ${
                                requirementsFiles?.length === 1
                                  ? "File"
                                  : "Files"
                              } Attached`
                            : ""}
                        </small>
                      </span>
                    </li>
                    <li className="file-attachment">
                      {requirementsFiles?.length > 0 &&
                      currentFileIndex === null ? (
                        <button className="btn text-secondary p-1 pe-0 opacity-75 ms-2">
                          <Tooltip
                            title="Reset Selection"
                            mouseEnterDelay={0.1}
                          >
                            <img
                              src="/assets/images/x.svg"
                              alt="X Icon"
                              width="20"
                              height="20"
                              onClick={resetSelection}
                            />
                          </Tooltip>
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn p-1 px-2 border mx-1 rounded-2 text-secondary"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            fill="currentColor"
                            className="bi bi-paperclip"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                          </svg>
                          <Tooltip title="Max 50MB/File" mouseEnterDelay={0.1}>
                            <input
                              type="file"
                              name="files"
                              multiple
                              onChange={handleDeliveryAttachments}
                            />{" "}
                            <small>Attach files</small>
                          </Tooltip>
                        </button>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ) : (
            <div className="order-countdown-card">
              <div className="card-header rounded-3 d-flex flex-wrap gap-3 align-items-center justify-content-center justify-content-md-between">
                <div className="header-left d-flex align-items-center gap-2">
                  <span className="icon secondary-text fs-2 text-uppercase">
                    <i className="bi bi-geo-alt-fill" />
                  </span>
                  <span className="small fw-semibold">
                    Order in{" "}
                    {orderDetails?.status === "Revision"
                      ? orderDetails?.status
                      : "Progress"}
                    <br />{" "}
                    <span className="text-secondary fw-normal">
                      Deliver Soon
                    </span>
                  </span>
                </div>
                <div className="header-right">
                  <a
                    href="#"
                    className="btn oms-btn position-relative overflow-hidden shadow"
                    onClick={deliveryClickHandler}
                  >
                    {orderDetails?.status === "Revision"
                      ? "Deliver Again"
                      : "Deliver Your Order"}
                  </a>
                </div>
              </div>
              <div className="card-body">
                <ul className="countdown-time-list list-unstyled d-flex justify-content-center m-0 gap-3 gap-md-5">
                  <li>
                    <span>
                      {timeRemaining?.days === 0 ? "00" : timeRemaining?.days}
                    </span>
                    Days
                  </li>
                  <li>
                    <span>
                      {timeRemaining?.hours === 0 ? "00" : timeRemaining?.hours}
                    </span>
                    Hours
                  </li>
                  <li>
                    <span>
                      {timeRemaining?.minutes === 0
                        ? "00"
                        : timeRemaining?.minutes}
                    </span>
                    Minutes
                  </li>
                  <li>
                    <span>
                      {timeRemaining?.seconds === 0
                        ? "00"
                        : timeRemaining?.seconds}
                    </span>
                    seconds
                  </li>
                </ul>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default DeliverOrder;
