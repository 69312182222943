import React, { useContext } from "react";
import { SearchCtx } from "../../../context/SearchContext";

function ACSearch() {
  const { searchOrder, setSearchOrder } = useContext(SearchCtx);

  const handleSearchChange = (e) => {
    setSearchOrder(e.target.value);
  };

  return (
    <>
      <div className="card-header p-3 bg-light">
        <div className="row">
          <div className="col-md-11 col-lg-10 col-xl-8">
            <div className="search-wrapper d-flex flex-column flex-sm-row align-items-sm-center">
              <label className="h6 fw-semibold me-2 mb-0" htmlFor="searh-input">
                Credits
              </label>
              <form
                className="search-form position-relative flex-fill mt-sm-0 mt-2"
                action="#"
              >
                <input
                  className="form-control w-100 rounded-pill ps-3"
                  type="text"
                  placeholder="Search..."
                  aria-label="Search"
                  value={searchOrder}
                  onChange={handleSearchChange}
                />
                <span className="search-box-icon position-absolute">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={14}
                    height={14}
                    fill="currentColor"
                    className="bi bi-search"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>
                </span>
              </form>
            </div>
            {/*.search-wrapper*/}
          </div>
          {/*.col-grid*/}
        </div>
        {/*.row*/}
      </div>
    </>
  );
}

export default ACSearch;
