import React, { useState } from "react";
import useService from "../../../hooks/useService";

function ServiceFaqsSec() {
  const data = useService();

  const [activeFaq, setActiveFaq] = useState(null);

  const handleToggle = (index) => {
    if (activeFaq === index) {
      setActiveFaq(null);
    } else {
      setActiveFaq(index);
    }
  };

  return (
    <>
      <section className="faq-sec py-4 py-sm-5">
        <div className="row align-items-center justify-content-center">
          <div className="col-xl-7 col-lg-9">
            <div className="section-title-wrapper text-center">
              <h2 className="section-title fs-2 fw-semibold mb-md-4 mb-3">
                <span className="title-highlighted">
                  FAQs
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 500 150"
                    className="highlighted-svg"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M15.2,133.3L15.2,133.3c121.9-7.6,244-9.9,366.1-6.8c34.6,0.9,69.1,2.3,103.7,4"
                      style={{ animationPlayState: "running" }}
                    />
                  </svg>
                </span>
              </h2>
            </div>
            {/*.-section-title*/}
          </div>
          {/*.col-grid*/}
        </div>
        {/*.row*/}
        <div
          className="accordion row justify-content-center accordion-flush mt-4"
          id="accordionFlushExample"
        >
          <div className="col-lg-10 col-xl-8">
            {data?.attributes?.faqs?.map((faq, i) => (
              <div
                className="accordion-item border-0 mb-3 rounded-2 overflow-hidden"
                key={i}
              >
                <h2 className="accordion-header" id={`flush-heading-${i}`}>
                  <button
                    className={`accordion-button fs-6 fw-semibold py-3 rounded-0 ${
                      activeFaq === i ? "" : "collapsed"
                    }`}
                    type="button"
                    onClick={() => handleToggle(i)}
                    aria-expanded={activeFaq === i ? "true" : "false"}
                    aria-controls={`flush-collapse-${i}`}
                  >
                    {faq?.question}{" "}
                  </button>
                </h2>
                <div
                  id={`flush-collapse-${i}`}
                  className={`accordion-collapse collapse ${
                    activeFaq === i ? "show" : ""
                  }`}
                  aria-labelledby={`flush-heading-${i}`}
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <p className="text-muted">{faq?.answer}</p>
                  </div>
                </div>
              </div>
            ))}

            {/*.accordion-item*/}
          </div>
          {/*.col-grid*/}
        </div>
        {/*.accordion*/}
      </section>
    </>
  );
}

export default ServiceFaqsSec;
