import React from "react";
import Footer from "../../components/layout/Footer";
import ClientHeader from "../../components/layout/client/ClientHeader";
import POPMainHeading from "../../components/client/placeOrderPayment/POPMainHeading";
import TableMainHeading from "../../components/client/placeOrderPayment/TableMainHeading";
import OrderDetailAndPayment from "../../components/client/placeOrderPayment/OrderDetailAndPayment";
import PaymentModal from "../../components/client/placeOrderPayment/PaymentModal";
import { Helmet } from "react-helmet";

function PlaceOrderPayment() {
  return (
    <>
      <Helmet>
        <title>Order Summary – AAMAX</title>
      </Helmet>
      {/*=============== Start AAMAX OMS  ===============*/}

      <ClientHeader>
        {/*.header*/}
        <main className="page-content d-flex flex-column justify-content-between h-100">
          <div className="container-fluid p-3 p-md-4">
            <POPMainHeading />
            {/*.row*/}
            <div className="row">
              <div className="col-12">
                <div className="page-card rounded-3 position-relative p-3 p-md-4">
                  <div className="card-body">
                    <TableMainHeading />
                    {/*.table-responsive*/}
                    <OrderDetailAndPayment />
                    {/*.row*/}
                    {/* Card Payment Modal */}
                    {/* <PaymentModal /> */}
                    {/*.card Payment Modal*/}
                  </div>
                  {/*.card-body*/}
                  {/*.card-footer*/}
                </div>
                {/*.page-card*/}
              </div>
              {/*.col-grid*/}
            </div>
            {/*.row*/}
          </div>
          {/*.container-fluid*/}
          <Footer />
        </main>
      </ClientHeader>
      {/*.page-content"*/}
      {/*=============== End AAMAX OMS  ===============*/}
    </>
  );
}

export default PlaceOrderPayment;
