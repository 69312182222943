import React, { useContext } from "react";
import NoMessage from "./NoMessage";
import { Order } from "../../../context/Order";
import { AuthContext } from "../../../context/Auth";
import { MessageCtx } from "../../../context/Message";
import EmojiPicker from "emoji-picker-react";
import { Tooltip } from "antd";
import {
  constructImageUrl,
  convertToHTML,
  formatDateTime,
  formatFileSize,
  getFileIcon,
  truncateFileName,
} from "../../../utils/helpers";
import { BACKEND_URL } from "../../../config/endPoints";
import { UtilsCtx } from "../../../context/UtilsContext";

function MessagesTab() {
  const { orderDetails } = useContext(Order);
  const { userInfo } = useContext(AuthContext);
  const {
    messages,
    showEmojiPicker,
    messageContent,
    handleMessageChange,
    sendMessage,
    toggleEmojiPicker,
    handleEmojiSelection,
    emojiPickerRef,
    handleMsgAttachments,
    resetSelection,
    uploading,
    requirementsFiles,
    currentFileIndex,
    currentChunkIndex,
    chunkSize,
  } = useContext(MessageCtx);

  const { handleDownloadAttachment } = useContext(UtilsCtx);

  return (
    <>
      {messages?.length === 0 ? (
        <>
          <NoMessage />
          {(userInfo?.userType === "client" ||
            userInfo?.userType === "clientTm" ||
            userInfo?.userType === "tMember" ||
            userInfo?.userType === "manager" ||
            userInfo?.userType === "admin") && (
            <div className="msgs-wrapper custom-margin">
              {(orderDetails?.status === "In Progress" ||
                orderDetails?.status === "Delivered" ||
                orderDetails?.status === "Revision") && (
                <div className="write-msg-box bg-light p-3 mt-4">
                  <div>
                    <div>
                      <div className="d-flex flex-wrap flex-row align-items-start">
                        {userInfo?.imageUrl ? (
                          <img
                            className="rounded-circle me-3"
                            src={constructImageUrl(userInfo?.imageUrl)}
                            width={40}
                            height={40}
                          />
                        ) : (
                          <div className="user-avator-order secondary-bg rounded-circle d-flex align-items-center justify-content-center text-uppercase fw-semibold text-white me-2">
                            <span className="name-txt">
                              {`${
                                userInfo?.firstName
                                  ? userInfo.firstName.charAt(0).toUpperCase()
                                  : ""
                              }${
                                userInfo?.lastName
                                  ? userInfo.lastName.charAt(0).toUpperCase()
                                  : ""
                              }`}
                            </span>
                          </div>
                        )}
                        <textarea
                          className="form-control flex-fill w-auto p-3 ml-1 shadow-none textarea"
                          id="exampleFormControlTextarea3"
                          placeholder="Send message..."
                          rows={5}
                          defaultValue={""}
                          value={messageContent.content}
                          onChange={handleMessageChange}
                        />
                      </div>
                      <div className="d-flex flex-wrap justify-content-between ms-5 ps-2 mb-0 mt-3">
                        <ul className="textarea-meta-list list-unstyled d-flex flex-wrap align-items-center ps-0 mb-0">
                          <li>
                            <button
                              type="button"
                              className="btn p-1 border rounded-2"
                              // data-bs-toggle="dropdown"
                              // aria-expanded="false"
                              onClick={toggleEmojiPicker}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="text-secondary"
                                width={20}
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>
                            </button>
                            {showEmojiPicker && (
                              <div ref={emojiPickerRef}>
                                <EmojiPicker
                                  onEmojiClick={handleEmojiSelection}
                                  height={425}
                                  width={300}
                                />
                              </div>
                            )}
                          </li>
                          <li className="file-attachment">
                            {requirementsFiles?.length > 0 &&
                            currentFileIndex === null ? (
                              <button className="btn text-secondary p-1 pe-0 opacity-75 ms-2">
                                <Tooltip
                                  title="Reset Selection"
                                  mouseEnterDelay={0.1}
                                >
                                  <img
                                    src="/assets/images/x.svg"
                                    alt="X Icon"
                                    width="20"
                                    height="20"
                                    onClick={resetSelection}
                                  />
                                </Tooltip>
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn p-1 px-2 border mx-1 rounded-2 text-secondary"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={20}
                                  height={20}
                                  fill="currentColor"
                                  className="bi bi-paperclip"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                                </svg>
                                <Tooltip
                                  title="Max 50MB/File"
                                  mouseEnterDelay={0.1}
                                >
                                  <input
                                    type="file"
                                    name="files"
                                    multiple
                                    onChange={handleMsgAttachments}
                                  />{" "}
                                  <small>Attach files</small>
                                </Tooltip>
                              </button>
                            )}
                          </li>
                          <li>
                            <span className="secondary-text small">
                              <small>
                                {requirementsFiles?.length > 0 &&
                                currentFileIndex !== null
                                  ? `Uploading ${currentFileIndex + 1}/${
                                      requirementsFiles?.length
                                    } (${Math.round(
                                      (currentChunkIndex /
                                        Math.ceil(
                                          requirementsFiles[currentFileIndex]
                                            ?.size / chunkSize
                                        )) *
                                        100
                                    )}%)`
                                  : requirementsFiles?.length > 0
                                  ? `${requirementsFiles?.length} ${
                                      requirementsFiles?.length === 1
                                        ? "File"
                                        : "Files"
                                    } Attached`
                                  : ""}
                              </small>
                            </span>
                          </li>
                        </ul>
                        <button
                          className="btn primary-btn fw-semibold fs-6 px-4 my-md-0 my-2"
                          type="button"
                          onClick={sendMessage}
                          disabled={uploading}
                        >
                          {uploading && (
                            <span className="spinner-grow spinner-grow-sm me-2"></span>
                          )}

                          {uploading ? "Uploading..." : "Send"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      ) : (
        <>
          <div className="msgs-wrapper">
            <>
              <ul className="list-group border-0 rounded-0">
                {messages?.map((m, i) => (
                  <li
                    className="list-group-item px-0 py-3 d-flex flex-wrap align-items-center border-0 border-bottom"
                    key={i}
                  >
                    <a
                      className=""
                      data-bs-toggle="collapse"
                      href={`#collapse${i}`}
                      role="button"
                      aria-expanded="true"
                      aria-controls={`collapse${i}`}
                    >
                      <h4 className="h6 m-0 d-inline-flex align-items-center">
                        <span className="icon bg-icon-style d-inline-flex rounded-pill justify-content-center align-items-center me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            className="bi bi-chat-left-dots"
                            viewBox="0 0 16 16"
                          >
                            <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                            <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                          </svg>
                        </span>
                        {userInfo?._id === m?.user?._id
                          ? "You sent message"
                          : `${m?.user?.firstName} ${m?.user?.lastName}'s message`}
                      </h4>

                      <span className="small d-inline-flex text-secondary ms-4 ps-2">
                        {formatDateTime(m?.timestamp)}
                      </span>
                    </a>
                    <div className="collapse mt-2 show" id={`collapse${i}`}>
                      <div className="card card-body rounded-2 border-0 py-0">
                        <div className="devivery-item-wrapper">
                          <div className="d-flex flex-column py-2">
                            <div className="d-flex flex-row user-info">
                              <div>
                                {m?.user?.imageUrl ? (
                                  <img
                                    className="rounded-circle me-3"
                                    src={constructImageUrl(m?.user?.imageUrl)}
                                    width={40}
                                    height={40}
                                  />
                                ) : (
                                  <div className="user-avator-order secondary-bg rounded-circle d-flex align-items-center justify-content-center text-uppercase fw-semibold text-white me-3">
                                    <span className="name-txt">
                                      {`${
                                        m?.user?.firstName
                                          ? m?.user?.firstName
                                              .charAt(0)
                                              .toUpperCase()
                                          : ""
                                      }${
                                        m?.user?.lastName
                                          ? m?.user?.lastName
                                              .charAt(0)
                                              .toUpperCase()
                                          : ""
                                      }`}
                                    </span>
                                  </div>
                                )}
                              </div>
                              <div className="mt-2 pe-sm-4">
                                <p
                                  className="small"
                                  dangerouslySetInnerHTML={convertToHTML(
                                    m?.content
                                  )}
                                />
                                {m?.msgAttachments?.length > 0 && (
                                  <div className="attachment-wrapper pt-2">
                                    <span className="h6 d-inline-block mb-3">
                                      {m?.msgAttachments?.length === 1
                                        ? "Attachment"
                                        : "Attachments"}
                                    </span>
                                    <div className="attach-thumb-wrapper d-flex flex-wrap align-items-center gap-3">
                                      {m?.msgAttachments?.map((ra, i) => (
                                        <div
                                          className="attach-thumb-item rounded-1 overflow-hidden"
                                          key={i}
                                        >
                                          <figure className="mb-0 bg-light d-flex align-items-center justify-content-center">
                                            <i
                                              className={getFileIcon(
                                                `${BACKEND_URL}/${ra?.filePath}`
                                              )}
                                            />
                                          </figure>
                                          <figcaption className="d-flex justify-content-between align-items-center px-3">
                                            <span className="small">
                                              {truncateFileName(
                                                ra?.filename,
                                                10
                                              )}{" "}
                                              ({formatFileSize(ra?.filesize)})
                                            </span>
                                            <span className="icons-wrapper">
                                              <span
                                                className="icon file-download secondary-text mx-2 opacity-75"
                                                onClick={() =>
                                                  handleDownloadAttachment(
                                                    ra?.filename,
                                                    ra?.filePath
                                                  )
                                                }
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width={16}
                                                  height={16}
                                                  fill="currentColor"
                                                  className="bi bi-download"
                                                  viewBox="0 0 16 16"
                                                >
                                                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                                </svg>
                                              </span>
                                            </span>
                                          </figcaption>
                                        </div>
                                      ))}
                                      {/*.attach-thumb-item*/}

                                      {/*.attach-thumb-item rounded-1 overflow-hidden*/}
                                    </div>

                                    {/*.attach-thumb-wrapper*/}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </>

            {(userInfo?.userType === "client" ||
              userInfo?.userType === "clientTm" ||
              userInfo?.userType === "tMember" ||
              userInfo?.userType === "manager" ||
              userInfo?.userType === "admin") &&
              (orderDetails?.status === "In Progress" ||
                orderDetails?.status === "Delivered" ||
                orderDetails?.status === "Revision") && (
                <div className="write-msg-box bg-light p-3 mt-4">
                  <div>
                    <div>
                      <div className="d-flex flex-wrap flex-row align-items-start">
                        {userInfo?.imageUrl ? (
                          <img
                            className="rounded-circle me-3"
                            src={constructImageUrl(userInfo?.imageUrl)}
                            width={40}
                            height={40}
                          />
                        ) : (
                          <div className="user-avator-order secondary-bg rounded-circle d-flex align-items-center justify-content-center text-uppercase fw-semibold text-white me-2">
                            <span className="name-txt">
                              {`${
                                userInfo?.firstName
                                  ? userInfo.firstName.charAt(0).toUpperCase()
                                  : ""
                              }${
                                userInfo?.lastName
                                  ? userInfo.lastName.charAt(0).toUpperCase()
                                  : ""
                              }`}
                            </span>
                          </div>
                        )}
                        <textarea
                          className="form-control flex-fill w-auto p-3 ml-1 shadow-none textarea"
                          id="exampleFormControlTextarea3"
                          placeholder="Send message..."
                          rows={5}
                          defaultValue={""}
                          value={messageContent.content}
                          onChange={handleMessageChange}
                        />
                      </div>
                      <div className="d-flex flex-wrap justify-content-between ms-5 ps-2 mb-0 mt-3">
                        <ul className="textarea-meta-list list-unstyled d-flex flex-wrap align-items-center ps-0 mb-0">
                          <li>
                            <button
                              type="button"
                              className="btn p-1 border rounded-2"
                              // data-bs-toggle="dropdown"
                              // aria-expanded="false"
                              onClick={toggleEmojiPicker}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="text-secondary"
                                width={20}
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>
                            </button>
                            {showEmojiPicker && (
                              <div ref={emojiPickerRef}>
                                <EmojiPicker
                                  onEmojiClick={handleEmojiSelection}
                                  height={425}
                                  width={300}
                                />
                              </div>
                            )}
                          </li>
                          <li className="file-attachment">
                            {requirementsFiles?.length > 0 &&
                            currentFileIndex === null ? (
                              <button className="btn text-secondary p-1 pe-0 opacity-75 ms-2">
                                <Tooltip
                                  title="Reset Selection"
                                  mouseEnterDelay={0.1}
                                >
                                  <img
                                    src="/assets/images/x.svg"
                                    alt="X Icon"
                                    width="20"
                                    height="20"
                                    onClick={resetSelection}
                                  />
                                </Tooltip>
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn p-1 px-2 border mx-1 rounded-2 text-secondary"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={20}
                                  height={20}
                                  fill="currentColor"
                                  className="bi bi-paperclip"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                                </svg>
                                <Tooltip
                                  title="Max 50MB/File"
                                  mouseEnterDelay={0.1}
                                >
                                  <input
                                    type="file"
                                    name="files"
                                    multiple
                                    onChange={handleMsgAttachments}
                                  />{" "}
                                  <small>Attach files</small>
                                </Tooltip>
                              </button>
                            )}
                          </li>
                          <li>
                            <span className="secondary-text small">
                              <small>
                                {requirementsFiles?.length > 0 &&
                                currentFileIndex !== null
                                  ? `Uploading ${currentFileIndex + 1}/${
                                      requirementsFiles?.length
                                    } (${Math.round(
                                      (currentChunkIndex /
                                        Math.ceil(
                                          requirementsFiles[currentFileIndex]
                                            ?.size / chunkSize
                                        )) *
                                        100
                                    )}%)`
                                  : requirementsFiles?.length > 0
                                  ? `${requirementsFiles?.length} ${
                                      requirementsFiles?.length === 1
                                        ? "File"
                                        : "Files"
                                    } Attached`
                                  : ""}
                              </small>
                            </span>
                          </li>
                        </ul>
                        <button
                          className="btn primary-btn fw-semibold fs-6 px-4 my-md-0 my-2"
                          type="button"
                          onClick={sendMessage}
                          disabled={uploading}
                        >
                          {uploading && (
                            <span className="spinner-grow spinner-grow-sm me-2"></span>
                          )}

                          {uploading ? "Uploading..." : "Send"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </>
      )}
      {/*.msgs-wrapper*/}
    </>
  );
}

export default MessagesTab;
