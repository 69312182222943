import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function ServicesSkeleton() {
  return (
    <>
      <Skeleton count={3} height={55} style={{ marginBottom: "20px" }} />
    </>
  );
}

export default ServicesSkeleton;
