import React, { useContext } from "react";
import useService from "../../../hooks/useService";
import { convertToHTML } from "../../../utils/helpers";
import { ServiceVideo } from "./ServiceVideo";
import { AuthContext } from "../../../context/Auth";

function SSDMainSec() {
  const data = useService();
  const { userInfo } = useContext(AuthContext);

  return (
    <>
      <div className="row g-xl-5 align-items-center">
        <div className="col-lg-6">
          <div className="left-content-wrapper position-relative">
            <ServiceVideo />
          </div>
          {/*.left-content-wrapper*/}
        </div>
        {/*.col-grid*/}
        <div className="col-lg-6">
          <div className="content-wrapper my-3">
            <h2 className="fs-3 fw-semibold mb-3">
              {data?.attributes?.mainHeading}
            </h2>
            {/* <p className="mb-3">{data?.attributes?.description}</p> */}

            <div
              className="mb-3"
              dangerouslySetInnerHTML={convertToHTML(
                data?.attributes?.description
              )}
            />

            {(userInfo?.userType === "admin" ||
              userInfo?.userType === "client" ||
              userInfo?.userType === "clientTm" ||
              userInfo?.userType === "viewer") && (
              <a
                href="#place-order"
                className="btn oms-btn position-relative mt-lg-4 mt-2 overflow-hidden"
              >
                Order Now
              </a>
            )}
          </div>
          {/*.content-wrapper*/}
        </div>
        {/*.col-grid*/}
      </div>
    </>
  );
}

export default SSDMainSec;
