import React from "react";
import { Link } from "react-router-dom";
import useServiceDetail from "../../hooks/useServiceDetail";

function Breadcrumb() {
  const data = useServiceDetail();

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-header py-2 mb-lg-2">
            <div className="d-inline-block align-items-center">
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item d-flex align-items-center small">
                  <Link to="/dashboard" className="d-flex align-items-center">
                    <span className="icon d-inline-flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={14}
                        height={14}
                        fill="currentColor"
                        className="bi bi-house-door"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146ZM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5Z" />
                      </svg>
                    </span>
                  </Link>
                </li>
                <li
                  className="breadcrumb-item d-flex align-items-center small"
                  aria-current="page"
                >
                  <Link to="/services" className="d-flex align-items-center">
                    Services
                  </Link>
                </li>
                <li
                  className="breadcrumb-item d-flex align-items-center small active"
                  aria-current="page"
                >
                  {data?.attributes?.name}
                </li>
              </ol>
            </div>
          </div>
          {/*.page-header*/}
        </div>
        {/*.col-grid*/}
      </div>
    </>
  );
}

export default Breadcrumb;
