import { useEffect, useState } from "react";
import client from "../config/apolloClient";
import { CAREERS_QUERY } from "../graphql/queries/CareersQuery";

function useCareers() {
  const [data, setData] = useState({});

  const fetchData = async () => {
    try {
      const { data } = await client.query({ query: CAREERS_QUERY });
      setData(data?.careers?.data);
    } catch (error) {}
  };
  useEffect(() => {
    fetchData();
  }, []);
  return data;
}

export default useCareers;
