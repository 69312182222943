import { marked } from "marked";
import { BACKEND_URL } from "../config/endPoints";

export function validateEmail(email) {
  const re = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/;
  return re.test(String(email).toLowerCase());
}

export function validatePassword(password) {
  return password.length >= 8;
}

export function convertToHTML(markdownText) {
  if (markdownText) {
    const htmlContent = marked(markdownText);

    return { __html: htmlContent };
  }
  return { __html: "" };
}

export function calculateDeliveryDate(duration, expDelivery) {
  const currentDate = new Date();
  let deliveryDate = new Date(currentDate);

  if (duration) {
    const matches = duration.match(/(\d+)\s+(\w+)/);

    if (matches) {
      const value = parseInt(matches[1]);
      const unit = matches[2].toLowerCase();

      switch (unit) {
        case "day":
        case "days":
          deliveryDate.setDate(
            currentDate.getDate() + value / (expDelivery ? 2 : 1)
          );
          break;
        case "week":
        case "weeks":
          deliveryDate.setDate(
            currentDate.getDate() + (value * 7) / (expDelivery ? 2 : 1)
          );
          break;
        case "month":
        case "months":
          deliveryDate.setMonth(
            currentDate.getMonth() + value / (expDelivery ? 2 : 1)
          );
          break;
        default:
          console.error("Unsupported unit:", unit);
          break;
      }
    } else {
      console.error("Invalid duration format:", duration);
    }
  } else {
    console.error("Duration is undefined");
  }

  return deliveryDate;
}

export function standardDelivery(duration) {
  const currentDate = new Date();
  let deliveryDate = new Date(currentDate);

  if (duration) {
    const matches = duration.match(/(\d+)\s+(\w+)/);

    if (matches) {
      const value = parseInt(matches[1]);
      const unit = matches[2].toLowerCase();

      switch (unit) {
        case "day":
        case "days":
          deliveryDate.setDate(currentDate.getDate() + value);
          break;
        case "week":
        case "weeks":
          deliveryDate.setDate(currentDate.getDate() + value * 7);
          break;
        case "month":
        case "months":
          deliveryDate.setMonth(currentDate.getMonth() + value);
          break;
        default:
          break;
      }
    } else {
      console.error("Invalid duration format:", duration);
    }
  } else {
    console.error("Duration is undefined");
  }

  return deliveryDate;
}

export function expressDeliveryOpt(duration) {
  const currentDate = new Date();
  let deliveryDate = new Date(currentDate);

  if (duration) {
    const matches = duration.match(/(\d+)\s+(\w+)/);

    if (matches) {
      const value = parseInt(matches[1]);
      const unit = matches[2].toLowerCase();

      switch (unit) {
        case "day":
        case "days":
          deliveryDate.setDate(currentDate.getDate() + value / 2);
          break;
        case "week":
        case "weeks":
          deliveryDate.setDate(currentDate.getDate() + (value * 7) / 2);
          break;
        case "month":
        case "months":
          deliveryDate.setMonth(currentDate.getMonth() + value / 2);
          break;
        default:
          console.error("Unsupported unit:", unit);
          break;
      }
    } else {
      console.error("Invalid duration format:", duration);
    }
  } else {
    console.error("Duration is undefined");
  }

  return deliveryDate;
}

export const formatDate = (timestamp) => {
  var date = new Date(timestamp);
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return (
    months[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear()
  );
};

export const formatDateWithYear = (timestamp) => {
  var date = new Date(timestamp);
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    months[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear()
  );
};

export function formatDateString(dateString) {
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  const formattedDate = new Date(dateString).toLocaleDateString(
    undefined,
    options
  );
  return formattedDate.replace(/\//g, "/");
}

export const formatDateTime = (dateTimeString) => {
  if (!dateTimeString) {
    return "";
  }

  const options = {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const dateTime = new Date(dateTimeString);
  const formattedDateTime = new Intl.DateTimeFormat("en-US", options).format(
    dateTime
  );

  return formattedDateTime;
};

export function getStatusColor(status) {
  switch (status) {
    case "Incomplete":
    case "Cancelled":
    case "Unverified":
      return "bg-danger";
    case "Verifying Payment":
    case "Verifying":
      return "verifying-payment";
    case "In Progress":
      return "blue-col";
    case "Delivered":
      return "pink-background";
    case "Completed":
    case "Credited":
      return "green-col";
    case "Revision":
      return "bg-info";
    default:
      return "verifying-payment";
  }
}

export function getSRStatusColor(status) {
  switch (status) {
    case "Incomplete":
    case "Cancelled":
      return "bg-danger";
    case "Verifying Payment":
      return "verifying-payment";
    case "In Progress":
      return "bg-primary";
    case "Delivered":
      return "pink-background";
    case "Revision":
      return "bg-info";
    case "Completed":
      return "bg-success";
    default:
      return "bg-danger";
  }
}

export function getInvStatusColor(status) {
  switch (status) {
    case "unpaid":
    case "cancelled":
      return "bg-danger";
    case "Verifying Payment":
      return "verifying-payment";

    default:
      return "bg-success";
  }
}

export function getInvType(status) {
  switch (status) {
    case "unpaid":
      return "Overdue";
    case "cancelled":
      return "Cancelled";
    case "Verifying Payment":
      return "Verifying Payment";
    default:
      return "Paid";
  }
}

export const orderDuration = (duration, expDelivery) => {
  if (!duration) return null;

  const match = duration.match(/(\d+)\s*(\w+)/);
  if (!match) return null;

  const numericValue = parseInt(match[1]);
  let unit = match[2].toLowerCase();

  unit = unit.charAt(0).toUpperCase() + unit.slice(1);

  const modifiedNumericValue = expDelivery ? numericValue / 2 : numericValue;

  const modifiedDuration = `${modifiedNumericValue} ${unit}`;

  return modifiedDuration;
};

export function truncateFileName(fileName, maxLength) {
  if (fileName?.length <= maxLength) {
    return fileName;
  }

  const extensionIndex = fileName?.lastIndexOf(".");
  const nameWithoutExtension = fileName?.substring(0, extensionIndex);
  const truncatedName = nameWithoutExtension?.substring(0, maxLength - 3);
  const extension = fileName?.substring(extensionIndex);

  return truncatedName + ".." + extension;
}

export function formatFileSize(sizeInBytes) {
  const kilobyte = 1024;
  const megabyte = kilobyte * 1024;

  if (sizeInBytes < kilobyte) {
    return sizeInBytes + " B";
  } else if (sizeInBytes < megabyte) {
    return Math.floor(sizeInBytes / kilobyte) + " KB";
  } else {
    return (sizeInBytes / megabyte).toFixed(2) + " MB";
  }
}

export function getFileIcon(url) {
  const lastDotIndex = url.lastIndexOf(".");
  if (lastDotIndex !== -1 && lastDotIndex < url.length - 1) {
    const fileExtension = url.slice(lastDotIndex + 1).toLowerCase();
    switch (fileExtension) {
      case "jpg":
        return "bi bi-filetype-jpg";
      case "jpeg":
      case "webp":
      case "heif":
        return "bi bi-file-image";
      case "png":
        return "bi bi-filetype-png";
      case "heic":
        return "bi bi-filetype-heic";
      case "gif":
        return "bi bi-filetype-gif";
      case "tiff":
        return "bi bi-filetype-tiff";
      case "bmp":
        return "bi bi-filetype-bmp";
      case "svg":
        return "bi bi-filetype-svg";
      case "pdf":
        return "bi bi-filetype-pdf";
      case "docx":
        return "bi bi-filetype-docx";
      case "doc":
        return "bi bi-filetype-doc";
      case "pptx":
        return "bi bi-filetype-pptx";
      case "ppt":
        return "bi bi-filetype-ppt";
      case "xlsx":
        return "bi bi-filetype-xlsx";
      case "xls":
        return "bi bi-filetype-xls";
      case "txt":
        return "bi bi-filetype-txt";
      case "csv":
        return "bi bi-filetype-csv";
      case "html":
        return "bi bi-filetype-html";
      case "css":
        return "bi bi-filetype-css";
      case "scss":
        return "bi bi-filetype-scss";
      case "js":
        return "bi bi-filetype-js";
      case "jsx":
        return "bi bi-filetype-jsx";
      case "json":
        return "bi bi-filetype-json";
      case "xml":
        return "bi bi-filetype-xml";
      case "zip":
        return "bi bi-file-zip";
      case "mp3":
        return "bi bi-filetype-mp3";
      case "wav":
        return "bi bi-filetype-wav";
      case "aac":
        return "bi bi-filetype-aac";
      case "mp4":
        return "bi bi-filetype-mp4";
      case "mov":
        return "bi bi-filetype-mov";
      default:
        return "bi bi-file-check";
    }
  } else {
    return "bi bi-file-check";
  }
}

export const constructImageUrl = (imageUrl) => {
  if (!imageUrl) {
    return null;
  }

  if (imageUrl.startsWith("src")) {
    return `${BACKEND_URL}/${imageUrl}`;
  } else if (imageUrl.startsWith("https://res.cloudinary.com")) {
    return imageUrl;
  } else {
    return null;
  }
};

export const calculateDaysLate = (deliveryDate) => {
  const currentDate = new Date();
  const deliveryDateObj = new Date(deliveryDate);
  const differenceInDays = Math.floor(
    (currentDate - deliveryDateObj) / (1000 * 60 * 60 * 24)
  );
  return differenceInDays;
};

export const convertToNumber = (numberString) => {
  const integerValue = parseInt(numberString, 10);
  return integerValue;
};

export function truncateString(inputString, maxWords) {
  const words = inputString.split(" ");

  if (words.length > maxWords) {
    const truncatedString = words.slice(0, maxWords).join(" ");

    return truncatedString + "...";
  } else {
    return inputString;
  }
}

export function formatCurrency(amount) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
}
