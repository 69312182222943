import React, { useContext, useEffect } from "react";
import { SearchCtx } from "../../../context/SearchContext";
import {
  constructImageUrl,
  formatCurrency,
  formatDateString,
  formatFileSize,
  getFileIcon,
  getStatusColor,
  truncateFileName,
} from "../../../utils/helpers";
import { BACKEND_URL } from "../../../config/endPoints";
import { UtilsCtx } from "../../../context/UtilsContext";
import { CreditCtx } from "../../../context/Credit";
import setAuthToken from "../../../utils/setAuthToken";
import { Link } from "react-router-dom";
import { InvoiceCtx } from "../../../context/Invoice";

function AllCTable() {
  const { credits, getCredits } = useContext(CreditCtx);
  const { searchOrder } = useContext(SearchCtx);
  const { handleDownloadAttachment } = useContext(UtilsCtx);
  const { setCriNumber } = useContext(InvoiceCtx);

  const token = localStorage.getItem("token");

  const filteredCredits = credits?.filter(
    (credit) =>
      credit?.user?.firstName
        ?.toLowerCase()
        .includes(searchOrder.toLowerCase()) ||
      credit?.user?.lastName
        ?.toLowerCase()
        .includes(searchOrder.toLowerCase()) ||
      credit?.user?.email?.toLowerCase().includes(searchOrder.toLowerCase())
  );

  const sInvoiceIdHandler = (iid) => {
    setCriNumber(iid);
    localStorage.setItem("sCriId", iid);
  };

  useEffect(() => {
    if (token) {
      setAuthToken(token);
      getCredits(token);
    }
  }, []);

  return (
    <>
      <div className="card-body pt-4">
        <div className="table-responsive">
          <table className="table table-hover emp-attend-list">
            <thead>
              <tr>
                <th scope="col">Image</th>
                <th scope="col">Client Email</th>
                <th scope="col">Date</th>
                <th scope="col">Credit</th>
                <th scope="col">Sum Incl. Free Credit</th>
                <th scope="col">Status</th>
                <th scope="col">Unverification Reason</th>
                <th scope="col">Payment Method</th>
                <th scope="col">Payment Proof</th>
                <th scope="col">Invoice</th>
              </tr>
            </thead>
            <tbody>
              {filteredCredits?.map((credit, i) => (
                <tr key={i}>
                  <td>
                    {credit?.user?.imageUrl ? (
                      <img
                        className="table-img"
                        src={constructImageUrl(credit?.user?.imageUrl)}
                      />
                    ) : (
                      <div className="user-avator-order secondary-bg rounded-circle d-flex align-items-center justify-content-center text-uppercase fw-semibold text-white me-2">
                        <span className="name-txt">
                          {`${
                            credit?.user?.firstName
                              ? credit?.user?.firstName?.charAt(0).toUpperCase()
                              : ""
                          }${
                            credit?.user?.lastName
                              ? credit?.user?.lastName.charAt(0).toUpperCase()
                              : ""
                          }`}
                        </span>
                      </div>
                    )}
                  </td>
                  <td>{credit?.user?.email}</td>
                  <td>{formatDateString(credit?.createdAt)}</td>
                  <td>{formatCurrency(credit?.credit)}</td>
                  <td>
                    {credit?.verifyingAmount
                      ? `${formatCurrency(credit?.verifyingAmount)}`
                      : "–"}
                  </td>

                  <td>
                    <span
                      className={`d-inline-block badge ${getStatusColor(
                        credit?.creditStatus
                      )}`}
                    >
                      {credit?.creditStatus}
                    </span>
                  </td>
                  <td>
                    {credit?.rejectionReason ? credit?.rejectionReason : "–"}
                  </td>

                  <td>{credit?.paymentMethod}</td>
                  {credit?.paymentProof?.attachment[0]?.filePath ? (
                    <td>
                      <div className="attachment-wrapper pt-2">
                        <div className="attach-thumb-wrapper d-flex flex-wrap align-items-center gap-3">
                          <div className="attach-thumb-item bg-light rounded-1 overflow-hidden">
                            <figure className="mb-0 bg-light d-flex align-items-center justify-content-center">
                              <i
                                className={getFileIcon(
                                  `${BACKEND_URL}/${credit?.paymentProof?.attachment[0]?.filePath}`
                                )}
                              />
                            </figure>
                            <figcaption className="d-flex justify-content-between align-items-center px-3">
                              <span className="small">
                                {truncateFileName(
                                  credit?.paymentProof?.attachment[0]?.filename,
                                  10
                                )}{" "}
                                (
                                {formatFileSize(
                                  credit?.paymentProof?.attachment[0]?.filesize
                                )}
                                )
                              </span>
                              <span className="icons-wrapper">
                                <span
                                  className="icon file-download secondary-text mx-2 opacity-75"
                                  onClick={() =>
                                    handleDownloadAttachment(
                                      credit?.paymentProof?.attachment[0]
                                        ?.filename,
                                      credit?.paymentProof?.attachment[0]
                                        ?.filePath
                                    )
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={16}
                                    height={16}
                                    fill="currentColor"
                                    className="bi bi-download"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                  </svg>
                                </span>
                              </span>
                            </figcaption>
                          </div>

                          {/*.attach-thumb-item rounded-1 overflow-hidden*/}
                        </div>
                        {/*.attach-thumb-wrapper*/}
                      </div>
                    </td>
                  ) : (
                    <td>–</td>
                  )}
                  <td>
                    <Link
                      to="/credit-invoice"
                      className="preview-action small text-decoration-none fw-semibold d-flex align-items-center"
                      onClick={() => sInvoiceIdHandler(credit?.invoiceNumber)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-eye me-1"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                      </svg>
                      View
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/*.edit-user-modal*/}
        </div>
        {/*.table-responsive*/}
      </div>
    </>
  );
}

export default AllCTable;
