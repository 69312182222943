import React, { useContext } from "react";
import Footer from "../../components/layout/Footer";
import ClientHeader from "../../components/layout/client/ClientHeader";
import ServicesHeading from "../../components/client/services/ServicesHeading";
import ServicesDetails from "../../components/client/services/ServicesDetails";
import { LoadingContext } from "../../context/LoadingLogo";
import useServices from "../../hooks/useServices";
import ServicesSkeleton from "../../components/client/services/ServicesSkeleton";
import { Helmet } from "react-helmet";

function Services() {
  const { logoLoading } = useContext(LoadingContext);
  const data = useServices();

  return (
    <>
      <Helmet>
        <title>Services – Order Now – AAMAX</title>
      </Helmet>
      {/*=============== Start AAMAX OMS  ===============*/}

      <ClientHeader>
        {/*.header*/}
        <main className="page-content d-flex flex-column justify-content-between h-100">
          <div className="container-fluid p-3 p-md-4">
            {logoLoading ? (
              <>
                <div className="row">
                  <div className="col-12">
                    <div className="page-card rounded-3 position-relative p-3 p-md-4">
                      <div className="card-body">
                        <div
                          className="accordion services-accodion row"
                          id="accordionServices"
                        >
                          <div className="col-lg-6">
                            <ServicesSkeleton />
                          </div>
                          <div className="col-lg-6">
                            <ServicesSkeleton />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <ServicesHeading />
                {/*.row*/}
                <ServicesDetails data={data} />
              </>
            )}
            {/*.row*/}
          </div>
          {/*.container-fluid*/}
          <Footer />
        </main>
      </ClientHeader>
      {/*.page-content"*/}
      {/*=============== End AAMAX OMS  ===============*/}
    </>
  );
}

export default Services;
