import axios from "axios";
import React, { createContext, useState } from "react";
import { CONTACT_API_URL } from "../config/endPoints";
import { message } from "antd";

const ContactCtx = createContext();

const ContactContext = ({ children }) => {
  const [data, setData] = useState([]);

  const fetchData = async (token) => {
    try {
      if (token) {
        const res = await axios.get(CONTACT_API_URL);
        setData(res?.data?.contacts);
      }
    } catch (err) {
      message.error(err?.response?.data?.error);
    }
  };

  return (
    <ContactCtx.Provider
      value={{
        data,
        fetchData,
      }}
    >
      {children}
    </ContactCtx.Provider>
  );
};

export { ContactContext, ContactCtx };
