import React, { useContext, useEffect } from "react";
import Footer from "../../components/layout/Footer";
import ClientHeader from "../../components/layout/client/ClientHeader";
import OrderDButtons from "../../components/client/orderDetails/OrderDButtons";
import OrderDetailOverview from "../../components/client/orderDetails/OrderDetailOverview";
import { Order } from "../../context/Order";
import { useNavigate } from "react-router-dom";
import useOrderDetails from "../../hooks/useOrderDetails";
import { LoadingContext } from "../../context/LoadingLogo";
import OrderDetailsSkeleton from "../../components/client/orderDetails/OrderDetailsSkeleton";
import { Helmet } from "react-helmet";

function OrderDetails() {
  const { setSOrderId, getOrderDetails, orderDetails } = useContext(Order);
  const data = useOrderDetails();

  const { logoLoading } = useContext(LoadingContext);

  const navigate = useNavigate();

  if (orderDetails?.status === "Incomplete") {
    navigate("/submit-requirements");
  }

  if (orderDetails?.status === "Verifying Payment") {
    navigate("/verifying-payment");
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    const id = searchParams.get("id");

    id && setSOrderId(id);
    id && localStorage.setItem("sOrderId", id);
  }, []);

  useEffect(() => {
    getOrderDetails();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {`${orderDetails?.service} (${orderDetails?.package}) – ${orderDetails?.orderNumber}`}{" "}
          – AAMAX
        </title>
      </Helmet>
      {/*=============== Start AAMAX OMS  ===============*/}

      <ClientHeader>
        {/*.header*/}
        <main className="page-content d-flex flex-column justify-content-between h-100">
          <div className="container-fluid p-3 p-md-4">
            {/* <ODMainHeading /> */}
            {/*.row*/}
            {logoLoading ? (
              <OrderDetailsSkeleton />
            ) : (
              <div className="row">
                <OrderDButtons />
                {/*.col-grid*/}
                <OrderDetailOverview data={data} />
                {/*.col-grid*/}
              </div>
            )}

            {/*.row*/}
          </div>
          {/*.container-fluid*/}
          <Footer />
        </main>
      </ClientHeader>
      {/*.page-content"*/}
      {/*=============== End AAMAX OMS  ===============*/}
    </>
  );
}

export default OrderDetails;
