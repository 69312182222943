import React from "react";
import Footer from "../../components/layout/Footer";
import AdminHeader from "../../components/layout/admin/AdminHeader";
import VCSearch from "../../components/admin/verifyingCredit/VCSearch";
import VCTable from "../../components/admin/verifyingCredit/VCTable";
import { Helmet } from "react-helmet";

function VerifyingCredits() {
  return (
    <>
      <Helmet>
        <title>Verifying Credits – AAMAX</title>
      </Helmet>
      {/*=============== Start AAMAX OMS  ===============*/}
      <AdminHeader>
        {/*.header*/}
        <main className="page-content d-flex flex-column justify-content-between h-100">
          <div className="container-fluid p-3 p-md-4">
            {/*.row*/}
            <div className="row">
              <div className="col-12">
                <div className="page-card rounded-3 position-relative p-3 p-md-4">
                  <VCSearch />
                  {/*.card-header*/}
                  <VCTable />
                  {/*.card-body*/}
                </div>
                {/*.page-card*/}
              </div>
              {/*.col-grid*/}
            </div>
            {/*.row*/}
          </div>
          {/*.container-fluid*/}
          <Footer />
        </main>
      </AdminHeader>
      {/*.page-content"*/}
      {/*=============== End AAMAX OMS  ===============*/}
    </>
  );
}

export default VerifyingCredits;
