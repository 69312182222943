import React from "react";
import useCareers from "../../../hooks/useCareers";

function CareersMainHeading() {
  const data = useCareers();

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-header py-2 mb-lg-2">
            <h4 className="my-1 fw-bold">{data?.attributes?.mainHeading}</h4>
          </div>
        </div>
        {/*.col-grid*/}
      </div>
    </>
  );
}

export default CareersMainHeading;
