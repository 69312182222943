import React from "react";
import Footer from "../../components/layout/Footer";
import ClientHeader from "../../components/layout/client/ClientHeader";
import ReviewsMainHeading from "../../components/client/reviews/ReviewsMainHeading";
import ReviewsOverview from "../../components/client/reviews/ReviewsOverview";
import ReviewsDetails from "../../components/client/reviews/ReviewsDetails";
import ReviewsPagination from "../../components/client/reviews/ReviewsPagination";

function Reviews() {
  return (
    <>
      {/*=============== Start AAMAX OMS  ===============*/}
      <ClientHeader>
      {/*.header*/}
      <main className="page-content d-flex flex-column justify-content-between h-100">
        <div className="container-fluid p-3 p-md-4">
          <ReviewsMainHeading />
          {/*.row*/}
          <div className="row">
            <div className="col-12">
              <div className="page-card rounded-3 position-relative p-3 p-md-4">
                <ReviewsOverview />
                <ReviewsDetails />
                {/*.card-body*/}
                <ReviewsPagination />
                {/*.card-footer*/}
              </div>
              {/*.page-card*/}
            </div>
            {/*.col-grid*/}
          </div>
          {/*.row*/}
        </div>
        {/*.container-fluid*/}
        <Footer />
      </main>
      </ClientHeader>
      {/*.page-content"*/}
      {/*=============== End AAMAX OMS  ===============*/}
    </>
  );
}

export default Reviews;
