import React, { useEffect } from "react";
import Footer from "../../components/layout/Footer";
import ClientHeader from "../../components/layout/client/ClientHeader";
import ContactInfo from "../../components/client/contactSupport/ContactInfo";
import SubmitInquiry from "../../components/client/contactSupport/SubmitInquiry";
import { Helmet } from "react-helmet";

function ContactSupport() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Contact Us – AAMAX</title>
      </Helmet>
      {/*=============== Start AAMAX OMS  ===============*/}
      <ClientHeader>
        {/*.header*/}
        <main className="page-content d-flex flex-column justify-content-between h-100">
          <div className="container-fluid p-3 p-md-4">
            {/* <CSMainHeading /> */}
            {/*.row*/}
            <div className="row">
              <div className="col-12">
                <div className="page-card rounded-3 position-relative p-3 p-md-4">
                  <div className="card-body">
                    <div className="row justify-content-center">
                      <ContactInfo />
                      {/*.col-grid*/}
                      <SubmitInquiry />
                      {/*.col-grid*/}
                    </div>
                  </div>
                  {/*.card-body*/}
                </div>
                {/*.page-card*/}
              </div>
              {/*.col-grid*/}
            </div>
            {/*.row*/}
          </div>
          {/*.container-fluid*/}
          <Footer />
        </main>
      </ClientHeader>
      {/*.page-content"*/}
      {/*=============== End AAMAX OMS  ===============*/}
    </>
  );
}

export default ContactSupport;
