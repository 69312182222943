import React, { useEffect, useState } from "react";
import useCareers from "../../../hooks/useCareers";
import { Tooltip, message } from "antd";
import axios from "axios";
import { CAREER_API_URL, UPLOAD_API_URL } from "../../../config/endPoints";

function CareersOpenPositions() {
  const data = useCareers();

  const MAX_ATTACHMENTS = 1;
  const MAX_FILE_SIZE_MB = 50;
  const chunkSize = 500 * 1024;

  const [jobTitle, setJobTitle] = useState("");
  const [requirementsFiles, setRequirementsFiles] = useState([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(null);
  const [lastUploadedFileIndex, setLastUploadedFileIndex] = useState(null);
  const [currentChunkIndex, setCurrentChunkIndex] = useState(null);
  const [uploadedAttachments, setUploadedAttachments] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [fData, setFData] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const formData = {
    name: fData.name,
    email: fData.email,
    phone: fData.phone,
    jobTitle: jobTitle,
    cv: uploadedAttachments,
  };

  const jobTitleHandler = (jt) => {
    setJobTitle(jt);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const resetSelection = () => {
    setCurrentFileIndex(null);
    setLastUploadedFileIndex(null);
    setCurrentChunkIndex(null);
    setUploadedAttachments([]);
    setRequirementsFiles([]);
  };

  const resetForm = () => {
    setFData({
      name: "",
      email: "",
      phone: "",
    });
  };

  const handleCVChange = (e) => {
    const files = e.target.files;
    if (files.length > MAX_ATTACHMENTS) {
      message.error(`You can only attach up to ${MAX_ATTACHMENTS} files.`);
      e.target.value = null;
      return;
    }

    for (let i = 0; i < files.length; i++) {
      if (files[i].size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        message.error(`File size should not exceed ${MAX_FILE_SIZE_MB} MB.`);
        e.target.value = null;
        return;
      }
    }

    setRequirementsFiles(files);
  };

  function readNUploadCurrentChunk() {
    const reader = new FileReader();
    const file = requirementsFiles[currentFileIndex];
    if (!file) {
      return;
    }
    const from = currentChunkIndex * chunkSize;
    const to = from + chunkSize;
    const blob = file.slice(from, to);
    reader.onload = (e) => uploadAChunk(e);
    reader.readAsDataURL(blob);
  }

  function uploadAChunk(readerEvent) {
    const file = requirementsFiles[currentFileIndex];
    const data = readerEvent.target.result;
    const params = new URLSearchParams();
    params.set("name", file.name);
    params.set("size", file.size);
    params.set("currentChunkIndex", currentChunkIndex);
    params.set("totalChunks", Math.ceil(file.size / chunkSize));
    params.set("fileType", "cv");
    const headers = { "Content-Type": "application/octet-stream" };
    const url = `${UPLOAD_API_URL}?` + params.toString();
    axios.post(url, data, { headers }).then((response) => {
      const file = requirementsFiles[currentFileIndex];
      const filesize = requirementsFiles[currentFileIndex].size;
      const chunks = Math.ceil(filesize / chunkSize) - 1;
      const isLastChunk = currentChunkIndex === chunks;
      if (isLastChunk) {
        file.finalFilename = response.data.finalFilename;

        setLastUploadedFileIndex(currentFileIndex);
        setCurrentChunkIndex(null);
        setUploadedAttachments((prevAttachments) => [
          ...prevAttachments,
          {
            filename: file.name,
            filePath: response.data,
            filesize: filesize,
          },
        ]);
      } else {
        setCurrentChunkIndex(currentChunkIndex + 1);
      }
    });
  }

  useEffect(() => {
    if (lastUploadedFileIndex === null) {
      return;
    }
    const isLastFile = lastUploadedFileIndex === requirementsFiles.length - 1;
    const nextFileIndex = isLastFile ? null : currentFileIndex + 1;
    setCurrentFileIndex(nextFileIndex);
  }, [lastUploadedFileIndex]);

  useEffect(() => {
    if (requirementsFiles.length > 0) {
      if (currentFileIndex === null) {
        setCurrentFileIndex(
          lastUploadedFileIndex === null ? 0 : lastUploadedFileIndex + 1
        );
      }
    }
  }, [requirementsFiles.length]);

  useEffect(() => {
    if (currentFileIndex !== null) {
      setCurrentChunkIndex(0);
    }
  }, [currentFileIndex]);

  useEffect(() => {
    if (currentChunkIndex !== null) {
      readNUploadCurrentChunk();
    }
  }, [currentChunkIndex]);

  useEffect(() => {
    if (requirementsFiles?.length > 0 && currentFileIndex !== null) {
      setUploading(true);
    } else {
      setUploading(false);
    }
  }, [requirementsFiles, currentFileIndex]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!fData.name.trim() || !fData.email.trim() || !fData.phone.trim()) {
      message.error("Name, email, and phone are required.");
      return;
    }

    try {
      const res = await axios.post(CAREER_API_URL, formData);
      message.success(res?.data?.message);
      setFData({
        name: "",
        email: "",
        phone: "",
      });
      resetSelection();
      setTimeout(() => {
        const cancelButton = document.querySelector(
          "#applyJobModal .btn-light"
        );
        if (cancelButton) {
          cancelButton.click();
        }
      }, 500);
    } catch (err) {
      message.error(err?.response?.data?.error);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-card rounded-3 position-relative p-3 p-md-4">
            <div className="card-body">
              <div className="row justify-content-center mb-3">
                {data?.attributes?.openPositions?.map((j, i) => (
                  <div className="col-sm-6 mt-3" key={i}>
                    <div className="career-wrapper card border-0 p-3">
                      <div className="card-body py-3 row align-items-end">
                        <div className="col-12 text-lg-start text-center">
                          <h5 className="card-title fw-semibold mb-1">
                            {j?.jobTitle}
                          </h5>
                        </div>
                        {/*.col-grid*/}
                        <div className="col-lg-8 text-lg-start text-center my-2">
                          <ul className="list-unstyled d-flex flex-wrap gap-2 justify-content-center justify-content-lg-start mb-0">
                            <li className="text-dark">
                              <small className="d-flex align-items-center">
                                <span className="icon text-muted d-inline-flex me-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={14}
                                    height={14}
                                    fill="currentColor"
                                    className="bi bi-clock"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                  </svg>
                                </span>
                                {j?.jobType}
                              </small>
                            </li>
                            <li className="text-dark">
                              <small className="d-flex align-items-center">
                                <span className="icon text-muted d-inline-flex me-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={14}
                                    height={14}
                                    fill="currentColor"
                                    className="bi bi-geo-alt"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                  </svg>
                                </span>
                                {j?.location}
                              </small>
                            </li>
                          </ul>
                        </div>
                        {/*.col-grid*/}
                        <div className="col-lg-4 text-center text-lg-end mt-2 mb-lg-2 mb-0">
                          <a
                            href="#"
                            className="btn fw-semibold border-0 p-0 d-inline-flex align-items-center justify-content-end"
                            data-bs-toggle="modal"
                            data-bs-target="#applyJobModal"
                            onClick={() => jobTitleHandler(j?.jobTitle)}
                          >
                            Apply Now
                            <span className="icon d-inline-flex ms-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={14}
                                height={14}
                                fill="currentColor"
                                className="bi bi-arrow-right"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                        {/*.col-grid*/}
                      </div>
                      {/*.card-body*/}
                    </div>
                    {/*.career-wrapper*/}
                  </div>
                ))}
              </div>
              {/*.row*/}
              {/* Apply Job */}
              <div
                className="modal fade"
                id="applyJobModal"
                tabIndex={-1}
                aria-labelledby="applyJobModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header dark-bg px-4">
                      <h1
                        className="modal-title fs-5 text-white"
                        id="cardPaymentModalLabel"
                      >
                        Apply Job
                      </h1>
                      <button
                        type="button"
                        className="btn-close btn-close-white"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <div className="modal-body px-4">
                      <form className="needs-validation mt-lg-3">
                        <div className="row g-3">
                          <div className="col-sm-6">
                            <label
                              htmlFor="name"
                              className="form-label small fw-semibold"
                            >
                              Full Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              name="name"
                              value={fData?.name}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-sm-6">
                            <label
                              htmlFor="email"
                              className="form-label small fw-semibold"
                            >
                              Email
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              name="email"
                              value={fData?.email}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-sm-6">
                            <label
                              htmlFor="phone"
                              className="form-label small fw-semibold"
                            >
                              Phone
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="phone"
                              name="phone"
                              value={fData?.phone}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-sm-6">
                            <label
                              htmlFor="jobTitle"
                              className="form-label small fw-semibold"
                            >
                              Job Title
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="jobTitle"
                              value={jobTitle}
                              placeholder
                              defaultValue
                              disabled
                            />
                          </div>
                          {requirementsFiles?.length > 0 &&
                          currentFileIndex === null ? (
                            <div className="col-sm-12">
                              <label
                                htmlFor="inputFile01"
                                className="form-label small fw-semibold"
                              >
                                Attached CV
                              </label>

                              <button className="upload-file-input w-100">
                                <Tooltip
                                  title="Reset Selection"
                                  mouseEnterDelay={0.1}
                                >
                                  <img
                                    src="/assets/images/x.svg"
                                    alt="X Icon"
                                    width="30"
                                    height="30"
                                    onClick={resetSelection}
                                  />
                                </Tooltip>
                                {requirementsFiles[0]?.name}
                              </button>
                            </div>
                          ) : (
                            <div className="col-sm-12">
                              <label
                                htmlFor="inputFile01"
                                className="form-label small fw-semibold"
                              >
                                Upload CV
                              </label>

                              <Tooltip
                                title="Max 50MB File"
                                mouseEnterDelay={0.1}
                              >
                                <input
                                  type="file"
                                  name="file"
                                  className="upload-file-input w-100"
                                  id="inputFile01"
                                  multiple={false}
                                  onChange={handleCVChange}
                                />
                              </Tooltip>
                            </div>
                          )}
                        </div>
                        {/*.row*/}
                        <div className="btn-wrapper text-end mb-3 mt-4 mt-lg-5">
                          <a
                            href="#"
                            className="fs-6 btn btn-light me-2 px-4 py-2 text-decoration-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={resetSelection}
                            onClick={resetForm}
                          >
                            Cancel
                          </a>
                          <button
                            type="submit"
                            className="btn primary-btn fw-semibold fs-6 px-4 py-2"
                            onClick={handleSubmit}
                            disabled={uploading}
                          >
                            {uploading && (
                              <span className="spinner-grow spinner-grow-sm me-2"></span>
                            )}

                            {uploading ? "Uploading..." : "Submit"}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {/*.edit-profile-modal*/}
            </div>
            {/*.card-body*/}
          </div>
          {/*.page-card*/}
        </div>
        {/*.col-grid*/}
      </div>
    </>
  );
}

export default CareersOpenPositions;
