import { message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { CONTACT_API_URL } from "../../../config/endPoints";

function SubmitInquiry() {
  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    website: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !data.name.trim() ||
      !data.email.trim() ||
      !data.subject.trim() ||
      !data.message.trim()
    ) {
      message.error("Name, email, subject and message are required.");
      return;
    }

    try {
      setLoading(true);
      const res = await axios.post(CONTACT_API_URL, data);
      message.success(res?.data?.message);
      setData({
        name: "",
        email: "",
        phone: "",
        subject: "",
        website: "",
        message: "",
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      message.error(err?.response?.data?.error);
    }
  };

  return (
    <>
      <div className="col-xl-10">
        <div className="contact-form-wrapper my-3">
          <h3 className="h5 mb-4 fw-semibold">Send Us Your Inquiry</h3>
          <form className="contact-form">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Full Name"
                    id="name"
                    name="name"
                    value={data?.name}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-4">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    id="email"
                    name="email"
                    value={data?.email}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Subject"
                    id="subject"
                    name="subject"
                    value={data?.subject}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Website"
                    id="website"
                    name="website"
                    value={data?.website}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="mb-4">
                  <textarea
                    className="form-control"
                    rows={6}
                    placeholder="Your Message"
                    defaultValue={""}
                    id="message"
                    name="message"
                    value={data?.message}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="btn primary-btn fs-6 fw-semibold rounded-2 px-3 py-2"
              onClick={handleSubmit}
            >
              {loading && (
                <span className="spinner-grow spinner-grow-sm me-2"></span>
              )}
              {!loading ? " Send Message" : " Sending..."}
            </button>
          </form>
        </div>
        {/*.contact-form-wrapper*/}
      </div>
    </>
  );
}

export default SubmitInquiry;
