import React, { useContext, useEffect, useState } from "react";
import AdminSidebar from "./AdminSidebar";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../context/Auth";
import { constructImageUrl } from "../../../utils/helpers";

function AdminHeader({ children }) {
  const token = localStorage.getItem("token");

  const { userInfo, fetchUserInfo, logout } = useContext(AuthContext);

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const handleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  const handleFullScreenToggle = () => {
    const contentDiv = document.documentElement;

    if (!isFullscreen) {
      if (contentDiv.requestFullscreen) {
        contentDiv.requestFullscreen();
      } else if (contentDiv.mozRequestFullScreen) {
        contentDiv.mozRequestFullScreen();
      } else if (contentDiv.webkitRequestFullscreen) {
        contentDiv.webkitRequestFullscreen();
      } else if (contentDiv.msRequestFullscreen) {
        contentDiv.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  useEffect(() => {
    const fullscreenChangeHandler = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", fullscreenChangeHandler);

    return () => {
      document.removeEventListener("fullscreenchange", fullscreenChangeHandler);
    };
  }, []);

  useEffect(() => {
    if (token) {
      fetchUserInfo(token);
    }
  }, []);

  return (
    <div
      className={`aamax-oms bg-light  ${isSidebarOpen ? "sidebar-toggle" : ""}`}
    >
      <AdminSidebar />
      <header className=".header">
        <nav className="dashboard-nav primary-bg position-fixed top-0 border-bottom py-2 px-3 mb-0">
          <div className="nav-inner position-relative d-flex align-items-center">
            <div className="d-flex align-items-center">
              <a
                id="sidebarBtn"
                className="btn-hamburger d-inline-flex p-2"
                onClick={handleSidebar}
              >
                <span className="d-block position-relative" />
              </a>
              {/*.btn-hamburger*/}
              <div className="btn-wrapper d-none d-md-block">
                {userInfo?.userType === "admin" ||
                userInfo?.userType === "manager" ? (
                  <Link
                    to="/dashboard"
                    className="btn white-btn-outline rounded-pill fw-semibold ms-3 client-dashboard-link"
                  >
                    Client Dashboard
                  </Link>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="flex-fill ">
              <div className="nav-icons-wrapper d-flex flex-wrap justify-content-end  align-items-center gap-3 gap-sm-4 ms-auto">
                <span
                  id="fullWidthBtn"
                  className="d-inline-flex icons-wrapper"
                  onClick={handleFullScreenToggle}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={18}
                    height={18}
                    fill="currentColor"
                    className="bi bi-fullscreen"
                    viewBox="0 0 16 16"
                  >
                    <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z" />
                  </svg>
                </span>

                <div className="dropdown profile-dropdown dropdown-end">
                  <a
                    className="d-flex align-items-center text-white gap-1 gap-sm-2 text-decoration-none dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div className="profile d-block rounded-pill overflow-hidden me-sm-1 ">
                      {userInfo?.imageUrl ? (
                        <img
                          src={constructImageUrl(userInfo?.imageUrl)}
                          className="rounded-pill user-img img-fluid"
                          id="pfImg"
                          width={110}
                          height={110}
                          alt
                        />
                      ) : (
                        <span className="user-name-txt">
                          {`${
                            userInfo?.firstName
                              ? userInfo.firstName.charAt(0).toUpperCase()
                              : ""
                          }${
                            userInfo?.lastName
                              ? userInfo.lastName.charAt(0).toUpperCase()
                              : ""
                          }`}
                        </span>
                      )}
                    </div>
                    {/*.profile*/}
                    <div className="profile-bio">
                      <h6 className="fw-semibold mb-1 lh- d-none d-md-block">
                        {userInfo?.firstName}
                      </h6>
                    </div>
                    {/*.profile-bio*/}
                  </a>
                  <ul className="dropdown-menu dropdown-menu-end border-0 mt-2 py-0">
                    <li>
                      <Link
                        className="dropdown-item d-flex align-items-center gap-2 py-2"
                        to="/admin"
                      >
                        <span className="d-inline-flex lh-base">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={18}
                            height={18}
                            fill="currentColor"
                            className="bi bi-person"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
                          </svg>
                        </span>
                        Profile
                      </Link>
                    </li>
                    {userInfo?.userType === "manager" ||
                    userInfo?.userType === "tMember" ? (
                      ""
                    ) : (
                      <>
                        <li>
                          <Link
                            className="dropdown-item d-flex align-items-center"
                            to="/manage-users-admin"
                          >
                            <span className="d-inline-flex lh-base me-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={14}
                                height={14}
                                fill="currentColor"
                                className="bi bi-people"
                                viewBox="0 0 16 16"
                              >
                                <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4" />
                              </svg>
                            </span>
                            Manage Team
                          </Link>
                        </li>
                      </>
                    )}
                    <li>
                      <a
                        className="dropdown-item d-flex align-items-center"
                        onClick={() => {
                          logout();
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <span className="d-inline-flex lh-base me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={14}
                            height={14}
                            fill="currentColor"
                            className="bi bi-box-arrow-in-left"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 3.5a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 1 1 0v2A1.5 1.5 0 0 1 9.5 14h-8A1.5 1.5 0 0 1 0 12.5v-9A1.5 1.5 0 0 1 1.5 2h8A1.5 1.5 0 0 1 11 3.5v2a.5.5 0 0 1-1 0v-2z"
                            />
                            <path
                              fillRule="evenodd"
                              d="M4.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H14.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"
                            />
                          </svg>
                        </span>
                        Sign Out
                      </a>
                    </li>
                  </ul>
                  {/*.dropdown-menu*/}
                </div>
                {/*.dropdown*/}
              </div>
              {/*.nav-icons-wrapper*/}
            </div>
          </div>
        </nav>
        {/*dashboard-nav*/}
      </header>
      {children}
    </div>
  );
}

export default AdminHeader;
