import React, { useContext } from "react";
import { RevisionCtx } from "../../../context/Revision";

function ButtonTabs() {
  const { revisions } = useContext(RevisionCtx);

  return (
    <>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className="nav-link active"
            id="activity-tab"
            data-bs-toggle="tab"
            data-bs-target="#activity-tab-pane"
            type="button"
            role="tab"
            aria-controls="activity-tab-pane"
            aria-selected="true"
          >
            Activity
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="details-tab"
            data-bs-toggle="tab"
            data-bs-target="#details-tab-pane"
            type="button"
            role="tab"
            aria-controls="details-tab-pane"
            aria-selected="false"
          >
            Details
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="requirements-tab"
            data-bs-toggle="tab"
            data-bs-target="#requirements-tab-pane"
            type="button"
            role="tab"
            aria-controls="requirements-tab-pane"
            aria-selected="false"
          >
            Requirements
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="message-tab"
            data-bs-toggle="tab"
            data-bs-target="#message-tab-pane"
            type="button"
            role="tab"
            aria-controls="message-tab-pane"
            aria-selected="false"
          >
            Message
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="delivery-tab"
            data-bs-toggle="tab"
            data-bs-target="#delivery-tab-pane"
            type="button"
            role="tab"
            aria-controls="delivery-tab-pane"
            aria-selected="false"
          >
            Delivery
          </button>
        </li>
        {revisions?.length > 0 && (
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="revision-tab"
              data-bs-toggle="tab"
              data-bs-target="#revision-tab-pane"
              type="button"
              role="tab"
              aria-controls="revision-tab-pane"
              aria-selected="false"
            >
              Revision
            </button>
          </li>
        )}
      </ul>
    </>
  );
}

export default ButtonTabs;
