import { gql } from "@apollo/client";

export const SERVICE_QUERY = gql`
  query Service($url: String!) {
    service(url: $url) {
      data {
        attributes {
          name
          mainHeading
          description

          video {
            data {
              attributes {
                url
              }
            }
          }

          url
          features {
            feature(pagination: { limit: 25 }) {
              name
            }
            packageType
            packageUrl
            packageSpeciality
            price
            duration
            cta
          }

          howServiceWork {
            heading
            description
          }

          serviceBenefits {
            heading
            description
          }

          faqs {
            question
            answer
          }
          videoThumbnail {
            data {
              attributes {
                url
              }
            }
          }
          howSWHeading
          sBHeading
        }
      }
    }
  }
`;
