import React, { useContext } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { ExpandedLink } from "../../../context/SidebarNavContext";
import { AuthContext } from "../../../context/Auth";

function TeamSidebar() {
  const location = useLocation();
  const { expandedItem, setExpandedItem } = useContext(ExpandedLink);
  const { logout } = useContext(AuthContext);
  const activePath = location.pathname;

  const ordersUrlsToCheck = [
    "/active-order",
    "/cancelled-orders",
    "/view-invoices",
  ];

  const ordersNestedPaths = ordersUrlsToCheck.some((url) =>
    activePath.includes(url)
  );

  const handleCollapse = (id) => {
    if (expandedItem === id) {
      setExpandedItem(null);
    } else {
      setExpandedItem(id);
    }
  };

  return (
    <>
      <aside className="position-fixed top-0 start-0">
        <div className="sidebar-wrapper h-100 d-flex flex-column">
          <div className="logo-wrapper">
            <Link to="/" className="navbar-brand d-block">
              <img
                className="img-fluid px-3 logo"
                src="./assets/images/logo.png"
                alt
              />
              <img
                className="img-fluid px-3 logo-icon"
                src="./assets/images/logo-icon.png"
                alt
              />
            </Link>
            {/*.navbar-brand*/}
          </div>
          <div className="sidebar-menu-wrapper py-4 my-2 flex-fill d-flex flex-column justify-content-between">
            <ul
              className="sidebar-listing list-unstyled px-3 mb-0 pb-4"
              id="collapse"
            >
              <li className="nav-item">
                <NavLink
                  to="/dashboard"
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link active d-flex align-items-center rounded mb-1"
                      : "nav-link d-flex align-items-center rounded mb-1"
                  }
                >
                  <span className="icon d-inline-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      fill="currentColor"
                      className="bi bi-house-door"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146ZM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5Z" />
                    </svg>
                  </span>
                  <span className="sidebar-item ms-2">Dashboard</span>
                </NavLink>
                {/*.nav-link*/}
              </li>

              <li className="nav-item">
                <a
                  className={`${
                    expandedItem === "ordersCollapse" || ordersNestedPaths
                      ? "nav-link active d-flex align-items-center rounded mb-1"
                      : "nav-link d-flex align-items-center rounded collapsed mb-1"
                  } `}
                  data-bs-toggle="collapse"
                  href="#ordersCollapse"
                  role="button"
                  aria-expanded={
                    expandedItem === "ordersCollapse" ? "true" : "false"
                  }
                  aria-controls="chartsCollapse"
                  onClick={() => handleCollapse("ordersCollapse")}
                >
                  <span className="icon d-inline-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      fill="currentColor"
                      className="bi bi-cart2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l1.25 5h8.22l1.25-5H3.14zM5 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
                    </svg>
                  </span>
                  <span className="sidebar-item ms-2">Orders</span>
                  <span className="sidebar-arrow d-inline-flex ms-auto">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={14}
                      height={14}
                      fill="currentColor"
                      className="bi bi-chevron-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </span>
                </a>
                <ul
                  className={
                    expandedItem === "ordersCollapse"
                      ? "collapse show list-unstyled ps-0"
                      : "collapse list-unstyled ps-0"
                  }
                  id="ordersCollapse"
                  data-bs-parent="#collapse"
                >
                  <li className="nav-item ps-2">
                    <NavLink
                      to="/active-order"
                      className={({ isActive }) =>
                        isActive
                          ? "nav-link active d-flex align-items-center px-3 py-2"
                          : "nav-link d-flex align-items-center px-3 py-2"
                      }
                    >
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          fill="currentColor"
                          className="bi bi-card-list"
                          viewBox="0 0 16 16"
                        >
                          <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                          <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                        </svg>
                      </span>
                      <span className="sidebar-item">Active</span>
                    </NavLink>
                  </li>
                  <li className="nav-item ps-2">
                    <NavLink
                      to="/completed-order"
                      className={({ isActive }) =>
                        isActive
                          ? "nav-link active d-flex align-items-center px-3 py-2"
                          : "nav-link d-flex align-items-center px-3 py-2"
                      }
                    >
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          fill="currentColor"
                          className="bi bi-check2-square"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5z" />
                          <path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0" />
                        </svg>
                      </span>
                      <span className="sidebar-item">Completed</span>
                    </NavLink>
                  </li>
                  <li className="nav-item ps-2">
                    <NavLink
                      to="/cancelled-orders"
                      className={({ isActive }) =>
                        isActive
                          ? "nav-link active d-flex align-items-center px-3 py-2"
                          : "nav-link d-flex align-items-center px-3 py-2"
                      }
                    >
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          fill="currentColor"
                          className="bi bi-trash"
                          viewBox="0 0 16 16"
                        >
                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                          <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                        </svg>
                      </span>
                      <span className="sidebar-item">Cancelled</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              {/*.nav-item*/}
              <li className="nav-item">
                <NavLink
                  to="/services"
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link active d-flex align-items-center rounded mb-1"
                      : "nav-link d-flex align-items-center rounded mb-1"
                  }
                >
                  <span className="icon d-inline-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      fill="currentColor"
                      className="bi bi-gear"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                      <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
                    </svg>
                  </span>
                  <span className="sidebar-item ms-2">Services</span>
                </NavLink>
                {/*.nav-link*/}
              </li>
              {/*.nav-item*/}
              <li className="nav-item">
                <NavLink
                  to="/account-settings"
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link active d-flex align-items-center rounded mb-1"
                      : "nav-link d-flex align-items-center rounded mb-1"
                  }
                >
                  <span className="icon d-inline-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      fill="currentColor"
                      className="bi bi-person-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                      <path
                        fillRule="evenodd"
                        d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                      />
                    </svg>
                  </span>
                  <span className="sidebar-item ms-2">Profile</span>
                </NavLink>
                {/*.nav-link*/}
              </li>
              {/*.nav-item*/}
            </ul>
            <ul className="sidebar-listing list-unstyled px-3 mb-0">
              <li className="nav-item">
                <a
                  className="nav-link d-flex align-items-center rounded mb-1"
                  onClick={() => {
                    logout();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <span className="icon d-inline-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      fill="currentColor"
                      className="bi bi-box-arrow-in-left"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 3.5a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 1 1 0v2A1.5 1.5 0 0 1 9.5 14h-8A1.5 1.5 0 0 1 0 12.5v-9A1.5 1.5 0 0 1 1.5 2h8A1.5 1.5 0 0 1 11 3.5v2a.5.5 0 0 1-1 0v-2z"
                      />
                      <path
                        fillRule="evenodd"
                        d="M4.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H14.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"
                      />
                    </svg>
                  </span>
                  <span className="sidebar-item ms-2">Sign Out</span>
                </a>
                {/*.nav-link*/}
              </li>
              {/*.nav-item*/}
            </ul>
            {/*.sidebar-listing*/}
          </div>
        </div>
        {/*.sidebar-wrapper*/}
      </aside>
    </>
  );
}

export default TeamSidebar;
