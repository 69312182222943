import React, { useContext, useEffect } from "react";
import Footer from "../../components/layout/Footer";
import ClientHeader from "../../components/layout/client/ClientHeader";
import UIHeading from "../../components/client/unpaidInvoice/UIHeading";
import ClientBillingInfo from "../../components/client/unpaidInvoice/ClientBillingInfo";
import OrderNInvoiceDetail from "../../components/client/unpaidInvoice/OrderNInvoiceDetail";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/Auth";
import { InvoiceCtx } from "../../context/Invoice";
import { Helmet } from "react-helmet";

function UnpaidInvoice() {
  const navigate = useNavigate();

  const { userInfo } = useContext(AuthContext);
  const { unpaidInvNo, getUnpaidInvoice, unpaidInv } = useContext(InvoiceCtx);

  if (userInfo?.userType === "manager" || userInfo?.userType === "tMember") {
    navigate("/dashboard");
  }

  useEffect(() => {
    if (unpaidInvNo) {
      getUnpaidInvoice();
    }
  }, [unpaidInvNo]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>{`Invoice: ${unpaidInv?.invoiceNumber}`} – AAMAX</title>
      </Helmet>
      {/*=============== Start AAMAX OMS  ===============*/}

      <ClientHeader>
        {/*.header*/}
        <main className="page-content d-flex flex-column justify-content-between h-100">
          <div id="unpaid-invoice" className="container-fluid p-3 p-md-4">
            <UIHeading />
            {/*.row*/}
            <div className="row">
              <div className="col-12">
                <div className="page-card rounded-3 position-relative p-3 p-md-4">
                  <ClientBillingInfo />
                  {/*.card-header*/}
                  <OrderNInvoiceDetail />
                  {/*.card-body*/}
                </div>
                {/*.page-card*/}
              </div>
              {/*.col-grid*/}
            </div>
            {/*.row*/}
          </div>
          {/*.container-fluid*/}
          <Footer />
        </main>
      </ClientHeader>
      {/*.page-content"*/}
      {/*=============== End AAMAX OMS  ===============*/}
    </>
  );
}

export default UnpaidInvoice;
