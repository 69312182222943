import React from "react";
import Footer from "../../components/layout/Footer";
import ClientHeader from "../../components/layout/client/ClientHeader";
import AddCreditsMainHeading from "../../components/client/addCredits/AddCreditsMainHeading";
import PaymentMethodsSec from "../../components/client/addCredits/PaymentMethodsSec";
import { Helmet } from "react-helmet";

function AddCredits() {
  return (
    <>
      <Helmet>
        <title>Add Credits – AAMAX</title>
      </Helmet>
      {/*=============== Start AAMAX OMS  ===============*/}
      {/*.aside*/}
      <ClientHeader>
        {/*.header*/}
        <main className="page-content d-flex flex-column justify-content-between h-100">
          <div className="container-fluid p-3 p-md-4">
            <AddCreditsMainHeading />
            {/*.row*/}
            <div className="row">
              <div className="col-12">
                <div className="page-card rounded-3 position-relative p-3 p-md-4">
                  <PaymentMethodsSec />
                  {/* <PaymentModal /> */}

                  {/*.card-body*/}
                  {/* <div class="card-footer p-3 p-md-4 pt-md-0 pt-0 d-flex justify-content-end">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination">
                          <li class="page-item">
                            <a class="page-link" href="#" aria-label="Previous">
                              <span aria-hidden="true"><i class="bi bi-chevron-left"></i></span>
                            </a>
                          </li>
                          <li class="page-item"><a class="page-link active" href="#">1</a></li>
                          <li class="page-item"><a class="page-link" href="#">2</a></li>
                          <li class="page-item"><a class="page-link" href="#">3</a></li>
                          <li class="page-item">
                            <a class="page-link" href="#" aria-label="Next">
                              <span aria-hidden="true"><i class="bi bi-chevron-right"></i></span>
                            </a>
                          </li>
                        </ul>
                    </nav>
                </div> */}
                  {/*.card-footer*/}
                </div>
                {/*.page-card*/}
              </div>
              {/*.col-grid*/}
            </div>
            {/*.row*/}
          </div>
          {/*.container-fluid*/}
          <Footer />
        </main>
      </ClientHeader>
      {/*.page-content"*/}
      {/*=============== End AAMAX OMS  ===============*/}
    </>
  );
}

export default AddCredits;
