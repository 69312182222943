import React from "react";
import AdminHeader from "../../components/layout/admin/AdminHeader";
import Footer from "../../components/layout/Footer";
import AdminHeading from "../../components/admin/admin/AdminHeading";
import AdminProfile from "../../components/admin/admin/AdminProfile";
import { Helmet } from "react-helmet";
function Admin() {
  return (
    <>
      <Helmet>
        <title>Admin Profile – AAMAX</title>
      </Helmet>
      {/*=============== Start AAMAX OMS  ===============*/}

      <AdminHeader>
        {/*.header*/}
        <main className="page-content d-flex flex-column justify-content-between h-100">
          <div className="container-fluid p-3 p-md-4">
            <AdminHeading />
            {/*.row*/}
            <AdminProfile />
            {/*.row*/}
          </div>
          {/*.container-fluid*/}
          <Footer />
        </main>
      </AdminHeader>
      {/*.page-content"*/}
      {/*=============== End AAMAX OMS  ===============*/}
    </>
  );
}

export default Admin;
