import AboutDesc from "../../components/client/about/AboutDesc";
import AboutIcons from "../../components/client/about/AboutIcons";
import WhyChooseUs from "../../components/client/about/WhyChooseUs";
import ReviewsSec from "../../components/client/about/ReviewsSec";
import CtaSec from "../../components/misc/CtaSec";
import Footer from "../../components/layout/Footer";
import ClientHeader from "../../components/layout/client/ClientHeader";
import { useContext, useEffect } from "react";
import { LoadingContext } from "../../context/LoadingLogo";
import ServiceDetailSkeleton from "../../components/client/singleServiceDetails/ServiceDetailSkeleton";
import useAbout from "../../hooks/useAbout";
import { Helmet } from "react-helmet";

function AboutUs() {
  const data = useAbout();

  const { logoLoading } = useContext(LoadingContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>About Us – AAMAX</title>
      </Helmet>
      {/*=============== Start AAMAX OMS  ===============*/}

      <ClientHeader>
        {/*.header*/}
        <main
          className="page-content d-flex flex-column justify-content-between h-100"
          id="price-scroll"
        >
          <div className="container-fluid p-3 p-md-4">
            {/* <Breadcrumb /> */}
            {logoLoading ? (
              <ServiceDetailSkeleton />
            ) : (
              <div className="row">
                <div className="col-12">
                  <div className="page-card rounded-3 position-relative p-3 p-md-4">
                    <div className="card-body">
                      <AboutDesc data={data} />
                      {/*.row*/}
                      <AboutIcons data={data} />
                      <WhyChooseUs data={data} />
                      {/*.work-process*/}
                      {/* <TeamSec data={data} /> */}
                      {/*.team-sec*/}
                      <ReviewsSec data={data} />
                      {/*.testimonial-sec*/}
                      <CtaSec />
                      {/*.cta-sec*/}
                    </div>
                    {/*.card-body*/}
                  </div>
                  {/*.page-card*/}
                </div>
                {/*.col-grid*/}
              </div>
            )}
            {/*.row*/}
          </div>
          {/*.container-fluid*/}
          <Footer />
        </main>
      </ClientHeader>
      {/*.page-content"*/}
      {/*=============== End AAMAX OMS  ===============*/}
    </>
  );
}

export default AboutUs;
