import React, { useContext } from "react";
import { InvoiceCtx } from "../../../context/Invoice";
import { formatCurrency } from "../../../utils/helpers";

function ClientIDetails() {
  const { invoice } = useContext(InvoiceCtx);

  const expDelvAmountCalc = 0.2 * (invoice?.quantity * invoice?.price);

  const expDeliveryAmount = invoice?.expDelivery ? expDelvAmountCalc : 0;

  const tenPercAmount = 0.1 * (invoice?.quantity * invoice?.price);
  const expTenPercAmount = 0.1 * expDeliveryAmount;

  const bankTransferAmount =
    invoice?.paymentMethod === "Bank Transfer"
      ? tenPercAmount + expTenPercAmount
      : 0;

  return (
    <>
      <div className="card-body">
        <div className="row">
          <div className="col-12">
            <div className="table-responsive mb-3">
              <table className="table emp-attend-list mb-0">
                <thead>
                  <tr>
                    <th scope="col">Service</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Unit Price</th>
                    <th scope="col">Net Subtotal</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {invoice?.service} ({invoice?.package})
                    </td>
                    <td>{invoice?.quantity}</td>
                    <td>{formatCurrency(invoice?.price)}</td>
                    <td>
                      {formatCurrency(invoice?.quantity * invoice?.price)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/*.col-grid*/}
        </div>
        {/*.row*/}
        <div className="row">
          <div className="col-12 text-end">
            <div>
              <p className="text-dark">
                Sub Total : {formatCurrency(invoice?.quantity * invoice?.price)}
              </p>
              <p className="text-dark">% VAT : $0.00</p>
              <p className="text-dark">
                Express Delivery :
                {expDeliveryAmount ? formatCurrency(expDeliveryAmount) : "0.00"}
              </p>
              <p className="text-dark">
                Bank Transfer Discount : -{formatCurrency(bankTransferAmount)}
              </p>
            </div>
            <div className="total-payment border-top pt-3">
              <h3 className="h5">
                <b>Total :</b> {formatCurrency(invoice?.totalPaidAmount)}
              </h3>
            </div>
          </div>
          {/*.col-grid*/}
        </div>
        {/*.row*/}
      </div>
    </>
  );
}

export default ClientIDetails;
