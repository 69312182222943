import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { GET_COMPLETED_ORDERS_API_URL } from "../config/endPoints";
import { LoadingContext } from "../context/LoadingLogo";

function useCompOrders() {
  const { setLogoLoading } = useContext(LoadingContext);

  const [cOrders, setCOrders] = useState([]);

  const getCompletedOrders = async () => {
    try {
      setLogoLoading(true);
      const res = await axios.get(GET_COMPLETED_ORDERS_API_URL);
      setCOrders(res?.data?.cOrders);
      setLogoLoading(false);
    } catch (err) {
      console.error(err);
      setLogoLoading(false);
    }
  };

  useEffect(() => {
    getCompletedOrders();
  }, []);

  return cOrders;
}

export default useCompOrders;
