import axios from "axios";
import React, { createContext, useState } from "react";
import { CAREER_API_URL } from "../config/endPoints";
import { message } from "antd";

const CareerCtx = createContext();

const CareerContext = ({ children }) => {
  const [data, setData] = useState([]);

  const fetchData = async (token) => {
    try {
      if (token) {
        const res = await axios.get(CAREER_API_URL);
        setData(res?.data?.careers);
      }
    } catch (err) {
      message.error(err?.response?.data?.error);
    }
  };

  return (
    <CareerCtx.Provider
      value={{
        data,
        fetchData,
      }}
    >
      {children}
    </CareerCtx.Provider>
  );
};

export { CareerContext, CareerCtx };
