import axios from "axios";
import React, { createContext, useState } from "react";
import { ORDER_CART_API_URL, OVERDUE_CART_API_URL } from "../config/endPoints";
import { useNavigate } from "react-router-dom";
import { message } from "antd";

const OrderCartDetail = createContext();

function OrderCartContext({ children }) {
  const navigate = useNavigate();

  const [orderCart, setOrderCart] = useState({
    service: "",
    package: "",
    packageUrl: "",
    price: "",
    duration: "",
    quantity: "",
  });

  const [orderCartData, setOrderCartData] = useState({});
  const [overdueCid, setOverdueCid] = useState(
    localStorage.getItem("overdueCid") || ""
  );
  const [overdueCartData, setOverdueCartData] = useState({});

  const saveCartInfo = async () => {
    if (orderCart.quantity <= 0 || !orderCart.quantity) {
      message.error("Ensure quantity is greater than 0.");
      return;
    }

    if (
      !orderCart.service ||
      !orderCart.package ||
      !orderCart.packageUrl ||
      !orderCart.price ||
      !orderCart.duration
    ) {
      message.error("Please choose the package again");
      return;
    }

    try {
      await axios.post(ORDER_CART_API_URL, orderCart);
      navigate("/place-order-payment");
    } catch (err) {
      message.error(err?.response?.data?.message);
      console.error(err);
    }
  };

  const getOrderDetail = async () => {
    try {
      const res = await axios.get(ORDER_CART_API_URL);
      setOrderCartData(res?.data);
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  const getOverdueCart = async () => {
    try {
      const res = await axios.get(`${OVERDUE_CART_API_URL}/${overdueCid}`);
      setOverdueCartData(res?.data);
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  return (
    <OrderCartDetail.Provider
      value={{
        orderCart,
        setOrderCart,
        saveCartInfo,
        orderCartData,
        getOrderDetail,
        getOverdueCart,
        setOverdueCid,
        overdueCartData,
      }}
    >
      {children}
    </OrderCartDetail.Provider>
  );
}

export { OrderCartContext, OrderCartDetail };
