import React, { useContext, useState } from "react";
import { Order } from "../../../context/Order";
import { constructImageUrl, formatDateTime } from "../../../utils/helpers";
import { AuthContext } from "../../../context/Auth";
import { message } from "antd";
import axios from "axios";
import { FEEDBACK_REPLY_ORDER_API_URL } from "../../../config/endPoints";

function ReviewRating() {
  const { orderDetails, sOrderId, orderId, getOrderDetails } =
    useContext(Order);
  const { userInfo } = useContext(AuthContext);

  const [writeAReply, setWriteAReply] = useState(false);
  const [reply, setReply] = useState("");

  const generateStars = (rating) => {
    const filledStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;
    const emptyStars = 5 - filledStars - (hasHalfStar ? 1 : 0);

    return Array.from({ length: filledStars }, (_, index) => (
      <li key={index} className="small">
        <i className="bi bi-star-fill" />
      </li>
    )).concat(
      hasHalfStar ? (
        <li key="half" className="small">
          <i className="bi bi-star-half" />
        </li>
      ) : null,
      Array.from({ length: emptyStars }, (_, index) => (
        <li key={index + filledStars + (hasHalfStar ? 1 : 0)} className="small">
          <i className="bi bi-star" />
        </li>
      ))
    );
  };

  const calcAvrRating = () => {
    const commRating = orderDetails?.feedback?.communication || 0;
    const recommRating = orderDetails?.feedback?.recommend || 0;
    const servRating = orderDetails?.feedback?.service || 0;

    const averageRating = (commRating + recommRating + servRating) / 3;

    return averageRating;
  };

  const generateAvrRating = () => {
    const averageRating = calcAvrRating();
    return generateStars(averageRating);
  };

  const writeAReplyHandler = (e) => {
    e.preventDefault();
    setWriteAReply((prevValue) => !prevValue);
    setReply("");
  };

  const handleReplyChange = (e) => {
    setReply(e.target.value);
  };

  const reqOrderId = sOrderId ? sOrderId : orderId;

  const submitReply = async () => {
    try {
      if (!reply.trim()) {
        message.error("Please write reply before submiting.");
        return;
      }

      await axios.put(`${FEEDBACK_REPLY_ORDER_API_URL}/${reqOrderId}`, {
        reply: reply,
      });
      message.success("Reply submitted successfully.");
      getOrderDetails();
      setReply("");
      setWriteAReply(false);
    } catch (err) {
      message.error(err?.response?.data?.error);
    }
  };

  return (
    <>
      <li className="list-group-item px-0 py-3 d-flex flex-wrap align-items-center border-0 border-bottom">
        <a
          className=""
          data-bs-toggle="collapse"
          href="#collapseReviews"
          role="button"
          aria-expanded="true"
          aria-controls="collapseReviews"
        >
          <h4 className="h6 m-0 d-inline-flex align-items-center">
            <span className="icon bg-icon-style d-inline-flex rounded-pill justify-content-center align-items-center me-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-star"
                viewBox="0 0 16 16"
              >
                <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
              </svg>
            </span>
            {userInfo?._id === orderDetails?.feedback?.createdBy?._id
              ? "You "
              : `${orderDetails?.feedback?.createdBy?.firstName} ${orderDetails?.feedback?.createdBy?.lastName}`}{" "}
            left a{" "}
            {calcAvrRating() % 1 !== 0
              ? calcAvrRating().toFixed(1)
              : calcAvrRating()}
            -star review
          </h4>
          <span className="small d-inline-flex text-secondary ms-4 ps-2">
            {formatDateTime(orderDetails?.feedback?.createdAt)}
          </span>
        </a>
        <div className="collapse w-100 mt-2 show" id="collapseReviews">
          <div className="card card-body rounded-2 border-0 py-0 px-0">
            <ul className="list-unstyled">
              <li className="border rounded-2 mt-2 px-3">
                <div className="msgs-wrapper col-lg-8 col-md-10">
                  <div className="d-flex flex-column">
                    <div className="msg-list-item py-4">
                      <div className="d-flex flex-row user-info">
                        <div>
                          {orderDetails?.feedback?.createdBy?.imageUrl ? (
                            <img
                              className="rounded-circle me-3"
                              src={constructImageUrl(
                                orderDetails?.feedback?.createdBy?.imageUrl
                              )}
                              width={40}
                              height={40}
                            />
                          ) : (
                            <div className="user-avator-order secondary-bg rounded-circle d-flex align-items-center justify-content-center text-uppercase fw-semibold text-white me-3">
                              <span className="name-txt">
                                {typeof orderDetails?.feedback?.createdBy
                                  ?.firstName === "string"
                                  ? orderDetails?.feedback?.createdBy?.firstName
                                      .charAt(0)
                                      .toUpperCase()
                                  : ""}
                                {typeof orderDetails?.feedback?.createdBy
                                  ?.lastName === "string"
                                  ? orderDetails?.feedback?.createdBy?.lastName
                                      .charAt(0)
                                      .toUpperCase()
                                  : ""}
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="ps-2 flex-fill">
                          <div className="d-flex gap-2 align-items-center">
                            <h4 className="h6 d-flex flex-column justify-content-start m-0">
                              <span className="d-block name lh-sm">
                                {userInfo?._id ===
                                orderDetails?.feedback?.createdBy?._id
                                  ? "Me"
                                  : orderDetails?.feedback?.createdBy
                                      ?.firstName}{" "}
                                <span />
                              </span>
                            </h4>
                            <div className="list-reivew-wrapper d-flex gap-1">
                              <ul className="rating-review-list list-unstyled d-inline-flex gap-1 small primary-text">
                                {generateAvrRating()}
                              </ul>
                              <span className="small text-secondary">
                                (
                                {calcAvrRating() % 1 !== 0
                                  ? calcAvrRating().toFixed(1)
                                  : calcAvrRating()}
                                )
                              </span>
                            </div>
                          </div>

                          <p className="msg-text small m-0">
                            {orderDetails?.feedback?.review}
                          </p>
                          <div className="review-bottom pt-md-2 mt-4">
                            <ul className="list-unstyled d-flex flex-column gap-3">
                              <li className="d-flex flex-wrap justify-content-between">
                                <div className="review-bottom-left">
                                  <span className="small fw-semibold">
                                    Communication with team
                                  </span>
                                </div>
                                <div className="review-bottom-right">
                                  <ul className="rating-review-list list-unstyled d-inline-flex gap-1">
                                    {generateStars(
                                      orderDetails?.feedback?.communication
                                    )}
                                  </ul>
                                  <span className="small text-secondary">
                                    {" "}
                                    ({orderDetails?.feedback?.communication})
                                  </span>
                                </div>
                              </li>
                              <li className="d-flex flex-wrap justify-content-between">
                                <div className="review-bottom-left">
                                  <span className="small fw-semibold">
                                    Service as described
                                  </span>
                                </div>
                                <div className="review-bottom-right">
                                  <ul className="rating-review-list list-unstyled d-inline-flex gap-1">
                                    {generateStars(
                                      orderDetails?.feedback?.service
                                    )}
                                  </ul>
                                  <span className="small text-secondary">
                                    {" "}
                                    ({orderDetails?.feedback?.service})
                                  </span>
                                </div>
                              </li>
                              <li className="d-flex flex-wrap justify-content-between">
                                <div className="review-bottom-left">
                                  <span className="small fw-semibold">
                                    Buy again or recommend
                                  </span>
                                </div>
                                <div className="review-bottom-right">
                                  <ul className="rating-review-list list-unstyled d-inline-flex gap-1">
                                    {generateStars(
                                      orderDetails?.feedback?.recommend
                                    )}
                                  </ul>
                                  <span className="small text-secondary">
                                    {" "}
                                    ({orderDetails?.feedback?.recommend})
                                  </span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*.msg-list-item*/}
                  </div>
                </div>
                {/*.msgs-wrapper*/}
                {orderDetails.feedback?.reply ? (
                  <div
                    className="alert bg-white d-flex gap-3 shadow-none rounded-0 border-0 border-1 border-top m-0 align-items-center py-3 px-0"
                    role="alert"
                  >
                    <span className="icon-img">
                      <img src="./assets/images/logo-icon-aamax.png" alt />
                    </span>
                    <div className="alert-text">
                      <h4 className="h6 mb-1">
                        <span className="primary-text">AAMAX</span>'s response:{" "}
                      </h4>
                      <p className="mb-0 small">
                        {orderDetails.feedback?.reply}
                      </p>
                    </div>
                  </div>
                ) : (
                  <>
                    {(userInfo?.userType === "manager" ||
                      userInfo?.userType === "admin") &&
                    writeAReply ? (
                      <div className="card-body">
                        <div className="write-msg-box bg-light p-4 mt-4">
                          <div className="d-flex flex-wrap flex-column gap-md-4 gap-3">
                            <div className="d-flex align-items-center">
                              {userInfo?.imageUrl ? (
                                <img
                                  className="rounded-circle me-3"
                                  src={constructImageUrl(userInfo?.imageUrl)}
                                  width={45}
                                  height={45}
                                />
                              ) : (
                                <div className="user-avator-order secondary-bg rounded-circle d-flex align-items-center justify-content-center text-uppercase fw-semibold text-white me-2">
                                  <span className="name-txt">
                                    {`${
                                      userInfo?.firstName
                                        ? userInfo.firstName
                                            .charAt(0)
                                            .toUpperCase()
                                        : ""
                                    }${
                                      userInfo?.lastName
                                        ? userInfo.lastName
                                            .charAt(0)
                                            .toUpperCase()
                                        : ""
                                    }`}
                                  </span>
                                </div>
                              )}
                              <h6 className="h6 mb-0">
                                Write a reply to this review.
                              </h6>
                            </div>
                            <textarea
                              className="form-control flex-fill w-100 p-3 ml-1 mt-md-0 mt-2 shadow-none textarea"
                              id="exampleFormControlTextarea3"
                              placeholder="Your Reply..."
                              rows={8}
                              defaultValue={""}
                              value={reply}
                              onChange={handleReplyChange}
                            />
                          </div>
                          <div className="d-flex flex-wrap justify-content-between gap-3 mb-0 mt-3 mt-md-4">
                            <div className="d-flex flex-wrap gap-3 align-items-center">
                              <button
                                className="btn primary-btn flex-fill fw-semibold fs-6 px-4 py-2"
                                type="button"
                                onClick={submitReply}
                              >
                                Submit Reply
                              </button>
                              <button
                                className="btn primary-btn-outline fw-semibold flex-fill fs-6 px-4 py-2"
                                type="button"
                                onClick={writeAReplyHandler}
                              >
                                Back to Feedback
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      !orderDetails.feedback?.reply &&
                      (userInfo?.userType === "admin" ||
                        userInfo?.userType === "manager") && (
                        <div
                          className="alert bg-white d-flex gap-3 shadow-none rounded-0 border-0 border-1 border-top m-0 align-items-center py-3 px-0"
                          role="alert"
                        >
                          <div className="header-right">
                            <a
                              href="#"
                              className="btn oms-btn position-relative overflow-hidden shadow"
                              onClick={writeAReplyHandler}
                            >
                              Write the Reply
                            </a>
                          </div>
                        </div>
                      )
                    )}
                  </>
                )}
              </li>
            </ul>
          </div>
        </div>
      </li>
    </>
  );
}

export default ReviewRating;
