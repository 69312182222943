import React from "react";

function CharityDetailSec() {
  return (
    <>
      <div className="card-body">
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-10">
            <div className="charity-card card text-center p-sm-5 p-4 my-4 bg-light border">
              <div className="logo-wrapper mb-4">
                <img
                  src="./assets/images/aam-nation-care-logo.png"
                  className="img-fluid"
                  alt="#"
                />
              </div>
              <div className="card-body p-0 py-3">
                <h4 className="card-title mb-3 text-uppercase primary-text">
                  AAM Nation Care
                </h4>
                <p className="card-text small lh-lg">
                  AAM Nation Care is a unique organization that genuinely cares
                  about people and aims to see everyone succeed. AAM Nation Care
                  assists students by educating them on making money online,
                  offering various courses both through their academies and via
                  YouTube. Additionally, they provide financial support, food
                  rations, health improvements, and other forms of assistance to
                  individuals in need. For further insights into the impactful
                  work of AAM Nation Care, please explore their website at{" "}
                  <a href="https://aamnationcare.org" target="_blank">
                    aamnationcare.org
                  </a>
                  .
                </p>
              </div>
            </div>
            {/*.charity-card*/}
          </div>
          {/*.col-grid*/}
        </div>
        {/*.row*/}
      </div>
    </>
  );
}

export default CharityDetailSec;
