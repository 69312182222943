import React, { createContext, useState } from "react";

const LiveChatCtx = createContext();

const LiveChatContext = ({ children }) => {
  const [isLiveChatOpen, setIsLiveChatOpen] = useState(false);

  return (
    <LiveChatCtx.Provider
      value={{
        isLiveChatOpen,
        setIsLiveChatOpen,
      }}
    >
      {children}
    </LiveChatCtx.Provider>
  );
};

export { LiveChatContext, LiveChatCtx };
