import React, { createContext, useContext, useEffect, useState } from "react";
import { Order } from "./Order";
import axios from "axios";
import {
  DELIVER_YOUR_ORDER_API_URL,
  GET_DELIVERIES_API_URL,
  UPLOAD_API_URL,
} from "../config/endPoints";
import { message } from "antd";

const DeliveryCtx = createContext();

const DeliveryContext = ({ children }) => {
  const MAX_ATTACHMENTS = 5;
  const MAX_FILE_SIZE_MB = 50;
  const chunkSize = 500 * 1024;

  const { sOrderId, orderId, getOrderDetails, setDeliverYOrder } =
    useContext(Order);

  const [deliveries, setDeliveries] = useState([]);

  const [deliveryDesc, setDeliveryDesc] = useState({
    description: "",
  });

  const [requirementsFiles, setRequirementsFiles] = useState([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(null);
  const [lastUploadedFileIndex, setLastUploadedFileIndex] = useState(null);
  const [currentChunkIndex, setCurrentChunkIndex] = useState(null);
  const [uploadedAttachments, setUploadedAttachments] = useState([]);
  const [uploading, setUploading] = useState(false);

  const formData = {
    description: deliveryDesc.description,
    deliveryAttachments: uploadedAttachments,
  };

  const resetSelection = () => {
    setCurrentFileIndex(null);
    setLastUploadedFileIndex(null);
    setCurrentChunkIndex(null);
    setUploadedAttachments([]);
    setRequirementsFiles([]);
  };

  const reqOrderId = sOrderId ? sOrderId : orderId;

  const getDeliveries = async () => {
    try {
      const res = await axios.get(`${GET_DELIVERIES_API_URL}/${reqOrderId}`);
      if (!res?.data) {
        return;
      }
      setDeliveries(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeliveryChange = (e) => {
    setDeliveryDesc((prev) => ({ ...prev, description: e.target.value }));
  };

  const handleDeliveryAttachments = (e) => {
    const files = e.target.files;
    if (files.length > MAX_ATTACHMENTS) {
      message.error(`You can only attach up to ${MAX_ATTACHMENTS} files.`);
      e.target.value = null;
      return;
    }

    for (let i = 0; i < files.length; i++) {
      if (files[i].size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        message.error(`File size should not exceed ${MAX_FILE_SIZE_MB} MB.`);
        e.target.value = null;
        return;
      }
    }

    setRequirementsFiles(files);
  };

  function readNUploadCurrentChunk() {
    const reader = new FileReader();
    const file = requirementsFiles[currentFileIndex];
    if (!file) {
      return;
    }
    const from = currentChunkIndex * chunkSize;
    const to = from + chunkSize;
    const blob = file.slice(from, to);
    reader.onload = (e) => uploadAChunk(e);
    reader.readAsDataURL(blob);
  }

  function uploadAChunk(readerEvent) {
    const file = requirementsFiles[currentFileIndex];
    const data = readerEvent.target.result;
    const params = new URLSearchParams();
    params.set("name", file.name);
    params.set("size", file.size);
    params.set("currentChunkIndex", currentChunkIndex);
    params.set("totalChunks", Math.ceil(file.size / chunkSize));
    params.set("fileType", "deliveryattachments");
    const headers = { "Content-Type": "application/octet-stream" };
    const url = `${UPLOAD_API_URL}?` + params.toString();
    axios.post(url, data, { headers }).then((response) => {
      const file = requirementsFiles[currentFileIndex];
      const filesize = requirementsFiles[currentFileIndex].size;
      const chunks = Math.ceil(filesize / chunkSize) - 1;
      const isLastChunk = currentChunkIndex === chunks;
      if (isLastChunk) {
        file.finalFilename = response.data.finalFilename;

        setLastUploadedFileIndex(currentFileIndex);
        setCurrentChunkIndex(null);
        setUploadedAttachments((prevAttachments) => [
          ...prevAttachments,
          {
            filename: file.name,
            filePath: response.data,
            filesize: filesize,
          },
        ]);
      } else {
        setCurrentChunkIndex(currentChunkIndex + 1);
      }
    });
  }

  useEffect(() => {
    if (lastUploadedFileIndex === null) {
      return;
    }
    const isLastFile = lastUploadedFileIndex === requirementsFiles.length - 1;
    const nextFileIndex = isLastFile ? null : currentFileIndex + 1;
    setCurrentFileIndex(nextFileIndex);
  }, [lastUploadedFileIndex]);

  useEffect(() => {
    if (requirementsFiles.length > 0) {
      if (currentFileIndex === null) {
        setCurrentFileIndex(
          lastUploadedFileIndex === null ? 0 : lastUploadedFileIndex + 1
        );
      }
    }
  }, [requirementsFiles.length]);

  useEffect(() => {
    if (currentFileIndex !== null) {
      setCurrentChunkIndex(0);
    }
  }, [currentFileIndex]);

  useEffect(() => {
    if (currentChunkIndex !== null) {
      readNUploadCurrentChunk();
    }
  }, [currentChunkIndex]);

  useEffect(() => {
    if (requirementsFiles?.length > 0 && currentFileIndex !== null) {
      setUploading(true);
    } else {
      setUploading(false);
    }
  }, [requirementsFiles, currentFileIndex]);

  const deliverOrder = async () => {
    try {
      if (!deliveryDesc.description.trim()) {
        message.error("Please add description before delivering order.");
        return;
      }

      await axios.post(`${DELIVER_YOUR_ORDER_API_URL}/${reqOrderId}`, formData);
      message.success("Order delivered successfully.");
      getOrderDetails();
      setDeliveryDesc({
        description: "",
      });
      setCurrentFileIndex(null);
      setLastUploadedFileIndex(null);
      setCurrentChunkIndex(null);
      setUploadedAttachments([]);
      setRequirementsFiles([]);
      setDeliverYOrder(false);
    } catch (err) {
      message.error(err?.response?.data?.error);
    }
  };

  useEffect(() => {
    if (reqOrderId) {
      getDeliveries();
    }
  }, [reqOrderId]);

  return (
    <DeliveryCtx.Provider
      value={{
        deliveries,
        deliverOrder,
        deliveryDesc,
        setDeliveryDesc,
        handleDeliveryChange,
        handleDeliveryAttachments,
        setDeliveries,
        getDeliveries,
        resetSelection,
        uploading,
        requirementsFiles,
        currentFileIndex,
        currentChunkIndex,
        chunkSize,
      }}
    >
      {children}
    </DeliveryCtx.Provider>
  );
};

export { DeliveryContext, DeliveryCtx };
