import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { GET_ACTIVE_ORDERS_API_URL } from "../config/endPoints";
import { LoadingContext } from "../context/LoadingLogo";

function useAOrders() {
  const { setLogoLoading } = useContext(LoadingContext);

  const [aOrders, setAOrders] = useState([]);

  const getActiveOrders = async () => {
    try {
      setLogoLoading(true);
      const res = await axios.get(GET_ACTIVE_ORDERS_API_URL);
      setAOrders(res?.data?.aOrders);
      setLogoLoading(false);
    } catch (err) {
      console.error(err);
      setLogoLoading(false);
    }
  };

  useEffect(() => {
    getActiveOrders();
  }, []);

  return aOrders;
}

export default useAOrders;
