import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import countries from "../../utils/countries.json";
import Select from "react-select";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../context/Auth";
import { Helmet } from "react-helmet";

function CreateAccount() {
  const { register, setConfirmPassword, loading, userInfo } =
    useContext(AuthContext);

  const token = localStorage.getItem("token");

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    accountType: "",
    password: "",
    userType: "client",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === "confirmPassword") {
      setConfirmPassword(value);
    } else {
      setData({ ...data, [id]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    register(data);
  };

  const selectCountry = (sc) => {
    setData({ ...data, country: sc.value });
  };

  if (token && !loading && userInfo?.userType) {
    return (
      <Navigate
        to={userInfo?.userType === "admin" ? "/total-orders" : "/dashboard"}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>Sign Up – AAMAX</title>
      </Helmet>
      {/*====================== Start Sign in Form ======================*/}
      <section className="oms-authentication py-md-5 py-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8 col-xl-6">
              <div className="form-wrapper">
                <div className="logo-wrapper text-center mb-md-5 mb-4 pb-lg-4 pb-2">
                  <Link to="/">
                    <img src="assets/images/logo-dark.png" alt="aamax" />
                  </Link>
                </div>
                <form className="sign-in-form">
                  <div className="form-title text-center mb-md-5 mb-4">
                    <h3 className="display-6 fw-semibold mb-md-3 mb-2">
                      Sign up
                    </h3>
                    <p className="text-muted">Create your account</p>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-md-6">
                      <div className="mb-3 pb-md-3">
                        <label
                          htmlFor="firstName"
                          className="form-label small text-secondary"
                        >
                          First name
                        </label>
                        <input
                          id="firstName"
                          type="text"
                          className="form-control bg-white border px-3"
                          autofocus
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3 pb-md-3">
                        <label
                          htmlFor="lastName"
                          className="form-label small text-secondary"
                        >
                          Last name
                        </label>
                        <input
                          id="lastName"
                          type="text"
                          className="form-control bg-white border px-3"
                          autofocus
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3 pb-md-3">
                        <label
                          htmlFor="country"
                          className="form-label small text-secondary"
                        >
                          Email address
                        </label>
                        <input
                          id="email"
                          type="email"
                          className="form-control bg-white px-3 border"
                          autofocus
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3 pb-md-3">
                        <label
                          htmlFor="country"
                          className="form-label small text-secondary"
                        >
                          Billing country
                        </label>
                        <Select
                          options={countries.map((country) => ({
                            label: country.name,
                            value: country.name,
                          }))}
                          onChange={selectCountry}
                          isSearchable
                          placeholder="Please select"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3 pb-md-3">
                        <label
                          htmlFor="accountType"
                          className="form-label small text-secondary"
                        >
                          What best describe you?
                        </label>
                        <select
                          className="form-select"
                          id="accountType"
                          required
                          onChange={handleChange}
                          value={data.accountType}
                        >
                          <option value="">Please select</option>
                          <option value="Agency">Agency</option>
                          <option value="Brand">Brand</option>
                          <option value="Individual">Individual</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3 pb-md-3">
                        <label
                          htmlFor="password"
                          className="form-label small text-secondary"
                        >
                          Password
                        </label>
                        <input
                          id="password"
                          type="password"
                          className="form-control form-control-lg bg-white px-3 border"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3 pb-md-3">
                        <label
                          htmlFor="confirmPassword"
                          className="form-label small text-secondary"
                        >
                          Confirm password
                        </label>
                        <input
                          id="confirmPassword"
                          type="password"
                          className="form-control form-control-lg bg-white px-3 border"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <p className="text-muted small text-center">
                      Need help? Watch our quick{" "}
                      <a
                        href="https://www.youtube.com/watch?v=rjZf__vVqBs&ab_channel=AAMAX"
                        target="_blank"
                      >
                        tutorial video
                      </a>{" "}
                      to master the AAMAX app in minutes!
                    </p>
                    <div className="col-md-8">
                      <button
                        onClick={handleSubmit}
                        disabled={loading}
                        className="btn primary-btn shadow w-100 mb-2 mt-2"
                      >
                        {loading && (
                          <span className="spinner-grow spinner-grow-sm me-2"></span>
                        )}
                        {!loading ? "Create account" : "Creating account"}
                      </button>
                      <p className="mt-4 text-center text-muted">
                        Back to{" "}
                        <Link
                          to="/"
                          className="text-decoration-underline create-account-action"
                        >
                          Login
                        </Link>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
              {/*.form-wrapper*/}
            </div>
            {/*.col-grid*/}
          </div>
          {/*.row*/}
        </div>
        {/*.container*/}
      </section>
      {/*====================== End Sign in Form ======================*/}
    </>
  );
}

export default CreateAccount;
