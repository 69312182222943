import { useContext, useEffect, useState } from "react";
import { LoadingContext } from "../context/LoadingLogo";
import axios from "axios";
import { GET_INVOICES_API_URL } from "../config/endPoints";

function useInvoices() {
  const { setLogoLoading } = useContext(LoadingContext);

  const [invoices, setInvoices] = useState([]);

  const getInvoices = async () => {
    try {
      setLogoLoading(true);
      const res = await axios.get(GET_INVOICES_API_URL);
      if (res?.data) {
        setInvoices(res.data);
      }
      setLogoLoading(false);
    } catch (err) {
      console.log(err);
      setLogoLoading(false);
    }
  };
  useEffect(() => {
    getInvoices();
  }, []);
  return invoices;
}

export default useInvoices;
