import {
  APP_STATUS,
  LOCAL_API_URL,
  LOCAL_BACKEND_URL,
  LOCAL_DOMAIN_URL,
  LOCAL_GRAPHQL_URL,
  LOCAL_STRAPI_API_URL,
  LOCAL_STRAPI_BACKEND_URL,
  PRODUCTIOL_GRAPHQL_URL,
  PRODUCTION_API_URL,
  PRODUCTION_BACKEND_URL,
  PRODUCTION_DOMAIN_URL,
  PRODUCTION_STRAPI_API_URL,
  PRODUCTION_STRAPI_BACKEND_URL,
} from "./env";

export const DOMAIN_URL =
  APP_STATUS === "production" ? PRODUCTION_DOMAIN_URL : LOCAL_DOMAIN_URL;
export const BACKEND_URL =
  APP_STATUS === "production" ? PRODUCTION_BACKEND_URL : LOCAL_BACKEND_URL;
export const API_URL =
  APP_STATUS === "production" ? PRODUCTION_API_URL : LOCAL_API_URL;
export const STRAPI_URL =
  APP_STATUS === "production"
    ? PRODUCTION_STRAPI_BACKEND_URL
    : LOCAL_STRAPI_BACKEND_URL;
export const STRAPI_API_URL =
  APP_STATUS === "production"
    ? PRODUCTION_STRAPI_API_URL
    : LOCAL_STRAPI_API_URL;
export const GRAPHQL_URL =
  APP_STATUS === "production" ? PRODUCTIOL_GRAPHQL_URL : LOCAL_GRAPHQL_URL;

// NodeJs Backend URLS
export const LOGIN_API_URL = API_URL + "/user/login";
export const REGISTER_API_URL = API_URL + "/user/register";
export const GET_USER_INFO_API_URL = API_URL + "/user/info";
export const GET_ADMIN_USERS_API_URL = API_URL + "/user/getausers";
export const GET_CLIENTS_API_URL = API_URL + "/user/getclients";
export const GET_CREDITS_API_URL = API_URL + "/user/getcredits";
export const UPDATE_USER_INFO_API_URL = API_URL + "/user/update";
export const UPDATE_CLIENT_USER_INFO_API_URL = API_URL + "/user/clientteamupd";
export const UPDATE_ADMIN_USER_API_URL = API_URL + "/user/updateauser";
export const UPDATE_CLIENT_API_URL = API_URL + "/user/updateclient";
export const DELETE_CLIENT_USER_INFO_API_URL = API_URL + "/user/cuserdelete";
export const DELETE_ADMIN_USER_API_URL = API_URL + "/user/auserdelete";
export const FORGET_PASSWORD_API_URL = API_URL + "/user/forget-password";
export const VERFIY_LINK_TOKEN_API_URL = API_URL + "/user/verify-token";
export const RESET_PASSWORD_API_URL = API_URL + "/user/reset-password";
export const DELETE_ORDER_API_URL = API_URL + "/delete";
export const ORDER_CART_API_URL = API_URL + "/ordercart";
export const OVERDUE_CART_API_URL = API_URL + "/ocart";
export const ORDER_API_URL = API_URL + "/order";
export const REQS_A_ORDER_API_URL = API_URL + "/reqsaorder";
export const GET_ALL_ORDER_API_URL = API_URL + "/orders";
export const GET_ACTIVE_ORDERS_API_URL = API_URL + "/aorders";
export const GET_CANCELLED_ORDERS_API_URL = API_URL + "/corders";
export const GET_COMPLETED_ORDERS_API_URL = API_URL + "/comporders";
export const VERIFYING_ORDERS_API_URL = API_URL + "/verifying-orders";
export const VERIFYING_CREDITS_API_URL = API_URL + "/verifying-credits";
export const CREDITS_API_URL = API_URL + "/credits";
export const APPROVE_PAYMENT_API_URL = API_URL + "/approve-payment";
export const DOWNLOAD_ATTACHMENT_API_URL = API_URL + "/downloadattachment";
export const SEND_MESSAGE_API_URL = API_URL + "/message";
export const DELIVER_YOUR_ORDER_API_URL = API_URL + "/delivery";
export const SEND_REVISION_REQ_API_URL = API_URL + "/revision";
export const GET_MESSAGES_API_URL = API_URL + "/messages";
export const GET_DELIVERIES_API_URL = API_URL + "/deliveries";
export const GET_REVISIONS_API_URL = API_URL + "/revisions";
export const COMPLETE_ORDER_API_URL = API_URL + "/completeorder";
export const FEEDBACK_ORDER_API_URL = API_URL + "/feedback";
export const FEEDBACK_REPLY_ORDER_API_URL = API_URL + "/feedbackreply";
export const GET_INVOICE_API_URL = API_URL + "/invoice";
export const GET_CREDIT_INVOICE_API_URL = API_URL + "/crinvoice";
export const GET_UNPAID_INVOICE_API_URL = API_URL + "/unpaid-invoice";
export const GET_INVOICES_API_URL = API_URL + "/invoices";
export const TEAM_WORKING_API_URL = API_URL + "/teamworking";
export const UPLOAD_API_URL = API_URL + "/upload";
export const CAREER_API_URL = API_URL + "/career";
export const CONTACT_API_URL = API_URL + "/contact";
export const ADD_CREDIT_API_URL = API_URL + "/addcredit";
export const APPROVE_CREDIT_API_URL = API_URL + "/approve-credit";
export const UNVERIFY_CREDIT_API_URL = API_URL + "/unverify-credit";
export const CANCEL_ORDER_API_URL = API_URL + "/cancel-order";
export const PAYMENT_INTENT_API_URL = API_URL + "/payment-intent";

// Strapi Backend URLs
export const SERVICE_CATEGORIES_API_URL =
  STRAPI_API_URL + "/service-categories?populate=*";
