import React from "react";
import { AboutVideo } from "./AboutVideo";
import { convertToHTML } from "../../../utils/helpers";
import { Link } from "react-router-dom";

function AboutDesc({ data }) {
  return (
    <>
      <div className="row g-xl-5 align-items-center">
        <div className="col-lg-6">
          <div className="left-content-wrapper position-relative">
            {/* <div className="video-wrapper rounded">
              <video
                id="my-video"
                className="video-js vjs-theme-fantasy shadow"
                controls
                preload="auto"
                poster="assets/images/video-plc.jpg"
                data-setup="{}"
              >
                <source src="assets/images/about-video.mp4" type="video/mp4" />
              </video>
            </div> */}
            <AboutVideo data={data} />
          </div>
          {/*.left-content-wrapper*/}
        </div>
        {/*.col-grid*/}
        <div className="col-lg-6">
          <div className="content-wrapper my-3">
            <h2 className="fs-3 fw-semibold mb-3">
              {data?.attributes?.mainHeading}
            </h2>
            <div
              className="mb-3"
              dangerouslySetInnerHTML={convertToHTML(
                data?.attributes?.description
              )}
            />

            <Link
              type="button"
              to="/services"
              className="btn oms-btn position-relative mt-lg-4 mt-2 overflow-hidden"
            >
              Order Now
            </Link>

            {/* <ul className="list-unstyled text-secondary lh-lg p-0 mb-4">
              <li className="d-flex align-items-start text-secondary gap-1">
                <span className="icon primary-text">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={26}
                    fill="currentColor"
                    className="bi bi-check"
                    viewBox="0 0 16 16"
                  >
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                  </svg>
                </span>
                Duty or the obligations of business
              </li>
              <li className="d-flex align-items-start text-secondary gap-1">
                <span className="icon primary-text">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={26}
                    fill="currentColor"
                    className="bi bi-check"
                    viewBox="0 0 16 16"
                  >
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                  </svg>
                </span>
                Duty or the obligations of business
              </li>
              <li className="d-flex align-items-start text-secondary gap-1">
                <span className="icon primary-text">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={26}
                    fill="currentColor"
                    className="bi bi-check"
                    viewBox="0 0 16 16"
                  >
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                  </svg>
                </span>
                Duty or the obligations of business
              </li>
            </ul> */}
          </div>
          {/*.content-wrapper*/}
        </div>
        {/*.col-grid*/}
      </div>
    </>
  );
}

export default AboutDesc;
