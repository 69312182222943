import React, { useContext, useEffect } from "react";
import Footer from "../../components/layout/Footer";
import ClientHeader from "../../components/layout/client/ClientHeader";
import Breadcrumb from "../../components/misc/Breadcrumb";
import SSDMainSec from "../../components/client/singleServiceDetails/SSDMainSec";
import PricingSec from "../../components/client/singleServiceDetails/PricingSec";
import WorkDetailsSec from "../../components/client/singleServiceDetails/WorkDetailsSec";
import ServiceMainHeadings from "../../components/client/singleServiceDetails/ServiceMainHeadings";
import FeaturedTasksCompSec from "../../components/client/singleServiceDetails/FeaturedTasksCompSec";
import ClientsFeedbackSec from "../../components/client/singleServiceDetails/ClientsFeedbackSec";
import GuaranteeSec from "../../components/client/singleServiceDetails/GuaranteeSec";
import ServiceFaqsSec from "../../components/client/singleServiceDetails/ServiceFaqsSec";
import ServiceCtaSec from "../../components/client/singleServiceDetails/ServiceCtaSec";
import PageNotFound from "../404";
import { LoadingContext } from "../../context/LoadingLogo";
import ServiceDetailSkeleton from "../../components/client/singleServiceDetails/ServiceDetailSkeleton";
import useServiceDetail from "../../hooks/useServiceDetail";
import { AuthContext } from "../../context/Auth";
import { Helmet } from "react-helmet";

function SingleServiceDetails() {
  const data = useServiceDetail();

  const { logoLoading } = useContext(LoadingContext);
  const { userInfo } = useContext(AuthContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!data) {
    return <PageNotFound />;
  }

  return (
    <>
      <Helmet>
        <title>{`${data?.attributes?.name}`} – AAMAX</title>
      </Helmet>
      {/*=============== Start AAMAX OMS  ===============*/}

      <ClientHeader>
        {/*.header*/}
        <main
          className="page-content d-flex flex-column justify-content-between h-100"
          id="price-scroll"
        >
          <div className="container-fluid p-3 p-md-4">
            <Breadcrumb />
            {logoLoading ? (
              <ServiceDetailSkeleton />
            ) : (
              <div className="row">
                <div className="col-12">
                  <div className="page-card rounded-3 position-relative p-3 p-md-4">
                    <div className="card-body">
                      <SSDMainSec />
                      {/*.row*/}
                      {(userInfo?.userType === "admin" ||
                        userInfo?.userType === "client" ||
                        userInfo?.userType === "clientTm" ||
                        userInfo?.userType === "viewer") && <PricingSec />}
                      {/*.pricing*/}
                      <WorkDetailsSec />
                      {/*.work-process*/}
                      <ServiceMainHeadings />
                      {/*.benefits-sec*/}
                      {/* <FeaturedTasksCompSec /> */}
                      {/*.example-sec*/}
                      <ClientsFeedbackSec />
                      {/*.testimonial-sec*/}
                      <GuaranteeSec />
                      {/*.money-back-sec*/}
                      <ServiceFaqsSec />
                      {/*.faq-sec*/}
                      {(userInfo?.userType === "admin" ||
                        userInfo?.userType === "client" ||
                        userInfo?.userType === "clientTm" ||
                        userInfo?.userType === "viewer") && <ServiceCtaSec />}
                      {/*.cta-sec*/}
                    </div>
                    {/*.card-body*/}
                  </div>
                  {/*.page-card*/}
                </div>
                {/*.col-grid*/}
              </div>
            )}
            {/*.row*/}
          </div>
          {/*.container-fluid*/}
          <Footer />
        </main>
      </ClientHeader>
      {/*.page-content"*/}
      {/*=============== End AAMAX OMS  ===============*/}
    </>
  );
}

export default SingleServiceDetails;
