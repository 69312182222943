import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Order } from "../../../context/Order";
import { SearchCtx } from "../../../context/SearchContext";
import {
  calculateDaysLate,
  formatCurrency,
  formatDateString,
  getStatusColor,
} from "../../../utils/helpers";
import dayjs from "dayjs";
import { AuthContext } from "../../../context/Auth";
import { DatePicker, Popconfirm, message } from "antd";
import { DELETE_ORDER_API_URL } from "../../../config/endPoints";
import axios from "axios";
import Pagination from "../../misc/Pagination";
import { InvoiceCtx } from "../../../context/Invoice";

function TOTable() {
  const token = localStorage.getItem("token");

  const {
    getAllOrders,
    allOrders,
    setSOrderId,
    updateTeamWorking,
    updateTeamNO,
    setUpdateTeamNO,
    paginationData,
  } = useContext(Order);
  const { searchOrder } = useContext(SearchCtx);
  const { getAdminUsers, adminUsers, loading } = useContext(AuthContext);
  const { setInvoiceNumber } = useContext(InvoiceCtx);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [selectedOInfo, setSelectedOInfo] = useState({});

  const handleDateChange = (date, dateString) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");

    setUpdateTeamNO({
      ...updateTeamNO,
      deliveryDate: formattedDate,
    });
  };

  const handleDateBlur = (e) => {
    const formattedDate = dayjs(e.target.value, "DD/MM/YYYY").format(
      "YYYY-MM-DD"
    );

    setUpdateTeamNO({
      ...updateTeamNO,
      deliveryDate: formattedDate,
    });
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setUpdateTeamNO({ ...updateTeamNO, [id]: value });
  };

  const sOid = selectedOInfo?._id;

  const handleSubmit = (e) => {
    e.preventDefault();
    updateTeamWorking(updateTeamNO, sOid);
  };

  const confirm = (id) => {
    axios
      .delete(`${DELETE_ORDER_API_URL}/${id}`)
      .then(() => {
        getAllOrders();
        message.success("Order deleted successfully.");
      })
      .catch((err) => {
        message.error(err?.response?.data?.error);
      });
  };

  const sortedOrders = allOrders?.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  const filteredOrders = sortedOrders?.filter(
    (order) =>
      order.service.toLowerCase().includes(searchOrder.toLowerCase()) ||
      order.orderNumber.toLowerCase().includes(searchOrder.toLowerCase())
  );

  const sOrderIdHandler = (oid) => {
    setSOrderId(oid);
    localStorage.setItem("sOrderId", oid);
  };

  const sInvoiceIdHandler = (iid) => {
    setInvoiceNumber(iid);
    localStorage.setItem("sInvoiceId", iid);
  };

  const handleUserChange = (selected) => {
    setSelectedUsers(selected);
  };

  const handleCloseModal = () => {
    if (selectedOInfo?.teamWorking && selectedOInfo?.teamWorking?.length > 0) {
      const teamMembers = selectedOInfo?.teamWorking?.map((tm) => ({
        value: tm?._id,
        label: `${tm?.firstName} ${tm?.lastName}`,
      }));
      setSelectedUsers(teamMembers);
    }
    setUpdateTeamNO({
      deliveryDate: null,
      status: "",
      teamWorking: [],
    });
  };

  useEffect(() => {
    if (token) {
      getAdminUsers(token);
    }
    getAllOrders();
  }, [paginationData.currentPage]);
  useEffect(() => {
    const newOptions = adminUsers?.map((user) => ({
      value: user._id,
      label: `${user.firstName} ${user.lastName}`,
    }));
    setUserOptions(newOptions);
  }, [adminUsers]);

  useEffect(() => {
    if (selectedOInfo?.teamWorking && selectedOInfo?.teamWorking?.length > 0) {
      const teamMembers = selectedOInfo?.teamWorking?.map((tm) => ({
        value: tm?._id,
        label: `${tm?.firstName} ${tm?.lastName}`,
      }));
      if (teamMembers.length > 0) {
        setSelectedUsers(teamMembers);
      }
    } else {
      setSelectedUsers([]);
    }
  }, [selectedOInfo]);

  useEffect(() => {
    const selectedUserIds = selectedUsers?.map((tm) => tm?.value || null);

    setUpdateTeamNO((prevState) => ({
      ...prevState,
      teamWorking: selectedUserIds,
    }));
  }, [selectedUsers]);

  return (
    <>
      <div className="card-body pt-4">
        <div className="table-responsive">
          <table className="table table-hover emp-attend-list">
            <thead>
              <tr>
                <th scope="col">Order Number</th>
                <th scope="col">Service</th>
                <th scope="col">Date Ordered</th>
                <th scope="col">Delivery Date</th>
                <th scope="col">Team</th>
                <th scope="col">Order Price</th>
                <th scope="col">Status</th>
                <th scope="col">Invoice</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredOrders?.map((order, i) => (
                <tr key={i}>
                  <td>{order?.orderNumber}</td>
                  <td>
                    {
                      <Link
                        to={
                          order?.status === "Verifying Payment"
                            ? "/verifying-payment"
                            : "/order-details"
                        }
                        style={{ textDecoration: "none", color: "#212529" }}
                        onClick={() => sOrderIdHandler(order._id)}
                      >{`${order?.service} (${order?.package})`}</Link>
                    }
                  </td>
                  <td>{formatDateString(order?.createdAt)}</td>
                  {order?.status === "Completed" ||
                  order?.status === "Incomplete" ||
                  order?.status === "Delivered" ||
                  order?.status === "Cancelled" ? (
                    <td>{formatDateString(order?.deliveryDate)}</td>
                  ) : calculateDaysLate(order?.deliveryDate) > 0 ? (
                    <td className="text-danger">
                      <i className="bi bi-clock"></i>{" "}
                      {`${calculateDaysLate(order?.deliveryDate)} Days Late`}
                    </td>
                  ) : (
                    <td>{formatDateString(order?.deliveryDate)}</td>
                  )}
                  <td>
                    {order?.teamWorking?.length > 0 ? (
                      order?.teamWorking?.map((t, i) => (
                        <span key={i}>
                          {t?.firstName}
                          {i !== order?.teamWorking?.length - 1 ? ", " : ""}
                        </span>
                      ))
                    ) : (
                      <span>No Team Assigned Yet</span>
                    )}
                  </td>
                  <td>{formatCurrency(order?.totalPaidAmount)}</td>
                  <td>
                    <span
                      className={`d-inline-block badge ${getStatusColor(
                        order?.status
                      )}`}
                    >
                      {order?.status}
                    </span>
                  </td>
                  <td>
                    <Link
                      to="/paid-invoice"
                      className="preview-action small text-decoration-none fw-semibold d-flex align-items-center"
                      onClick={() => sInvoiceIdHandler(order?.invoiceNumber)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-eye me-1"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                      </svg>
                      View
                    </Link>
                  </td>
                  <td>
                    <button
                      className="tb-action edit"
                      data-bs-toggle="modal"
                      data-bs-target="#editUserModal"
                      onClick={() => setSelectedOInfo(order)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-pencil-square"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path
                          fillRule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                        />
                      </svg>
                    </button>
                    <Popconfirm
                      title="Confirm Delete"
                      description="Are you sure you want to delete this order?"
                      onConfirm={() => confirm(order?._id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <button className="tb-action delete">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          fill="currentColor"
                          className="bi bi-trash3"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                        </svg>
                      </button>
                    </Popconfirm>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div
            className="modal fade"
            id="editUserModal"
            tabIndex={-1}
            aria-labelledby="editUserModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header dark-bg px-4">
                  <h1
                    className="modal-title fs-5 text-white"
                    id="cardPaymentModalLabel"
                  >
                    Edit Order
                  </h1>
                  <button
                    type="button"
                    className="btn-close btn-close-white"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={handleCloseModal}
                  />
                </div>
                <div className="modal-body px-4">
                  <form className="needs-validation mt-lg-3">
                    <div className="row g-3">
                      <div className="col-sm-6">
                        <label
                          htmlFor="service"
                          className="form-label small fw-semibold"
                        >
                          Service
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          id="service"
                          placeholder
                          value={`${selectedOInfo?.service} (${selectedOInfo?.package})`}
                        />
                      </div>
                      <div className="col-sm-6">
                        <label
                          htmlFor="orderNumber"
                          className="form-label small fw-semibold"
                        >
                          Order Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          id="orderNumber"
                          placeholder
                          value={selectedOInfo?.orderNumber}
                        />
                      </div>
                      <div className="col-sm-6">
                        <label
                          htmlFor="email"
                          className="form-label small fw-semibold"
                        >
                          Delivery Date
                        </label>
                        <DatePicker
                          className="form-control"
                          id="deliveryDate"
                          value={dayjs(
                            updateTeamNO?.deliveryDate
                              ? updateTeamNO?.deliveryDate
                              : selectedOInfo?.deliveryDate
                          )}
                          format="DD/MM/YYYY"
                          onChange={handleDateChange}
                          onBlur={handleDateBlur}
                        />
                      </div>
                      <div className="col-sm-6">
                        <label
                          htmlFor="name"
                          className="form-label small fw-semibold"
                        >
                          Working On
                        </label>
                        <Select
                          isMulti
                          placeholder={
                            selectedOInfo?.teamWorking?.length > 0
                              ? selectedOInfo?.teamWorking
                                  .map((t) => `${t?.firstName} ${t?.lastName}`)
                                  .join(", ")
                              : "Add Team"
                          }
                          options={userOptions}
                          value={selectedUsers}
                          onChange={handleUserChange}
                        />
                      </div>
                      <div className="col-sm-6">
                        <label
                          htmlFor="country"
                          className="form-label small fw-semibold"
                        >
                          Status
                        </label>
                        <select
                          className="form-select"
                          id="status"
                          required
                          value={
                            updateTeamNO?.status
                              ? updateTeamNO?.status
                              : selectedOInfo?.status
                          }
                          style={{ cursor: "pointer" }}
                          onChange={handleChange}
                        >
                          <option value="Incomplete">Incomplete</option>
                          <option value="In Progress">In Progress</option>
                          <option value="Delivered">Delivered</option>
                          <option value="Revision">Revision</option>
                          <option value="Completed">Completed</option>
                          <option value="Cancelled">Cancelled</option>
                        </select>
                      </div>
                      {(updateTeamNO?.status === "Cancelled" ||
                        selectedOInfo?.cancReason) && (
                        <div className="col-sm-6">
                          <label
                            htmlFor="cancelledReason"
                            className="form-label small fw-semibold"
                          >
                            Cancelled Reason
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="cancReason"
                            name="cancReason"
                            placeholder="Enter Cancelled Reason"
                            value={
                              updateTeamNO?.cancReason
                                ? updateTeamNO?.cancReason
                                : selectedOInfo?.cancReason
                            }
                            onChange={handleChange}
                          />
                        </div>
                      )}
                    </div>
                    {/*.row*/}
                    {/* / Change address form*/}
                    <div className="btn-wrapper text-end mb-3 mt-4 mt-lg-5">
                      <a
                        href="#"
                        className="fs-6 btn btn-light me-2 px-4 py-2 text-decoration-none"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={handleCloseModal}
                      >
                        Cancel
                      </a>
                      <button
                        onClick={handleSubmit}
                        disabled={loading}
                        className="btn primary-btn fw-semibold fs-6 px-4 py-2"
                      >
                        {loading && (
                          <span className="spinner-grow spinner-grow-sm me-2"></span>
                        )}
                        {!loading ? "Update User" : "Updating User"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/*.edit-user-modal*/}
        </div>
        {/*.table-responsive*/}
        <Pagination />
      </div>
    </>
  );
}

export default TOTable;
