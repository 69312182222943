import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Order } from "../../../context/Order";
import { SearchCtx } from "../../../context/SearchContext";
import {
  calculateDaysLate,
  formatCurrency,
  formatDateString,
  formatFileSize,
  getFileIcon,
  getStatusColor,
  truncateFileName,
} from "../../../utils/helpers";
import {
  APPROVE_PAYMENT_API_URL,
  BACKEND_URL,
  CANCEL_ORDER_API_URL,
} from "../../../config/endPoints";
import { InvoiceCtx } from "../../../context/Invoice";
import { UtilsCtx } from "../../../context/UtilsContext";
import { Dropdown, Menu, Popconfirm, message } from "antd";
import axios from "axios";

function VOTable() {
  const { VerifOrders, getVerifyingOrders, setSOrderId } = useContext(Order);
  const { searchOrder } = useContext(SearchCtx);
  const { setInvoiceNumber } = useContext(InvoiceCtx);
  const { handleDownloadAttachment } = useContext(UtilsCtx);
  const [selectedOption, setSelectedOption] = useState(null);
  const [orderId, setOrderId] = useState("");

  const sortedOrders = VerifOrders?.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  const filteredOrders = sortedOrders?.filter(
    (order) =>
      order.service.toLowerCase().includes(searchOrder.toLowerCase()) ||
      order.orderNumber.toLowerCase().includes(searchOrder.toLowerCase())
  );

  const sOrderIdHandler = (oid) => {
    setSOrderId(oid);
    localStorage.setItem("sOrderId", oid);
  };

  const sInvoiceIdHandler = (iid) => {
    setInvoiceNumber(iid);
    localStorage.setItem("sInvoiceId", iid);
  };

  const confirm = (id) => {
    axios
      .put(`${APPROVE_PAYMENT_API_URL}/${id}`)
      .then(() => {
        getVerifyingOrders();
        message.success("Payment approved successfully.");
      })
      .catch((err) => {
        message.error(err?.response?.data?.error);
      });
  };

  const paramsData = {
    rejectionReason: selectedOption,
  };

  const options = [
    "Payment Not Received",
    "Banking Issues",
    "Refunded",
    "Incorrect Payment Screenshot",
    "Incomplete Transaction",
  ];

  const handleMenuClick = (e) => {
    setSelectedOption(e.key);

    setTimeout(() => {
      axios
        .put(`${CANCEL_ORDER_API_URL}/${orderId}`, paramsData)
        .then(() => {
          getVerifyingOrders();
          message.success("Order cancelled successfully.");
        })
        .catch((err) => {
          message.error(err?.response?.data?.error);
        });
    }, 700);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {options.map((option) => (
        <Menu.Item key={option}>{option}</Menu.Item>
      ))}
    </Menu>
  );

  useEffect(() => {
    getVerifyingOrders();
  }, []);

  return (
    <>
      <div className="card-body pt-4">
        <div className="table-responsive">
          <table className="table table-hover emp-attend-list">
            <thead>
              <tr>
                <th scope="col">Order Number</th>
                <th scope="col">Service</th>
                <th scope="col">Date Ordered</th>
                <th scope="col">Delivery Date</th>
                <th scope="col">Order Price</th>
                <th scope="col">Status</th>
                <th scope="col">Payment Proof</th>
                <th scope="col">Invoice</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredOrders?.map((order, i) => (
                <tr key={i}>
                  <td>{order?.orderNumber}</td>

                  <td>
                    {
                      <Link
                        to={
                          order?.status === "Verifying Payment"
                            ? "/verifying-payment"
                            : "/order-details"
                        }
                        style={{ textDecoration: "none", color: "#212529" }}
                        onClick={() => sOrderIdHandler(order._id)}
                      >{`${order?.service} (${order?.package})`}</Link>
                    }
                  </td>
                  <td>{formatDateString(order?.createdAt)}</td>
                  {order?.status === "Completed" ||
                  order?.status === "Incomplete" ||
                  order?.status === "Delivered" ||
                  order?.status === "Cancelled" ? (
                    <td>{formatDateString(order?.deliveryDate)}</td>
                  ) : calculateDaysLate(order?.deliveryDate) > 0 ? (
                    <td className="text-danger">
                      <i className="bi bi-clock"></i>{" "}
                      {`${calculateDaysLate(order?.deliveryDate)} Days Late`}
                    </td>
                  ) : (
                    <td>{formatDateString(order?.deliveryDate)}</td>
                  )}

                  <td>{formatCurrency(order?.totalPaidAmount)}</td>
                  <td>
                    <span
                      className={`d-inline-block badge ${getStatusColor(
                        order?.status
                      )}`}
                    >
                      {order?.status}
                    </span>
                  </td>
                  <td>
                    <div className="attachment-wrapper pt-2">
                      <div className="attach-thumb-wrapper d-flex flex-wrap align-items-center gap-3">
                        <div className="attach-thumb-item bg-light rounded-1 overflow-hidden">
                          <figure className="mb-0 bg-light d-flex align-items-center justify-content-center">
                            <i
                              className={getFileIcon(
                                `${BACKEND_URL}/${order?.paymentProof?.attachment[0]?.filePath}`
                              )}
                            />
                          </figure>
                          <figcaption className="d-flex justify-content-between align-items-center px-3">
                            <span className="small">
                              {truncateFileName(
                                order?.paymentProof?.attachment[0]?.filename,
                                10
                              )}{" "}
                              (
                              {formatFileSize(
                                order?.paymentProof?.attachment[0]?.filesize
                              )}
                              )
                            </span>
                            <span className="icons-wrapper">
                              <span
                                className="icon file-download secondary-text mx-2 opacity-75"
                                onClick={() =>
                                  handleDownloadAttachment(
                                    order?.paymentProof?.attachment[0]
                                      ?.filename,
                                    order?.paymentProof?.attachment[0]?.filePath
                                  )
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={16}
                                  height={16}
                                  fill="currentColor"
                                  className="bi bi-download"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                </svg>
                              </span>
                            </span>
                          </figcaption>
                        </div>

                        {/*.attach-thumb-item rounded-1 overflow-hidden*/}
                      </div>
                      {/*.attach-thumb-wrapper*/}
                    </div>
                  </td>
                  <td>
                    <Link
                      to="/paid-invoice"
                      className="preview-action small text-decoration-none fw-semibold d-flex align-items-center"
                      onClick={() => sInvoiceIdHandler(order?.invoiceNumber)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-eye me-1"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                      </svg>
                      View
                    </Link>
                  </td>
                  <td>
                    <Popconfirm
                      title="Confirm Approve"
                      description="Are you sure you want to approve this payment?"
                      onConfirm={() => confirm(order?._id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <button className="tb-action edit">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-check-square"
                          viewBox="0 0 16 16"
                        >
                          <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                          <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                        </svg>
                      </button>
                    </Popconfirm>
                    <Dropdown overlay={menu} trigger={["click"]}>
                      <button className="tb-action edit">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-x-square"
                          viewBox="0 0 16 16"
                          onClick={() => setOrderId(order?._id)}
                        >
                          <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                        </svg>
                      </button>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/*.edit-user-modal*/}
        </div>
        {/*.table-responsive*/}
      </div>
    </>
  );
}

export default VOTable;
