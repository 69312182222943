import React, { useContext } from "react";
import { InvoiceCtx } from "../../../context/Invoice";
import { formatCurrency } from "../../../utils/helpers";

function CriDetail() {
  const { crInvoice } = useContext(InvoiceCtx);

  return (
    <>
      <div className="card-body">
        <div className="row">
          <div className="col-12 text-end">
            <div>
              <p className="text-dark">
                Credit : {formatCurrency(crInvoice?.credit)}
              </p>
              <p className="text-dark">% VAT : $0.00</p>
              {crInvoice?.verifyingAmount && (
                <p className="text-dark">
                  Sum Incl. Free Credit :{" "}
                  {formatCurrency(crInvoice?.verifyingAmount)}
                </p>
              )}
            </div>
            <div className="total-payment border-top pt-3">
              <h3 className="h5">
                <b>
                  {crInvoice?.creditStatus === "Verifying"
                    ? "Amount to be Credited"
                    : crInvoice?.creditStatus === "Unverified"
                    ? "Total"
                    : "Credited Amount"}{" "}
                  :
                </b>{" "}
                {crInvoice?.verifyingAmount
                  ? formatCurrency(crInvoice?.verifyingAmount)
                  : formatCurrency(crInvoice?.credit)}
              </h3>
            </div>
          </div>
          {/*.col-grid*/}
        </div>
        {/*.row*/}
      </div>
    </>
  );
}

export default CriDetail;
