import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import AboutUs from "./pages/client/AboutUs";
import AccountSettings from "./pages/client/AccountSettings";
import AddCredits from "./pages/client/AddCredits";
import CancelledOrders from "./pages/client/CancelledOrders";
import Careers from "./pages/client/Careers";
import Charity from "./pages/client/Charity";
import ContactSupport from "./pages/client/ContactSupport";
import CurrentOrder from "./pages/client/CurrentOrder";
import Dashboard from "./pages/client/Dashboard";
import ManageUsers from "./pages/client/ManageUsers";
import OrderDetails from "./pages/client/OrderDetails";
import PlaceOrderPayment from "./pages/client/PlaceOrderPayment";
import PlaceOrderQuantity from "./pages/client/PlaceOrderQuantity";
import PublicFeedback from "./pages/client/PublicFeedback";
import Reviews from "./pages/client/Reviews";
import Services from "./pages/client/Services";
import SingleServiceDetails from "./pages/client/SingleServiceDetails";
import SubmitRequirements from "./pages/client/SubmitRequirements";
import TermsAndConditions from "./pages/client/TermsAndConditions";
import UnpaidInvoice from "./pages/client/UnpaidInvoice";
import ViewInvoices from "./pages/client/ViewInvoices";
import ActiveOrders from "./pages/admin/ActiveOrders";
import Admin from "./pages/admin/Admin";
import ClientOrdersList from "./pages/admin/ClientOrdersList";
import Clients from "./pages/admin/Clients";
import CompleteOrders from "./pages/admin/CompleteOrders";
import Invoices from "./pages/admin/Invoices";
import ManageUsersAdmin from "./pages/admin/ManageUsers";
import TotalOrders from "./pages/admin/TotalOrders";
import CancelledOrdersAdmin from "./pages/admin/CancelledOrders";
import ProtectedRoutes from "./components/misc/ProtectedRoutes";
import PageNotFound from "./pages/404";
import CreateAccount from "./pages/client/CreateAccount";
import ForgotPassword from "./pages/ForgotPassword";
import { useContext, useEffect } from "react";
import setAuthToken from "./utils/setAuthToken";
import { AuthContext } from "./context/Auth";
import OrderPaidInvoice from "./pages/client/OrderPaidInvoice";
import CompletedOrders from "./pages/client/CompletedOrders";
import AdminRoute from "./components/misc/AdminRoute";
import MakeOverduePayment from "./pages/client/MakeOverduePayment";
import ResetPassword from "./pages/client/ResetPassword";
import JobsForm from "./pages/admin/JobsForm";
import ContactForm from "./pages/admin/ContactForm";
import VerifyingPayment from "./pages/client/VerifyingPayment";
import VerifyingOrders from "./pages/admin/VerifyingOrders";
import Credit from "./pages/admin/Credit";
import VerifyingCredit from "./pages/client/VerifyingCredit";
import CreditConfirmation from "./pages/client/CreditConfirmation";
import VerifyingCredits from "./pages/admin/VerifyingCredits";
import AllCredits from "./pages/admin/AllCredits";
import CreditHistory from "./pages/client/CreditHistory";
import CreditInvoice from "./pages/client/CreditInvoice";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { stripePubKey } from "./config/env";

function App() {
  const token = localStorage.getItem("token");

  const { fetchUserInfo } = useContext(AuthContext);

  const stripePromise = loadStripe(stripePubKey);

  useEffect(() => {
    if (token) {
      setAuthToken(token);
      fetchUserInfo(token);
    }
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/signup" element={<CreateAccount />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />

        <Route element={<ProtectedRoutes />}>
          {/* Client Routes */}
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/account-settings" element={<AccountSettings />} />
          <Route path="/add-credits" element={<AddCredits />} />
          <Route path="/cancelled-orders" element={<CancelledOrders />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/charity" element={<Charity />} />
          <Route path="/contact-support" element={<ContactSupport />} />
          <Route path="/active-order" element={<CurrentOrder />} />
          <Route path="/completed-order" element={<CompletedOrders />} />
          <Route path="/dashboard" element={<Dashboard />} />
          {/* <Route path="/faqs" element={<Faqs />} /> */}
          <Route path="/manage-users" element={<ManageUsers />} />
          <Route path="/order-details" element={<OrderDetails />} />
          <Route path="/place-order-payment" element={<PlaceOrderPayment />} />

          <Route
            path="/make-overdue-payment"
            element={<MakeOverduePayment />}
          />
          <Route
            path="/place-order-quantity"
            element={<PlaceOrderQuantity />}
          />
          {/* <Route path="/privacy-policy" element={<PrivacyPolicy />} /> */}
          <Route path="/public-feedback" element={<PublicFeedback />} />
          {/* <Route path="/leave-a-tip" element={<LeaveATip />} /> */}
          <Route path="/reviews" element={<Reviews />} />
          <Route path="/services" element={<Services />} />
          <Route path="/:url" element={<SingleServiceDetails />} />
          <Route path="/submit-requirements" element={<SubmitRequirements />} />
          {/* <Route path="/team" element={<Team />} /> */}
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/view-invoices" element={<ViewInvoices />} />
          <Route path="/paid-invoice" element={<OrderPaidInvoice />} />
          <Route path="/unpaid-invoice" element={<UnpaidInvoice />} />
          <Route path="/verifying-payment" element={<VerifyingPayment />} />
          <Route path="/verifying-credit" element={<VerifyingCredit />} />
          <Route path="/credit-confirmation" element={<CreditConfirmation />} />
          <Route path="/credit-history" element={<CreditHistory />} />
          <Route path="/credit-invoice" element={<CreditInvoice />} />

          {/* Admin Routes */}
          <Route element={<AdminRoute />}>
            <Route path="/admin" element={<Admin />} />
            <Route path="/active-orders" element={<ActiveOrders />} />
            <Route
              path="/cancelled-orders-admin"
              element={<CancelledOrdersAdmin />}
            />
            {/* <Route path="/chat" element={<Chat />} />
          <Route path="/chat-client-user" element={<ChatClientUser />} /> */}
            <Route path="/client-orders-list" element={<ClientOrdersList />} />
            <Route path="/clients" element={<Clients />} />
            <Route path="/clients-credit" element={<Credit />} />
            <Route path="/credits" element={<AllCredits />} />
            <Route path="/complete-orders" element={<CompleteOrders />} />
            {/* <Route path="/invoice-details" element={<InvoiceDetails />} /> */}
            <Route path="/invoices" element={<Invoices />} />
            <Route path="/manage-users-admin" element={<ManageUsersAdmin />} />
            {/* <Route path="/order-status" element={<OrderStatus />} /> */}
            <Route path="/total-orders" element={<TotalOrders />} />
            <Route path="/jobs-form" element={<JobsForm />} />
            <Route path="/contact-form" element={<ContactForm />} />
            <Route path="/verifying-orders" element={<VerifyingOrders />} />
            <Route path="/verifying-credits" element={<VerifyingCredits />} />
          </Route>
        </Route>
      </Routes>
    </Elements>
  );
}

export default App;
