import React, { createContext, useContext } from "react";
import { DOWNLOAD_ATTACHMENT_API_URL } from "../config/endPoints";
import { Order } from "./Order";
import axios from "axios";
import { message } from "antd";

const UtilsCtx = createContext();

const UtilsContext = ({ children }) => {
  const { sOrderId, orderId } = useContext(Order);

  const reqOrderId = sOrderId ? sOrderId : orderId;

  const handleDownloadAttachment = async (fn, fp) => {
    try {
      const filepath = fp.replace(/^src\/data\//, "");

      const url = `${DOWNLOAD_ATTACHMENT_API_URL}/${reqOrderId}/download/${filepath}`;

      const res = await axios.get(url, { responseType: "blob" });

      if (res.status !== 200) {
        message.error(`Error downloading file: ${res.status}`);
        return;
      }

      const blob = new Blob([res.data], { type: res.headers["content-type"] });
      const link = document.createElement("a");
      link.download = fn;
      link.href = URL.createObjectURL(blob);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      message.error(err?.response?.data?.message);
      console.log(err);
    }
  };

  return (
    <UtilsCtx.Provider
      value={{
        handleDownloadAttachment,
      }}
    >
      {children}
    </UtilsCtx.Provider>
  );
};

export { UtilsContext, UtilsCtx };
