import React from "react";
import useService from "../../../hooks/useService";

function ServiceCtaSec() {
  const data = useService();

  return (
    <>
      <section className="cta-sec">
        <div className="row align-items-center justify-content-center">
          <div className="col-xl-8 col-lg-12 d-flex flex-wrap align-items-center justify-content-center text-center my-2">
            <h2 className="fs-5 d-inline-block my-3">
              Ready to place your {data?.attributes?.name} order?{" "}
            </h2>
            <a
              type="button"
              href="#place-order"
              className="btn oms-btn d-inline-block position-relative overflow-hidden mx-3"
            >
              Order Now
            </a>
          </div>
          {/*.col-grid*/}
        </div>
        {/*.row*/}
      </section>
    </>
  );
}

export default ServiceCtaSec;
