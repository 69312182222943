import React, { useContext } from "react";
import { AuthContext } from "../../../context/Auth";

function AdminHeading() {
  const { userInfo } = useContext(AuthContext);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-header py-2 mb-lg-2">
            <h4 className="my-1 fw-bold">
              {" "}
              Welcome {`${userInfo?.firstName} ${userInfo?.lastName},`}
            </h4>
          </div>
          {/*.page-header*/}
        </div>
        {/*.col-grid*/}
      </div>
    </>
  );
}

export default AdminHeading;
