import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "../../context/Auth";

function AdminRoute() {
  const { userInfo } = useContext(AuthContext);

  if (
    userInfo?.userType === "manager" ||
    userInfo?.userType === "client" ||
    userInfo?.userType === "tMember" ||
    userInfo?.userType === "clientTm" ||
    userInfo?.userType === "viewer"
  ) {
    return <Navigate to="/dashboard" />;
  }

  return <Outlet />;
}

export default AdminRoute;
