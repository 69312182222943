import React from "react";
import useService from "../../../hooks/useService";

function ServiceMainHeadings() {
  const data = useService();

  return (
    <>
      <section className="benefits-sec py-4 py-sm-5">
        <div className="row align-items-center justify-content-center mb-4">
          <div className="col-xl-7 col-lg-9">
            <div className="section-title-wrapper text-center">
              <h2 className="section-title fs-2 fw-semibold mb-md-4 mb-3">
                {data?.attributes?.sBHeading}
                {/* <span className="title-highlighted">
                  Benefits
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 500 150"
                    className="highlighted-svg"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M15.2,133.3L15.2,133.3c121.9-7.6,244-9.9,366.1-6.8c34.6,0.9,69.1,2.3,103.7,4"
                      style={{ animationPlayState: "running" }}
                    />
                  </svg>
                </span> */}
              </h2>
            </div>
            {/*.-section-title*/}
          </div>
          {/*.col-grid*/}
        </div>
        {/*.row*/}
        <div className="row">
          {data?.attributes?.serviceBenefits?.map((sb, i) => (
            <div className="col-md-6 col-lg-3">
              <div className="position-relative card-wrapper pb-2 pb-md-4 me-lg-3 mb-3">
                <h3 className="display-5 fw-semibold">0{i + 1}.</h3>
                <h4 className="fw-semibold">{sb?.heading}</h4>
                <p className="text-muted small">{sb?.description}</p>
              </div>
            </div>
          ))}

          {/*.col-grid*/}
        </div>
        {/*.row*/}
      </section>
    </>
  );
}

export default ServiceMainHeadings;
