import React, { useContext } from "react";
import {
  constructImageUrl,
  formatDate,
  formatDateTime,
} from "../../../utils/helpers";
import ActivityMessageBox from "./ActivityMessageBox";
import { Order } from "../../../context/Order";
import { AuthContext } from "../../../context/Auth";
import { MessageCtx } from "../../../context/Message";
import { RevisionCtx } from "../../../context/Revision";
import { Link } from "react-router-dom";
import { DeliveryCtx } from "../../../context/DeliverOrder";
import CombinedData from "./CombinedData";
import ReviewRating from "./ReviewRating";

function ActivityTab() {
  const { orderDetails, reqRevision } = useContext(Order);
  const { userInfo } = useContext(AuthContext);
  const { messages } = useContext(MessageCtx);
  const { revisions } = useContext(RevisionCtx);
  const { deliveries } = useContext(DeliveryCtx);

  const combinedActivities = [...deliveries, ...revisions, ...messages];

  const handleViewReqs = (e) => {
    e.preventDefault();
    const requirementsTabButton = document.getElementById("requirements-tab");
    if (requirementsTabButton) {
      requirementsTabButton.click();
    }
  };

  return (
    <>
      {orderDetails?.status === "Completed" &&
        !orderDetails?.feedback?.review &&
        (userInfo?.userType === "client" ||
          userInfo?.userType === "clientTm") && (
          <div className="card-body">
            <div className="msg-alert-box p-4 p-md-5 bg-white rounded-2 bg-opacity-50 mb-2">
              <div className="msg-alert-box-inner d-flex align-items-center flex-wrap justify-content-between">
                <div className="msg-alert-content pe-3">
                  <h5 className="fw-semibold">Your order was completed</h5>
                  <p>
                    We hope you're satisfied with the delivery. Share your
                    feedback with us!
                  </p>
                </div>
                <div className="btn-wrapper">
                  <Link to="/public-feedback">
                    <button className="btn primary-btn fw-semibold fs-6 px-4 py-2">
                      Rate experience
                    </button>
                  </Link>
                </div>
              </div>
              {/*.msg-alert-box-inner*/}
            </div>
            {/*.msg-alert-box*/}
          </div>
        )}
      {/*.card-body*/}
      <ul className="list-group border-0 rounded-0">
        <li className="list-group-item px-0 py-3 d-flex flex-wrap align-items-center border-0 border-bottom">
          <h4 className="h6 m-0 d-inline-flex align-items-center">
            <span className="icon bg-icon-style d-inline-flex rounded-pill justify-content-center align-items-center me-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-card-checklist"
                viewBox="0 0 16 16"
              >
                <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z" />
              </svg>
            </span>
            {userInfo?.userType === "tMember" ||
            userInfo?.userType === "manager" ||
            userInfo?.userType === "admin"
              ? "Client"
              : "You"}{" "}
            placed the order
          </h4>
          <span className="small d-inline-flex text-secondary ms-4 ps-2">
            {formatDateTime(orderDetails?.createdAt)}
          </span>
        </li>
        <li className="list-group-item px-0 py-3 d-flex flex-wrap align-items-center border-0 border-bottom">
          <h4 className="h6 m-0 d-inline-flex align-items-center">
            <span className="icon bg-icon-style d-inline-flex rounded-pill justify-content-center align-items-center me-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-pencil-square"
                viewBox="0 0 16 16"
              >
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                <path
                  fillRule="evenodd"
                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                />
              </svg>
            </span>
            {userInfo?.userType === "tMember" ||
            userInfo?.userType === "manager" ||
            userInfo?.userType === "admin"
              ? "Client"
              : "You"}{" "}
            submitted the requirements
          </h4>
          <span className="small d-inline-flex text-secondary ms-4 ps-2">
            {formatDateTime(orderDetails?.reqSubmitTime)}
          </span>
          <a
            href="#requirements-tab-pane"
            className="d-inline-block ms-auto small my-1"
            onClick={handleViewReqs}
          >
            View requirements
          </a>
        </li>
        <li className="list-group-item px-0 py-3 d-flex flex-wrap align-items-center border-0 border-bottom">
          <h4 className="h6 m-0 d-inline-flex align-items-center">
            <span className="icon bg-icon-style d-inline-flex rounded-pill justify-content-center align-items-center me-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-rocket-takeoff"
                viewBox="0 0 16 16"
              >
                <path d="M9.752 6.193c.599.6 1.73.437 2.528-.362.798-.799.96-1.932.362-2.531-.599-.6-1.73-.438-2.528.361-.798.8-.96 1.933-.362 2.532Z" />
                <path d="M15.811 3.312c-.363 1.534-1.334 3.626-3.64 6.218l-.24 2.408a2.56 2.56 0 0 1-.732 1.526L8.817 15.85a.51.51 0 0 1-.867-.434l.27-1.899c.04-.28-.013-.593-.131-.956a9.42 9.42 0 0 0-.249-.657l-.082-.202c-.815-.197-1.578-.662-2.191-1.277-.614-.615-1.079-1.379-1.275-2.195l-.203-.083a9.556 9.556 0 0 0-.655-.248c-.363-.119-.675-.172-.955-.132l-1.896.27A.51.51 0 0 1 .15 7.17l2.382-2.386c.41-.41.947-.67 1.524-.734h.006l2.4-.238C9.005 1.55 11.087.582 12.623.208c.89-.217 1.59-.232 2.08-.188.244.023.435.06.57.093.067.017.12.033.16.045.184.06.279.13.351.295l.029.073a3.475 3.475 0 0 1 .157.721c.055.485.051 1.178-.159 2.065Zm-4.828 7.475.04-.04-.107 1.081a1.536 1.536 0 0 1-.44.913l-1.298 1.3.054-.38c.072-.506-.034-.993-.172-1.418a8.548 8.548 0 0 0-.164-.45c.738-.065 1.462-.38 2.087-1.006ZM5.205 5c-.625.626-.94 1.351-1.004 2.09a8.497 8.497 0 0 0-.45-.164c-.424-.138-.91-.244-1.416-.172l-.38.054 1.3-1.3c.245-.246.566-.401.91-.44l1.08-.107-.04.039Zm9.406-3.961c-.38-.034-.967-.027-1.746.163-1.558.38-3.917 1.496-6.937 4.521-.62.62-.799 1.34-.687 2.051.107.676.483 1.362 1.048 1.928.564.565 1.25.941 1.924 1.049.71.112 1.429-.067 2.048-.688 3.079-3.083 4.192-5.444 4.556-6.987.183-.771.18-1.345.138-1.713a2.835 2.835 0 0 0-.045-.283 3.078 3.078 0 0 0-.3-.041Z" />
                <path d="M7.009 12.139a7.632 7.632 0 0 1-1.804-1.352A7.568 7.568 0 0 1 3.794 8.86c-1.102.992-1.965 5.054-1.839 5.18.125.126 3.936-.896 5.054-1.902Z" />
              </svg>
            </span>
            {userInfo?.userType === "tMember" ||
            userInfo?.userType === "manager" ||
            userInfo?.userType === "admin"
              ? "Order"
              : "Your order"}{" "}
            started
          </h4>
          <span className="small d-inline-flex text-secondary ms-4 ps-2">
            {formatDateTime(orderDetails?.reqSubmitTime)}
          </span>
        </li>
        <li className="list-group-item px-0 py-3 d-flex flex-wrap align-items-center border-0 border-bottom">
          <h4 className="h6 m-0 d-inline-flex align-items-center">
            <span className="icon bg-icon-style d-inline-flex rounded-pill justify-content-center align-items-center me-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-clock"
                viewBox="0 0 16 16"
              >
                <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
              </svg>
            </span>
            {userInfo?.userType === "tMember" ||
            userInfo?.userType === "manager" ||
            userInfo?.userType === "admin"
              ? "Delivery"
              : "Your delivery"}{" "}
            date was updated to {formatDate(orderDetails?.deliveryDate)}
          </h4>
          <span className="small d-inline-flex text-secondary ms-4 ps-2">
            {formatDateTime(orderDetails?.delvDateUpdTime)}
          </span>
        </li>

        <div className="msgs-wrapper">
          {combinedActivities?.length > 0 && <CombinedData />}
          {!reqRevision && <ActivityMessageBox />}
          {orderDetails?.status === "Completed" &&
            orderDetails?.feedback?.review && <ReviewRating />}
        </div>
      </ul>
      {orderDetails?.status === "Completed" && (
        <div
          className="alert alert-success bg-white d-flex gap-3 shadow-sm border-0 border-start border-4 border-success align-items-center py-2"
          role="alert"
        >
          <span className="icon-img">
            {/* <img src="./assets/images/user-2.jpg.png" alt /> */}
            {userInfo?.imageUrl ? (
              <img src={constructImageUrl(userInfo?.imageUrl)} />
            ) : (
              <div className="user-avator-order secondary-bg rounded-circle d-flex align-items-center justify-content-center text-uppercase fw-semibold text-white me-2">
                <span className="name-txt">
                  {`${
                    userInfo?.firstName
                      ? userInfo?.firstName.charAt(0).toUpperCase()
                      : ""
                  }${
                    userInfo?.lastName
                      ? userInfo?.lastName.charAt(0).toUpperCase()
                      : ""
                  }`}
                </span>
              </div>
            )}
          </span>
          <div className="alert-text">
            {userInfo?.userType === "admin" ||
            userInfo?.userType === "manager" ||
            userInfo?.userType === "tMember" ? (
              <span>
                Your order has been successfully completed. Great job!
              </span>
            ) : (
              <span>
                Your order is complete. If you have any questions, please
                contact our <Link to="/contact-support">Support Team</Link>.
              </span>
            )}
          </div>
        </div>
      )}
      {orderDetails?.status === "Cancelled" && (
        <div
          className="alert alert-danger bg-white d-flex gap-3 shadow-sm border-0 border-start border-4 border-danger align-items-center py-2"
          role="alert"
        >
          <span className="icon-img">
            {/* <img src="./assets/images/user-2.jpg.png" alt /> */}
            {userInfo?.imageUrl ? (
              <img src={constructImageUrl(userInfo?.imageUrl)} />
            ) : (
              <div className="user-avator-order secondary-bg rounded-circle d-flex align-items-center justify-content-center text-uppercase fw-semibold text-white me-2">
                <span className="name-txt">
                  {`${
                    userInfo?.firstName
                      ? userInfo?.firstName.charAt(0).toUpperCase()
                      : ""
                  }${
                    userInfo?.lastName
                      ? userInfo?.lastName.charAt(0).toUpperCase()
                      : ""
                  }`}
                </span>
              </div>
            )}
          </span>
          <div className="alert-text">
            {userInfo?.userType === "admin" ||
            userInfo?.userType === "manager" ||
            userInfo?.userType === "tMember" ? (
              <span>Unfortunately, this order has been canceled.</span>
            ) : (
              <span>
                Your order is cancelled. If you have any questions, please
                contact our <Link to="/contact-support">Support Team</Link>.
              </span>
            )}
          </div>
        </div>
      )}
      {/*.msgs-wrapper*/}
    </>
  );
}

export default ActivityTab;
