import React, { useEffect } from "react";
import Footer from "../../components/layout/Footer";
import ClientHeader from "../../components/layout/client/ClientHeader";
import TNCHeading from "../../components/client/termsAndConditions/TNCHeading";
import TNCContent from "../../components/client/termsAndConditions/TNCContent";
import { Helmet } from "react-helmet";

function TermsAndConditions() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Terms of Service – AAMAX</title>
      </Helmet>
      {/*=============== Start AAMAX OMS  ===============*/}

      <ClientHeader>
        {/*.header*/}
        <main className="page-content d-flex flex-column justify-content-between h-100">
          <div className="container-fluid p-3 p-md-4">
            <TNCHeading />
            {/*.row*/}
            <TNCContent />
            {/*.row*/}
          </div>
          {/*.container-fluid*/}
          <Footer />
        </main>
      </ClientHeader>
      {/*.page-content"*/}
      {/*=============== End AAMAX OMS  ===============*/}
    </>
  );
}

export default TermsAndConditions;
