import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/Auth";
import { Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";

function Home() {
  const { login, loading, userInfo } = useContext(AuthContext);

  const token = localStorage.getItem("token");

  const [data, setData] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });

  const handleChange = (e) => {
    if (e.target.type === "checkbox") {
      setData({ ...data, [e.target.name]: e.target.checked });
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();
    login(data);
  };

  if (token && !loading && userInfo?.userType) {
    return (
      <Navigate
        to={userInfo?.userType === "admin" ? "/total-orders" : "/dashboard"}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>Login – AAMAX</title>
      </Helmet>
      {/*====================== Start Sign in Form ======================*/}
      <section className="oms-authentication py-md-5 py-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-7 col-xl-5">
              <div className="form-wrapper py-md-4">
                <div className="logo-wrapper text-center mb-md-5 mb-4 pb-lg-4 pb-2">
                  <Link to="/">
                    <img src="assets/images/logo-dark.png" alt="aamax" />
                  </Link>
                </div>
                <form className="sign-in-form">
                  <div className="form-title text-center mb-md-5 mb-4">
                    <h3 className="display-6 fw-semibold mb-md-3 mb-2">
                      Sign in to your account
                    </h3>
                    <p className="text-muted">
                      Welcome back! Please enter your credentials to sign in
                    </p>
                  </div>
                  <div className="input-group mb-3 rounded-2 overflow-hidden border">
                    <span
                      className="input-group-text bg-light text-muted border-0 px-3"
                      id="basic-addon1"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={22}
                        height={22}
                        fill="currentColor"
                        className="bi bi-envelope"
                        viewBox="0 0 16 16"
                      >
                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                      </svg>
                    </span>
                    <input
                      name="email"
                      id="email"
                      type="email"
                      className="form-control bg-white border-0 px-3"
                      placeholder="info@example.com"
                      value={data.email}
                      onChange={handleChange}
                      autofocus
                    />
                  </div>
                  <div className="input-group mb-3 rounded-2 overflow-hidden border">
                    <span
                      className="input-group-text text-muted bg-light border-0 px-3"
                      id="basic-addon1"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={22}
                        height={22}
                        fill="currentColor"
                        className="bi bi-key"
                        viewBox="0 0 16 16"
                      >
                        <path d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8zm4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5z" />
                        <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                      </svg>
                    </span>
                    <input
                      name="password"
                      id="password"
                      type="password"
                      className="form-control form-control-lg bg-white border-0 px-3"
                      placeholder="Password"
                      value={data.password}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="remember"
                        name="rememberMe"
                        onChange={handleChange}
                        checked={data.rememberMe}
                      />
                      <label
                        className="form-check-label text-secondary"
                        htmlFor="remember"
                      >
                        <small>Remember Me</small>
                      </label>
                    </div>
                    <Link
                      to="/forgot-password"
                      className="text-decoration-none"
                    >
                      <small>Forgot password?</small>
                    </Link>
                  </div>
                  {/* <Link to="/dashboard"> */}
                  <p className="text-muted small text-center">
                    Need help? Watch our quick{" "}
                    <a
                      href="https://www.youtube.com/watch?v=rjZf__vVqBs&ab_channel=AAMAX"
                      target="_blank"
                    >
                      tutorial video
                    </a>{" "}
                    to master the AAMAX app in minutes!
                  </p>
                  <button
                    // type="submit"
                    className="btn primary-btn shadow w-100 mb-2"
                    disabled={loading}
                    onClick={handleLogin}
                  >
                    {loading && (
                      <span className="spinner-grow spinner-grow-sm me-2"></span>
                    )}
                    {!loading ? "Sign in" : "Signing In..."}
                  </button>
                  {/* </Link> */}
                  <p className="mt-4 text-center text-muted">
                    Don’t have an account?{" "}
                    <Link
                      to="/signup"
                      className="text-decoration-none text-decoration-underline create-account-action"
                    >
                      Sign up here
                    </Link>
                  </p>
                </form>
              </div>
              {/*.form-wrapper*/}
            </div>
            {/*.col-grid*/}
          </div>
          {/*.row*/}
        </div>
        {/*.container*/}
      </section>
      {/*====================== End Sign in Form ======================*/}
    </>
  );
}

export default Home;
