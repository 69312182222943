import React from "react";

function COLHeading() {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-header py-2 mb-lg-2">
            <h4 className="my-1 fw-bold">John Doe Orders List</h4>
          </div>
          {/*.page-header*/}
        </div>
        {/*.col-grid*/}
      </div>
    </>
  );
}

export default COLHeading;
