import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { SidebarNavContext } from "./context/SidebarNavContext";
import { AuthenticationContext } from "./context/Auth";
import { ApolloProvider } from "@apollo/client";
import client from "./config/apolloClient";
import { OrderCartContext } from "./context/OrderCart";
import { OrderContext } from "./context/Order";
import { LoadingLogoContext } from "./context/LoadingLogo";
import { SearchContext } from "./context/SearchContext";
import { LiveChatContext } from "./context/LiveChat";
import { MessageContext } from "./context/Message";
import { UtilsContext } from "./context/UtilsContext";
import { RevisionContext } from "./context/Revision";
import { DeliveryContext } from "./context/DeliverOrder";
import { InvoiceContext } from "./context/Invoice";
import { CareerContext } from "./context/Career";
import { ContactContext } from "./context/Contact";
import { CreditContext } from "./context/Credit";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <LoadingLogoContext>
        <SidebarNavContext>
          <AuthenticationContext>
            <OrderCartContext>
              <OrderContext>
                <SearchContext>
                  <LiveChatContext>
                    <MessageContext>
                      <UtilsContext>
                        <RevisionContext>
                          <DeliveryContext>
                            <InvoiceContext>
                              <CareerContext>
                                <ContactContext>
                                  <CreditContext>
                                    <React.StrictMode>
                                      <App />
                                    </React.StrictMode>
                                  </CreditContext>
                                </ContactContext>
                              </CareerContext>
                            </InvoiceContext>
                          </DeliveryContext>
                        </RevisionContext>
                      </UtilsContext>
                    </MessageContext>
                  </LiveChatContext>
                </SearchContext>
              </OrderContext>
            </OrderCartContext>
          </AuthenticationContext>
        </SidebarNavContext>
      </LoadingLogoContext>
    </ApolloProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
