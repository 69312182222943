import React, { useContext } from "react";
import countries from "../../../utils/countries.json";
import Select from "react-select";
import { AuthContext } from "../../../context/Auth";
import wtAxios from "../../../utils/wtAxios";
import { message } from "antd";
import { cloudName, uploadPreset } from "../../../config/env";
import { constructImageUrl } from "../../../utils/helpers";

function AdminProfile() {
  const {
    userInfo,
    updateUser,
    confirmPassword,
    setConfirmPassword,
    loading,
    updateUserInfo,
    setUpdateUserInfo,
    cancelUpdate,
  } = useContext(AuthContext);

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === "confirmPassword") {
      setConfirmPassword(value);
    } else {
      setUpdateUserInfo({ ...updateUserInfo, [id]: value });
    }
    userInfo[id] = value;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateUser(updateUserInfo);
  };

  const selectCountry = (sc) => {
    setUpdateUserInfo({ ...updateUserInfo, country: sc.value });
  };

  function handleImageUpload(e) {
    const file = e.target.files[0];

    if (file?.size > 2 * 1024 * 1024) {
      message.error("Only 2MB image is allowed!");
      return;
    }

    const fileName = file?.name;
    const ext = fileName
      ?.substring(fileName?.lastIndexOf(".") + 1)
      .toLowerCase();

    const allowedExtensions = [
      "jpg",
      "jpeg",
      "png",
      "gif",
      "JPEG",
      "JPG",
      "PNG",
      "GIF",
    ];

    if (!allowedExtensions.includes(ext)) {
      message.error("Only image files (JPEG, JPG, PNG, GIF) are allowed.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", uploadPreset);
    const messageDuration = file.size > 500 * 1024 ? 2 : 1;
    message.info("Updating Profile Image", messageDuration);
    wtAxios
      .post(
        `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
        formData
      )
      .then((res) => {
        const imgUrl = res.data.secure_url;
        const updatedUserInfo = {
          ...updateUserInfo,
          imageUrl: imgUrl,
        };
        updateUser(updatedUserInfo);
      })
      .catch((error) => {
        console.error("Error uploading image: ", error);
      });
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-card rounded-3 position-relative p-3 p-md-4">
            <div className="card-body">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-3 col-lg-2">
                    <div className="card-header py-2">
                      <div className="d-flex flex-column">
                        <div className="user-img-edit mb-2">
                          <div className="user-img-wrapper profile-img mb-2">
                            <label className="label-overlay" htmlFor="file">
                              <span className="icon">
                                <i className="bi bi-camera" />
                              </span>
                              <span className="overylay-txt">
                                {userInfo?.imageUrl ? "Change" : "Upload"}
                              </span>
                            </label>
                            <input
                              id="file"
                              type="file"
                              onChange={handleImageUpload}
                            />
                            {userInfo?.imageUrl ? (
                              <img
                                src={constructImageUrl(userInfo?.imageUrl)}
                                className="rounded-pill user-img"
                                id="pfImg"
                                width={110}
                                height={110}
                                alt
                              />
                            ) : (
                              <span className="user-name-txt">
                                {`${
                                  userInfo?.firstName
                                    ? userInfo.firstName.charAt(0).toUpperCase()
                                    : ""
                                }${
                                  userInfo?.lastName
                                    ? userInfo.lastName.charAt(0).toUpperCase()
                                    : ""
                                }`}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="admin-info-text">
                          <h5 className="mb-1">{`${userInfo?.firstName} ${userInfo?.lastName}`}</h5>
                        </div>
                      </div>
                    </div>
                    {/*.card-header*/}
                  </div>
                  <div className="col-md-9 col-lg-10">
                    <div className="card-body position-relative ps-md-5">
                      <a
                        href="#"
                        className="edit-icon text-dark position-absolute top-0 end-0 translate-middle p-2 d-inline-flex rounded-pill mt-2"
                        data-bs-toggle="modal"
                        data-bs-target="#editProfileModal"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={12}
                          height={12}
                          fill="currentColor"
                          className="bi bi-pencil"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                        </svg>
                      </a>
                      <ul className="user-info list-unstyled m-0">
                        <li className="d-flex flex-wrap flex-column flex-md-row flex-md-nowrap border-0 flex-wrap flex-md-nowrap py-2">
                          <span className="title fw-semibold">Email :</span>
                          <span className="text">{userInfo?.email}</span>
                        </li>
                        <li className="d-flex flex-wrap flex-column flex-md-row flex-md-nowrap border-0 py-2">
                          <span className="title fw-semibold">Phone :</span>
                          <span className="text">{userInfo?.phone}</span>
                        </li>
                        <li className="d-flex flex-wrap flex-column flex-md-row flex-md-nowrap border-0 py-2">
                          <span className="title fw-semibold">Role :</span>
                          <span className="text">Admin</span>
                        </li>
                        <li className="d-flex flex-wrap flex-column flex-md-row flex-md-nowrap border-0 py-2">
                          <span className="title fw-semibold">Address :</span>
                          <span className="text">{userInfo?.address}</span>
                        </li>
                        <li className="d-flex flex-wrap flex-column flex-md-row flex-md-nowrap border-0 py-2">
                          <span className="title fw-semibold">Country :</span>
                          <span className="text">{userInfo?.country}</span>
                        </li>
                      </ul>
                      {/* Edit Profile */}
                      <div
                        className="modal fade"
                        id="editProfileModal"
                        tabIndex={-1}
                        aria-labelledby="editProfileModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                          <div className="modal-content">
                            <div className="modal-header dark-bg px-4">
                              <h1
                                className="modal-title fs-5 text-white"
                                id="cardPaymentModalLabel"
                              >
                                My Profile
                              </h1>
                              <button
                                type="button"
                                className="btn-close btn-close-white"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              />
                            </div>
                            <div className="modal-body px-4">
                              <form className="needs-validation mt-lg-3">
                                <div className="row g-3">
                                  <div className="col-sm-6">
                                    <label
                                      htmlFor="firstName"
                                      className="form-label small fw-semibold"
                                    >
                                      First name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="firstName"
                                      placeholder
                                      defaultValue
                                      required
                                      onChange={handleChange}
                                      value={
                                        userInfo?.firstName
                                          ? userInfo?.firstName
                                          : ""
                                      }
                                    />
                                  </div>
                                  <div className="col-sm-6">
                                    <label
                                      htmlFor="lastName"
                                      className="form-label small fw-semibold"
                                    >
                                      Last name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="lastName"
                                      placeholder
                                      defaultValue
                                      required
                                      onChange={handleChange}
                                      value={
                                        userInfo?.lastName
                                          ? userInfo?.lastName
                                          : ""
                                      }
                                    />
                                  </div>
                                  <div className="col-sm-6">
                                    <label
                                      htmlFor="email"
                                      className="form-label small fw-semibold"
                                    >
                                      Email
                                    </label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      id="email"
                                      placeholder
                                      defaultValue
                                      onChange={handleChange}
                                      value={
                                        userInfo?.email ? userInfo?.email : ""
                                      }
                                    />
                                  </div>
                                  <div className="col-sm-6">
                                    <label
                                      htmlFor="phone"
                                      className="form-label small fw-semibold"
                                    >
                                      Phone Number
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="phone"
                                      placeholder
                                      defaultValue
                                      required
                                      onChange={handleChange}
                                      value={
                                        userInfo?.phone ? userInfo?.phone : ""
                                      }
                                    />
                                  </div>
                                  <div className="col-12">
                                    <label
                                      htmlFor="address"
                                      className="form-label small fw-semibold"
                                    >
                                      Address
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="address"
                                      placeholder="1234 Main St"
                                      required
                                      onChange={handleChange}
                                      value={
                                        userInfo?.address
                                          ? userInfo?.address
                                          : ""
                                      }
                                    />
                                  </div>

                                  <div className="col-md-6">
                                    <label
                                      htmlFor="country"
                                      className="form-label small fw-semibold"
                                    >
                                      Country
                                    </label>
                                    <Select
                                      options={countries.map((country) => ({
                                        label: country.name,
                                        value: country.name,
                                      }))}
                                      onChange={selectCountry}
                                      isSearchable
                                      placeholder={
                                        userInfo?.country
                                          ? userInfo?.country
                                          : "Select your country"
                                      }
                                    />
                                  </div>
                                  <div className="col-sm-6">
                                    <label
                                      htmlFor="password"
                                      className="form-label small fw-semibold"
                                    >
                                      Password
                                    </label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      id="password"
                                      placeholder
                                      defaultValue
                                      required
                                      onChange={handleChange}
                                      value={
                                        updateUserInfo.password
                                          ? updateUserInfo.password
                                          : ""
                                      }
                                    />
                                  </div>
                                  <div className="col-sm-6">
                                    <label
                                      htmlFor="confirmPassword"
                                      className="form-label small fw-semibold"
                                    >
                                      Confirm password
                                    </label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      id="confirmPassword"
                                      placeholder
                                      defaultValue
                                      required
                                      onChange={handleChange}
                                      value={confirmPassword}
                                    />
                                  </div>
                                </div>
                                {/*.row*/}
                                {/* / Change address form*/}
                                <div className="btn-wrapper text-end mb-3 mt-4 mt-lg-5">
                                  <a
                                    href="#"
                                    className="fs-6 btn btn-light me-2 px-4 py-2 text-decoration-none"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={cancelUpdate}
                                  >
                                    Cancel
                                  </a>
                                  <button
                                    onClick={handleSubmit}
                                    className="btn primary-btn fw-semibold fs-6 px-4 py-2"
                                  >
                                    {loading && (
                                      <span className="spinner-grow spinner-grow-sm me-2"></span>
                                    )}
                                    {!loading ? "Update" : "Updating..."}
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*.edit-profile-modal*/}
                    </div>
                    {/*.card-body*/}
                  </div>
                </div>
              </div>
              {/*.container-fluid*/}
            </div>
            {/*.card-body*/}
          </div>
          {/*.page-card*/}
        </div>
        {/*.col-grid*/}
      </div>
    </>
  );
}

export default AdminProfile;
