import { useContext, useEffect, useState } from "react";
import client from "../config/apolloClient";

import { LoadingContext } from "../context/LoadingLogo";
import { ABOUT_US_QUERY } from "../graphql/queries/AboutQuery";

function useAbout() {
  const { setLogoLoading } = useContext(LoadingContext);

  const [data, setData] = useState({});

  const fetchData = async () => {
    try {
      setLogoLoading(true);

      const { data } = await client.query({ query: ABOUT_US_QUERY });
      setData(data?.aboutUs?.data);
      setLogoLoading(false);
    } catch (error) {
      setLogoLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return data;
}

export default useAbout;
