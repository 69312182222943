import React, { useContext } from "react";
import Footer from "../../components/layout/Footer";
import ClientHeader from "../../components/layout/client/ClientHeader";
import MUsersMainHeading from "../../components/client/manageUsers/MUsersMainHeading";
import MUsersSearchBox from "../../components/client/manageUsers/MUsersSearchBox";
import MUsersDetail from "../../components/client/manageUsers/MUsersDetail";
import { AuthContext } from "../../context/Auth";
import NoUsers from "../../components/client/manageUsers/NoUsers";
import NotAllowedToAddUsers from "../../components/client/manageUsers/NotAllowedToAddUsers";
import { Helmet } from "react-helmet";

function ManageUsers() {
  const { addedUsers, userInfo } = useContext(AuthContext);

  return (
    <>
      <Helmet>
        <title>Manage Users – AAMAX</title>
      </Helmet>
      {/*=============== Start AAMAX OMS  ===============*/}

      <ClientHeader>
        {/*.header*/}
        <main className="page-content d-flex flex-column justify-content-between h-100">
          <div className="container-fluid p-3 p-md-4">
            {userInfo?.userType === "viewer" ||
            userInfo?.userType === "clientTm" ? (
              <NotAllowedToAddUsers />
            ) : addedUsers?.length === 0 ? (
              <NoUsers />
            ) : (
              <>
                <MUsersMainHeading />
                {/*.row*/}
                <div className="row">
                  <div className="col-12">
                    <div className="page-card rounded-3 position-relative p-3 p-md-4">
                      <div className="card-header p-3 bg-light">
                        <MUsersSearchBox />
                        {/*.row*/}
                      </div>
                      {/*.card-header*/}
                      <MUsersDetail />
                      {/*.card-body*/}
                    </div>
                    {/*.page-card*/}
                  </div>
                  {/*.col-grid*/}
                </div>
                {/*.row*/}
              </>
            )}
          </div>
          {/*.container-fluid*/}
          <Footer />
        </main>
      </ClientHeader>
      {/*.page-content"*/}
      {/*=============== End AAMAX OMS  ===============*/}
    </>
  );
}

export default ManageUsers;
