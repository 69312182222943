import React, { useContext, useEffect, useState } from "react";
import { Order } from "../../../context/Order";
import {
  calculateDaysLate,
  formatCurrency,
  formatDate,
  getSRStatusColor,
} from "../../../utils/helpers";
import client from "../../../config/apolloClient";
import { SERVICE_IMAGE_QUERY } from "../../../graphql/queries/ServiceImageQuery";
import { AuthContext } from "../../../context/Auth";

function OrderDetailOverview() {
  const { getOrderDetails, orderDetails } = useContext(Order);
  const { userInfo } = useContext(AuthContext);

  const [sImageUrl, setSImageUrl] = useState("");

  const packageUrl = orderDetails?.packageUrl;

  const getServiceImage = async () => {
    const { data } = await client.query({
      query: SERVICE_IMAGE_QUERY,
      variables: {
        url: packageUrl,
      },
    });
    setSImageUrl(data?.package?.data?.attributes?.image?.data?.attributes?.url);
  };

  useEffect(() => {
    getOrderDetails();
  }, []);

  useEffect(() => {
    if (packageUrl) {
      getServiceImage();
    }
  }, [packageUrl]);

  return (
    <>
      <div className="col-lg-4 col-md-12">
        <div className="page-card rounded-3 position-relative p-3 p-md-4 mt-lg-0 mt-4">
          <div className="card-body">
            <div className="card border-0">
              <div className="d-flex align-items-center">
                <img
                  className="rounded-pill me-3 fit-user-img"
                  src={sImageUrl}
                  alt={orderDetails?.service}
                  width={60}
                  height={60}
                />
                <div>
                  <h4 className="h6 fw-semibold card-title mb-2">
                    {orderDetails?.service}
                  </h4>
                  <span
                    className={`badge ${getSRStatusColor(
                      orderDetails?.status
                    )} small text-uppercase`}
                  >
                    {orderDetails?.status}
                  </span>
                </div>
              </div>
              <div className="card-body px-0 py-4 pb-0">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item px-0 d-flex justify-content-between align-items-center">
                    <span className="card-service-label">Order Number</span>
                    <span className="card-service-value">
                      {orderDetails?.orderNumber}
                    </span>
                  </li>
                  <li className="list-group-item px-0 d-flex justify-content-between align-items-center">
                    <span className="card-service-label">Order Date</span>
                    <span className="card-service-value">
                      {formatDate(orderDetails?.createdAt)}
                    </span>
                  </li>
                  <li className="list-group-item px-0 d-flex justify-content-between align-items-center">
                    <span className="card-service-label">Delivery Date</span>

                    <span className="card-service-value">
                      {orderDetails?.status === "Completed" ||
                      orderDetails?.status === "Incomplete" ||
                      orderDetails?.status === "Delivered" ||
                      orderDetails?.status === "Cancelled" ? (
                        formatDate(orderDetails?.deliveryDate)
                      ) : calculateDaysLate(orderDetails?.deliveryDate) > 0 ? (
                        <span className="text-danger">
                          <i className="bi bi-clock"></i>{" "}
                          {`${calculateDaysLate(
                            orderDetails?.deliveryDate
                          )} Days Late`}
                        </span>
                      ) : (
                        formatDate(orderDetails?.deliveryDate)
                      )}
                    </span>
                  </li>
                  <li className="list-group-item px-0 d-flex justify-content-between align-items-center">
                    <span className="card-service-label">Quantity</span>
                    <span className="card-service-value">
                      X {orderDetails?.quantity}
                    </span>
                  </li>
                  {(userInfo?.userType === "admin" ||
                    userInfo?.userType === "client" ||
                    userInfo?.userType === "clientTm" ||
                    userInfo?.userType === "viewer") && (
                    <li className="list-group-item px-0 d-flex justify-content-between align-items-center">
                      <span className="card-service-label">Total Price</span>
                      <span className="card-service-value">
                        {formatCurrency(orderDetails?.totalPaidAmount)}
                      </span>
                    </li>
                  )}
                </ul>
              </div>
            </div>
            {/*.card*/}
          </div>
          {/*.card-body*/}
        </div>
        {/*.page-card*/}
      </div>
    </>
  );
}

export default OrderDetailOverview;
