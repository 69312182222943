import React, { useContext, useEffect } from "react";
import ClientHeader from "../../components/layout/client/ClientHeader";
import Footer from "../../components/layout/Footer";
import AccountSettingsMainHeading from "../../components/client/accountSettings/AccountSettingsMainHeading";
import ClientAccountInfo from "../../components/client/accountSettings/ClientAccountInfo";
import ClientBillingInfo from "../../components/client/accountSettings/ClientBillingInfo";
import { AuthContext } from "../../context/Auth";
import { Helmet } from "react-helmet";

function AccountSettings() {
  const { fetchUserInfo, userInfo } = useContext(AuthContext);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      fetchUserInfo(token);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {`${userInfo?.firstName} ${userInfo?.lastName} Profile`} – AAMAX
        </title>
      </Helmet>
      {/*=============== Start AAMAX OMS  ===============*/}
      {/*.aside*/}
      <ClientHeader>
        {/*.header*/}
        <main className="page-content d-flex flex-column justify-content-between h-100">
          <div className="container-fluid p-3 p-md-4">
            <AccountSettingsMainHeading />
            {/*.row*/}
            <div className="row">
              <div className="col-12">
                <div className="page-card rounded-3 position-relative p-3 p-md-4">
                  <div className="card-body">
                    <div className="container-fluid">
                      <ClientAccountInfo />
                      <hr className="my-4 my-md-5 border-secondary" />
                      <ClientBillingInfo />
                    </div>
                    {/*.container-fluid*/}
                  </div>
                  {/*.card-body*/}
                </div>
                {/*.page-card*/}
              </div>
              {/*.col-grid*/}
            </div>
            {/*.row*/}
          </div>
          {/*.container-fluid*/}
          <Footer />
        </main>
      </ClientHeader>
      {/*.page-content"*/}
      {/*=============== End AAMAX OMS  ===============*/}
    </>
  );
}

export default AccountSettings;
