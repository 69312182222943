import React, { useContext, useEffect } from "react";
import {
  formatDateString,
  formatFileSize,
  getFileIcon,
  truncateFileName,
} from "../../../utils/helpers";
import { CareerCtx } from "../../../context/Career";
import setAuthToken from "../../../utils/setAuthToken";
import { BACKEND_URL } from "../../../config/endPoints";
import { UtilsCtx } from "../../../context/UtilsContext";

function JobFormTable() {
  const { data, fetchData } = useContext(CareerCtx);
  const { handleDownloadAttachment } = useContext(UtilsCtx);

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      setAuthToken(token);
      fetchData(token);
    }
  }, []);
  return (
    <>
      <div className="card-body pt-4">
        <div className="table-responsive">
          <table className="table table-hover emp-attend-list">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone</th>
                <th scope="col">Job Title</th>
                <th scope="col">Applied On</th>
                <th scope="col">CV</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((d, i) => (
                <tr key={i}>
                  <td>{d?.name}</td>
                  <td>{d?.email}</td>
                  <td>{d?.phone}</td>
                  <td>{d?.jobTitle}</td>
                  <td>{formatDateString(d?.createdAt)}</td>
                  <td>
                    {d?.cv?.attachment[0]?.filePath ? (
                      <div className="attachment-wrapper pt-2">
                        <div className="attach-thumb-wrapper d-flex flex-wrap align-items-center gap-3">
                          <div className="attach-thumb-item bg-light rounded-1 overflow-hidden">
                            <figure className="mb-0 bg-light d-flex align-items-center justify-content-center">
                              <i
                                className={getFileIcon(
                                  `${BACKEND_URL}/${d?.cv?.attachment[0]?.filePath}`
                                )}
                              />
                            </figure>
                            <figcaption className="d-flex justify-content-between align-items-center px-3">
                              <span className="small">
                                {truncateFileName(
                                  d?.cv?.attachment[0]?.filename,
                                  10
                                )}{" "}
                                (
                                {formatFileSize(d?.cv?.attachment[0]?.filesize)}
                                )
                              </span>
                              <span className="icons-wrapper">
                                <span
                                  className="icon file-download secondary-text mx-2 opacity-75"
                                  onClick={() =>
                                    handleDownloadAttachment(
                                      d?.cv?.attachment[0]?.filename,
                                      d?.cv?.attachment[0]?.filePath
                                    )
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={16}
                                    height={16}
                                    fill="currentColor"
                                    className="bi bi-download"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                  </svg>
                                </span>
                              </span>
                            </figcaption>
                          </div>

                          {/*.attach-thumb-item rounded-1 overflow-hidden*/}
                        </div>
                        {/*.attach-thumb-wrapper*/}
                      </div>
                    ) : (
                      "CV document not uploaded"
                    )}
                  </td>
                </tr>
              ))}{" "}
            </tbody>
          </table>

          {/*.edit-user-modal*/}
        </div>
        {/*.table-responsive*/}
      </div>
    </>
  );
}

export default JobFormTable;
