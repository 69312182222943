import React, { useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ExpandedLink } from "../../../context/SidebarNavContext";
import { AuthContext } from "../../../context/Auth";

function AdminSidebar() {
  const { logout } = useContext(AuthContext);
  const { expandedItem, setExpandedItem } = useContext(ExpandedLink);

  const location = useLocation();
  const activePath = location.pathname;

  const ordersUrlsToCheck = [
    "/total-orders",
    "/active-orders",
    "/complete-orders",
    "/cancelled-orders-admin",
  ];

  const creditUrlsToCheck = ["/clients-credit", "/verifying-credits"];

  const ordersNestedPaths = ordersUrlsToCheck.some((url) =>
    activePath.includes(url)
  );
  const creditNestedPaths = creditUrlsToCheck.some((url) =>
    activePath.includes(url)
  );

  const handleCollapse = (id) => {
    if (expandedItem === id) {
      setExpandedItem(null);
    } else {
      setExpandedItem(id);
    }
  };

  return (
    <>
      <aside className="position-fixed top-0 start-0">
        <div className="sidebar-wrapper h-100 d-flex flex-column">
          <div className="logo-wrapper">
            <a className="navbar-brand d-block" href="#">
              <img
                className="img-fluid px-3 logo"
                src="../assets/images/logo.png"
                alt
              />
              <img
                className="img-fluid px-3 logo-icon"
                src="../assets/images/logo-icon.png"
                alt
              />
            </a>
            {/*.navbar-brand*/}
          </div>
          <div className="sidebar-menu-wrapper py-4 my-2 flex-fill d-flex flex-column justify-content-between">
            <ul
              className="sidebar-listing list-unstyled px-3 mb-0 pb-4"
              id="collapse"
            >
              <li className="nav-item">
                <a
                  className={`${
                    expandedItem === "ordersCollapseAdmin" || ordersNestedPaths
                      ? "nav-link active d-flex align-items-center rounded collapsed mb-1"
                      : "nav-link d-flex align-items-center rounded collapsed mb-1"
                  } `}
                  data-bs-toggle="collapse"
                  href="#ordersCollapseAdmin"
                  role="button"
                  aria-expanded={
                    expandedItem === "ordersCollapseAdmin" ? "true" : "false"
                  }
                  aria-controls="chartsCollapse"
                  onClick={() => handleCollapse("ordersCollapseAdmin")}
                >
                  <span className="icon d-inline-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      fill="currentColor"
                      className="bi bi-cart2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l1.25 5h8.22l1.25-5H3.14zM5 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
                    </svg>
                  </span>
                  <span className="sidebar-item ms-2">Orders</span>
                  <span className="sidebar-arrow d-inline-flex ms-auto">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={14}
                      height={14}
                      fill="currentColor"
                      className="bi bi-chevron-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </span>
                </a>
                <ul
                  className={
                    expandedItem === "ordersCollapseAdmin"
                      ? "collapse show list-unstyled ps-0"
                      : "collapse list-unstyled ps-0"
                  }
                  id="ordersCollapseAdmin"
                  data-bs-parent="#collapse"
                >
                  <li className="nav-item ps-2">
                    <NavLink
                      to="/total-orders"
                      className={({ isActive }) =>
                        isActive
                          ? "nav-link active d-flex align-items-center px-3 py-2"
                          : "nav-link d-flex align-items-center px-3 py-2"
                      }
                    >
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          fill="currentColor"
                          className="bi bi-card-list"
                          viewBox="0 0 16 16"
                        >
                          <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                          <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                        </svg>
                      </span>
                      <span className="sidebar-item">Total Orders</span>
                    </NavLink>
                  </li>
                  <li className="nav-item ps-2">
                    <NavLink
                      to="/active-orders"
                      className={({ isActive }) =>
                        isActive
                          ? "nav-link active d-flex align-items-center px-3 py-2"
                          : "nav-link d-flex align-items-center px-3 py-2"
                      }
                    >
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          fill="currentColor"
                          className="bi bi-card-checklist"
                          viewBox="0 0 16 16"
                        >
                          <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                          <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z" />
                        </svg>
                      </span>
                      <span className="sidebar-item">Active Orders</span>
                    </NavLink>
                  </li>
                  <li className="nav-item ps-2">
                    <NavLink
                      to="/complete-orders"
                      className={({ isActive }) =>
                        isActive
                          ? "nav-link active d-flex align-items-center px-3 py-2"
                          : "nav-link d-flex align-items-center px-3 py-2"
                      }
                    >
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          fill="currentColor"
                          className="bi bi-bag-check"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                          />
                          <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z" />
                        </svg>
                      </span>
                      <span className="sidebar-item">Complete Orders</span>
                    </NavLink>
                  </li>

                  <li className="nav-item ps-2">
                    <NavLink
                      to="/cancelled-orders-admin"
                      className={({ isActive }) =>
                        isActive
                          ? "nav-link active d-flex align-items-center px-3 py-2"
                          : "nav-link d-flex align-items-center px-3 py-2"
                      }
                    >
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={14}
                          height={14}
                          fill="currentColor"
                          className="bi bi-x-square"
                          viewBox="0 0 16 16"
                        >
                          <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                      </span>
                      <span className="sidebar-item">Cancelled Orders</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              {/*.nav-item*/}

              <li className="nav-item">
                <NavLink
                  to="/verifying-orders"
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link active d-flex align-items-center rounded mb-1"
                      : "nav-link d-flex align-items-center rounded mb-1"
                  }
                >
                  <span className="icon d-inline-flex">
                    {/*?xml version="1.0" ?*/}
                    <img
                      src="../assets/images/verifying.png"
                      style={{ width: "16px", height: "16px" }}
                    />
                  </span>
                  <span className="sidebar-item ms-2">Verifying Payments</span>
                </NavLink>
                {/*.nav-link*/}
              </li>
              <li className="nav-item">
                <NavLink
                  to="/invoices"
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link active d-flex align-items-center rounded mb-1"
                      : "nav-link d-flex align-items-center rounded mb-1"
                  }
                >
                  <span className="icon d-inline-flex">
                    {/*?xml version="1.0" ?*/}
                    <svg
                      fill="none"
                      height={16}
                      strokeWidth="1.5"
                      viewBox="0 0 24 24"
                      width={16}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 11H14.5H17"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 7H14.5H17"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8 15V3.6C8 3.26863 8.26863 3 8.6 3H20.4C20.7314 3 21 3.26863 21 3.6V17C21 19.2091 19.2091 21 17 21V21"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5 15H8H12.4C12.7314 15 13.0031 15.2668 13.0298 15.5971C13.1526 17.1147 13.7812 21 17 21H8H6C4.34315 21 3 19.6569 3 18V17C3 15.8954 3.89543 15 5 15Z"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  <span className="sidebar-item ms-2">Invoices</span>
                </NavLink>
                {/*.nav-link*/}
              </li>
              {/*.nav-item*/}
              <li className="nav-item">
                <NavLink
                  to="/clients"
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link active d-flex align-items-center rounded mb-1"
                      : "nav-link d-flex align-items-center rounded mb-1"
                  }
                  href="#"
                >
                  <span className="icon d-inline-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      fill="currentColor"
                      className="bi bi-people"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8Zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816ZM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z" />
                    </svg>
                  </span>
                  <span className="sidebar-item ms-2">Clients</span>
                </NavLink>
                {/*.nav-link*/}
              </li>
              <li className="nav-item">
                <a
                  className={`${
                    expandedItem === "creditsCollapseAdmin" || creditNestedPaths
                      ? "nav-link active d-flex align-items-center rounded mb-1"
                      : "nav-link d-flex align-items-center rounded collapsed mb-1"
                  } `}
                  data-bs-toggle="collapse"
                  href="#creditsCollapseAdmin"
                  role="button"
                  aria-expanded={
                    expandedItem === "creditsCollapseAdmin" ? "true" : "false"
                  }
                  aria-controls="chartsCollapse"
                  onClick={() => handleCollapse("creditsCollapseAdmin")}
                >
                  <span className="icon d-inline-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      fill="currentColor"
                      className="bi bi-credit-card-2-back"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1z" />
                      <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm13 2v5H1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm-1 9H2a1 1 0 0 1-1-1v-1h14v1a1 1 0 0 1-1 1z" />
                    </svg>
                  </span>
                  <span className="sidebar-item ms-2">Credit</span>
                  <span className="sidebar-arrow d-inline-flex ms-auto">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={14}
                      height={14}
                      fill="currentColor"
                      className="bi bi-chevron-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </span>
                </a>
                <ul
                  className={
                    expandedItem === "creditsCollapseAdmin"
                      ? "collapse show list-unstyled ps-0"
                      : "collapse list-unstyled ps-0"
                  }
                  id="creditsCollapseAdmin"
                  data-bs-parent="#collapse"
                >
                  <li className="nav-item ps-2">
                    <NavLink
                      to="/clients-credit"
                      className={({ isActive }) =>
                        isActive
                          ? "nav-link active d-flex align-items-center px-3 py-2"
                          : "nav-link d-flex align-items-center px-3 py-2"
                      }
                    >
                      <span className="icon d-inline-flex">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          fill="currentColor"
                          className="bi bi-wallet2"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499L12.136.326zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484L5.562 3zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z" />
                        </svg>
                      </span>
                      <span className="sidebar-item">Clients' Credit</span>
                    </NavLink>
                  </li>
                  <li className="nav-item ps-2">
                    <NavLink
                      to="/credits"
                      className={({ isActive }) =>
                        isActive
                          ? "nav-link active d-flex align-items-center px-3 py-2"
                          : "nav-link d-flex align-items-center px-3 py-2"
                      }
                    >
                      <span className="icon d-inline-flex">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-coin"
                          viewBox="0 0 16 16"
                        >
                          <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518z" />
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                          <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11m0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12" />
                        </svg>
                      </span>
                      <span className="sidebar-item">Credits</span>
                    </NavLink>
                  </li>
                  <li className="nav-item ps-2">
                    <NavLink
                      to="/verifying-credits"
                      className={({ isActive }) =>
                        isActive
                          ? "nav-link active d-flex align-items-center px-3 py-2"
                          : "nav-link d-flex align-items-center px-3 py-2"
                      }
                    >
                      <span className="icon d-inline-flex">
                        <img
                          src="../assets/images/verifying.png"
                          style={{ width: "16px", height: "16px" }}
                        />
                      </span>
                      <span className="sidebar-item">Verifying Credit</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/manage-users-admin"
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link active d-flex align-items-center rounded mb-1"
                      : "nav-link d-flex align-items-center rounded mb-1"
                  }
                >
                  <span className="icon d-inline-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      fill="currentColor"
                      className="bi bi-people"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8Zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816ZM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z" />
                    </svg>
                  </span>
                  <span className="sidebar-item ms-2">Manage Team</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/contact-form"
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link active d-flex align-items-center rounded mb-1"
                      : "nav-link d-flex align-items-center rounded mb-1"
                  }
                >
                  <span className="icon d-inline-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-chat-right-dots"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2 1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h9.586a2 2 0 0 1 1.414.586l2 2V2a1 1 0 0 0-1-1zm12-1a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z" />
                      <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                    </svg>
                  </span>
                  <span className="sidebar-item ms-2">Contact Form</span>
                </NavLink>
              </li>
              {/*.nav-item*/}
              <li className="nav-item">
                <NavLink
                  to="/jobs-form"
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link active d-flex align-items-center rounded mb-1"
                      : "nav-link d-flex align-items-center rounded mb-1"
                  }
                >
                  <span className="icon d-inline-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      fill="currentColor"
                      className="bi bi-briefcase"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
                    </svg>
                  </span>
                  <span className="sidebar-item ms-2">Jobs Form</span>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  to="/admin"
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link active d-flex align-items-center rounded mb-1"
                      : "nav-link d-flex align-items-center rounded mb-1"
                  }
                >
                  <span className="icon d-inline-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      fill="currentColor"
                      className="bi bi-person-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                      <path
                        fillRule="evenodd"
                        d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                      />
                    </svg>
                  </span>
                  <span className="sidebar-item ms-2">Admin</span>
                </NavLink>
                {/*.nav-link*/}
              </li>
              {/*.nav-item*/}
            </ul>
            <ul className="sidebar-listing list-unstyled px-3 mb-0">
              {/*.nav-item*/}
              <li className="nav-item">
                <a
                  className="nav-link d-flex align-items-center rounded mb-1"
                  onClick={() => {
                    logout();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <span className="icon d-inline-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      fill="currentColor"
                      className="bi bi-box-arrow-in-left"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 3.5a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 1 1 0v2A1.5 1.5 0 0 1 9.5 14h-8A1.5 1.5 0 0 1 0 12.5v-9A1.5 1.5 0 0 1 1.5 2h8A1.5 1.5 0 0 1 11 3.5v2a.5.5 0 0 1-1 0v-2z"
                      />
                      <path
                        fillRule="evenodd"
                        d="M4.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H14.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"
                      />
                    </svg>
                  </span>
                  <span className="sidebar-item ms-2">Sign Out</span>
                </a>
                {/*.nav-link*/}
              </li>
              {/*.nav-item*/}
            </ul>
          </div>
        </div>
        {/*.sidebar-wrapper*/}
      </aside>
    </>
  );
}

export default AdminSidebar;
