import React, { useContext, useEffect } from "react";
import { BACKEND_URL } from "../../../config/endPoints";
import { AuthContext } from "../../../context/Auth";
import {
  constructImageUrl,
  convertToHTML,
  formatDateTime,
  formatFileSize,
  getFileIcon,
  truncateFileName,
} from "../../../utils/helpers";
import { UtilsCtx } from "../../../context/UtilsContext";
import { RevisionCtx } from "../../../context/Revision";
import io from "socket.io-client";
import { Order } from "../../../context/Order";

function Revision() {
  const { userInfo } = useContext(AuthContext);
  const { handleDownloadAttachment } = useContext(UtilsCtx);
  const { revisions, setRevisions, getRevisions } = useContext(RevisionCtx);
  const { getOrderDetails } = useContext(Order);

  useEffect(() => {
    const socket = io(BACKEND_URL);

    socket.on("newRevision", (data) => {
      setRevisions((prevRevision) => [...prevRevision, data.delivery]);
      getRevisions();
      getOrderDetails();
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <>
      <>
        <ul className="list-group border-0 rounded-0">
          {revisions?.map((r, i) => (
            <li
              className="list-group-item px-0 py-3 d-flex flex-wrap align-items-center border-0 border-bottom"
              key={i}
            >
              <a
                className=""
                data-bs-toggle="collapse"
                href={`#collapse${i}`}
                role="button"
                aria-expanded="true"
                aria-controls={`collapse${i}`}
              >
                <h4 className="h6 m-0 d-inline-flex align-items-center">
                  <span class="icon bg-icon-style d-inline-flex rounded-pill justify-content-center align-items-center me-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-arrow-counterclockwise"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2z"
                      />
                      <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466" />
                    </svg>
                  </span>
                  <strong className="me-1 secondary-text d-inline-block">
                    Revision Request
                  </strong>
                  #{i + 1}
                </h4>

                <span className="small d-inline-flex text-secondary ms-4 ps-2">
                  {formatDateTime(r?.timestamp)}
                </span>
              </a>
              <div className="collapse w-100 mt-2 show" id={`collapse${i}`}>
                <div className="card card-body rounded-2 border-0 py-0">
                  <div className="devivery-item-wrapper">
                    <div className="delivery-item">
                      <div className="d-flex pt-3">
                        <div>
                          {r?.user?.imageUrl ? (
                            <img
                              className="rounded-circle me-3"
                              src={constructImageUrl(r?.user?.imageUrl)}
                              width={40}
                              height={40}
                            />
                          ) : (
                            <div className="user-avator-order secondary-bg rounded-circle d-flex align-items-center justify-content-center text-uppercase fw-semibold text-white me-3">
                              <span className="name-txt">
                                {`${
                                  r?.user?.firstName
                                    ? r?.user?.firstName.charAt(0).toUpperCase()
                                    : ""
                                }${
                                  r?.user?.lastName
                                    ? r?.user?.lastName.charAt(0).toUpperCase()
                                    : ""
                                }`}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="mt-2 pe-sm-4">
                          <h4 className="h6 d-flex flex-column justify-content-start">
                            <span className="d-block name lh-sm">
                              {userInfo?._id === r?.user?._id
                                ? "Me"
                                : `${r?.user?.firstName} ${r?.user?.lastName}`}
                            </span>
                          </h4>

                          <p
                            className="small"
                            dangerouslySetInnerHTML={convertToHTML(
                              r?.description
                            )}
                          />

                          {r?.deliveryAttachments?.length > 0 && (
                            <div className="attachment-wrapper pt-2">
                              <span className="h6 d-inline-block mb-3">
                                {r?.deliveryAttachments?.length === 1
                                  ? "Attachment"
                                  : "Attachments"}
                              </span>
                              <div className="attach-thumb-wrapper d-flex flex-wrap align-items-center gap-3">
                                {r?.deliveryAttachments?.map((ra, i) => (
                                  <div
                                    className="attach-thumb-item rounded-1 overflow-hidden"
                                    key={i}
                                  >
                                    <figure className="mb-0 bg-light d-flex align-items-center justify-content-center">
                                      <i
                                        className={getFileIcon(
                                          `${BACKEND_URL}/${ra?.filePath}`
                                        )}
                                      />
                                    </figure>
                                    <figcaption className="d-flex justify-content-between align-items-center px-3">
                                      <span className="small">
                                        {truncateFileName(ra?.filename, 10)} (
                                        {formatFileSize(ra?.filesize)})
                                      </span>
                                      <span className="icons-wrapper">
                                        <span
                                          className="icon file-download secondary-text mx-2 opacity-75"
                                          onClick={() =>
                                            handleDownloadAttachment(
                                              ra?.filename,
                                              ra?.filePath
                                            )
                                          }
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={16}
                                            height={16}
                                            fill="currentColor"
                                            className="bi bi-download"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                          </svg>
                                        </span>
                                      </span>
                                    </figcaption>
                                  </div>
                                ))}
                                {/*.attach-thumb-item*/}

                                {/*.attach-thumb-item rounded-1 overflow-hidden*/}
                              </div>

                              {/*.attach-thumb-wrapper*/}
                            </div>
                          )}
                          {r?.revisionAttachments?.length > 0 && (
                            <div className="attachment-wrapper pt-2">
                              <span className="h6 d-inline-block mb-3">
                                {r?.revisionAttachments?.length === 1
                                  ? "Attachment"
                                  : "Attachments"}
                              </span>
                              <div className="attach-thumb-wrapper d-flex flex-wrap align-items-center gap-3">
                                {r?.revisionAttachments?.map((ra, i) => (
                                  <div
                                    className="attach-thumb-item rounded-1 overflow-hidden"
                                    key={i}
                                  >
                                    <figure className="mb-0 bg-light d-flex align-items-center justify-content-center">
                                      <i
                                        className={getFileIcon(
                                          `${BACKEND_URL}/${ra?.filePath}`
                                        )}
                                      />
                                    </figure>
                                    <figcaption className="d-flex justify-content-between align-items-center px-3">
                                      <span className="small">
                                        {truncateFileName(ra?.filename, 10)} (
                                        {formatFileSize(ra?.filesize)})
                                      </span>
                                      <span className="icons-wrapper">
                                        <span
                                          className="icon file-download secondary-text mx-2 opacity-75"
                                          onClick={() =>
                                            handleDownloadAttachment(
                                              ra?.filename,
                                              ra?.filePath
                                            )
                                          }
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={16}
                                            height={16}
                                            fill="currentColor"
                                            className="bi bi-download"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                          </svg>
                                        </span>
                                      </span>
                                    </figcaption>
                                  </div>
                                ))}
                                {/*.attach-thumb-item*/}

                                {/*.attach-thumb-item rounded-1 overflow-hidden*/}
                              </div>

                              {/*.attach-thumb-wrapper*/}
                            </div>
                          )}
                          {/*.ttachment-wrapper*/}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </>
    </>
  );
}

export default Revision;
