import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function ServiceDetailSkeleton() {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-card rounded-3 position-relative p-3 p-md-4">
            <div className="card-body">
              <div className="row g-xl-5 align-items-center">
                <div className="col-lg-6">
                  <div className="left-content-wrapper position-relative">
                    <div className="video-wrapper rounded">
                      <Skeleton height={370} />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="content-wrapper my-3">
                    <h2 className="fs-3 fw-semibold mb-3">
                      <Skeleton height={33} />
                    </h2>
                    <div className="mb-3">
                      {" "}
                      <Skeleton count={8} height={24} />
                    </div>
                    <div className="position-relative mt-lg-4 mt-2 overflow-hidden">
                      <Skeleton height={46} width={146} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*.table-responsive*/}
    </>
  );
}

export default ServiceDetailSkeleton;
