import React, { useContext } from "react";
import { InvoiceCtx } from "../../../context/Invoice";
import { formatDateString } from "../../../utils/helpers";

function ClientBInfo() {
  const { invoice } = useContext(InvoiceCtx);

  return (
    <>
      <div className="card-header">
        {/* <div className="row">
          <div className="col-12">
            <div className="date-invocie text-end mb-4">
              <h3 className="h5">22 April 2018</h3>
            </div>
          </div>
        </div> */}
        {/*.row*/}
        <div className="row invoice-info">
          <div className="col-md-6 invoice-col">
            {/* <strong className="small mb-2 d-block">From</strong> */}
            <address>
              <strong className="h4 fw-semibold d-block mb-2">AAMAX</strong>
              <span className="small text-secondary">
                2nd Floor College House, 17 King Edwards Road, Ruislip, United
                Kingdom, HA4 7AE
              </span>
              <br />
              <strong className="small">Phone: </strong>+92-308-4281241{" "}
              <strong className="small">Email: </strong>
              info@aamax.co
            </address>
          </div>
          {/*.col-grid*/}
          <div className="col-md-6 text-md-end">
            {/* <strong className="small mb-2 d-block">To</strong> */}
            <address>
              <strong className="h4 fw-semibold d-block mb-2">
                {`${invoice?.user?.firstName} ${invoice?.user?.lastName}`}
              </strong>
              <span className="small text-secondary">
                {invoice?.user?.company && `${invoice.user.company}, `}
                {invoice?.user?.address && `${invoice.user.address}, `}
                {invoice?.user?.state && `${invoice.user.state}, `}
                {invoice?.user?.zipCode && `${invoice.user.zipCode}, `}
                {invoice?.user?.country && `${invoice.user.country}`}
              </span>
              <br />
              {invoice?.user?.phone && (
                <>
                  <strong className="small">Phone: </strong>
                  <span>{invoice.user.phone}</span>
                </>
              )}{" "}
              {invoice?.user?.email && (
                <>
                  <strong className="small">Email: </strong>
                  <span>{invoice.user.email}</span>
                </>
              )}
            </address>
          </div>
          {/*.col-grid*/}
          <div className="col-12">
            <div className="invoice-details bg-light border px-3 py-2 mb-3 mt-3">
              <div className="row">
                <div className="col-md-6 col-lg-3">
                  <span className="small">
                    <b>Invoice Number: </b>
                    {invoice?.invoiceNumber}
                  </span>
                </div>
                <div className="col-md-6 col-lg-3">
                  <span className="small">
                    <b>Order Number:</b> {invoice?.orderNumber}
                  </span>
                </div>
                <div className="col-md-6 col-lg-3">
                  <span className="small">
                    <b>Paid On:</b> {formatDateString(invoice?.createdAt)}
                  </span>
                </div>
                <div className="col-md-6 col-lg-3">
                  <span className="small">
                    <b>Paid Via:</b> {invoice?.paymentMethod}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/*.col-grid*/}
        </div>
        {/*.row*/}
      </div>
    </>
  );
}

export default ClientBInfo;
