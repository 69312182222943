import React from "react";
import Footer from "../../components/layout/Footer";
import AdminHeader from "../../components/layout/admin/AdminHeader";
import TOSearch from "../../components/admin/totalOrders/TOSearch";
import VOTable from "../../components/admin/verifyingOrders/VOTable";
import { Helmet } from "react-helmet";

function VerifyingOrders() {
  return (
    <>
      <Helmet>
        <title>Verifying Orders – AAMAX</title>
      </Helmet>
      {/*=============== Start AAMAX OMS  ===============*/}
      <AdminHeader>
        {/*.header*/}
        <main className="page-content d-flex flex-column justify-content-between h-100">
          <div className="container-fluid p-3 p-md-4">
            {/*.row*/}
            <div className="row">
              <div className="col-12">
                <div className="page-card rounded-3 position-relative p-3 p-md-4">
                  <TOSearch />
                  {/*.card-header*/}
                  <VOTable />
                  {/*.card-body*/}
                </div>
                {/*.page-card*/}
              </div>
              {/*.col-grid*/}
            </div>
            {/*.row*/}
          </div>
          {/*.container-fluid*/}
          <Footer />
        </main>
      </AdminHeader>
      {/*.page-content"*/}
      {/*=============== End AAMAX OMS  ===============*/}
    </>
  );
}

export default VerifyingOrders;
