import React, { useContext, useEffect } from "react";
import Footer from "../../components/layout/Footer";
import ClientHeader from "../../components/layout/client/ClientHeader";
import CreditHistoryTable from "../../components/client/creditHistory/CreditHistoryTable";
import { CreditCtx } from "../../context/Credit";
import setAuthToken from "../../utils/setAuthToken";
import NoCreditHistory from "../../components/client/creditHistory/NoCreditHistory";
import { Helmet } from "react-helmet";

function CreditHistory() {
  const { credits, getCredits } = useContext(CreditCtx);

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      setAuthToken(token);
      getCredits(token);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Credit History – AAMAX</title>
      </Helmet>
      {/*=============== Start AAMAX OMS  ===============*/}
      <ClientHeader>
        {/*.header*/}
        <main className="page-content d-flex flex-column justify-content-between h-100">
          <div className="container-fluid p-3 p-md-4">
            {/*.row*/}
            {credits?.length === 0 ? (
              <NoCreditHistory />
            ) : (
              <div className="row">
                <div className="col-12">
                  <div className="page-card rounded-3 position-relative p-3 p-md-4">
                    {/* <ACSearch /> */}
                    {/*.card-header*/}
                    <CreditHistoryTable />
                    {/*.card-body*/}
                  </div>
                  {/*.page-card*/}
                </div>
                {/*.col-grid*/}
              </div>
            )}
            {/*.row*/}
          </div>
          {/*.container-fluid*/}
          <Footer />
        </main>
      </ClientHeader>
      {/*.page-content"*/}
      {/*=============== End AAMAX OMS  ===============*/}
    </>
  );
}

export default CreditHistory;
