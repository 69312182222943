import React, { useContext, useEffect } from "react";
import { formatDateString, truncateString } from "../../../utils/helpers";
import setAuthToken from "../../../utils/setAuthToken";
import { ContactCtx } from "../../../context/Contact";

function ContactTable() {
  const { data, fetchData } = useContext(ContactCtx);

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      setAuthToken(token);
      fetchData(token);
    }
  }, []);
  return (
    <>
      <div className="card-body pt-4">
        <div className="table-responsive">
          <table className="table table-hover emp-attend-list">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone</th>
                <th scope="col">Website</th>
                <th scope="col">Subject</th>
                <th scope="col">Message</th>
                <th scope="col">Received On</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((d, i) => (
                <tr key={i}>
                  <td>{d?.name}</td>
                  <td>{d?.email}</td>
                  <td>{d?.phone ? d?.phone : "–"}</td>
                  <td>{d?.website}</td>
                  <td>{d?.subject ? truncateString(d?.subject, 5) : "–"}</td>
                  <td>{truncateString(d?.message, 8)}</td>
                  <td>{formatDateString(d?.createdAt)}</td>
                </tr>
              ))}{" "}
            </tbody>
          </table>

          {/*.edit-user-modal*/}
        </div>
        {/*.table-responsive*/}
      </div>
    </>
  );
}

export default ContactTable;
