import React, { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { validateEmail, validatePassword } from "../utils/helpers";
import {
  GET_ADMIN_USERS_API_URL,
  GET_CLIENTS_API_URL,
  GET_CREDITS_API_URL,
  GET_USER_INFO_API_URL,
  LOGIN_API_URL,
  REGISTER_API_URL,
  UPDATE_ADMIN_USER_API_URL,
  UPDATE_CLIENT_API_URL,
  UPDATE_CLIENT_USER_INFO_API_URL,
  UPDATE_USER_INFO_API_URL,
} from "../config/endPoints";
import { message } from "antd";
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwtDecode from "jwt-decode";

const AuthContext = createContext();

const AuthenticationContext = ({ children }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [addedUsers, setAddedUsers] = useState([]);
  const [clients, setClients] = useState([]);
  const [credits, setCredits] = useState([]);
  const [updateUserInfo, setUpdateUserInfo] = useState(userInfo);
  const [addTeamMember, setAddTeamMember] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    userType: "",
    userId: userInfo?._id,
  });
  const [adminUser, setAdminUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    userType: "",
    department: "",
    image: [],
    userId: userInfo?._id,
  });

  const [adminUsers, setAdminUsers] = useState([]);
  const [redirectUrl, setRedirectUrl] = useState("");

  const token = localStorage.getItem("token");

  const login = (data) => {
    const { email, password } = data;

    if (!validateEmail(email)) {
      message.error("Please enter a valid email address.");
      return;
    }

    if (!validatePassword(password)) {
      message.error("Password must be at least 8 characters.");
      return;
    }
    setLoading(true);
    axios
      .post(LOGIN_API_URL, data)
      .then((res) => {
        const token = res.data.token;
        if (token) {
          setAuthToken(token);
          localStorage.setItem("token", token);
          const redirectPath = redirectUrl
            ? redirectUrl
            : res?.data?.userType === "admin"
            ? "/total-orders"
            : "/dashboard";
          navigate(redirectPath);
          fetchUserInfo();
        }
        message.success(res.data.message);
        setLoading(false);
        setRedirectUrl("");
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.response.data.error);
        console.log(err);
      });
  };

  const register = (data) => {
    const { firstName, lastName, email, country, accountType, password } = data;

    if (
      !firstName ||
      !lastName ||
      !email ||
      !country ||
      !accountType ||
      !password
    ) {
      message.error("Hold on! Please fill in all the fields.");
      return;
    }

    if (!validateEmail(email)) {
      message.error("Oops! Please provide a valid email address.");
      return;
    }

    if (!validatePassword(password)) {
      message.error("Password should be at least 8 characters long.");
      return;
    }
    if (data.password !== confirmPassword) {
      message.error("Uh-oh! The password and confirm password do not match.");
      return;
    }
    setLoading(true);
    axios
      .post(REGISTER_API_URL, data)
      .then((res) => {
        const token = res.data.token;
        if (token) {
          setAuthToken(token);
          localStorage.setItem("token", token);
          const redirectPath = redirectUrl ? redirectUrl : "/dashboard";
          navigate(redirectPath);
        }
        message.success(res.data.message);
        setLoading(false);
        setRedirectUrl("");
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.response.data.error);
        console.log(err);
      });
  };

  const addUser = (data) => {
    const { firstName, lastName, email, password } = data;

    if (!firstName || !lastName || !email || !password) {
      message.error("Please fill in all fields.");
      return;
    }

    if (!validateEmail(email)) {
      message.error("Please enter a valid email address.");
      return;
    }

    if (!validatePassword(password)) {
      message.error("Password must be at least 8 characters.");
      return;
    }
    if (data.password !== confirmPassword) {
      message.error("Passwords do not match");
      return;
    }
    setLoading(true);
    axios
      .post(REGISTER_API_URL, data)
      .then((res) => {
        message.success(res.data.message);
        setAddTeamMember({
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          userType: "",
          userId: userInfo?._id,
        });
        setTimeout(() => {
          const cancelButton = document.querySelector(".btn-light");
          if (cancelButton) {
            cancelButton.click();
          }
          setLoading(false);
          fetchUserInfo(token);
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.response.data.error);
        console.log(err);
      });
  };

  const addAdminUser = (data) => {
    const { firstName, lastName, email, password, image } = data;

    if (!firstName || !lastName || !email || !password) {
      message.error("Please fill in all fields.");
      return;
    }

    if (!validateEmail(email)) {
      message.error("Please enter a valid email address.");
      return;
    }

    if (!validatePassword(password)) {
      message.error("Password must be at least 8 characters.");
      return;
    }

    const formData = new FormData();

    for (const key in data) {
      if (key === "image") {
        if (image && Array.isArray(image) && image.length > 1) {
          message.error("Please provide a single image file.");
          return;
        }
        if (image && image.length === 1) {
          formData.append("images", image[0]);
        }
      } else {
        formData.append(key, data[key]);
      }
    }

    setLoading(true);
    axios
      .post(REGISTER_API_URL, formData)
      .then((res) => {
        message.success(res.data.message);
        setAdminUser({
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          userType: "",
          department: "",
          image: [],
          userId: userInfo?._id,
        });
        setTimeout(() => {
          const cancelButton = document.querySelector(".btn-light");
          if (cancelButton) {
            cancelButton.click();
          }
          setLoading(false);
          getAdminUsers(token);
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.response.data.error);
        console.log(err);
      });
  };

  const updateCUser = (data, sUid) => {
    const { email, password } = data;

    if (email && !validateEmail(email)) {
      message.error("Please enter a valid email address.");
      return;
    }

    if (password && !validatePassword(password)) {
      message.error("Password must be at least 8 characters.");
      return;
    }

    setLoading(true);
    axios
      .patch(`${UPDATE_CLIENT_USER_INFO_API_URL}/${sUid}`, data)
      .then((res) => {
        message.success(res.data.message);
        setTimeout(() => {
          const cancelButton = document.querySelector(
            "#editUserModal .btn-light"
          );
          if (cancelButton) {
            cancelButton.click();
          }
          setLoading(false);
          fetchUserInfo(token);
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.response.data.error);
        console.log(err);
      });
  };

  const fetchUserInfo = async (token) => {
    try {
      if (token) {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp < currentTime) {
          logout();
          return;
        }
        setAuthToken(token);
        const res = await axios.get(GET_USER_INFO_API_URL);
        const data = res?.data?.user;
        setUserInfo(data);
        setAddedUsers(res?.data?.addedUsers);
      }
    } catch (error) {
      console.error("Error fetching user info:", error);
    }
  };

  const getClients = async (token) => {
    try {
      if (token) {
        setAuthToken(token);
        const res = await axios.get(GET_CLIENTS_API_URL);
        const data = res?.data?.clients;
        setClients(data);
      }
    } catch (error) {
      message.error(error?.response?.data?.error);
    }
  };

  const getCredits = async (token) => {
    try {
      if (token) {
        setAuthToken(token);
        const res = await axios.get(GET_CREDITS_API_URL);
        const data = res?.data?.credits;
        setCredits(data);
      }
    } catch (error) {
      message.error(error?.response?.data?.error);
    }
  };

  const updateUser = (data) => {
    const { email, password, imageUrl } = data;

    if (email && !validateEmail(email)) {
      message.error("Please enter a valid email address.");
      return;
    }

    if (password && !validatePassword(password)) {
      message.error("Password must be at least 8 characters.");
      return;
    }
    if (password && data.password !== confirmPassword) {
      message.error("Passwords do not match");
      return;
    }
    setLoading(true);
    axios
      .patch(UPDATE_USER_INFO_API_URL, data)
      .then((res) => {
        message.success(
          imageUrl
            ? "Your Profile Image Uploaded Successfully."
            : res.data.message
        );
        fetchUserInfo(token);
        setTimeout(() => {
          const cancelButton = document.querySelector(".btn-light");
          if (cancelButton) {
            cancelButton.click();
          }
          setLoading(false);
        }, 500);
        setUpdateUserInfo({});
        setConfirmPassword("");
      })
      .catch((err) => {
        setLoading(false);
        message.error(
          imageUrl
            ? "Error uploading image, please try again!"
            : err.response.data.error
        );
        console.log(err);
      });
  };

  const updateAUser = (updateAltUser, sUid) => {
    const { email, password, image } = updateAltUser;

    if (email && !validateEmail(email)) {
      message.error("Please enter a valid email address.");
      return;
    }

    if (password && !validatePassword(password)) {
      message.error("Password must be at least 8 characters.");
      return;
    }

    const formData = new FormData();

    for (const key in updateAltUser) {
      if (key === "image") {
        if (Array.isArray(image) && image.length === 1) {
          formData.append("images", image[0]);
        } else {
          message.error("Please provide a single image file.");
          return;
        }
      } else {
        formData.append(key, updateAltUser[key]);
      }
    }
    setLoading(true);
    axios
      .patch(`${UPDATE_ADMIN_USER_API_URL}/${sUid}`, formData)
      .then((res) => {
        message.success(res.data.message);
        setTimeout(() => {
          const cancelButton = document.querySelector(
            "#editUserModal .btn-light"
          );
          if (cancelButton) {
            cancelButton.click();
          }
          setLoading(false);
          getAdminUsers(token);
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.response.data.error);
      });
  };

  const updateClient = (updateAltUser, sUid) => {
    const { email, password, image } = updateAltUser;

    if (email && !validateEmail(email)) {
      message.error("Please enter a valid email address.");
      return;
    }

    if (password && !validatePassword(password)) {
      message.error("Password must be at least 8 characters.");
      return;
    }

    const formData = new FormData();

    for (const key in updateAltUser) {
      if (key === "image") {
        if (Array.isArray(image) && image.length === 1) {
          formData.append("images", image[0]);
        } else {
          message.error("Please provide a single image file.");
          return;
        }
      } else {
        formData.append(key, updateAltUser[key]);
      }
    }
    setLoading(true);
    axios
      .patch(`${UPDATE_CLIENT_API_URL}/${sUid}`, formData)
      .then((res) => {
        message.success(res.data.message);
        setTimeout(() => {
          const cancelButton = document.querySelector(
            "#editUserModal .btn-light"
          );
          if (cancelButton) {
            cancelButton.click();
          }
          setLoading(false);
          getClients(token);
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.response.data.error);
      });
  };

  const logout = () => {
    setAuthToken();
    localStorage.removeItem("token");
    navigate("/");
  };

  const getAdminUsers = async (token) => {
    try {
      if (token) {
        setAuthToken(token);
        const res = await axios.get(GET_ADMIN_USERS_API_URL);
        setAdminUsers(res?.data?.adminUsers);
      }
    } catch (err) {
      message.error(err?.response?.data?.error);
    }
  };

  const cancelUpdate = () => {
    fetchUserInfo(token);
    setUpdateUserInfo({});
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        loading,
        setLoading,
        register,
        addUser,
        confirmPassword,
        setConfirmPassword,
        userInfo,
        addedUsers,
        fetchUserInfo,
        updateUser,
        logout,
        updateUserInfo,
        setUpdateUserInfo,
        cancelUpdate,
        addTeamMember,
        setAddTeamMember,
        updateCUser,
        getAdminUsers,
        adminUsers,
        updateAUser,
        addAdminUser,
        adminUser,
        setAdminUser,
        clients,
        getClients,
        updateClient,
        credits,
        getCredits,
        redirectUrl,
        setRedirectUrl,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthenticationContext, AuthContext };
