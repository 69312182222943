import React, { useContext, useEffect, useState } from "react";
import { Order } from "../../../context/Order";
import axios from "axios";
import { Tooltip, message } from "antd";
import { ORDER_API_URL, UPLOAD_API_URL } from "../../../config/endPoints";
import { Link, useNavigate } from "react-router-dom";

function SubmitReqDetails() {
  const MAX_ATTACHMENTS = 5;
  const MAX_FILE_SIZE_MB = 50;
  const chunkSize = 500 * 1024;

  const { sOrderId, orderId } = useContext(Order);
  const navigate = useNavigate();

  const [requirements, setRequirements] = useState("");
  const [addnlRequirements, setAddnlRequirements] = useState("");
  const [requirementsFiles, setRequirementsFiles] = useState([]);
  const [addnlRequirementsFiles, setAddnlRequirementsFiles] = useState([]);
  const [termsCheck, setTermsCheck] = useState(false);
  const [currentFileIndex, setCurrentFileIndex] = useState(null);
  const [lastUploadedFileIndex, setLastUploadedFileIndex] = useState(null);
  const [currentChunkIndex, setCurrentChunkIndex] = useState(null);
  const [currentAddFileIndex, setCurrentAddFileIndex] = useState(null);
  const [lastUploadedAddFileIndex, setLastUploadedAddFileIndex] =
    useState(null);
  const [currentAddChunkIndex, setCurrentAddChunkIndex] = useState(null);
  const [uploadedAttachments, setUploadedAttachments] = useState([]);
  const [uploadedAddAttachments, setUploadedAddAttachments] = useState([]);
  const [uploading, setUploading] = useState(false);

  const formData = {
    requirements,
    addnlRequirements,
    attachments: uploadedAttachments,
    addnlAttachments: uploadedAddAttachments,
  };

  const resetSelection = () => {
    setCurrentFileIndex(null);
    setLastUploadedFileIndex(null);
    setCurrentChunkIndex(null);
    setUploadedAttachments([]);
    setRequirementsFiles([]);
  };

  const resetSelect = () => {
    setCurrentAddFileIndex(null);
    setLastUploadedAddFileIndex(null);
    setCurrentAddChunkIndex(null);
    setUploadedAddAttachments([]);
    setAddnlRequirementsFiles([]);
  };

  const handleReqFChange = (e) => {
    const files = e.target.files;
    if (files.length > MAX_ATTACHMENTS) {
      message.error(`You can only attach up to ${MAX_ATTACHMENTS} files.`);
      e.target.value = null;
      return;
    }

    for (let i = 0; i < files.length; i++) {
      if (files[i].size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        message.error(`File size should not exceed ${MAX_FILE_SIZE_MB} MB.`);
        e.target.value = null;
        return;
      }
    }

    setRequirementsFiles(files);
  };

  function readNUploadCurrentChunk() {
    const reader = new FileReader();
    const file = requirementsFiles[currentFileIndex];
    if (!file) {
      return;
    }
    const from = currentChunkIndex * chunkSize;
    const to = from + chunkSize;
    const blob = file.slice(from, to);
    reader.onload = (e) => uploadAChunk(e);
    reader.readAsDataURL(blob);
  }

  function uploadAChunk(readerEvent) {
    const file = requirementsFiles[currentFileIndex];
    const data = readerEvent.target.result;
    const params = new URLSearchParams();
    params.set("name", file.name);
    params.set("size", file.size);
    params.set("currentChunkIndex", currentChunkIndex);
    params.set("totalChunks", Math.ceil(file.size / chunkSize));
    params.set("fileType", "attachments");
    const headers = { "Content-Type": "application/octet-stream" };
    const url = `${UPLOAD_API_URL}?` + params.toString();
    axios.post(url, data, { headers }).then((response) => {
      const file = requirementsFiles[currentFileIndex];
      const filesize = requirementsFiles[currentFileIndex].size;
      const chunks = Math.ceil(filesize / chunkSize) - 1;
      const isLastChunk = currentChunkIndex === chunks;
      if (isLastChunk) {
        file.finalFilename = response.data.finalFilename;

        setLastUploadedFileIndex(currentFileIndex);
        setCurrentChunkIndex(null);
        setUploadedAttachments((prevAttachments) => [
          ...prevAttachments,
          {
            filename: file.name,
            filePath: response.data,
            filesize: filesize,
          },
        ]);
      } else {
        setCurrentChunkIndex(currentChunkIndex + 1);
      }
    });
  }

  useEffect(() => {
    if (lastUploadedFileIndex === null) {
      return;
    }
    const isLastFile = lastUploadedFileIndex === requirementsFiles.length - 1;
    const nextFileIndex = isLastFile ? null : currentFileIndex + 1;
    setCurrentFileIndex(nextFileIndex);
  }, [lastUploadedFileIndex]);

  useEffect(() => {
    if (requirementsFiles.length > 0) {
      if (currentFileIndex === null) {
        setCurrentFileIndex(
          lastUploadedFileIndex === null ? 0 : lastUploadedFileIndex + 1
        );
      }
    }
  }, [requirementsFiles.length]);

  useEffect(() => {
    if (currentFileIndex !== null) {
      setCurrentChunkIndex(0);
    }
  }, [currentFileIndex]);

  useEffect(() => {
    if (currentChunkIndex !== null) {
      readNUploadCurrentChunk();
    }
  }, [currentChunkIndex]);

  const handleAddnlReqFChange = (e) => {
    const files = e.target.files;
    if (files.length > MAX_ATTACHMENTS) {
      message.error(`You can only attach up to ${MAX_ATTACHMENTS} files.`);
      e.target.value = null;
      return;
    }

    for (let i = 0; i < files.length; i++) {
      if (files[i].size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        message.error(`File size should not exceed ${MAX_FILE_SIZE_MB} MB.`);
        e.target.value = null;
        return;
      }
    }

    setAddnlRequirementsFiles(files);
  };

  function readAndUploadCurrentChunk() {
    const reader = new FileReader();
    const file = addnlRequirementsFiles[currentAddFileIndex];
    if (!file) {
      return;
    }
    const from = currentAddChunkIndex * chunkSize;
    const to = from + chunkSize;
    const blob = file.slice(from, to);
    reader.onload = (e) => uploadChunk(e);
    reader.readAsDataURL(blob);
  }

  function uploadChunk(readerEvent) {
    const file = addnlRequirementsFiles[currentAddFileIndex];
    const data = readerEvent.target.result;
    const params = new URLSearchParams();
    params.set("name", file.name);
    params.set("size", file.size);
    params.set("currentChunkIndex", currentAddChunkIndex);
    params.set("totalChunks", Math.ceil(file.size / chunkSize));
    params.set("fileType", "addnlattachments");
    const headers = { "Content-Type": "application/octet-stream" };
    const url = `${UPLOAD_API_URL}?` + params.toString();
    axios.post(url, data, { headers }).then((response) => {
      const file = addnlRequirementsFiles[currentAddFileIndex];
      const filesize = addnlRequirementsFiles[currentAddFileIndex].size;
      const chunks = Math.ceil(filesize / chunkSize) - 1;
      const isLastChunk = currentAddChunkIndex === chunks;
      if (isLastChunk) {
        file.finalFilename = response.data.finalFilename;
        setLastUploadedAddFileIndex(currentAddFileIndex);
        setCurrentAddChunkIndex(null);
        setUploadedAddAttachments((prevAttachments) => [
          ...prevAttachments,
          {
            filename: file.name,
            filePath: response.data,
            filesize: filesize,
          },
        ]);
      } else {
        setCurrentAddChunkIndex(currentAddChunkIndex + 1);
      }
    });
  }

  useEffect(() => {
    if (lastUploadedAddFileIndex === null) {
      return;
    }
    const isLastFile =
      lastUploadedAddFileIndex === addnlRequirementsFiles.length - 1;
    const nextFileIndex = isLastFile ? null : currentAddFileIndex + 1;
    setCurrentAddFileIndex(nextFileIndex);
  }, [lastUploadedAddFileIndex]);

  useEffect(() => {
    if (addnlRequirementsFiles.length > 0) {
      if (currentAddFileIndex === null) {
        setCurrentAddFileIndex(
          lastUploadedAddFileIndex === null ? 0 : lastUploadedAddFileIndex + 1
        );
      }
    }
  }, [addnlRequirementsFiles.length]);

  useEffect(() => {
    if (currentAddFileIndex !== null) {
      setCurrentAddChunkIndex(0);
    }
  }, [currentAddFileIndex]);

  useEffect(() => {
    if (currentAddChunkIndex !== null) {
      readAndUploadCurrentChunk();
    }
  }, [currentAddChunkIndex]);

  useEffect(() => {
    if (requirementsFiles?.length > 0 && currentFileIndex !== null) {
      setUploading(true);
    } else {
      setUploading(false);
    }
  }, [requirementsFiles, currentFileIndex]);

  useEffect(() => {
    if (addnlRequirementsFiles?.length > 0 && currentAddFileIndex !== null) {
      setUploading(true);
    } else {
      setUploading(false);
    }
  }, [addnlRequirementsFiles, currentAddFileIndex]);

  const reqOrderId = sOrderId ? sOrderId : orderId;

  const handleSubmit = async () => {
    if (!requirements.trim()) {
      message.error("Please add your requirements details to continue.");
      return;
    }

    if (!termsCheck) {
      message.error(
        "Please confirm accuracy by checking the box before proceeding."
      );
      return;
    }

    try {
      const res = await axios.put(`${ORDER_API_URL}/${reqOrderId}`, formData);
      if (res.status === 200) {
        navigate("/order-details");
        message.success(res?.data?.message);
      }
    } catch (err) {
      message.error(err?.response?.data?.error);
      console.error("Error updating order:", err);
    }
  };

  const handleTermsCheck = () => {
    setTermsCheck((prevState) => !prevState);
  };

  return (
    <>
      <div className="col-lg-8 col-md-7">
        <div className="page-card rounded-3 position-relative p-3 p-md-4">
          <div className="card-body">
            {/* <p className="small">Until we provide this information</p> */}
            <h4 className="h5 fw-semibold mb-3 d-flex align-items-lg-center">
              <span className="icon d-inline-flex me-1 pe-1 primary-text">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={20}
                  height={20}
                  fill="currentColor"
                  className="bi bi-card-text"
                  viewBox="0 0 16 16"
                >
                  <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                  <path d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z" />
                </svg>
              </span>
              Please Provide Your Order Details and Requirements to Start the
              Order
            </h4>
            {/* <p>
              The seller needs the following information to start working on
              your order :
            </p> */}
            <div className="form-wrapepr mb-3 pb-2">
              <label className="form-label">
                <p className="mb-0 small">Requirements</p>
              </label>
              <div className="form-text-area border rounded-3">
                <div className="form-label-group mb-0">
                  <textarea
                    className="form-control border-0"
                    id="exampleFormControlTextarea3"
                    rows={6}
                    defaultValue={""}
                    value={requirements}
                    onChange={(e) => setRequirements(e.target.value)}
                  />
                </div>
                <div className="form-footer border-top px-2 py-1 d-flex justify-content-between align-items-center">
                  <small className="counter-value-text text-secondary small opacity-75">
                    {/* <span className="char-count">0</span> / 1500{" "} */}
                  </small>
                  <div className="file-attachment">
                    <span
                      className={`attachment-numb ${
                        requirementsFiles.length > 0
                          ? "secondary-text"
                          : "text-secondary"
                      } small`}
                    >
                      {requirementsFiles?.length > 0 &&
                      currentFileIndex !== null
                        ? `Uploading ${currentFileIndex + 1}/${
                            requirementsFiles?.length
                          } (${Math.round(
                            (currentChunkIndex /
                              Math.ceil(
                                requirementsFiles[currentFileIndex]?.size /
                                  chunkSize
                              )) *
                              100
                          )}%)`
                        : requirementsFiles?.length > 0
                        ? `${requirementsFiles?.length} ${
                            requirementsFiles?.length === 1 ? "File" : "Files"
                          } Attached`
                        : "No File Chosen"}
                    </span>
                    {requirementsFiles?.length > 0 &&
                    currentFileIndex === null ? (
                      <button className="btn text-secondary p-1 pe-0 opacity-75 ms-2">
                        <Tooltip title="Reset Selection" mouseEnterDelay={0.1}>
                          <img
                            src="/assets/images/x.svg"
                            alt="X Icon"
                            width="20"
                            height="20"
                            onClick={resetSelection}
                          />
                        </Tooltip>
                      </button>
                    ) : (
                      <button className="btn text-secondary p-1 pe-0 opacity-75 ms-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={20}
                          height={20}
                          fill="currentColor"
                          className="bi bi-paperclip"
                          viewBox="0 0 16 16"
                        >
                          <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                        </svg>
                        <Tooltip title="Max 50MB/File" mouseEnterDelay={0.1}>
                          <input
                            type="file"
                            name="file"
                            multiple
                            onChange={handleReqFChange}
                          />
                        </Tooltip>
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {/*.form-text-area*/}
            </div>
            {/*.form-wrapepr*/}
            <div className="form-wrapepr mb-3 pb-2">
              <label className="form-label">
                <p className="mb-0 small">Additional Requirements</p>
              </label>
              <div className="form-text-area border rounded-3">
                <div className="form-label-group mb-0">
                  <textarea
                    className="form-control border-0"
                    id="exampleFormControlTextarea3"
                    rows={6}
                    defaultValue={""}
                    value={addnlRequirements}
                    onChange={(e) => setAddnlRequirements(e.target.value)}
                  />
                </div>
                <div className="form-footer border-top px-2 py-1 d-flex justify-content-between align-items-center">
                  <small className="counter-value-text text-secondary small opacity-75">
                    {/* <span className="char-count">0</span> / 1500{" "} */}
                  </small>
                  <div className="file-attachment">
                    <span
                      className={`attachment-numb ${
                        addnlRequirementsFiles.length > 0
                          ? "secondary-text"
                          : "text-secondary"
                      } small`}
                    >
                      {addnlRequirementsFiles?.length > 0 &&
                      currentAddFileIndex !== null
                        ? `Uploading ${currentAddFileIndex + 1}/${
                            addnlRequirementsFiles?.length
                          } (${Math.round(
                            (currentAddChunkIndex /
                              Math.ceil(
                                addnlRequirementsFiles[currentAddFileIndex]
                                  ?.size / chunkSize
                              )) *
                              100
                          )}%)`
                        : addnlRequirementsFiles?.length > 0
                        ? `${addnlRequirementsFiles?.length} ${
                            addnlRequirementsFiles?.length === 1
                              ? "File"
                              : "Files"
                          } Attached`
                        : "No File Chosen"}
                    </span>
                    {addnlRequirementsFiles?.length > 0 &&
                    currentAddFileIndex === null ? (
                      <button className="btn text-secondary p-1 pe-0 opacity-75 ms-2">
                        <Tooltip title="Reset Selection" mouseEnterDelay={0.1}>
                          <img
                            src="/assets/images/x.svg"
                            alt="X Icon"
                            width="20"
                            height="20"
                            onClick={resetSelect}
                          />
                        </Tooltip>
                      </button>
                    ) : (
                      <button className="btn text-secondary p-1 pe-0 opacity-75 ms-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={20}
                          height={20}
                          fill="currentColor"
                          className="bi bi-paperclip"
                          viewBox="0 0 16 16"
                        >
                          <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                        </svg>
                        <Tooltip title="Max 50MB/File" mouseEnterDelay={0.1}>
                          <input
                            type="file"
                            name="file"
                            multiple
                            onChange={handleAddnlReqFChange}
                          />
                        </Tooltip>
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {/*.form-text-area*/}
            </div>
            {/*.form-wrapepr*/}
            <div className="form-check pe-lg-5 me-lg-5">
              <input
                className="form-check-input"
                type="checkbox"
                id="remember"
                onClick={handleTermsCheck}
                style={{ cursor: "pointer" }}
              />
              <label
                className="form-check-label text-secondary"
                htmlFor="remember"
              >
                <small>
                  I confirm the accuracy and completeness of the information
                  provided. Any modifications require approval from AAMAX and
                  may incur additional costs.
                </small>
              </label>
            </div>
            {/*.form-check*/}
            <div className="btn-wrapper mt-4 pt-lg-3 text-end">
              <Link
                to="/dashboard"
                className="primary-action me-2 opacity-75 text-decoration-none small"
              >
                Submit Later
              </Link>
              <button
                type="submit"
                className="btn primary-btn fw-semibold fs-6 px-4 py-2"
                onClick={handleSubmit}
                disabled={uploading}
              >
                {uploading && (
                  <span className="spinner-grow spinner-grow-sm me-2"></span>
                )}

                {uploading ? "Uploading..." : "Start Order"}
              </button>
            </div>
          </div>
          {/*.card-body*/}
        </div>
        {/*.page-card*/}
      </div>
    </>
  );
}

export default SubmitReqDetails;
