import React, { useEffect } from "react";
import Footer from "../../components/layout/Footer";
import ClientHeader from "../../components/layout/client/ClientHeader";
import PaymentModal from "../../components/client/placeOrderPayment/PaymentModal";
import MOPMainHeading from "../../components/client/makeOverduePayment/POPMainHeading";
import TableMainH from "../../components/client/makeOverduePayment/TableMainHeading";
import OrderDetailnPayment from "../../components/client/makeOverduePayment/OrderDetailAndPayment";
import { Helmet } from "react-helmet";

function MakeOverduePayment() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Overdue Order Summary – AAMAX</title>
      </Helmet>
      {/*=============== Start AAMAX OMS  ===============*/}

      <ClientHeader>
        {/*.header*/}
        <main className="page-content d-flex flex-column justify-content-between h-100">
          <div className="container-fluid p-3 p-md-4">
            <MOPMainHeading />
            {/*.row*/}
            <div className="row">
              <div className="col-12">
                <div className="page-card rounded-3 position-relative p-3 p-md-4">
                  <div className="card-body">
                    <TableMainH />
                    {/*.table-responsive*/}
                    <OrderDetailnPayment />
                    {/*.row*/}
                    {/* Card Payment Modal */}
                    {/* <PaymentModal /> */}
                    {/*.card Payment Modal*/}
                  </div>
                  {/*.card-body*/}
                  {/*.card-footer*/}
                </div>
                {/*.page-card*/}
              </div>
              {/*.col-grid*/}
            </div>
            {/*.row*/}
          </div>
          {/*.container-fluid*/}
          <Footer />
        </main>
      </ClientHeader>
      {/*.page-content"*/}
      {/*=============== End AAMAX OMS  ===============*/}
    </>
  );
}

export default MakeOverduePayment;
