import React from "react";
import { Link } from "react-router-dom";

function WhyChooseUs({ data }) {
  return (
    <>
      <section className="work-process-sec py-4 py-md-5">
        <div className="row align-items-center justify-content-center">
          <div className="col-xl-7 col-lg-9">
            <div className="section-title-wrapper text-center">
              <h2 className="section-title fs-2 fw-semibold mb-md-4 mb-3">
                Our{" "}
                <span className="title-highlighted">
                  Services
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 500 150"
                    className="highlighted-svg"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M15.2,133.3L15.2,133.3c121.9-7.6,244-9.9,366.1-6.8c34.6,0.9,69.1,2.3,103.7,4"
                      style={{ animationPlayState: "running" }}
                    />
                  </svg>
                </span>{" "}
              </h2>
            </div>
            {/*.-section-title*/}
          </div>
          {/*.col-grid*/}
        </div>
        {/*.row*/}
        <div className="row">
          {data?.attributes?.whyChooseUs?.map((wcu, i) => (
            <div className="col-lg-4 col-sm-6" key={i}>
              <div className="feature-wrapper bg-light rounded-3 text-center p-4 py-4 py-lg-5 mt-3">
                <span className="icon-wrapper shadow-sm m-auto mb-4 fs-4">
                  {i + 1}
                </span>
                <h4 className="fs-5 fw-semibold mb-3">
                  <Link to={wcu?.url} className="s-link-style">
                    {wcu?.heading}
                  </Link>
                </h4>
                <p className="text-muted mb-0">{wcu?.description}</p>
              </div>
              {/*.feature-wrapper*/}
            </div>
          ))}
        </div>
        {/*.row*/}
      </section>
    </>
  );
}

export default WhyChooseUs;
