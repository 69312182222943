import React from "react";
import { Link } from "react-router-dom";

function TNCContent() {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-card rounded-3 position-relative p-3 p-md-4">
            <div className="card-body">
              <article className="col-lg-10">
                <p className="small">
                  Welcome to AAMAX, a Website & Application Development and
                  Digital Marketing Agency. By using our services, you agree to
                  the following terms and conditions:
                </p>
                <h4 className="h6 mt-3">Service Overview</h4>
                <p className="small">
                  AAMAX provides services related to Website & Application
                  Development, Digital Marketing, SEO, Content Writing and
                  Graphic Design to its clients. We are committed to providing
                  high-quality services to our clients in a timely and efficient
                  manner.
                </p>
                <h4 className="h6 mt-3">Prohibited Activities</h4>
                <p className="small">
                  We do not work with companies involved in illegal activities
                  or those associated with businesses such as essay writing,
                  CBD, casinos, gambling, betting, dating, drugs, movies, songs,
                  dances, insurance, mortgages, banking loans, or any other
                  illegal activities. We reserve the right to cancel the order
                  or terminate any agreement with any client who is found to be
                  involved in such activities. If you are unsure if your site
                  violates our restrictions, please contact us first.
                </p>
                <h4 className="h6 mt-3">Project Scope</h4>
                <p className="small">
                  Please review the timeline, cost, and other features of our
                  services before placing an order. Any proposed changes to the
                  project scope or timeline must receive written approval from
                  both parties and may incur additional charges.
                </p>
                <h4 className="h6 mt-3">Payment</h4>
                <p className="small">
                  We require 100% payment in advance through our order
                  management system. For larger or major projects, we can also
                  discuss payment in milestones. We accept payments through
                  various methods, including card payments, PayPal, and bank
                  transfers.
                </p>
                <h4 className="h6 mt-3">Refund Policy</h4>
                <p className="small">
                  You are eligible for a 100% refund if you request to cancel
                  your order within one hour of placing it. This ensures that
                  you have the flexibility to reconsider your decision shortly
                  after making the purchase.
                </p>
                <p className="small">
                  We commit to providing a 100% refund if we cancel your order
                  without initiating any work on it or if we are unable to
                  accept the order due to Prohibited Activities terms. Please
                  note that payment method fees, such as those incurred through
                  PayPal or Stripe, are beyond our control and are not our
                  responsibility.
                </p>
                <p className="small">
                  Once we have commenced work on your order, a full refund may
                  not be possible. However, we value our clients, and our
                  support team is available to discuss your situation. In
                  certain cases, we may consider a 50% refund if the tasks have
                  not been completed.
                </p>
                <p className="small">
                  Credits are non-refundable, but any unused credits will remain
                  available in your account. You have the flexibility to utilize
                  these credits for future services at your convenience.
                </p>
                <h4 className="h6 mt-3">Delivery Date</h4>
                <p className="small">
                  The time elapsed between the placement of your order and the
                  submission of requirements will be factored into the delivery
                  date calculation. Additionally, if payment is made via bank
                  transfer, the duration between order placement and payment
                  verification will also be added to the delivery timeline.
                </p>
                <p className="small">
                  It is important to note that any revision requests made after
                  receiving the initial delivery will result in an automatic
                  extension of the delivery date. Each revision request will
                  contribute an additional 2 days to the original delivery date,
                  allowing our team the necessary time to implement the
                  requested changes thoroughly.
                </p>
                <h4 className="h6 mt-3">Images & Content</h4>
                <p className="small">
                  The client is responsible for providing all necessary images
                  and content for their website or application. If the client
                  requires assistance in obtaining content and images, they may
                  request our services for an additional fee. The cost for
                  providing content and images will be determined based on the
                  specific requirements and details of the work. The client will
                  be informed of the additional amount before any work is
                  undertaken.
                </p>
                <h4 className="h6 mt-3">Confidentiality</h4>
                <p className="small">
                  We respect the confidentiality of our clients and their
                  projects. All information provided to us will be kept
                  confidential and will not be shared with any third party.
                </p>
                <h4 className="h6 mt-3">Intellectual Property</h4>
                <p className="small">
                  All intellectual property created during the project belongs
                  to the client. We will provide the client with all necessary
                  files and documents upon completion of the project.
                </p>
                <h4 className="h6 mt-3">Copyright Developed by Notice</h4>
                <p className="small">
                  Our company link will be added to the copyright message in the
                  footer, which will state that the site was developed by AAMAX.
                </p>
                <h4 className="h6 mt-3">Emails</h4>
                <p className="small">
                  By joining, you agree to receive emails from AAMAX, which
                  could be in the form of order notifications, system updates,
                  new services, offers or educational material.
                </p>
                <h4 className="h6 mt-3">Limitation of Liability</h4>
                <p className="small">
                  AAMAX is not responsible for any loss or damage caused by the
                  client's use of the services provided by us. Our liability is
                  limited to the amount paid by the client for our services.
                </p>
                <h4 className="h6 mt-3">Changes to Terms and Conditions</h4>
                <p className="small">
                  We reserve the right to make changes to these terms and
                  conditions at any time. Any changes will be posted on our
                  website and will be effective immediately upon posting. By
                  using our services, you agree to these terms and conditions.
                  If you have any questions or concerns, please{" "}
                  <Link to="/contact-support">contact us</Link>.
                </p>
              </article>
            </div>
            {/*.card-body*/}
          </div>
          {/*.page-card*/}
        </div>
        {/*.col-grid*/}
      </div>
    </>
  );
}

export default TNCContent;
