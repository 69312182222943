import React, { useContext, useEffect, useState } from "react";
import { Order } from "../../../context/Order";
import { SearchCtx } from "../../../context/SearchContext";
import {
  constructImageUrl,
  formatCurrency,
  formatFileSize,
  getFileIcon,
  getStatusColor,
  truncateFileName,
} from "../../../utils/helpers";
import {
  APPROVE_CREDIT_API_URL,
  BACKEND_URL,
  UNVERIFY_CREDIT_API_URL,
} from "../../../config/endPoints";
import { UtilsCtx } from "../../../context/UtilsContext";
import { Dropdown, Menu, Popconfirm, message } from "antd";
import axios from "axios";

function VCTable() {
  const { VerifCredits, getVerifyingCredits } = useContext(Order);
  const { searchOrder } = useContext(SearchCtx);
  const { handleDownloadAttachment } = useContext(UtilsCtx);
  const [selectedOption, setSelectedOption] = useState(null);
  const [creditId, setCreditId] = useState("");

  const paramsData = {
    rejectionReason: selectedOption,
  };

  const options = [
    "Payment Not Received",
    "Banking Issues",
    "Refunded",
    "Incorrect Payment Screenshot",
    "Incomplete Transaction",
  ];

  const filteredCredits = VerifCredits?.filter(
    (credit) =>
      credit?.user?.firstName
        ?.toLowerCase()
        .includes(searchOrder.toLowerCase()) ||
      credit?.user?.lastName
        ?.toLowerCase()
        .includes(searchOrder.toLowerCase()) ||
      credit?.user?.email?.toLowerCase().includes(searchOrder.toLowerCase())
  );

  const confirm = (id) => {
    axios
      .put(`${APPROVE_CREDIT_API_URL}/${id}`)
      .then(() => {
        getVerifyingCredits();
        message.success("Credit approved successfully.");
      })
      .catch((err) => {
        message.error(err?.response?.data?.error);
      });
  };

  const handleMenuClick = (e) => {
    setSelectedOption(e.key);

    setTimeout(() => {
      axios
        .put(`${UNVERIFY_CREDIT_API_URL}/${creditId}`, paramsData)
        .then(() => {
          getVerifyingCredits();
          message.success("Credit unverified successfully.");
        })
        .catch((err) => {
          message.error(err?.response?.data?.error);
        });
    }, 700);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {options.map((option) => (
        <Menu.Item key={option}>{option}</Menu.Item>
      ))}
    </Menu>
  );

  useEffect(() => {
    getVerifyingCredits();
  }, []);

  return (
    <>
      <div className="card-body pt-4">
        <div className="table-responsive">
          <table className="table table-hover emp-attend-list">
            <thead>
              <tr>
                <th scope="col">Image</th>
                <th scope="col">Name</th>
                <th scope="col">Company</th>
                <th scope="col">Email</th>
                <th scope="col">Phone Number</th>
                <th scope="col">Credit</th>
                <th scope="col">Verifying Amount</th>
                <th scope="col">Status</th>
                <th scope="col">Payment Proof</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredCredits?.map((credit, i) => (
                <tr key={i}>
                  <td>
                    {credit?.user?.imageUrl ? (
                      <img
                        className="table-img"
                        src={constructImageUrl(credit?.user?.imageUrl)}
                      />
                    ) : (
                      <div className="user-avator-order secondary-bg rounded-circle d-flex align-items-center justify-content-center text-uppercase fw-semibold text-white me-2">
                        <span className="name-txt">
                          {`${
                            credit?.user?.firstName
                              ? credit?.user?.firstName?.charAt(0).toUpperCase()
                              : ""
                          }${
                            credit?.user?.lastName
                              ? credit?.user?.lastName.charAt(0).toUpperCase()
                              : ""
                          }`}
                        </span>
                      </div>
                    )}
                  </td>
                  <td>{`${credit?.user?.firstName} ${credit?.user?.lastName}`}</td>
                  <td>{credit?.user?.company ? credit?.user?.company : "–"}</td>
                  <td>{credit?.user?.email}</td>
                  <td>{credit?.user?.phone ? credit?.user?.phone : "–"}</td>

                  <td>{formatCurrency(credit?.credit)}</td>
                  <td>{formatCurrency(credit?.verifyingAmount)}</td>

                  <td>
                    <span
                      className={`d-inline-block badge ${getStatusColor(
                        credit?.creditStatus
                      )}`}
                    >
                      {credit?.creditStatus}
                    </span>
                  </td>
                  <td>
                    <div className="attachment-wrapper pt-2">
                      <div className="attach-thumb-wrapper d-flex flex-wrap align-items-center gap-3">
                        <div className="attach-thumb-item bg-light rounded-1 overflow-hidden">
                          <figure className="mb-0 bg-light d-flex align-items-center justify-content-center">
                            <i
                              className={getFileIcon(
                                `${BACKEND_URL}/${credit?.paymentProof?.attachment[0]?.filePath}`
                              )}
                            />
                          </figure>
                          <figcaption className="d-flex justify-content-between align-items-center px-3">
                            <span className="small">
                              {truncateFileName(
                                credit?.paymentProof?.attachment[0]?.filename,
                                10
                              )}{" "}
                              (
                              {formatFileSize(
                                credit?.paymentProof?.attachment[0]?.filesize
                              )}
                              )
                            </span>
                            <span className="icons-wrapper">
                              <span
                                className="icon file-download secondary-text mx-2 opacity-75"
                                onClick={() =>
                                  handleDownloadAttachment(
                                    credit?.paymentProof?.attachment[0]
                                      ?.filename,
                                    credit?.paymentProof?.attachment[0]
                                      ?.filePath
                                  )
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={16}
                                  height={16}
                                  fill="currentColor"
                                  className="bi bi-download"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                </svg>
                              </span>
                            </span>
                          </figcaption>
                        </div>

                        {/*.attach-thumb-item rounded-1 overflow-hidden*/}
                      </div>
                      {/*.attach-thumb-wrapper*/}
                    </div>
                  </td>

                  <td>
                    <Popconfirm
                      title="Confirm Approve"
                      description="Are you sure you want to approve this credit?"
                      onConfirm={() => confirm(credit?._id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <button className="tb-action edit">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-check-square"
                          viewBox="0 0 16 16"
                        >
                          <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                          <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                        </svg>
                      </button>
                    </Popconfirm>
                    <Dropdown overlay={menu} trigger={["click"]}>
                      <button className="tb-action edit">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-x-square"
                          viewBox="0 0 16 16"
                          onClick={() => setCreditId(credit?._id)}
                        >
                          <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                        </svg>
                      </button>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/*.edit-user-modal*/}
        </div>
        {/*.table-responsive*/}
      </div>
    </>
  );
}

export default VCTable;
