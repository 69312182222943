import React from "react";

function SRMainHeading() {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-header py-2 mb-lg-2">
            <div
              className="alert alert-success d-flex align-items-center mb-0"
              role="alert"
            >
              <div className="alert-icon d-inline-flex me-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={18}
                  height={18}
                  fill="currentColor"
                  className="bi bi-check-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                </svg>
              </div>
              <div className="alert-text fw-semibold">
                Thanks for your order with AAMAX
              </div>
            </div>
          </div>
          {/*.page-header*/}
        </div>
        {/*.col-grid*/}
      </div>
    </>
  );
}

export default SRMainHeading;
